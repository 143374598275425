import { gray } from 'bold-ui/lib/styles/colors'
import CheckPermission from 'components/auth/CheckPermission'
import antecedentesIcon from 'images/soap/ic_antecedentes.svg'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { Problema } from '../aside/types/ProblemaModel'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model'
import { SoapSection } from '../SoapSection'
import { grupoCboAntecedentes } from './acessos'
import { AntecedentesForm, AntecedentesFormModel } from './AntecedentesForm'

export interface AntecedentesSectionProps {
  name: MetaPath<AntecedentesFormModel>
  dataAtualizacaoObstetricos: Instant
  dataAtualizacaoPuericultura: Instant
  problemasAvaliados: ProblemaCondicaoModel[]
  problemasResolvidos: Problema[]
}

export function AntecedentesSection(props: AntecedentesSectionProps) {
  return (
    <CheckPermission permission={grupoCboAntecedentes}>
      <SoapSection initialImage={antecedentesIcon} altText='Antecedentes' title='Antecedentes' color={gray}>
        <AntecedentesForm {...props} />
      </SoapSection>
    </CheckPermission>
  )
}
