import useFirebase from 'components/firebase/useFirebase'
import { PrivateRoute } from 'components/route'
import { useResultadosExamesCardQuery } from 'graphql/hooks.generated'
import { orderBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util'
import {
  getValueOfDataRealizacao,
  historicoResultadoExamesUrl,
  HistoricoResultadosExamesModal,
} from 'view/atendimentos/detail/components/modals/resultados-exames/HistoricoResultadosExamesModal'

import { grupoCboResultadosExames } from '../../objetivo/acessos'
import { ResultadoExameQueryModel } from '../../objetivo/resultados-exames/model'
import { ProntuarioCard } from '../ProntuarioCard'
import { ResultadosExamesCard } from './ResultadosExamesCard'

interface ResultadosExamesSectionProps {
  prontuarioId: ID
  atendimentoProfissionalId?: ID
  isAtendimentoObservacao?: boolean
}

export function ResultadosExamesSection(props: ResultadosExamesSectionProps) {
  const { prontuarioId, atendimentoProfissionalId, isAtendimentoObservacao = false } = props
  const { analytics } = useFirebase()

  const history = useHistory()
  const match = useRouteMatch()
  const [canRender, setCanRender] = useState(false)

  const modalUrl = `${match.url}${historicoResultadoExamesUrl}`
  const handleClick = () => {
    analytics.logEvent('historico_exm_lateral_atend')
    history.push(modalUrl)
  }

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const {
    data: { resultadosExame },
    loading,
  } = useResultadosExamesCardQuery({
    variables: { input: { prontuarioId, editAtendProfId, pageParams: { fetchPageInfo: false, size: 2000 } } },
  })

  const filteredResultado = useMemo(
    () => filterResultadosForCard(resultadosExame?.content as ResultadoExameQueryModel[]),
    [resultadosExame]
  )

  useEffect(() => {
    setCanRender(filteredResultado?.length > 0)
  }, [filteredResultado])

  return (
    <>
      <PrivateRoute
        path={modalUrl}
        permission={grupoCboResultadosExames.visualizar}
        render={() => <HistoricoResultadosExamesModal prontuarioId={prontuarioId} editAtendProfId={editAtendProfId} />}
      />
      <ProntuarioCard
        title='Resultados de exames'
        subTitle={canRender && 'Últimas 3 avaliações'}
        tooltip={canRender ? 'Ver histórico de resultados' : 'Nenhum resultado registrado'}
        onClick={handleClick}
      >
        <ResultadosExamesCard filteredResultado={filteredResultado} loading={loading} canRender={canRender} />
      </ProntuarioCard>
    </>
  )
}

const filterResultadosForCard = (resultados: ResultadoExameQueryModel[]) => {
  if (!resultados) return null

  const conferidos = resultados.filter((item: ResultadoExameQueryModel) => !!item.conferido)

  const ordered = orderBy(
    conferidos,
    [(item) => getValueOfDataRealizacao(item), (item) => item.atendimentoProfissional.iniciadoEm],
    ['desc', 'desc']
  )

  return ordered.slice(0, 3)
}
