import { HFlow, SelectHelperMenuItem, Text, VFlow } from 'bold-ui'
import locale from 'bold-ui/lib/i18n/locales/pt-BR'
import { AsyncSelectQueryFieldProps, SelectField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { MedicamentoCatmatSelectFieldDocument } from 'graphql/hooks.generated'
import { MedicamentoCatmatSelectFieldQuery, MedicamentoCatmatSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type MedicamentoCatmatSelectModel = MedicamentoCatmatSelectFieldQuery['medicamentosCatmat']['content'][0]
export interface MedicamentoCatmatSelectFieldProps
  extends AsyncSelectQueryFieldProps<
    MedicamentoCatmatSelectModel,
    MedicamentoCatmatSelectFieldQuery,
    MedicamentoCatmatSelectFieldQueryVariables
  > {}

export function MedicamentoCatmatSelectField(props: MedicamentoCatmatSelectFieldProps) {
  const extractItems = (data: MedicamentoCatmatSelectFieldQuery) => {
    return data?.medicamentosCatmat?.content
  }

  const handleItemToString = (item: MedicamentoCatmatSelectModel) => {
    return item?.medicamento?.principioAtivo ? `${item.medicamento.principioAtivo}` : ''
  }

  const handleRenderItem = (item: MedicamentoCatmatSelectModel) => {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>
          {`${item.medicamento.principioAtivo} ${item.medicamento.concentracao ?? ''}`.trim()}
        </Text>
        <HFlow hSpacing={0.25}>
          <HLabel title='Forma farmacêutica'>{item.medicamento.formaFarmaceutica.nome}</HLabel>
        </HFlow>
        <HFlow hSpacing={0.25}>
          <HLabel title='Unidade de fornecimento'>{item.medicamento.unidadeFornecimento}</HLabel>
        </HFlow>
      </VFlow>
    )
  }

  const variables = (inputQuery: string): MedicamentoCatmatSelectFieldQueryVariables => ({
    input: {
      query: inputQuery,
      pageParams: {
        ...DEFAULT_SELECT_PAGE_PARAM,
        size: 25,
      },
    },
  })

  const { skipping, ...asyncProps } = useAsyncQuerySelect<
    MedicamentoCatmatSelectModel,
    MedicamentoCatmatSelectFieldQuery,
    MedicamentoCatmatSelectFieldQueryVariables
  >({
    query: MedicamentoCatmatSelectFieldDocument,
    extractItems,
    variables,
    skip: (inputString) => inputString?.trim()?.length < 2,
    debounceTime: 500,
  })

  return (
    <SelectField<MedicamentoCatmatSelectModel>
      itemToString={handleItemToString}
      renderItem={handleRenderItem}
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para buscar um agente da categoria medicamento.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
      }}
      {...asyncProps}
      {...props}
    />
  )
}
