/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Tag, Text, VFlow } from 'bold-ui'
import { gray, pink } from 'bold-ui/lib/styles/colors'
import { HLabel } from 'components/HLabel'
import { format, isSameDay } from 'date-fns'
import {
  useDadosEscutaPreAtendimentoLazyQuery,
  useEscutaPreAtendimentoMaisRecenteLazyQuery,
} from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import { ReactComponent as EscutaInicial } from 'images/historico/icone-escuta-inicial.svg'
import React, { useCallback, useEffect } from 'react'

import HistoricoEscutaInicialProcedimentoTable from '../../../historico/components/accordion/detail/escuta-inicial/HistoricoEscutaInicialProcedimentoTable'
import { recordEscutaHistoricoTagStyle } from '../../../historico/model/historicoModel'

export interface FolhaRostoEscutaInicialPanelProps {
  atendimentoId: ID
  isPreAtendimento: boolean
}

export const FolhaRostoEscutaInicialPanel = ({
  atendimentoId,
  isPreAtendimento,
}: FolhaRostoEscutaInicialPanelProps) => {
  const { getServerTimeNow } = useServerTime()

  const [loadDadosEscutaInicial, { data: dadosEscutaInicial }] = useDadosEscutaPreAtendimentoLazyQuery()
  const executeLoadDadosEscutaInicial = useCallback(
    (escutaInicialId: ID) =>
      loadDadosEscutaInicial({
        variables: {
          input: {
            idAtend: escutaInicialId,
            isOrigemPec: true,
          },
        },
      }),
    [loadDadosEscutaInicial]
  )

  const [
    loadEscutaInicialMaisRecenteId,
    { data: escutaInicialMaisRecenteId },
  ] = useEscutaPreAtendimentoMaisRecenteLazyQuery()
  const executeEscutaInicialMaisRecenteId = useCallback(
    () =>
      loadEscutaInicialMaisRecenteId({
        variables: {
          input: {
            dataAtendimentoProfissional: getServerTimeNow(),
            atendimentoId: atendimentoId,
          },
        },
      }),
    [atendimentoId, getServerTimeNow, loadEscutaInicialMaisRecenteId]
  )

  useEffect(() => executeEscutaInicialMaisRecenteId(), [
    executeEscutaInicialMaisRecenteId,
    loadEscutaInicialMaisRecenteId,
  ])

  useEffect(() => {
    const escutaInicialId = escutaInicialMaisRecenteId?.escutaOrPreAtendimentoOfAtendimento
    if (escutaInicialId) {
      executeLoadDadosEscutaInicial(escutaInicialId)
    }
  }, [escutaInicialMaisRecenteId, executeLoadDadosEscutaInicial, loadEscutaInicialMaisRecenteId])

  const escutaInicial = dadosEscutaInicial?.historicoAtendimentoPec
  const dataRealizacaoEscuta = escutaInicial?.finalizadoEm
  const dataRealizacaoText =
    dataRealizacaoEscuta &&
    `Realizado ${
      isSameDay(dataRealizacaoEscuta, getServerTimeNow())
        ? `hoje às ${format(dataRealizacaoEscuta, 'HH:mm')}`
        : `em ${format(dataRealizacaoEscuta, "dd/MM/yyyy 'às' HH:mm")}`
    } por:`

  return (
    <React.Fragment>
      <div css={styles.containerHeader}>
        <div css={styles.escutaInicialBar} />
        <HFlow alignItems='center' hSpacing={0.5}>
          <EscutaInicial
            css={css`
              height: 1.5rem;
              width: 1.5rem;
            `}
          />
          <Text css={styles.title}>{isPreAtendimento ? 'Pré-atendimento' : 'Escuta inicial'}</Text>
          {!isPreAtendimento && escutaInicial && (
            <Tag style={recordEscutaHistoricoTagStyle[escutaInicial.classificacaoRisco].style}>
              {recordEscutaHistoricoTagStyle[escutaInicial.classificacaoRisco].descricao}
            </Tag>
          )}
        </HFlow>
      </div>
      <div css={styles.containerContent}>
        {!escutaInicial ? (
          <Text>Não foi {isPreAtendimento ? 'realizado Pré-atendimento' : 'realizada Escuta inicial'}.</Text>
        ) : (
          <VFlow
            vSpacing={0.5}
            style={css`
              width: 100%;
            `}
          >
            <HFlow hSpacing={0.5}>
              <Text fontWeight='bold'>Motivo da Consulta:</Text>
              <Text>
                {escutaInicial?.evolucaoSubjetivo?.ciaps?.map(
                  (item) => `${item.ciap.descricao} - ${item.ciap.codigo} (CIAP2)`
                )}
              </Text>
            </HFlow>

            <Text dangerouslySetInnerHTML={{ __html: escutaInicial.evolucaoSubjetivo?.descricao }} />

            {escutaInicial.procedimentosAtendimento && (
              <VFlow vSpacing={0.5}>
                <Text fontWeight='bold'>Intervenções e/ou procedimentos clínicos</Text>
                <HistoricoEscutaInicialProcedimentoTable
                  procedimentos={escutaInicial.procedimentosAtendimento.map((item) => item.procedimento)}
                />
              </VFlow>
            )}

            <HFlow alignItems='center' hSpacing={0.5}>
              <Text>
                <HLabel title={dataRealizacaoText}>
                  {escutaInicial.lotacao.profissional?.nome} | {escutaInicial.lotacao.cbo.nome.capitalize()}
                  {!!escutaInicial.lotacao.equipe && ` | ${escutaInicial.lotacao.equipe.nome}`}
                </HLabel>
              </Text>
            </HFlow>
          </VFlow>
        )}
      </div>
    </React.Fragment>
  )
}

const styles = {
  escutaInicialBar: css`
    background-image: linear-gradient(to top, ${pink.c40}, ${pink.c40});
    width: 0.5rem;
    margin: 0rem 0.5rem 0rem 0rem;
    border-bottom-width: 20px;
  `,
  title: css`
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
  `,
  containerHeader: css`
    border: 1px solid ${gray.c80};
    border-bottom: none;
    min-height: 2.5rem;
    display: flex;
    border-radius: 2;
    background-color: ${gray.c90};
  `,
  containerContent: css`
    border: 1px solid ${gray.c80};
    padding: 0.5rem;
    min-height: 2.5rem;
    display: flex;
    border-radius: 2;
  `,
}
