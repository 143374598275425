import {
  AvaliacaoAlergiaInput,
  AvaliacaoInput,
  AvaliacaoProblemaInput,
  VigilanciaEmSaudeBucalEnum,
  VigilanciaSaudeBucalInput,
} from 'graphql/types.generated'

import { EvolucaoProblema } from '../aside/types/ProblemaModel'
import { convertEncerrarGestacaoFormToInput } from '../pre-natal/converter'
import { AvaliacaoFormModel } from './AvaliacaoForm'
import { convertNecessidadeProteseEnumToInput } from './components/necessidade-protese/converter'
import { NecessidadeProteseEnum } from './components/necessidade-protese/model'
import {
  convertAvaliacaoProblemasECondicoesQueryToForm,
  convertProblemaCondicaoFormToInput,
} from './components/problemas-condicoes/converter'
import { ProblemaCondicaoModel } from './components/problemas-condicoes/model'
import { EvolucaoAvaliacaoQueryModel } from './model'

export const convertAvaliacaoFormToInput = (form?: AvaliacaoFormModel): AvaliacaoInput =>
  form
    ? {
        texto: form.texto,
        problemasCondicoesAvaliadas: convertProblemasCondicoesFormToInput(form.problemasECondicoes),
        alergiasAvaliadas: form.alergias?.map(
          (alergia) =>
            ({
              substanciaId:
                alergia.substanciaCbara?.id ??
                alergia.substanciaImunobiologico?.id ??
                alergia.substanciaMedicamento?.medicamento?.id,
              categoriaSubstancia: alergia.categoriaSubstancia,
              tipoReacao: alergia.tipoReacao,
              alergiaId: alergia.alergiaId,
              criticidade: alergia.criticidade,
              dataInicio: alergia.dataInicio?.data,
              grauCerteza: alergia.grauCerteza,
              manifestacoesIds: alergia.manifestacoes?.map((m) => m.id),
              observacao: alergia.observacao,
              isAvaliacao: alergia.isAvaliacao,
            } as AvaliacaoAlergiaInput)
        ),
        vigilanciaSaudeBucal: convertVigilanciaSaudeBucalToInput(form.tiposVigilancia, form.necessidadeProtese),
        encerrarGestacao: convertEncerrarGestacaoFormToInput(form.encerrarGestacao),
      }
    : null

export const convertAvaliacaoQueryToForm = (
  prevValue: AvaliacaoFormModel,
  {
    evolucaoAvaliacao,
    evolucoesProblema,
  }: { evolucaoAvaliacao: EvolucaoAvaliacaoQueryModel; evolucoesProblema: EvolucaoProblema[] }
): AvaliacaoFormModel => {
  const ciapsCids = evolucaoAvaliacao?.ciapsCids

  return {
    ...prevValue,
    texto: evolucaoAvaliacao?.descricao,
    problemasECondicoes: convertAvaliacaoProblemasECondicoesQueryToForm(evolucoesProblema, ciapsCids),
  }
}

const convertVigilanciaSaudeBucalToInput = (
  tiposVigilancia: VigilanciaEmSaudeBucalEnum[],
  necessidadeProtese: NecessidadeProteseEnum
): VigilanciaSaudeBucalInput => {
  if (tiposVigilancia && necessidadeProtese) {
    return {
      tiposVigilancia,
      necessidadeProtese: convertNecessidadeProteseEnumToInput(necessidadeProtese),
    }
  }

  return null
}

const convertProblemasCondicoesFormToInput = (items: ProblemaCondicaoModel[]): AvaliacaoProblemaInput[] => {
  return items?.map((condicao) => convertProblemaCondicaoFormToInput(condicao)) ?? []
}
