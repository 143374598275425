import { Button, DropdownDivider, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { purple } from 'bold-ui/lib/styles/colors'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import { css } from 'emotion'
import {
  useAtenderMutation,
  useCancelarAusenciaMutation,
  useExcluirAtendimentoMutation,
  useInformarAusenciaMutation,
  useRealizarAtendimentoVacinacaoMutation,
  useRealizarEscutaInicialMutation,
  useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation,
} from 'graphql/hooks.generated'
import { AtendimentoActions, StatusAtendimento, StatusRevisaoAtendimento } from 'graphql/types.generated'
import { ReactComponent as IconRevisao } from 'images/atendimento/icon-revisao.svg'
import React from 'react'
import { useHistory } from 'react-router'
import Permissions from 'types/Permissions'

export interface ListaAtendimentoItemActionsProps {
  actions: AtendimentoActions
  responsavelId: ID
  atendId: ID
  cidadaoId: ID
  prontuarioId: ID
  statusAtendimento: StatusAtendimento
  statusRevisaoAtendimento: StatusRevisaoAtendimento
  isAtendimentoObservacao: boolean
}

export default function ListaAtendimentoItemActions(props: ListaAtendimentoItemActionsProps) {
  const { actions, atendId, responsavelId, cidadaoId, prontuarioId, statusAtendimento, isAtendimentoObservacao } = props
  const pathCidadao = `/cidadao/${cidadaoId}`
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()
  const history = useHistory()
  const alert = useAlert()
  const [atender] = useAtenderMutation()
  const [realizarEscutaInicial] = useRealizarEscutaInicialMutation()
  const [vacinacao] = useRealizarAtendimentoVacinacaoMutation()
  const handleRejection = useErrorHandler()
  const [
    salvarJustificativaAutomaticaVisualizarEscutaInicial,
  ] = useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation()
  const [informarAusencia] = useInformarAusenciaMutation()
  const [cancelarAusencia] = useCancelarAusenciaMutation()
  const [excluir] = useExcluirAtendimentoMutation()

  const onAtenderClick = () => {
    return atender({ variables: { atendimento: atendId } })
      .then((res) => {
        history.push(`/lista-atendimento/atendimento/${atendId}`)
        if (isAtendimentoObservacao) {
          analytics.logEvent('atender_cidadão_OBS')
        }
        return res
      })
      .catch(handleRejection)
  }

  const onAtenderVacinacao = () => {
    return vacinacao({ variables: { atendimento: atendId } })
      .then(() => {
        history.push(`/lista-atendimento/atendimento-vacinacao/${atendId}`)
      })
      .catch(handleRejection)
  }

  const onEscutaInicialClick = () => {
    analytics.logEvent('realizar_escuta_inicial_EI')
    return realizarEscutaInicial({ variables: { atendimento: atendId } })
      .then((res) => {
        history.push(`/lista-atendimento/escuta-inicial/${atendId}`)
        return res
      })
      .catch(handleRejection)
  }

  const onVisualizarProntuarioClick = () => history.push(`${pathCidadao}/folha-rosto`)

  const onInformarAusencia = () => {
    return informarAusencia({ variables: { atendimento: atendId } })
      .then((res) => {
        alert('success', 'Ausência do cidadão informada.')
      })
      .catch(handleRejection)
  }

  const onCancelarAusencia = () => {
    return cancelarAusencia({ variables: { atendimento: atendId } })
      .then((res) => {
        alert('success', 'Cidadão foi reinserido com sucesso.')
      })
      .catch(handleRejection)
  }

  const onGerarDeclaracao = () => {
    return history.push(`/lista-atendimento/declaracaoComparecimento/${atendId}?callbackUrl=lista-atendimento`)
  }

  const onVisualizarAtendimentosDia = () => {
    return history.push(`/lista-atendimento/atendimentoDoDia/${atendId}`)
  }

  const onVisualizarEscutaInicialClick = () => {
    analytics.logEvent('visualizar_escuta_inicial_EI')
    return salvarJustificativaAutomaticaVisualizarEscutaInicial({
      variables: { prontuarioId },
    })
      .then(() => {
        history.push(`/lista-atendimento/visualizar-escuta/${atendId}`)
      })
      .catch(handleRejection)
  }

  const onEditarClick = () => {
    return history.push(`/lista-atendimento/editar/${atendId}`)
  }

  const onExcluirClick = () => {
    confirm({
      title: 'Deseja realmente excluir o registro de atendimento?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        excluir({ variables: { atendimento: atendId } })
          .then((res) => {
            alert('success', 'Registro excluído com sucesso.')
            analytics.logEvent('excluir_cidadao_LA')
          })
          .catch(handleRejection)
      },
    })()
  }

  const isRevisarRetificar = !!actions.revisarRetificar
  const isEscutaInicialEnabled = !!actions.escutaInicial?.enabled
  const isVacinacaoEnabled = !!actions.vacinacao?.enabled
  const isAtenderEnabled = !!actions.atender?.enabled
  const isRevisarRetificarEscutaPreAtendimento = isEscutaInicialEnabled && isRevisarRetificar
  const isRevisarRetificarVacinacao = !isEscutaInicialEnabled && isVacinacaoEnabled && isRevisarRetificar
  const isRevisarRetificarAtendimento =
    !isEscutaInicialEnabled && !isVacinacaoEnabled && isAtenderEnabled && isRevisarRetificar

  return (
    <HFlow hSpacing={0.25} style={{ marginRight: '0.5rem', marginLeft: 'auto' }}>
      {actions.escutaInicial ? (
        isRevisarRetificarEscutaPreAtendimento ? (
          <RevisarRetificarButton
            hint={actions.revisarRetificar.hint}
            disabled={!actions.revisarRetificar.enabled}
            onClick={onEscutaInicialClick}
          />
        ) : (
          <Tooltip text={actions.escutaInicial?.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onEscutaInicialClick}
              disabled={!actions.escutaInicial?.enabled}
            >
              <Icon icon='nurseOutline' />
            </Button>
          </Tooltip>
        )
      ) : (
        <CheckPermission permission={Permissions.visualizarListaDeAtendimento.visualizarEscutaInicial}>
          <Tooltip text={actions.visualizarEscutaInicial?.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onVisualizarEscutaInicialClick}
              disabled={!actions.visualizarEscutaInicial?.enabled}
            >
              <Icon icon='nurseVisualizationOutline' />
            </Button>
          </Tooltip>
        </CheckPermission>
      )}

      {actions.vacinacao &&
        (isRevisarRetificarVacinacao ? (
          <RevisarRetificarButton
            hint={actions.revisarRetificar.hint}
            disabled={!actions.revisarRetificar.enabled}
            onClick={onAtenderVacinacao}
          />
        ) : (
          <Tooltip text={actions.vacinacao.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onAtenderVacinacao}
              disabled={!actions.vacinacao.enabled}
            >
              <Icon icon='needleOutline' />
            </Button>
          </Tooltip>
        ))}

      {!actions.vacinacao &&
        actions.atender &&
        (isRevisarRetificarAtendimento ? (
          <RevisarRetificarButton
            hint={actions.revisarRetificar.hint}
            disabled={!actions.revisarRetificar.enabled}
            onClick={onAtenderClick}
          />
        ) : (
          <Tooltip text={actions.atender.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onAtenderClick}
              disabled={!actions.atender.enabled}
            >
              <Icon icon='multipleUsersOutline' />
            </Button>
          </Tooltip>
        ))}

      <Tooltip text='Mais opções'>
        <DropdownButton icon='dots'>
          {actions.vacinacao && actions.atender && (
            <Tooltip text={!actions.atender.enabled && actions.atender?.hint}>
              <DropdownItem onClick={onAtenderClick} disabled={!actions.atender.enabled}>
                {statusAtendimento === 'EM_ATENDIMENTO' && !actions.vacinacao.enabled && responsavelId === acesso?.id
                  ? 'Continuar atendimento'
                  : 'Realizar atendimento'}
              </DropdownItem>
            </Tooltip>
          )}

          <CheckPermission permission={Permissions.visualizarListaDeAtendimento.informarQueCidadaoNaoAguardou}>
            {actions.cancelarAusencia ? (
              <Tooltip text={actions.cancelarAusencia?.hint}>
                <DropdownItem disabled={!actions.cancelarAusencia?.enabled} onClick={onCancelarAusencia}>
                  Cidadão retornou
                </DropdownItem>
              </Tooltip>
            ) : (
              <Tooltip text={actions.informarAusencia?.hint}>
                <DropdownItem disabled={!actions.informarAusencia?.enabled} onClick={onInformarAusencia}>
                  Cidadão não aguardou
                </DropdownItem>
              </Tooltip>
            )}
          </CheckPermission>

          {actions.declaracaoComparecimento && (
            <DropdownItem disabled={!actions.declaracaoComparecimento.enabled} onClick={onGerarDeclaracao}>
              Gerar declaração de comparecimento
            </DropdownItem>
          )}

          {actions.visualizarProntuario && (
            <DropdownItem disabled={!actions.visualizarProntuario.enabled} onClick={onVisualizarProntuarioClick}>
              Visualizar prontuário
            </DropdownItem>
          )}

          {actions.atendimentosDia && (
            <Tooltip text={actions.atendimentosDia?.hint}>
              <DropdownItem disabled={!actions.atendimentosDia.enabled} onClick={onVisualizarAtendimentosDia}>
                Visualizar atendimentos do dia
              </DropdownItem>
            </Tooltip>
          )}

          {actions.editar && (
            <Tooltip text={actions.editar?.hint}>
              <DropdownItem disabled={!actions.editar.enabled} onClick={onEditarClick}>
                Editar
              </DropdownItem>
            </Tooltip>
          )}

          {actions.excluir && (
            <>
              <DropdownDivider />

              <Tooltip text={actions.excluir?.hint}>
                <DropdownItem type='danger' disabled={!actions.excluir.enabled} onClick={onExcluirClick}>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='trashOutline' />
                    Excluir
                  </HFlow>
                </DropdownItem>
              </Tooltip>
            </>
          )}
        </DropdownButton>
      </Tooltip>
    </HFlow>
  )
}

interface RevisarRetificarButtonProps {
  onClick: () => void
  disabled: boolean
  hint: string
}

const RevisarRetificarButton = ({ onClick, disabled, hint }: RevisarRetificarButtonProps) => (
  <Tooltip text={hint}>
    <Button
      style={css`
        background-color: ${purple.c90};
      `}
      size='small'
      kind='normal'
      skin='ghost'
      onClick={onClick}
      disabled={disabled}
    >
      <IconRevisao
        className={css`
          fill: ${purple.c20};
        `}
      />
    </Button>
  </Tooltip>
)
