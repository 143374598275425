import { HorarioAgendaSelectFieldModel } from 'components/form'
import { Calculation } from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

import { AgendarConsultaSectionModel } from '../AgendarConsultaSection'

export const createAgendarProximaConsultaCalculations = (
  name: MetaRoot<AgendarConsultaSectionModel>
): Calculation[] => [
  {
    field: name.lotacao.absolutePath(),
    updates: {
      [name.data.absolutePath()]: (lotacaoValue: string, allValues: AgendarConsultaSectionModel) =>
        lotacaoValue && allValues.data,
    },
  },
  {
    field: name.data.absolutePath(),
    updates: {
      [name.horario.absolutePath()]: (
        horarioValue: HorarioAgendaSelectFieldModel,
        allValues: AgendarConsultaSectionModel
      ) => horarioValue && allValues.horario,
    },
  },
]
