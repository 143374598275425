import { Cell, Grid, Text } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import { isSameDay } from 'date-fns'
import { css } from 'emotion'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { ListaAtendimentoFilterTagsProps } from 'view/atendimentos/list/filter/ListaAtendimentoFilterTags'

import { listaRegistroTardioStyles } from '../utils/listaRegistroTardioStyles'

export function ListaRegistroTardioFilterTags(props: ListaAtendimentoFilterTagsProps) {
  const { getServerTimeNow } = useServerTime()
  const parsedStartDate = props.filtros.periodo.startDate && new Date(props.filtros.periodo.startDate)
  const parsedEndDate = props.filtros.periodo.endDate && new Date(props.filtros.periodo.endDate)

  const now = getServerTimeNow()

  const statusAtendimentoText = props.filtros.statusAtendimento
    ?.map((item) => listaRegistroTardioStyles[item].descricao)
    .join(', ')

  const statusAtendimento = props.filtros.statusAtendimento?.length > 0 && (
    <FilterItem label='Status do registro:' value={statusAtendimentoText} />
  )

  const startDateText = isSameDay(parsedStartDate, now) ? 'hoje' : dateAsDdMmYyyy(parsedStartDate)

  const endDateText = isSameDay(parsedEndDate, now) ? 'hoje' : dateAsDdMmYyyy(parsedEndDate)

  const periodoStartAndEndDateIsSameAndToday =
    isSameDay(parsedStartDate, parsedEndDate) && isSameDay(parsedStartDate, now)

  const periodoText = !periodoStartAndEndDateIsSameAndToday
    ? props.filtros?.periodo?.startDate &&
      props.filtros?.periodo?.endDate &&
      `${startDateText} até ${endDateText}`.capitalize()
    : 'Hoje'

  const periodo = !props.filtros.somenteNaoFinalizados &&
    props.filtros.periodo?.startDate &&
    props.filtros.periodo?.endDate && (
      <FilterItem
        label='Período:'
        value={periodoText}
        style={css`
          overflow: visible;
        `}
      />
    )

  const lotacoesText = props.filtros.responsaveis?.map((item) => item.profissional.nome).join(', ')

  const lotacoes = props.filtros.responsaveis?.length > 0 && <FilterItem label='Profissionais:' value={lotacoesText} />

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {statusAtendimento || periodo || lotacoes ? (
        <>
          {statusAtendimento}
          {periodo}
          {lotacoes}
        </>
      ) : (
        <Cell>
          <Text>'Sem filtros ativos'</Text>
        </Cell>
      )}
    </Grid>
  )
}
