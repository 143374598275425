import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { resolveName } from 'components/form/final-form/useField'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useState } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { MetaPath } from 'util/metaPath'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import HeadingFormControl from '../components/HeadingFormControl'
import { OrientacoesCadastroView } from './list/OrientacoesCadastroView'
import { OrientacoesTable } from './list/OrientacoesTable'
import { OrientacaoFormModel } from './types/OrientacoesModel'

interface OrientacoesViewProps {
  name: MetaPath<OrientacaoFormModel>
  prontuarioId: ID
  atendimentoId: ID
  cidadao: CidadaoAtendimento
  orientacoes: OrientacaoFormModel[]
  atendimentoProfissional: AtendimentoProfissional
}

export function OrientacoesView(props: OrientacoesViewProps) {
  const { name, prontuarioId, atendimentoId, orientacoes, atendimentoProfissional, cidadao } = props

  const [idOrientacaoEmEdicao, setIdOrientacaoEmEdicao] = useState<ID>()

  const { resetExpandedItems, ...accordionProps } = useAccordionControl({})

  const {
    observacao: { isAtendimentoObservacao },
  } = useAtendimentoContext()

  const renderFieldArray = (fieldArrayRenderProps: FieldArrayRenderProps<OrientacaoFormModel, any>) => {
    const {
      fields: { push, update, remove },
    } = fieldArrayRenderProps

    const handleSubmit = (value: OrientacaoFormModel) => {
      push(value)
      resetExpandedItems()
    }

    const handleRemove = (index: number) => {
      remove(index)
      resetExpandedItems()
    }

    return (
      <>
        <OrientacoesCadastroView onSubmit={handleSubmit} onFocus={() => setIdOrientacaoEmEdicao(null)} />
        <OrientacoesTable
          cidadao={cidadao}
          atendimentoId={atendimentoId}
          prontuarioId={prontuarioId}
          orientacoes={orientacoes}
          atendimentoProfissionalId={atendimentoProfissional.id}
          isAtendimentoObservacao={isAtendimentoObservacao}
          dataReferencia={atendimentoProfissional.iniciadoEm}
          lotacao={atendimentoProfissional.lotacao}
          idOrientacaoEmEdicao={idOrientacaoEmEdicao}
          update={update}
          remove={handleRemove}
          onEditClick={setIdOrientacaoEmEdicao}
          resetExpandedItems={resetExpandedItems}
          {...accordionProps}
        />
      </>
    )
  }

  return (
    <>
      <HeadingFormControl label='Orientações ao cidadão' required level='h4' />
      <FieldArray name={resolveName(name)} render={renderFieldArray} />
    </>
  )
}
