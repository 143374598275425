/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonGroup, Modal, ModalBody, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { TitleGroup } from 'components/TitleGroup'
import { useFlags } from 'config/useFlagsContext'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useState } from 'react'
import { useHistory } from 'react-router'

import { MedicamentoFormModel } from '../model'
import { ImpressaoPrescricaoView } from './impressao/ImpressaoPrescricaoView'
import { PrescricaoDigitalView } from './prescricao-digital/PrescricaoDigitalView'

enum ActiveTab {
  IMPRIMIR,
  PRESCRICAO_DIGITAL,
}

export interface EncaminharPrescricaoModalProps {
  medicamentos: MedicamentoFormModel[]
  atendimentoId: ID
}

export default function EncaminharPrescricaoModal(props: EncaminharPrescricaoModalProps) {
  const { medicamentos, atendimentoId } = props
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.IMPRIMIR)

  const {
    data: {
      acesso: {
        municipio: { id: municipioId },
      },
    },
  } = useSession()

  const { PRESCRICAO_DIGITAL_ENABLED } = useFlags()

  const {
    cidadao: { id: cidadaoId, dataNascimento: cidadaoDataNascimento, sexo: cidadaoSexo },
  } = useAtendimentoContext()

  const history = useHistory()
  const handleModalClose = () => history.goBack()

  return (
    <Modal open onClose={handleModalClose} closeOnBackdropClick={false} style={styles.mainContainer}>
      <ModalBody>
        <VFlow vSpacing={0.5}>
          <TitleGroup
            title='Encaminhar prescrição'
            style={css`
              margin-bottom: 1.5rem;
            `}
          />
          {PRESCRICAO_DIGITAL_ENABLED && (
            <ButtonGroup>
              <Button
                size='small'
                kind={activeTab === ActiveTab.IMPRIMIR ? 'primary' : 'normal'}
                onClick={() => setActiveTab(ActiveTab.IMPRIMIR)}
              >
                Imprimir
              </Button>
              <Button
                size='small'
                kind={activeTab === ActiveTab.PRESCRICAO_DIGITAL ? 'primary' : 'normal'}
                onClick={() => setActiveTab(ActiveTab.PRESCRICAO_DIGITAL)}
              >
                Prescrição digital
              </Button>
            </ButtonGroup>
          )}
        </VFlow>
      </ModalBody>
      {activeTab === ActiveTab.IMPRIMIR && (
        <ImpressaoPrescricaoView
          medicamentos={medicamentos}
          atendimentoId={atendimentoId}
          municipioId={municipioId}
          cidadaoDataNascimento={cidadaoDataNascimento}
          cidadaoSexo={cidadaoSexo}
          onModalClose={handleModalClose}
        />
      )}
      {activeTab === ActiveTab.PRESCRICAO_DIGITAL && (
        <PrescricaoDigitalView
          medicamentos={medicamentos}
          atendimentoId={atendimentoId}
          municipioId={municipioId}
          cidadaoId={cidadaoId}
          cidadaoDataNascimento={cidadaoDataNascimento}
          cidadaoSexo={cidadaoSexo}
          onModalClose={handleModalClose}
        />
      )}
    </Modal>
  )
}

const styles = {
  mainContainer: css`
    width: 42.5rem;
    height: auto;
  `,
}
