import { Text, TextColor, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { lotacaoAgendamentoToString } from 'view/agenda/util'

import { JustificativaCancelamentoAgendadoModel } from '../../model'

interface AgendamentoCidadaoJustificativaCancelamentoProps {
  justificativa: JustificativaCancelamentoAgendadoModel
  color?: TextColor
}

export function AgendamentoCidadaoJustificativaCancelamento(props: AgendamentoCidadaoJustificativaCancelamentoProps) {
  const {
    justificativa: { justificativa, data, responsavel },
    color,
  } = props

  return (
    <VFlow vSpacing={0.25}>
      <HLabel title='Justificativa cancelamento:' color={color}>
        <Text fontStyle='italic' color={color}>
          {justificativa}
        </Text>
      </HLabel>
      {responsavel && (
        <HLabel title={`Cancelado em ${formatDateAndHoursMinutes(data)} por:`} color={color}>
          {lotacaoAgendamentoToString({
            nomeProfissional: responsavel?.profissional?.nome,
            nomeCbo: responsavel?.cbo?.nome,
            ineEquipe: responsavel?.equipe?.ine,
            nomeEquipe: responsavel?.equipe?.nome,
          })}
        </HLabel>
      )}
    </VFlow>
  )
}
