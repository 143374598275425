import { Button, HFlow, Theme } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { useCancelarAtendimentoMutation } from 'graphql/hooks.generated'
import React, { Dispatch, useCallback } from 'react'
import { useHistory } from 'react-router'

import { REGISTRO_TARDIO_PATH } from '../../registro-tardio/RegistroTardioRootView'
import { useLocalStyles } from '../atendimento-individual/AtendimentoIndividualView'
import { TipoAtendimentoFooter } from '../model'
import { atendimentoFooterMessage } from './atendimentoFooterMessage'

export interface AtendimentoFooterProps {
  atendimentoId: ID
  registroTardio: boolean
  isAtendimentoObservacao: boolean
  isObservacaoAndResponsavel: boolean
  clearCache: Dispatch<void>
  tipoAtendimentoProfissional?: TipoAtendimentoFooter
}

export function AtendimentoFooter(props: AtendimentoFooterProps) {
  const {
    atendimentoId,
    clearCache,
    tipoAtendimentoProfissional,
    registroTardio,
    isAtendimentoObservacao,
    isObservacaoAndResponsavel,
  } = props
  const history = useHistory()
  const [cancelar] = useCancelarAtendimentoMutation()
  const handleRejection = useErrorHandler()
  const { classes } = useLocalStyles(createStyles) // o do bold retorna classes novas a todo render
  const messages = atendimentoFooterMessage(
    tipoAtendimentoProfissional,
    isAtendimentoObservacao,
    isObservacaoAndResponsavel
  )

  const handleCancelar = useCallback(() => {
    confirm({
      title: messages.confirmTitle,
      body: messages.body,
      confirmLabel: 'Sim',
      cancelLabel: 'Não',
      onConfirm: () => {
        cancelar({ variables: { atendimentoId } })
          .then(() => {
            clearCache()
            history.push(registroTardio ? REGISTRO_TARDIO_PATH : '/lista-atendimento')
          })
          .catch(handleRejection)
      },
    })()
  }, [
    atendimentoId,
    cancelar,
    clearCache,
    handleRejection,
    history,
    messages.body,
    messages.confirmTitle,
    registroTardio,
  ])

  return (
    <div className={classes.actions}>
      <PageContent type='filled' fluid>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleCancelar}>
            {messages.labelButton}
          </Button>
        </HFlow>
      </PageContent>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  actions: {
    borderTop: `1px solid ${theme.pallete.divider}`,
    height: '6.125rem',
  },
})
