import { OrdemListaAtendimentoEnum, StatusAtendimento } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'

import { AtendimentosFilterModel } from '../model'

export function useListaAtendimentoFilterDefault(): AtendimentosFilterModel {
  const { getServerTimeNow } = useServerTime()

  const serverTime = getServerTimeNow()

  return {
    filter: {
      periodo: {
        startDate: serverTime,
        endDate: serverTime,
      },
      ordem: OrdemListaAtendimentoEnum.CLASSIFICACAO_RISCO,
      statusAtendimento: [
        StatusAtendimento.AGUARDANDO_ATENDIMENTO,
        StatusAtendimento.EM_ATENDIMENTO,
        StatusAtendimento.EM_ESCUTA_INICIAL,
      ],
      tiposServico: [],
      equipes: [],
      responsaveis: [],
      somenteMeusAtendimentos: false,
      somenteNaoFinalizados: false,
    },
    lastUpdate: serverTime,
    lastDailyUpdate: serverTime,
    pageParams: {},
  }
}
