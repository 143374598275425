import { format, parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { isValidHora } from 'util/validation'
import { isEmpty } from 'util/validation/Util'

const dateOnly = /^\d{4}-\d{2}-\d{2}$/

export const formatDate = (value?: number | string): string => (value ? dateAsDdMmYyyy(toDate(value)) : '-')

export const dateAsDdMmYyyy = (value?: Date): string => (value ? format(value, 'dd/MM/yyyy') : '-')

export const formatDayOfWeek = (value?: number | string): string =>
  value ? format(toDate(value), 'EEEE', { locale: ptBR }) : '-'

export const formatWeekdayDayMonthYear = (value?: number | string): string =>
  value
    ? format(toDate(value), "eeee, dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
      })
    : '-'

export const formatHoursMinutes = (value?: number | string): string => (value ? format(toDate(value), 'HH:mm') : '-')

export const formatDayMonthYear = (value?: number | string): string =>
  value
    ? format(toDate(value), "dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
      })
    : '-'

export const toDate = (value: number | string): Date =>
  value ? new Date(typeof value === 'string' && dateOnly.test(value) ? `${value}T00:00:00` : value) : null

export const removeTimeZone = (data) => {
  return data?.toString().replace(/ \w+-\d+ \(.*\)$/, '')
}

export const dateAsYyyyMmDd = (value: Date | number): string => format(value, 'yyyy-MM-dd')
export const dateAsYyyyMmDdHhMm = (value: Date): string => format(value, 'yyyy-MM-dd-HH-mm')
export const dateAsYyyyMmDdHhMmSs = (value: Date): string => format(value, 'yyyy-MM-dd-HH-mm-ss')

export const safeDateAsYyyyMmDd = (date: Date): string => (date ? dateAsYyyyMmDd(new Date(date)) : undefined)

export const convertDateAndTimeToInstant = (date: LocalDate, time: string) => {
  const noDate = isEmpty(date)
  const noTime = isEmpty(time)

  if (noDate && noTime) return null
  if (noDate || noTime || !dateOnly.test(date) || !isValidHora(time)) throw new Error('Data ou hora inválida')

  const formatted = `${date} ${time}`
  return parse(formatted, 'yyyy-MM-dd HH:mm', new Date()).getTime()
}

export const formatDateAndHoursMinutes = (value?: number | string): string =>
  value ? format(toDate(value), "dd/MM/yyyy 'às' HH:mm") : '-'
