/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, Heading, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import useFirebase from 'components/firebase/useFirebase'
import { PageContent } from 'components/layout/PageContent'
import { TableBox, usePagedTableProps } from 'components/table'
import { TitleGroup } from 'components/TitleGroup'
import { useFlags } from 'config/useFlagsContext'
import { useBuscaAtivaVacinacaoLazyQuery } from 'graphql/hooks.generated'
import { TipoVisualizacaoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'
import { Fragment, useEffect, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import Permissions from 'types/Permissions'

import BuscaAtivaVacinacaoForm from './BuscaAtivaVacinacaoForm'
import { calculator } from './calculator'
import BuscaAtivaVacinacaoActiveFilters from './component/BuscaAtivaVacinacaoActiveFilters'
import BuscaAtivaVacinacaoCidadaosEncontradosTable from './component/BuscaAtivaVacinacaoCidadaosEncontradosTable'
import convertModelToInput from './convertModelToInput'
import { downloadCSVBuscaAtivaVacinacao, downloadImpressaoBuscaAtivaVacinacao } from './downloadBuscaAtivaVacinacao'
import { BuscaAtivaVacinacaoFormModel } from './model'
import { validator } from './validator'

export default function BuscaAtivaVacinacaoView() {
  const theme = useTheme()
  const styles = createStyles(theme)
  const { BUSCA_ATIVA_VACINACAO_ENABLED } = useFlags()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()
  const unidadeSaude = acesso?.unidadeSaude
  const equipe = acesso?.equipe

  const NAO_EXISTEM_DADOS_TEXT = 'Não existem dados para geração do relatório'

  const initialValues: BuscaAtivaVacinacaoFormModel = {
    tipoVisualizacao: TipoVisualizacaoBuscaAtivaVacinacaoEnum.CALENDARIO_VACINAL,
  }

  const [activeFilters, setActiveFilters] = useState<BuscaAtivaVacinacaoFormModel>()
  const [shouldRefetch, setShouldRefetch] = useState(false)

  const { hasAuthorization } = useSession()
  const isCoordenador = hasAuthorization(Permissions.buscaAtivaDeVacinacao.todasAsEquipes)

  const isAbleToBuscar = isCoordenador || equipe?.id

  const [executeQuery, { loading, data }] = useBuscaAtivaVacinacaoLazyQuery()

  const afterSubmit = () => {
    setShouldRefetch(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleExportarImpressao = () => {
    downloadImpressaoBuscaAtivaVacinacao(convertModelToInput(activeFilters))
    afterSubmit()
  }

  const handleExportarCSVClick = () => {
    analytics.logEvent('exportar_csv_busca_ativa_vacinacao')
    downloadCSVBuscaAtivaVacinacao(convertModelToInput(activeFilters))
    afterSubmit()
  }

  useEffect(() => {
    if (activeFilters) {
      executeQuery({ variables: { input: { ...convertModelToInput(activeFilters) } } })
    }
  }, [activeFilters, executeQuery])

  const tableProps = usePagedTableProps({
    result: data?.buscaAtivaVacinacao,
    loading: loading,
    onChange: setActiveFilters,
  })

  const hasResults = data?.buscaAtivaVacinacao?.content.length > 0

  const renderMensagemNaoExistemDados = !hasResults && NAO_EXISTEM_DADOS_TEXT

  return (
    <Fragment>
      <Breadcrumb title='Busca ativa de vacinação' />
      <VFlow style={styles.contentDivider}>
        <PageContent type='filled'>
          <TitleGroup title='Busca ativa de vacinação' style={styles.titleGroup} />
          <Grid gapVertical={1}>
            <Cell size={12}>
              <Alert
                type='info'
                inline
                styles={{
                  wrapper: styles.alertWrapper,
                  container: styles.alertContainer,
                }}
              >
                A busca ativa de vacinação apresenta cidadãos relacionados a alguma equipe/INE. A origem das informações
                são as fichas de cadastro individual e os cadastros do módulo dos cidadãos.
              </Alert>
            </Cell>
            <Form<BuscaAtivaVacinacaoFormModel>
              render={(formProps: FormRenderProps<BuscaAtivaVacinacaoFormModel>) => (
                <BuscaAtivaVacinacaoForm
                  unidadeSaude={{ id: unidadeSaude.id, nome: unidadeSaude.nome }}
                  equipe={equipe}
                  isCoordenador={isCoordenador}
                  shouldRefetch={shouldRefetch}
                  setShouldRefetch={setShouldRefetch}
                  {...formProps}
                />
              )}
              initialValues={initialValues}
              validate={validator}
              onSubmit={(values) => setActiveFilters(values)}
              decorators={[calculator]}
            />
          </Grid>
        </PageContent>
      </VFlow>
      <PageContent>
        {data?.buscaAtivaVacinacao.content || loading ? (
          <Fragment>
            <TitleGroup title='Cidadãos encontrados' style={styles.titleGroup} />
            <VFlow vSpacing={0.5}>
              {activeFilters && <BuscaAtivaVacinacaoActiveFilters filters={activeFilters} />}
              <VFlow vSpacing={2}>
                <TableBox>
                  <BuscaAtivaVacinacaoCidadaosEncontradosTable
                    statusVacinacao={activeFilters.status?.enum}
                    tipoVisualizacao={activeFilters.tipoVisualizacao}
                    {...tableProps}
                  />
                </TableBox>
                <HFlow justifyContent='flex-end'>
                  <Tooltip text={renderMensagemNaoExistemDados}>
                    <Button onClick={handleExportarCSVClick} disabled={!hasResults}>
                      Exportar CSV
                    </Button>
                  </Tooltip>
                  {BUSCA_ATIVA_VACINACAO_ENABLED && (
                    <Tooltip text={renderMensagemNaoExistemDados}>
                      <Button onClick={handleExportarImpressao} kind='primary' disabled={!hasResults}>
                        <HFlow hSpacing={0.5}>
                          <Icon icon='fileWithItemsOutline' />
                          Imprimir
                        </HFlow>
                      </Button>
                    </Tooltip>
                  )}
                </HFlow>
              </VFlow>
            </VFlow>
          </Fragment>
        ) : (
          <VFlow style={styles.mensagemPreBusca}>
            {isAbleToBuscar ? (
              <Fragment>
                <Heading level={3}>Realize uma busca por cidadãos que tenham vacina(s) no prazo ou atrasadas.</Heading>
                {isCoordenador ? (
                  <Text>Selecione a equipe responsável, o grupo-alvo e o status da vacina para gerar a listagem.</Text>
                ) : (
                  <Text>Selecione o grupo-alvo e o status da vacina para gerar a listagem.</Text>
                )}
              </Fragment>
            ) : (
              <Heading level={3}>
                Seu perfil não possui acesso aos resultados. Para buscar os dados é necessário estar vinculado a uma
                equipe.
              </Heading>
            )}
          </VFlow>
        )}
      </PageContent>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  responsavelTitle: css`
    margin-bottom: 0.5rem;
  `,
  mensagemPreBusca: css`
    text-align: center;
    margin-top: 2.5rem;
  `,
  contentDivider: css`
    border-bottom: 1px solid ${theme.pallete.divider};
    padding-bottom: 1.25rem;
  `,
  titleGroup: css`
    margin: 1rem 0;
  `,
  alertWrapper: css`
    padding: 0.375rem;
  `,
  alertContainer: css`
    align-items: center;
  `,
})
