import { HFlow, TableFooter, Text, VFlow } from 'bold-ui'
import AccordionList from 'components/accordion/AccordionList'
import { usePagination } from 'components/table/usePagination'
import React from 'react'
import {
  TerritorioMicroareaItemModel,
  TerritorioMicroareaQueryResult,
  TerritorioModel,
} from 'view/visualizacaoTerritorial/model'

import { formatNumImovelCidadao } from '../../utils/visualizacaoTerritorialUtils'
import MicroareaLogradouroListItemBody from './MicroareaLogradouroListItemBody'

interface MicroareaLogradouroListProps {
  logradouros: TerritorioMicroareaQueryResult
  territorioProps: TerritorioModel
  lotacaoId: ID
  profissionalId: ID
  onAccordionItemClick: (id: string) => void
  isExpanded: (id: string) => boolean
  resetExpandedItems: () => void
}

export function MicroareaLogradouroPagedList(props: MicroareaLogradouroListProps) {
  const {
    logradouros,
    territorioProps,
    lotacaoId,
    profissionalId,
    onAccordionItemClick,
    isExpanded,
    resetExpandedItems,
  } = props

  const { paginatedItems, tableProps } = usePagination({
    items: logradouros?.logradouros.content ?? [],
    onChange: resetExpandedItems,
  })

  return (
    <VFlow vSpacing={0.5}>
      <AccordionList<TerritorioMicroareaItemModel>
        data={{ content: paginatedItems, pageInfo: {} }}
        accordionItemHeader={renderAccordionItemHeader}
        accordionItemBody={(item) => (
          <MicroareaLogradouroListItemBody
            lotacaoId={lotacaoId}
            profissionalId={profissionalId}
            rowItem={item}
            territorioProps={territorioProps}
            isExpanded={isExpanded(item.id)}
          />
        )}
        paginate={false}
        controls={{ handleAccordionItemClick: onAccordionItemClick, isExpanded }}
        noResultPlaceholder=''
      />
      {logradouros?.logradouros.content?.length > 0 && <TableFooter {...tableProps} />}
    </VFlow>
  )
}

const renderAccordionItemHeader = (row: TerritorioMicroareaItemModel) => {
  return (
    <HFlow hSpacing={0.5} key={row.id}>
      <Text fontWeight='bold' fontSize={1}>
        {`${row.nomeTipoLogradouro} ${row.logradouro}, ${row.bairro}`.titleCase()}
      </Text>
      <Text fontSize={1}>{formatNumImovelCidadao(row.numeroImoveis, row.numeroCidadaos)}</Text>
    </HFlow>
  )
}
