/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, HFlow, Icon } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useFirebase from 'components/firebase/useFirebase'
import { RadioGroupField, SubmitButton } from 'components/form'
import { EquipeResponsavelField } from 'components/form/field/select/EquipeResponsavelSelectField/EquipeResponsavelField'
import { MicroareaSelectField } from 'components/form/field/select/MicroareaSelectField/MicroareaSelectField'
import { InfoIcon } from 'components/InfoIcon'
import { PopperButton, PopperControls } from 'components/popper'
import {
  ArquivoIdentificadorEnum,
  Equipe,
  GrupoAlvoVacinacaoEnum,
  StatusVacinacaoEnum,
  TipoVisualizacaoBuscaAtivaVacinacaoEnum,
  UnidadeSaude,
} from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react'
import { Fragment } from 'react'
import { FormRenderProps } from 'react-final-form'
import { dateAsYyyyMmDdHhMmSs } from 'util/date/formatDate'
import { UnidadeResponsavelLabel } from 'view/acompanhamento-cidadaos-vinculados/components/UnidadeResponsavelLabel'
import { RelatoriosRecentes } from 'view/arquivo/RelatoriosRecentes'

import BuscaAtivaVacinacaoFilterPopper from './component/BuscaAtivaVacinacaoFilterPopper'
import { GrupoAlvoVacinacaoSelectField } from './component/GrupoAlvoVacinacaoSelectField'
import { items as statusDoseEnumRecord, StatusDoseImunoSelectField } from './component/StatusDoseImunoSelectField'
import {
  BUSCA_ATIVA_VACINACAO_PATH,
  BuscaAtivaVacinacaoFilterPopperFormModel,
  BuscaAtivaVacinacaoFormModel,
  metaBuscaAtivaVacinacao,
  tipoVisualizacaoRecord,
} from './model'

interface BuscaAtivaVacinacaoFormProps extends FormRenderProps<BuscaAtivaVacinacaoFormModel> {
  unidadeSaude: Pick<UnidadeSaude, 'id' | 'nome'>
  equipe: Equipe
  isCoordenador: boolean
  shouldRefetch: boolean
  setShouldRefetch: Dispatch<SetStateAction<boolean>>
}

function BuscaAtivaVacinacaoForm(props: BuscaAtivaVacinacaoFormProps) {
  const {
    handleSubmit,
    hasValidationErrors,
    form,
    unidadeSaude,
    equipe,
    isCoordenador,
    shouldRefetch,
    setShouldRefetch,
    values,
  } = props

  const [counterFiltrosAvancadosAplicados, setCounterFiltrosAvancadosAplicados] = useState(0)
  const { analytics } = useFirebase()
  const { getServerTimeNow } = useServerTime()
  const { profissional } = useAcessoLotacaoOrEstagio()

  const isAbleToBuscar = isCoordenador || equipe?.id
  const fileName = `${BUSCA_ATIVA_VACINACAO_PATH}-${dateAsYyyyMmDdHhMmSs(getServerTimeNow())}`
  const isTipoBuscaAtivaCovid = values.tipoVisualizacao === TipoVisualizacaoBuscaAtivaVacinacaoEnum.COVID_19

  const statusField = form.getFieldState(metaBuscaAtivaVacinacao.status.absolutePath())

  const shouldCenterInfoIcon = statusField?.touched && statusField?.invalid

  const handleOnTipoVisualizacaoChange = () => {
    form.resetFieldState(metaBuscaAtivaVacinacao.grupoAlvo.absolutePath())
  }

  const itemsToStatusDoseSelect = () => {
    const statusAplicada = [statusDoseEnumRecord[StatusVacinacaoEnum.APLICADA]]
    const statusAtrasada = [statusDoseEnumRecord[StatusVacinacaoEnum.ATRASADA]]
    const statusNoPrazo = [statusDoseEnumRecord[StatusVacinacaoEnum.NO_PRAZO]]

    if (isTipoBuscaAtivaCovid) {
      return statusAplicada
    }

    if (values.grupoAlvo?.enum === GrupoAlvoVacinacaoEnum.GESTANTE) {
      return statusAtrasada
    }

    return [...statusNoPrazo, ...statusAtrasada]
  }

  const cleanFilterPopperValues = () => {
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados.imunobiologicos.absolutePath(), null)
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados.doses.absolutePath(), null)
  }

  const applyFilterPopperValues = (formValues: BuscaAtivaVacinacaoFilterPopperFormModel) => {
    analytics.logEvent('aplicar_filtros_avancados_busca_ativa_vacinacao')

    form.change(metaBuscaAtivaVacinacao.filtrosAvancados?.imunobiologicos.absolutePath(), formValues.imunobiologicos)
    form.change(metaBuscaAtivaVacinacao.filtrosAvancados?.doses.absolutePath(), formValues.doses)
  }

  const renderPopper = ({ close }: PopperControls) => {
    const initialValues: BuscaAtivaVacinacaoFilterPopperFormModel = {
      imunobiologicos: values.filtrosAvancados?.imunobiologicos,
      doses: values.filtrosAvancados?.doses,
    }

    return (
      <BuscaAtivaVacinacaoFilterPopper
        isTipoVisualizacaoCovid={isTipoBuscaAtivaCovid}
        initialValues={initialValues}
        closeFilterPopper={close}
        clearFilterPopper={cleanFilterPopperValues}
        applyFilterPopper={applyFilterPopperValues}
      />
    )
  }

  useEffect(() => {
    setCounterFiltrosAvancadosAplicados(
      (values.filtrosAvancados?.imunobiologicos?.length ?? 0) + (values.filtrosAvancados?.doses?.length ?? 0)
    )
  }, [values.filtrosAvancados])

  const handleOnSubmit = () => {
    analytics.logEvent('buscar_cidadaos_busca_ativa_vacinacao')

    handleSubmit()
    !hasValidationErrors && form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
  }

  return (
    <Fragment>
      <Cell size={3} style={styles.cell}>
        <UnidadeResponsavelLabel nomeUnidadeSaude={unidadeSaude.nome} />
      </Cell>
      <Cell size={isCoordenador ? 4 : 3} style={styles.cell}>
        <EquipeResponsavelField
          name={metaBuscaAtivaVacinacao.equipe}
          equipe={equipe}
          unidadeSaudeId={unidadeSaude.id}
          hasAccessToTodasEquipes={isCoordenador}
          placeholder='Selecione uma equipe'
          required
        />
      </Cell>
      <Cell size={3} style={styles.cell}>
        <MicroareaSelectField
          name={metaBuscaAtivaVacinacao.microareas}
          label='Microárea'
          metaEquipe={metaBuscaAtivaVacinacao.equipe}
          placeholder='Todas as microáreas'
        />
      </Cell>
      <Cell size={12}>
        <RadioGroupField
          name={metaBuscaAtivaVacinacao.tipoVisualizacao}
          required
          label='Tipo de visualização'
          onChange={handleOnTipoVisualizacaoChange}
          options={Object.values(TipoVisualizacaoBuscaAtivaVacinacaoEnum).map((tipoVisualizacao) => ({
            label: tipoVisualizacaoRecord[tipoVisualizacao],
            value: tipoVisualizacao,
          }))}
        />
      </Cell>
      <Cell size={4}>
        <GrupoAlvoVacinacaoSelectField
          name={metaBuscaAtivaVacinacao.grupoAlvo}
          label='Grupo-alvo'
          placeholder='Selecione um grupo-alvo'
          required
          isTipoBuscaAtivaCovid={isTipoBuscaAtivaCovid}
        />
      </Cell>
      <Cell size={12} />
      <Cell size={3}>
        <HFlow alignItems={shouldCenterInfoIcon ? 'center' : 'flex-end'}>
          <StatusDoseImunoSelectField
            name={metaBuscaAtivaVacinacao.status}
            label='Status da vacina'
            placeholder='Selecione um status'
            isTipoBuscaAtivaCovid={isTipoBuscaAtivaCovid}
            required
            items={itemsToStatusDoseSelect()}
            disabled={values.grupoAlvo?.enum === GrupoAlvoVacinacaoEnum.GESTANTE || isTipoBuscaAtivaCovid}
          />
          <InfoIcon
            icon='infoCircleFilled'
            text='Único(s) status disponível(is)'
            style={!shouldCenterInfoIcon && styles.centerButton}
          />
        </HFlow>
      </Cell>
      <Cell size={9} />
      <Cell size={12}>
        <PopperButton
          kind='primary'
          size='small'
          skin='outline'
          placement='bottom-end'
          renderPopper={renderPopper}
          closeOnBackdropClick={false}
        >
          <Icon icon='filterOutline' />
          {`Filtros avançados ${counterFiltrosAvancadosAplicados > 0 ? ` (${counterFiltrosAvancadosAplicados}) ` : ''}`}
        </PopperButton>
      </Cell>
      <Cell size={12}>
        <RelatoriosRecentes
          fileName={fileName}
          identificadorModuloArquivo={ArquivoIdentificadorEnum.RELATORIO_BUSCA_ATIVA_VACINACAO}
          atmospherePath={BUSCA_ATIVA_VACINACAO_PATH}
          shouldRefetch={shouldRefetch}
          usuarioId={profissional?.usuario?.id}
          setShouldRefetch={setShouldRefetch}
        />
      </Cell>
      <Cell size={12} style={styles.cell}>
        <HFlow justifyContent='flex-end'>
          <Button onClick={form.reset} disabled={!isAbleToBuscar}>
            Limpar filtros
          </Button>
          <SubmitButton handleSubmit={handleOnSubmit} kind='primary' disabled={!isAbleToBuscar}>
            <HFlow hSpacing={0.5}>
              <Icon icon='zoomOutline' />
              Buscar cidadãos
            </HFlow>
          </SubmitButton>
        </HFlow>
      </Cell>
    </Fragment>
  )
}

const styles = {
  cell: css`
    margin-top: 1rem;
  `,
  centerButton: css`
    margin-bottom: 0.5rem;
  `,
}

export default memo(BuscaAtivaVacinacaoForm)
