import { SubtipoRecord } from 'components/form/field/select/SubtipoAtendimentoSelectField/SubtipoAtendimentoSelectField'
import { format } from 'date-fns'
import {
  DadosUltimosContatosQuery,
  OrigemAtendimento,
  TipoAtendimentoHistoricoClinico,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'
import { turnoRecord } from 'types/enums'
import {
  recordEscutaHistoricoTagStyle,
  SubtipoAtendimentoTag,
  tipoAtendimentoHistoricoData,
} from 'view/atendimentos/detail/historico/model/historicoModel'

import { FolhaRostoUltimosContatosModel } from './model'

export type DadosUltimosContatosModel = DadosUltimosContatosQuery['historico']['content'][number]

export function convertContatoToModel(contato: DadosUltimosContatosModel): FolhaRostoUltimosContatosModel {
  const tipoAtendimentoEnum = contato.tipoApresentacao ?? contato.tipoAtendProf
  const historicoDataRecord = tipoAtendimentoHistoricoData[tipoAtendimentoEnum.toString()]
  const escutaInicialTagEnabled =
    (contato.tipoAtendProf === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
      contato.tipoApresentacao === TipoAtendimentoHistoricoClinico.ESCUTA_INICIAL) &&
    !!contato.classificacaoRisco
  const isConsumoAlimentar = tipoAtendimentoEnum === TipoAtendimentoHistoricoClinico.CONSUMO_ALIMENTAR

  return {
    id: contato.codigoUnicoRegistro,
    tipo: contato.tipoApresentacao,
    title: historicoDataRecord.descricao,
    barColor: historicoDataRecord.barColor,
    icon: historicoDataRecord.icon,
    color: historicoDataRecord.iconColor,
    dataOrTurno:
      contato.origemAtendimento === OrigemAtendimento.PEC
        ? format(contato.dataAtendimento, "dd/MM/yyyy 'às' HH:mm")
        : `${format(contato.dataAtendimento, 'dd/MM/yyyy')} ${contato.turno ? `- ${turnoRecord[contato.turno]}` : ''}`,
    headerTags: !isConsumoAlimentar ? convertTag(historicoDataRecord.tags, contato.coSubtipoAtendimento) : null,
    classificacaoRisco: escutaInicialTagEnabled ? recordEscutaHistoricoTagStyle[contato.classificacaoRisco] : null,
    idadeGestacional:
      contato.tipoApresentacao === TipoAtendimentoHistoricoClinico.PRE_NATAL ||
      contato.tipoAtendProf === TipoAtendimentoProfissional.PRE_NATAL
        ? contato.idadeGestacional
        : null,
    contentTags: isConsumoAlimentar ? convertTag(historicoDataRecord.tags, contato.coSubtipoAtendimento) : null,
    dadosClinicos: contato.dadosClinicos,
    examesRealizadosZika: contato.examesRealizadosZika.length > 0 ? contato.examesRealizadosZika : null,
    historicoAtendimento: contato,
    hasObservacao: contato.hasObservacao,
  }
}

function convertTag(tags: SubtipoAtendimentoTag[], subtipoAtendimento: ID) {
  return tags
    ?.filter((tag: SubtipoAtendimentoTag) => tag.id === subtipoAtendimento)
    .map((tag: SubtipoAtendimentoTag) => ({
      label: SubtipoRecord[tag.nome],
      color: tag.tagColor,
    }))
}
