import {
  AgendamentoConsultaFinalizacaoAtendimentoInput,
  DesfechoAtendimentoInput,
  FinalizacaoAtendimentoInput,
  ProcedimentoAtendimentoInput,
  RacionalidadeEmSaudeEnum,
} from 'graphql/types.generated'
import { compact, concat } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { convertNovoAtendimentoModelToInput } from 'view/atendimentos/converter'

import { AgendarConsultaPanelModel } from './components/AgendarConsultaPanel'
import { DesfechoAtendimentoFormModel, ManterCidadaoLista } from './components/DesfechoAtendimentoPanel'
import { NotificacaoCasoSuspeitoFieldModel } from './components/NotificacaoCasoSuspeitoField'
import { ProcedimentoSigtapFieldModel } from './components/ProcedimentoSigtapField'
import { FinalizacaoAtendimentoFormModel } from './FinalizacaoAtendimentoForm'
import {
  FinalizacaoAtendimentoQueryModel,
  NotificacaoCasoSuspeitoQueryModel,
  ProcedimentoAtendimentoQueryModel,
} from './model'

export const convertFinalizacaoFormToInput = (
  form: FinalizacaoAtendimentoFormModel,
  isObservacaoAndAuxiliarDaObservacao: boolean = false
): FinalizacaoAtendimentoInput => {
  return (
    form && {
      tipoAtendimento: form.tipoAtendimento,
      atendimentoCompartilhadoLotacaoId: form.atendimentoCompartilhadoLotacao?.id,
      procedimentosAdministrativos: convertProcedimentosAdministrativosFormToInput(form.procedimentosAdministrativos),
      fichaNotificacaoCasoSuspeito: form.fichasNotificacaoCasoSuspeito?.map((ficha) => ficha.fichaNotificacao.id),
      racionalidadeEmSaude: form.racionalidadeEmSaude as RacionalidadeEmSaudeEnum,
      condutas: form.conduta,
      condutasOdonto: form.condutasOdonto,
      fornecimentosOdonto: form.fornecimento,
      tipoConsultaOdonto: form.tipoConsulta,
      desfechoAtendimento: convertDesfechoAtendimentoFormToInput(
        form.desfechoAtendimento,
        isObservacaoAndAuxiliarDaObservacao
      ),
      agendamentoConsulta: convertAgendarConsulta(form.agendamentoConsulta),
    }
  )
}

export const convertAgendarConsulta = (
  agendarConsulta: AgendarConsultaPanelModel
): AgendamentoConsultaFinalizacaoAtendimentoInput =>
  agendarConsulta?.lotacao
    ? {
        lotacao: agendarConsulta.lotacao.id,
        horario: Number(agendarConsulta.horario?.inicial),
        isForaUbs: !!agendarConsulta.isForaUbs,
        localAtendimento: agendarConsulta.localAtendimento?.id,
        observacoes: agendarConsulta.observacoes,
      }
    : undefined

export const convertFinalizacaoQueryToForm = (
  prevValue: FinalizacaoAtendimentoFormModel,
  finalizacao: FinalizacaoAtendimentoQueryModel
): FinalizacaoAtendimentoFormModel => {
  return {
    ...prevValue,
    tipoAtendimento: finalizacao.tipoAtendimento || prevValue.tipoAtendimento,
    conduta: compact(concat(prevValue?.conduta, finalizacao?.condutas)).removeDuplicate(),
    racionalidadeEmSaude: finalizacao.racionalidadeSaude,
    atendimentoCompartilhadoLotacao: finalizacao.lotacaoAtendimentoCompartilhado,
    fichasNotificacaoCasoSuspeito: convertFichasNotificacaoCasoSuspeitoQueryToForm(
      finalizacao.notificacoesCasoSuspeito
    ),
    procedimentosAdministrativos: convertProcedimentosAdmistrativosQueryToForm(
      prevValue?.procedimentosAdministrativos,
      finalizacao?.procedimentosAtendimento
    ),
  }
}

const convertDesfechoAtendimentoFormToInput = (
  desfechoAtendimento: DesfechoAtendimentoFormModel,
  isObservacaoAndAuxiliarDaObservacao: boolean = false
): DesfechoAtendimentoInput => {
  if (!desfechoAtendimento) {
    return null
  }

  const manterCidadaoLista = desfechoAtendimento.manterCidadaoLista === ManterCidadaoLista.SIM

  return {
    manterCidadaoLista: isObservacaoAndAuxiliarDaObservacao ? true : manterCidadaoLista,
    atendimento: manterCidadaoLista ? convertNovoAtendimentoModelToInput(desfechoAtendimento.atendimento) : undefined,
  }
}

const convertFichasNotificacaoCasoSuspeitoQueryToForm = (
  items: NotificacaoCasoSuspeitoQueryModel[]
): NotificacaoCasoSuspeitoFieldModel[] => {
  return items?.map(({ id, notificacao }) => ({
    _id: uuidv4(),
    editingId: id,
    fichaNotificacao: notificacao,
  }))
}

const convertProcedimentosAdmistrativosQueryToForm = (
  prevValue: ProcedimentoSigtapFieldModel[],
  items: ProcedimentoAtendimentoQueryModel[]
): ProcedimentoSigtapFieldModel[] => {
  return [
    ...(prevValue ?? []),
    ...(items?.map((item) => convertProcedimentoAdminstrativoQueryToForm(item)) ?? []),
  ].removeDuplicate((item) => item.procedimento.id)
}

const convertProcedimentoAdminstrativoQueryToForm = ({
  id,
  procedimento,
  automatico,
}: ProcedimentoAtendimentoQueryModel): ProcedimentoSigtapFieldModel => {
  return {
    _id: uuidv4(),
    editingId: id,
    procedimento,
    isAutomatico: automatico,
  }
}

const convertProcedimentosAdministrativosFormToInput = (
  items: ProcedimentoSigtapFieldModel[]
): ProcedimentoAtendimentoInput[] => {
  return items?.map((procedimento) => ({
    id: procedimento.procedimento.id,
    cid10PrincipalId: procedimento.procedimento.procedimentoReferencia?.id,
    isAutomatico: procedimento.isAutomatico,
  }))
}
