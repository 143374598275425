/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormControl, HFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps, SubmitButton, TelefoneField } from 'components/form'
import { useCidadaoTelefoneCelularUpdateMutation } from 'graphql/hooks.generated'
import { noop } from 'lodash'
import { FormProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, required, telefone } from 'util/validation'

interface TelefoneCelularUpdateFormModel {
  telefoneCelular: string
}

const validate = createValidator<TelefoneCelularUpdateFormModel>({
  telefoneCelular: [required, telefone],
})

const path = metaPath<TelefoneCelularUpdateFormModel>()

interface TelefoneCelularUpdateFormProps
  extends Omit<FormProps<TelefoneCelularUpdateFormModel>, 'onSubmit' | 'validate' | 'render'> {
  cidadaoId: ID
  emailCidadao: string
  onSubmitSuccess?: (newValue: string) => void
}

export const TelefoneCelularUpdateForm = (props: TelefoneCelularUpdateFormProps) => {
  const { cidadaoId, emailCidadao, onSubmitSuccess, ...rest } = props

  const [updateTelefoneCelularMutation] = useCidadaoTelefoneCelularUpdateMutation()
  const handleRejection = useErrorHandler()

  const handleAtualizarTelefoneClicked = ({ telefoneCelular }: TelefoneCelularUpdateFormModel) =>
    updateTelefoneCelularMutation({
      variables: {
        input: {
          cidadaoId,
          telefoneCelular,
          email: emailCidadao,
        },
      },
    })
      .then(() => {
        onSubmitSuccess?.(telefoneCelular)
      })
      .catch(handleRejection)

  const renderForm = ({ handleSubmit }: FormRenderProps<TelefoneCelularUpdateFormModel>) => {
    return (
      <HFlow hSpacing={0.5}>
        <TelefoneField name={path.telefoneCelular} label='Telefone celular' required />
        <FormControl label={<span>&nbsp;</span>}>
          <SubmitButton kind='primary' size='small' handleSubmit={handleSubmit}>
            Atualizar
          </SubmitButton>
        </FormControl>
      </HFlow>
    )
  }

  return (
    <Form<TelefoneCelularUpdateFormModel>
      onSubmit={handleAtualizarTelefoneClicked}
      validate={validate}
      render={renderForm}
      onSubmitFailed={noop} // Evita a notificação de erro fora da modal
      {...rest}
    />
  )
}
