import { useProblemasModalSimplificadoQuery } from 'graphql/hooks.generated'
import React, { Fragment, useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'
import { Route, RouteComponentProps, useHistory, useRouteMatch } from 'react-router'
import { emptyArray } from 'util/array'
import { CiapCidPreNatal, meta, SoapState } from 'view/atendimentos/atendimento-individual/model'
import { ListaProblemasModal } from 'view/atendimentos/detail/components/modals/lista-problemas/ListaProblemasModal'
import { CidadaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'
import { ProntuarioCard } from '../ProntuarioCard'
import { ProblemasCard } from './ProblemasCard'
import { useFormatProblemas } from './useFormatProblemas'

interface ProblemasSectionProps {
  prontuarioId: ID
  cidadao: CidadaoAtendimento | CidadaoFolhaRosto
  isAtendimentoObservacao: boolean
  problemasAvaliacao?: ProblemaCondicaoModel[]
  problemasLPC?: ProblemaCondicaoModel[]
  ciapCidPreNatal?: CiapCidPreNatal
  somenteCiap?: boolean
  dataReferencia?: Instant
  readOnly?: boolean
}

export function ProblemasSection(props: ProblemasSectionProps) {
  const {
    prontuarioId,
    cidadao,
    isAtendimentoObservacao,
    problemasAvaliacao = emptyArray,
    problemasLPC = emptyArray,
    dataReferencia,
    ciapCidPreNatal,
    somenteCiap,
    readOnly,
  } = props

  const history = useHistory()
  const match = useRouteMatch()
  const [canRender, setCanRender] = useState(false)

  const handleClick = () => history.push(`${match.url}/lista-problemas-condicoes`)

  const {
    loading,
    data: { problemas },
  } = useProblemasModalSimplificadoQuery({
    variables: { filtro: { prontuarioId } },
  })

  const todosProblemas = useFormatProblemas(problemas?.content, problemasAvaliacao, problemasLPC)
  const problemasExpandido = todosProblemas.getProblemasExpandido()
  const problemasAccordion = todosProblemas.getProblemasAccordion()

  const tooltip = readOnly
    ? problemas?.content?.isNotEmpty()
      ? 'Ver lista de problemas/condições'
      : 'Nenhum problema/condição registrado'
    : canRender
    ? 'Ver ou adicionar problemas/condições'
    : 'Adicionar problemas/condições'

  useEffect(() => {
    setCanRender(problemasExpandido.isNotEmpty())
  }, [problemasExpandido])

  const renderModal = ({ renderProps, formValues }: { renderProps: RouteComponentProps; formValues?: SoapState }) => (
    <ListaProblemasModal
      {...renderProps}
      name={meta.problemasECondicoes}
      prontuarioId={prontuarioId}
      dataAtendimento={dataReferencia}
      isAtendimentoObservacao={isAtendimentoObservacao}
      problemasAvaliacao={formValues?.avaliacao?.problemasECondicoes}
      problemasLPC={formValues?.problemasECondicoes}
      ciapCidPreNatal={ciapCidPreNatal}
      somenteCiap={somenteCiap}
      cidadao={cidadao}
      readOnly={readOnly}
    />
  )

  return (
    <Fragment>
      <Route
        path={`${match.url}/lista-problemas-condicoes`}
        render={(renderProps) =>
          readOnly ? (
            renderModal({ renderProps })
          ) : (
            <FormSpy<SoapState> subscription={{ values: true }}>
              {({ values: formValues }) => renderModal({ renderProps, formValues })}
            </FormSpy>
          )
        }
      />
      <ProntuarioCard title='Lista de problemas/condições' tooltip={tooltip} onClick={handleClick}>
        <ProblemasCard
          problemasExpandido={problemasExpandido}
          problemasAccordion={problemasAccordion}
          loading={loading}
          canRender={canRender}
          readOnly={readOnly}
        />
      </ProntuarioCard>
    </Fragment>
  )
}
