import { isEmpty } from 'bold-ui'
import {
  EvolucaoDenteInput,
  EvolucaoProcedimentoOdontoInput,
  ExameRequisitadoInput,
  MedicamentoPlanoInput,
  OdontologiaInput,
  OrientacaoPlanoInput,
  ParteBucalEnum,
  PeriodontiaInput,
  PeriogramaCompletoInput,
  PlanoInput,
  PrescricaoMedicamentoPlanoInput,
  SituacaoFaceEnum,
  SituacaoRaizEnum,
  SolicitacaoExameInput,
  TecidoMoleInput,
  TipoEncaminhamentoExternoEnum,
} from 'graphql/types.generated'
import { compact, concat, partition } from 'lodash'
import { isObjectDeepEmpty } from 'util/object'
import { v4 as uuidv4 } from 'uuid'

import { SolicitacaoExameQueryModel } from '../objetivo/resultados-exames/model'
import { convertAtestadoPlanoFormToInput, convertAtestadoQueryToForm } from './atestados/converter'
import { AtestadoQueryModel } from './atestados/model'
import {
  convertEncaminhamentoEspecializadoQueryToForm,
  convertEncaminhamentoPlanoFormToInput,
} from './encaminhamento-externo/converter'
import { EncaminhamentoExternoEspecializadoQueryModel } from './encaminhamento-externo/model'
import { ArcadaFieldModel } from './evolucoes-odontologicas/components/odontograma-field/arcadas-field/ArcadasField'
import { DenteFieldModel } from './evolucoes-odontologicas/components/odontograma-field/dentes-field/DentesField'
import { EvolucaoDenteSupranumerarioModel } from './evolucoes-odontologicas/components/odontograma-field/dentes-supranumerarios/DentesSupranumerariosField'
import { OdontogramaFieldModel } from './evolucoes-odontologicas/components/odontograma-field/OdontogramaField'
import { OutrosFieldModel } from './evolucoes-odontologicas/components/odontograma-field/outros-field/OutrosField'
import { OdontologiaModel } from './evolucoes-odontologicas/EvolucoesOdontologicasView'
import {
  PeriogramaCompletoCondicao,
  PeriogramaCompletoFace,
  PeriogramaCompletoMultipleBoolean,
  PeriogramaCompletoTableInputValue,
  PeriogramaCompletoTableModel,
  possuiPeriogramaLesaoDeFurca,
} from './evolucoes-odontologicas/periograma/periograma-completo/model'
import { isPreenchendoRPS } from './evolucoes-odontologicas/periograma/periograma-simplificado/PeriogramaSimplificadoField'
import { PeriodontiaModel } from './evolucoes-odontologicas/periograma/PeriogramaView'
import { TecidosMolesFieldModel } from './evolucoes-odontologicas/tecidos-moles/TecidosMolesField'
import { getDentePosicaoProtese, isCoroaDisabled } from './evolucoes-odontologicas/util'
import { ExameListSelectFieldModel } from './exames/components/ExameListSelectField'
import { SolicitacaoExameModel } from './exames/model'
import { EvolucaoPlanoQueryModel, OrientacaoQueryModel } from './model'
import { OrientacaoFormModel } from './orientacoes/types/OrientacoesModel'
import { PlanoFormModel } from './PlanoForm'
import { ReceitaMedicamentoQueryModel } from './prescricao-medicamento/model'
import { PrescricaoMedicamentoPlanoModel } from './prescricao-medicamento/PrescricaoMedicamentoView'
import { convertReceitaMedicamentoToMedicamento } from './prescricao-medicamento/utils/prescricaoConverter'

export const convertPlanoFormToInput = (form: PlanoFormModel): PlanoInput =>
  form && {
    texto: form.texto,
    isObservacao: form.startObservacao,
    ciaps: form.intervencoesProcedimentos?.ciaps?.map((ciap) => ({
      id: ciap.editingId,
      ciapId: ciap.ciap.id,
      observacao: ciap.observacao,
    })),
    procedimentos: form.intervencoesProcedimentos?.procedimentos?.map((procedimento) => ({
      id: procedimento.editingId,
      procedimentoId: procedimento.procedimento.id,
      observacao: procedimento.observacao,
    })),
    prescricaoMedicamento:
      form?.prescricaoMedicamento && !isEmpty(form.prescricaoMedicamento.medicamentos)
        ? convertPrescricaoPlanoToInput(form.prescricaoMedicamento)
        : null,
    odontologia: form.odontologia && convertOdontologiaPlanoToInput(form.odontologia),
    atestado: form.atestado && convertAtestadoPlanoFormToInput(form.atestado),
    encaminhamentosExternos: form.encaminhamentoExterno?.encaminhamentosRecentes,
    encaminhamentosExternosEspecializados:
      form.encaminhamentoExterno?.encaminhamentosEspecializadosRecentes &&
      convertEncaminhamentoPlanoFormToInput(form.encaminhamentoExterno.encaminhamentosEspecializadosRecentes),
    orientacoes: form.orientacao && convertOrientacoesToInput(form.orientacao),
    solicitacoesExame: form?.solicitacoesExame && convertSolicitacoesExameToInput(form.solicitacoesExame),
  }

const convertPrescricaoPlanoToInput = (
  prescricaoMedicamento: PrescricaoMedicamentoPlanoModel
): PrescricaoMedicamentoPlanoInput => {
  return {
    medicamentos: prescricaoMedicamento.medicamentos.map(
      (medicamento) =>
        ({
          id: medicamento.editingId,
          medicamentoId: medicamento.principioAtivoCombo?.medicamento?.id,
          medicamentoRegistroManual: !!medicamento.registroManual
            ? {
                id: null,
                formaFarmaceutica: medicamento.formaFarmaceutica.id,
                unidadeFornecimento: medicamento.unidadeFornecimento,
                concentracao: medicamento.concentracaoText,
                medicamentoText: medicamento.principioAtivoText,
              }
            : null,
          tipoReceita: !!medicamento.registroManual
            ? medicamento.tipoReceita.tipoReceitaEnum
            : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita,
          viaAdministracao: medicamento.viaAdministracao.id,
          dose: medicamento.dose,
          doseUnica: !!medicamento.doseUnica,
          tipoFrequencia: !medicamento.doseUnica ? medicamento.tipoFrequencia : null,
          intervaloDose: medicamento.intervaloDose,
          frequenciaDose: medicamento.frequenciaDose,
          // valores para aba de frequencia e turno
          quantidadePeriodoFrequenciaTurno: medicamento.quantidadePeriodoFrequenciaTurno,
          unidadeMedidaTempoFrequenciaTurno: medicamento.unidadeMedidaTempoFrequenciaTurno,

          turno: medicamento.turno,
          posologia: medicamento.posologia,
          dataInicioTratamento: medicamento.dataInicioTratamento,
          dataFimTratamento: medicamento.dataFimTratamento,
          duracao: medicamento.duracao,
          escalaDuracao: medicamento.escalaDuracao,
          quantidade: medicamento.quantidade,
          usoContinuo: !!medicamento.usoContinuo,
          recomendacoes: medicamento.recomendacoes,
        } as MedicamentoPlanoInput)
    ),
  } as PrescricaoMedicamentoPlanoInput
}

const convertOdontologiaPlanoToInput = (odontologia: OdontologiaModel): OdontologiaInput => ({
  ...convertOdontogramaPlanoToInput(odontologia.odontograma),
  tecidosMoles: odontologia?.tecidosMoles && convertTecidosMolesToInput(odontologia.tecidosMoles),
  periodontia: odontologia?.periodontia && convertPeriodontiaToInput(odontologia.periodontia),
})

const convertTecidosMolesToInput = (tecidosMoles: TecidosMolesFieldModel[]): TecidoMoleInput[] =>
  tecidosMoles.map(
    (tecidoMole) =>
      ({
        parteBucal: tecidoMole.local.value,
        cid10: tecidoMole.problema?.id,
        procedimentos: tecidoMole.procedimentos?.map(({ id }) => id),
        observacao: tecidoMole.observacao,
        caracteristicasLesao:
          tecidoMole.caracteristicasLesao &&
          [
            tecidoMole.caracteristicasLesao.base,
            tecidoMole.caracteristicasLesao.consistencia,
            tecidoMole.caracteristicasLesao.cor,
            tecidoMole.caracteristicasLesao.lesaoFundamental?.lesaoEnvolvida,
            tecidoMole.caracteristicasLesao.quantidade,
            tecidoMole.caracteristicasLesao.sintomatologia?.value !== 'outros' &&
              tecidoMole.caracteristicasLesao.sintomatologia?.value,
            tecidoMole.caracteristicasLesao.superficie,
            tecidoMole.caracteristicasLesao.tamanho,
          ].filter((item) => item),
        descricaoSintomatologia: tecidoMole.caracteristicasLesao?.sintomatologia?.descricaoOutro,
      } as TecidoMoleInput)
  )

const convertPeriodontiaToInput = (periodontia: PeriodontiaModel): PeriodontiaInput => {
  const {
    avaliacaoSextante1,
    avaliacaoSextante2,
    avaliacaoSextante3,
    avaliacaoSextante4,
    avaliacaoSextante5,
    avaliacaoSextante6,
  } = periodontia.periogramaSimplificado || {}
  const isPreenchendo = isPreenchendoRPS([
    avaliacaoSextante1,
    avaliacaoSextante2,
    avaliacaoSextante3,
    avaliacaoSextante4,
    avaliacaoSextante5,
    avaliacaoSextante6,
  ])

  return {
    evolucoes: periodontia?.evolucoes?.map((evolucao) => ({
      parteBucal: evolucao.sextante,
      procedimentos: evolucao.procedimentos?.map(({ id }) => id),
      observacao: evolucao.observacao,
    })),
    periogramaSimplificado: isPreenchendo ? periodontia.periogramaSimplificado : null,
    periogramaCompleto: !isObjectDeepEmpty(periodontia?.periogramaCompleto?.dados)
      ? convertPeriogramaCompletoToInput(periodontia.periogramaCompleto?.dados)
      : null,
    observacaoPeriogramaCompleto: periodontia?.periogramaCompleto?.observacao,
  }
}

const convertPeriogramaCompletoToInput = (periogramaCompleto: PeriogramaCompletoTableModel) =>
  Object.entries(periogramaCompleto).map(([dente, denteValues]) => {
    const values = denteValues?.values
    const faceVestibular = PeriogramaCompletoFace.VESTIBULAR
    const faceInterna = values && Object.keys(values).find((face) => face !== faceVestibular)
    const excluido = !!denteValues.ausente

    return {
      dente: dente,
      ...(!excluido && {
        profundidadeVestibular: values[faceVestibular][PeriogramaCompletoCondicao.PROF_SONDAGEM],
        profundidadeInterna: values[faceInterna][PeriogramaCompletoCondicao.PROF_SONDAGEM],
        recessaoVestibular: values[faceVestibular][PeriogramaCompletoCondicao.RECESSAO],
        recessaoInterna: values[faceInterna][PeriogramaCompletoCondicao.RECESSAO],
        mobilidade: values[faceInterna][PeriogramaCompletoCondicao.MOBILIDADE],
        ...extractPeriogramaCompletoPlacas([
          values[faceVestibular][PeriogramaCompletoCondicao.PLACA],
          values[faceInterna][PeriogramaCompletoCondicao.PLACA],
        ]),
        ...extractPeriogramaCompletoSangramento([
          values[faceVestibular][PeriogramaCompletoCondicao.SANG_SONDAGEM],
          values[faceInterna][PeriogramaCompletoCondicao.SANG_SONDAGEM],
        ]),
        lesaoDeFurca: extractPeriogramaCompletoLesaoDeFurca(
          dente as ParteBucalEnum,
          values[faceInterna][PeriogramaCompletoCondicao.LESAO_FURCA]
        ),
      }),
      excluido,
    } as PeriogramaCompletoInput
  })

const extractPeriogramaCompletoPlacas = ([valueVestibular, valueInterna]: [
  PeriogramaCompletoTableInputValue,
  PeriogramaCompletoTableInputValue
]) => {
  const typedValues = [valueVestibular, valueInterna] as [
    PeriogramaCompletoMultipleBoolean,
    PeriogramaCompletoMultipleBoolean
  ]

  return {
    placaDVestibular: typedValues?.[0]?.d || false,
    placaVVestibular: typedValues?.[0]?.v || false,
    placaMVestibular: typedValues?.[0]?.m || false,
    placaDInterna: typedValues?.[1]?.d || false,
    placaVInterna: typedValues?.[1]?.v || false,
    placaMInterna: typedValues?.[1]?.m || false,
  }
}

const extractPeriogramaCompletoSangramento = ([valueVestibular, valueInterna]: [
  PeriogramaCompletoTableInputValue,
  PeriogramaCompletoTableInputValue
]) => {
  const typedValues = [valueVestibular, valueInterna] as [
    PeriogramaCompletoMultipleBoolean,
    PeriogramaCompletoMultipleBoolean
  ]

  return {
    sangramentoDVestibular: typedValues?.[0]?.d || false,
    sangramentoVVestibular: typedValues?.[0]?.v || false,
    sangramentoMVestibular: typedValues?.[0]?.m || false,
    sangramentoDInterna: typedValues?.[1]?.d || false,
    sangramentoVInterna: typedValues?.[1]?.v || false,
    sangramentoMInterna: typedValues?.[1]?.m || false,
  }
}

const extractPeriogramaCompletoLesaoDeFurca = (dente: ParteBucalEnum, value: PeriogramaCompletoTableInputValue) =>
  possuiPeriogramaLesaoDeFurca(dente) ? value : '-'

const convertOdontogramaPlanoToInput = (odontograma: OdontogramaFieldModel): OdontologiaInput =>
  odontograma &&
  ({
    evolucoesDente:
      odontograma.dentes &&
      convertOdontogramaDentesToInput(
        odontograma.dentes,
        odontograma.proteseTotalSuperior,
        odontograma.proteseTotalInferior
      ),
    evolucoesProcedimentoOdonto: concat(
      odontograma.arcadas && convertOdontogramaArcadasToInput(odontograma.arcadas),
      odontograma.outros && convertOdontogramaOutrosToInput(odontograma.outros),
      odontograma.dentesSupranumerarios &&
        convertOdontogramaDentesSupranumerariosToInput(odontograma.dentesSupranumerarios)
    ).filter((item) => !!item),
    aparelho: odontograma.possuiAparelho,
    contencao: odontograma.possuiContencao,
    proteseTotalInferior: odontograma.proteseTotalInferior,
    proteseTotalSuperior: odontograma.proteseTotalSuperior,
  } as OdontologiaInput)

const convertOdontogramaArcadasToInput = (
  arcadas: Record<ParteBucalEnum, ArcadaFieldModel>
): EvolucaoProcedimentoOdontoInput[] =>
  Object.entries(arcadas).map(
    ([key, arcada]) =>
      arcada.procedimentos?.length > 0 &&
      ({
        parteBucal: key,
        procedimentos: arcada.procedimentos.map(({ id }) => id),
        observacao: arcada.observacoes && arcada.observacoes[0],
      } as EvolucaoProcedimentoOdontoInput)
  )

const convertOdontogramaDentesToInput = (
  dentes: Record<ParteBucalEnum, DenteFieldModel>,
  proteseTotalSuperior?: boolean,
  proteseTotalInferior?: boolean
): EvolucaoDenteInput[] =>
  compact(
    Object.entries(dentes).map(([key, dente]: [ParteBucalEnum, DenteFieldModel]) => {
      const coroaDisabled =
        isCoroaDisabled(dente) ||
        (getDentePosicaoProtese(key) === 'superior' ? proteseTotalSuperior : proteseTotalInferior)

      return (
        !isObjectDeepEmpty(dente) &&
        ({
          parteBucal: key,
          situacoesRaiz:
            dente.condicoesDente &&
            dente.condicoesDente.filter((condicao) =>
              Object.values(SituacaoRaizEnum).includes(condicao as SituacaoRaizEnum)
            ),
          situacoesFace:
            dente.condicoesDente &&
            dente.condicoesDente.filter((condicao) =>
              Object.values(SituacaoFaceEnum).includes(condicao as SituacaoFaceEnum)
            ),
          procedimentos: dente.procedimentos?.map(({ id }) => id),
          observacao: dente.observacoes && dente.observacoes[0],
          situacaoCoroaCima:
            !coroaDisabled && dente.coroa?.faceTop?.activeColor ? dente.coroa.faceTop.activeColor.value : undefined,
          situacaoCoroaEsquerda:
            !coroaDisabled && dente.coroa?.faceLeft?.activeColor ? dente.coroa.faceLeft.activeColor.value : undefined,
          situacaoCoroaDireita:
            !coroaDisabled && dente.coroa?.faceRight?.activeColor ? dente.coroa.faceRight.activeColor.value : undefined,
          situacaoCoroaBaixo:
            !coroaDisabled && dente.coroa?.faceBottom?.activeColor
              ? dente.coroa.faceBottom.activeColor.value
              : undefined,
          situacaoCoroaCentro:
            !coroaDisabled && dente.coroa?.faceCenter?.activeColor
              ? dente.coroa.faceCenter.activeColor.value
              : undefined,
        } as EvolucaoDenteInput)
      )
    })
  )

const convertOdontogramaOutrosToInput = (outros: OutrosFieldModel[]): EvolucaoProcedimentoOdontoInput[] =>
  outros.map(
    (outro) =>
      outro.procedimentos?.length > 0 &&
      ({
        parteBucal: ParteBucalEnum.OUTROS,
        descricaoLocal: outro.local,
        procedimentos: outro.procedimentos.map(({ id }) => id),
        observacao: outro.observacao,
      } as EvolucaoProcedimentoOdontoInput)
  )

const convertOdontogramaDentesSupranumerariosToInput = (
  dentesSupranumerarios: EvolucaoDenteSupranumerarioModel[]
): EvolucaoProcedimentoOdontoInput[] =>
  dentesSupranumerarios.map(
    (denteSupranumerario) =>
      denteSupranumerario.procedimentos?.length > 0 &&
      ({
        parteBucal: ParteBucalEnum.DENTE_SUPRANUMERARIO,
        descricaoLocal: denteSupranumerario.local,
        procedimentos: denteSupranumerario.procedimentos.map(({ id }) => id),
        observacao: denteSupranumerario.observacao,
      } as EvolucaoProcedimentoOdontoInput)
  )

const convertSolicitacoesExameToInput = (solicitacoesExame: SolicitacaoExameModel[]): SolicitacaoExameInput[] => {
  return solicitacoesExame.map(
    (solicitacao: SolicitacaoExameModel): SolicitacaoExameInput => {
      return {
        id: solicitacao.editingId,
        justificativa: solicitacao.justificativa,
        tipoExame: solicitacao.tipoExame,
        observacoes: solicitacao.observacao,
        examesRequisitados: solicitacao.examesSolicitados.map(
          (exameRequisitado: ExameListSelectFieldModel): ExameRequisitadoInput => {
            return {
              id: exameRequisitado.editingId,
              exameId: exameRequisitado.exame.id,
              observacao: exameRequisitado.observacao,
            }
          }
        ),
        cid10: solicitacao.cid10?.id,
      }
    }
  )
}

const convertSolicitacoesExamesQueryToForm = (inputs: SolicitacaoExameQueryModel[]): SolicitacaoExameModel[] =>
  inputs?.map(
    (input): SolicitacaoExameModel => ({
      _id: uuidv4(),
      editingId: input.id,
      justificativa: input.justificativa,
      tipoExame: input.tipoExame,
      cid10: input.cid10,
      examesSolicitados: input.exames.map((exame) => ({
        _id: uuidv4(),
        editingId: exame.id,
        exame: exame.exame,
      })),
    })
  )

const convertOrientacoesToInput = (orientacoes: OrientacaoFormModel[]): OrientacaoPlanoInput[] => {
  return orientacoes.map((orientacao) => {
    return {
      id: orientacao.editingId,
      descricao: orientacao.descricao,
    }
  })
}

const convertOrientacoesQueryToForm = (inputs: OrientacaoQueryModel[]): OrientacaoFormModel[] =>
  inputs?.map((input) => ({
    editingId: input.id,
    descricao: input.descricao,
  }))

export const convertPlanoQueryToForm = (
  prevValue: PlanoFormModel,
  {
    evolucaoPlano,
    solicitacoesExames,
    orientacoes,
    receitasMedicamento,
    encaminhamentos,
    encaminhamentosEspecializados,
    atestados,
    dataReferencia,
  }: {
    evolucaoPlano: EvolucaoPlanoQueryModel
    solicitacoesExames: SolicitacaoExameQueryModel[]
    orientacoes: OrientacaoQueryModel[]
    receitasMedicamento: ReceitaMedicamentoQueryModel[]
    encaminhamentos: TipoEncaminhamentoExternoEnum[]
    encaminhamentosEspecializados: EncaminhamentoExternoEspecializadoQueryModel[]
    atestados: AtestadoQueryModel[]
    dataReferencia: Instant
  }
): PlanoFormModel => {
  const { intervencoesProcedimentos } = evolucaoPlano ?? {}

  const [intervencoesSigtaps, intervencoesCiaps] = intervencoesProcedimentos
    ? partition(intervencoesProcedimentos, (item) => isEmpty(item.ciap))
    : []

  return {
    ...prevValue,
    intervencoesProcedimentos: {
      ciaps: intervencoesCiaps?.map((ciap) => ({
        _id: uuidv4(),
        editingId: ciap.id,
        ciap: ciap.ciap,
        observacao: ciap.nota,
      })),
      procedimentos: intervencoesSigtaps?.map((sigtap) => ({
        _id: uuidv4(),
        editingId: sigtap.id,
        procedimento: sigtap.procedimento,
        observacao: sigtap.nota,
      })),
    },
    prescricaoMedicamento: {
      medicamentos: receitasMedicamento?.map((receita) =>
        convertReceitaMedicamentoToMedicamento(receita, false, false, dataReferencia)
      ),
      replicateStorage: [],
      alreadyPrinted: [],
      medicamentoIdsOnPrescricaoDigital: [],
    },
    solicitacoesExame: convertSolicitacoesExamesQueryToForm(solicitacoesExames),
    orientacao: convertOrientacoesQueryToForm(orientacoes),
    encaminhamentoExterno: {
      encaminhamentosRecentes:
        encaminhamentos?.filter((item) => item !== TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO) ?? [],
      encaminhamentosEspecializadosRecentes: encaminhamentosEspecializados?.map(
        convertEncaminhamentoEspecializadoQueryToForm
      ),
    },
    atestado: atestados?.isNotEmpty()
      ? {
          atestadoModelo: null,
          atestadosRecentes: atestados?.map(convertAtestadoQueryToForm),
        }
      : null,
  }
}
