import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router'
import AtendimentoListing from 'view/atendimentos/components/AtendimentoListing'

import ListaEsperaForm from './ListaEsperaForm'
import ListaEsperaTable from './ListaEsperaTable'

interface UrlParams {
  cidadaoId: string
}

export default function ListaEsperaView() {
  const location = useLocation()
  const {
    acesso: { unidadeSaude },
  } = useAcessoLotacaoOrEstagio()
  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 5,
  })

  return (
    <>
      <Breadcrumb title='Gestão da garantia do acesso' />
      <AtendimentoListing
        title='Gestão da garantia do acesso'
        heading={<ListaEsperaForm cidadaoId={urlParams.cidadaoId} />}
        filter={undefined}
        children={<ListaEsperaTable unidadeSaudeId={unidadeSaude.id} />}
        footer={undefined}
      />
    </>
  )
}
