/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Ellipsis } from 'components/Ellipsis'
import { Form, FormRenderProps } from 'components/form'
import { useCallback, useMemo } from 'react'
import Permissions from 'types/Permissions'

import { AgendaConfiguracaoDropdown } from './components/configuracao-dropdown/AgendaConfiguracaoDropdown'
import { ImpressaoAgendaButton, ImpressaoAgendaButtonProps } from './components/impressao/ImpressaoAgendaButton'
import { LotacaoAgendaSelectField } from './components/lotacao/LotacaoAgendaSelectField'
import { LotacaoAgendaFormModel, LotacaoAgendaModel } from './model'

export interface LotacaoAgendaSectionProps {
  atencaoDomiciliar?: boolean
  onChange(lotacao: LotacaoAgendaModel): void
  initialValue: LotacaoAgendaModel
  impressaoAgenda?: Pick<ImpressaoAgendaButtonProps, 'areThereEventos' | 'date'>
}

export function LotacaoAgendaSection(props: LotacaoAgendaSectionProps) {
  const { atencaoDomiciliar: isAD = false, onChange, initialValue: externalInitialValue, impressaoAgenda } = props
  const { hasAuthorization } = useSession()

  const hasAuthorizationRecursosConfigAgenda =
    hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.configurarAgenda) ||
    hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.configurarAgendaOnline) ||
    hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.fecharAgenda)
  const initialValue = useMemo<LotacaoAgendaFormModel>(() => ({ lotacao: externalInitialValue }), [
    externalInitialValue,
  ])

  const handleSubmit = useCallback((form: LotacaoAgendaFormModel) => onChange(form.lotacao), [onChange])

  const renderLotacaoForm = ({ values: { lotacao } = {}, form }: FormRenderProps<LotacaoAgendaFormModel>) => (
    <VFlow
      vSpacing={0.5}
      style={css`
        padding-left: 0.5rem;
      `}
    >
      <Grid wrap={false}>
        <Cell
          flexGrow={1}
          style={css`
            margin-right: -1rem;
          `}
        >
          <LotacaoAgendaSelectField
            name='lotacao'
            isAgendaAd={isAD}
            onChange={form.submit}
            placeholder='Busque um profissional pelo seu nome, CNS, equipe ou CBO'
          />
        </Cell>
        <Cell>
          <ImpressaoAgendaButton
            isAgendaAd={isAD}
            lotacaoId={lotacao?.id}
            nomeProfissional={lotacao?.profissional.nome}
            {...impressaoAgenda}
          />
          {hasAuthorizationRecursosConfigAgenda && <AgendaConfiguracaoDropdown lotacao={lotacao} />}
        </Cell>
      </Grid>

      {lotacao && (
        <HFlow hSpacing={0.4} style={styles.infos}>
          <Text fontWeight='bold'>CBO</Text>
          <Ellipsis>{lotacao.cbo.nome.capitalize()}</Ellipsis>
          <Text>|</Text>
          <Text fontWeight='bold'>Equipe</Text>
          <Ellipsis>{lotacao?.equipe ? `${lotacao.equipe?.ine} - ${lotacao.equipe?.nome}` : 'Não informada'}</Ellipsis>
          {!lotacao.ativo && (
            <Tooltip text='Lotação está inativada, mas possui agendamentos futuros.'>
              <Tag style={styles.tag}>Inativo</Tag>
            </Tooltip>
          )}
          {!lotacao.hasConfiguracaoAgenda && (
            <Tooltip text='Lotação não possui agenda configurada, mas possui agendamentos futuros.'>
              <Tag style={styles.tag}>Sem agenda</Tag>
            </Tooltip>
          )}
        </HFlow>
      )}
    </VFlow>
  )

  return (
    <Form<LotacaoAgendaFormModel> render={renderLotacaoForm} initialValues={initialValue} onSubmit={handleSubmit} />
  )
}

const styles = {
  infos: css`
    grid-template-columns: 1.5rem fit-content(100%);
  `,

  tag: css`
    margin-left: 0.5rem;
  `,
}
