/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HeadingSection, HFlow, Icon, Text } from 'bold-ui'
import {
  CepField,
  EquipeSelectField,
  Form,
  FormRenderProps,
  SubmitButton,
  TextField,
  UnidadeSaudeSelectField,
} from 'components/form'
import { MicroareaImovelSelectField } from 'components/form/field/select/MicroareaImovelSelectField/MicroareaImovelSelectField'
import { confirm } from 'components/modals/confirm'
import { SyntheticEvent } from 'react'
import { metaPath } from 'util/metaPath'

import { createBuscarImoveisFormCalculator } from './calculator'
import { ReterritorializacaoBuscaFormModel } from './model'
import { validateReterritorializacaoBuscaForm } from './validator'

const meta = metaPath<ReterritorializacaoBuscaFormModel>()

interface ReterritorializacaoFormProps {
  onSubmit: (formValues: ReterritorializacaoBuscaFormModel) => void
  resetToInitialValues: boolean
  resetPageState: () => void
  hasCheckedImoveis: boolean
  disabled?: boolean
}

export function ReterritorializacaoBuscaForm({
  onSubmit,
  resetToInitialValues,
  resetPageState,
  hasCheckedImoveis,
  disabled,
}: ReterritorializacaoFormProps) {
  const renderForm = (formApi: FormRenderProps<ReterritorializacaoBuscaFormModel>) => {
    const { handleSubmit, values } = formApi
    return (
      <form noValidate>
        <HeadingSection
          level={2}
          title='Endereço dos imóveis'
          vSpace={5}
          style={css`
            margin-top: 1rem;
          `}
        >
          <Text>
            Preencha os campos para realizar uma busca nas Fichas de Cadastro Domiciliar e Territorial e atualize as
            informações.
          </Text>
          <Grid
            wrap
            style={css`
              margin-top: 1rem;
            `}
          >
            <Cell size={2}>
              <TextField name={meta.bairro} label='Bairro' maxLength={72} disabled={disabled} />
            </Cell>
            <Cell size={4}>
              <TextField name={meta.logradouro} label='Logradouro' maxLength={72} disabled={disabled} />
            </Cell>
            <Cell size={2}>
              <CepField name={meta.cep} label='CEP' disabled={disabled} />
            </Cell>
            <Cell size={4} />
            <Cell size={3}>
              <UnidadeSaudeSelectField label='Unidade de Saúde' name={meta.unidadeSaude} disabled={disabled} />
            </Cell>
            <Cell size={2}>
              <EquipeSelectField
                name={meta.equipe}
                unidadeSaudeId={values.unidadeSaude?.id}
                label='Equipe'
                disabled={disabled || !values.unidadeSaude}
                showSemEquipeOption
              />
            </Cell>
            <Cell size={2}>
              <MicroareaImovelSelectField
                name={meta.microarea}
                label='Microárea'
                cnes={values.unidadeSaude?.cnes}
                ine={values.equipe?.ine}
                disabled={disabled || !values.equipe}
              />
            </Cell>
            <Cell size={1} />
            <Cell size={4}>
              <HFlow>
                <div style={{ marginTop: 25 }}>
                  <Button
                    size='small'
                    kind='normal'
                    onClick={
                      hasCheckedImoveis === true
                        ? () => onLimparCamposWithCheckedImoveisConfirmModal(resetPageState)
                        : resetPageState
                    }
                    disabled={disabled}
                  >
                    Limpar busca
                  </Button>
                </div>
                <div style={{ marginTop: 25 }}>
                  <SubmitButton
                    size='small'
                    kind='primary'
                    disabled={disabled || isSubmitButtonDisable(values)}
                    handleSubmit={
                      hasCheckedImoveis === true
                        ? (e) => onBuscarImoveisWithCheckedImoveisConfirmModal(e, handleSubmit)
                        : handleSubmit
                    }
                  >
                    <Icon
                      icon='zoomOutline'
                      style={css`
                        margin-right: 0.5rem;
                      `}
                    />
                    Buscar imóveis
                  </SubmitButton>
                </div>
              </HFlow>
            </Cell>
          </Grid>
        </HeadingSection>
      </form>
    )
  }

  return (
    <Form<ReterritorializacaoBuscaFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={validateReterritorializacaoBuscaForm}
      resetFormToInitialValues={resetToInitialValues}
      decorators={decorators}
    />
  )
}

const isSubmitButtonDisable = (values: ReterritorializacaoBuscaFormModel) =>
  !(values.logradouro || values.cep || values.unidadeSaude || values.microarea || values.bairro)

const decorators = [createBuscarImoveisFormCalculator(meta)]

const clearCheckedImoveisMessage =
  'Um ou mais imóveis foram selecionados. Ao realizar uma nova busca ou limpar os campos do filtro, essa seleção será desfeita.'

const onLimparCamposWithCheckedImoveisConfirmModal = (resetPageState: () => void) => {
  confirm({
    title: <Heading level={2}> Confirmar limpeza de campos?</Heading>,
    body: <Text>{clearCheckedImoveisMessage}</Text>,
    onConfirm: () => {
      resetPageState()
    },
  })()
}

const onBuscarImoveisWithCheckedImoveisConfirmModal = (
  e: SyntheticEvent<HTMLFormElement, Event>,
  handleSubmit: () => void
) => {
  e.preventDefault()
  confirm({
    title: <Heading level={2}>Confirmar nova busca?</Heading>,
    body: <Text>{clearCheckedImoveisMessage}</Text>,
    onConfirm: () => {
      handleSubmit()
    },
  })()
}
