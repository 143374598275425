import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import useFirebase from 'components/firebase/useFirebase'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/useTreeBuilder'
import React, { useCallback, useEffect, useState } from 'react'

import { RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO } from '../common/model'
import { RelatoriosGerenciaisTableWrapper } from '../components/RelatoriosGerenciaisTableWrapper'
import { Vacinacao } from './Vacinacao'
import { vacinacaoKeyMapping } from './vacinacaoKeyMapping'

const OPERATIONS = {
  build: '/bi/vacinacao/build',
  meta: '/bi/vacinacao/meta',
}

const NAME = 'Relatório gerencial de vacinação'

export function VacinacaoView() {
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()
  const {
    meta,
    treeBuilderStatus: { isBuilding, isSearchingMeta },
    isMetaEmpty,
    setDateFilter,
    ...rest
  } = useTreeBuilder<Vacinacao>({
    keyMapping: vacinacaoKeyMapping,
    operations: OPERATIONS,
  })

  const { analytics } = useFirebase()

  const handleSubmit = useCallback(
    (dateRange: DateRange, periodUnit: string) => {
      setDateRangeFilter(dateRange)
      setDateFilter(dateRange, periodUnit)
    },
    [setDateFilter]
  )

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, {
      relatorio: NAME,
    })
  }, [analytics])

  return (
    <>
      <Breadcrumb title={NAME} />

      <PageHeaderSection title={NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            Este relatório tem por objetivo apresentar a contagem de doses de vacinas aplicadas de acordo com os filtros
            selecionados. A origem dos atendimentos deste relatório são o CDS e o PEC.
          </Alert>
          <RelatoriosGerenciaisTableWrapper loading={isSearchingMeta} isEmpty={isMetaEmpty} onSubmit={handleSubmit}>
            {meta && (
              <PivotTable<Vacinacao>
                title={NAME}
                meta={meta}
                keyMapping={vacinacaoKeyMapping}
                dateRangeFilter={dateRangeFilter}
                isBuilding={isBuilding}
                {...rest}
              />
            )}
          </RelatoriosGerenciaisTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
