import { toEsusParameters } from 'components/esus'
import { differenceInCalendarDays } from 'date-fns'
import { useHistory, useRouteMatch } from 'react-router'
import { EsusDetailMode } from 'view/cds/model'

export const formatNumImovelCidadao = (numImoveis: number, numCidadaos: number) => {
  return `${numImoveis ?? 0} imóve${numImoveis === 1 ? 'l' : 'is'} | ${'cidadão'.pluralizeAndConcatValue(
    numCidadaos ?? 0
  )}`
}

export const getTextoDiasDesdeUltimaVisita = (now: Date, ultimaVisita: Instant) =>
  'dia'.pluralizeAndConcatValue(differenceInCalendarDays(now, ultimaVisita))

export const redirectToAtualizarImovelCds = (
  history: ReturnType<typeof useHistory>,
  match: ReturnType<typeof useRouteMatch>,
  uuidOrigemImovel: ID,
  profissionalId: ID,
  lotacaoId: ID
) => {
  history.push(
    `/cds/cadastro-domiciliar/detail?${toEsusParameters({
      pecBackLink: btoa(match.url),
      readOnly: false,
      esusDetailMode: EsusDetailMode.APPEND,
      isAtualizarDomicilio: true,
      uuidOrigemDomicilio: uuidOrigemImovel,
      userId: profissionalId,
      lotacaoId: lotacaoId,
    })}`
  )
}

export const redirectToEditarImovelCds = (
  history: ReturnType<typeof useHistory>,
  match: ReturnType<typeof useRouteMatch>,
  idFichaDomicilio: ID,
  profissionalId: ID,
  lotacaoId: ID
) => {
  history.push(
    `/cds/cadastro-domiciliar/detail?${toEsusParameters({
      pecBackLink: btoa(match.url),
      readOnly: false,
      esusDetailMode: EsusDetailMode.EDIT,
      idFicha: idFichaDomicilio,
      userId: profissionalId,
      lotacaoId: lotacaoId,
    })}`
  )
}
