import { merge } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { getIn, setIn } from 'final-form'
import { useCallback } from 'react'
import { useField, UseFieldConfig, useForm } from 'react-final-form'
import { Meta, MetaArray, MetaImpl, MetaPath, MetaRoot } from 'util/metaPath'

const noop = () => null

export interface UseFieldProps<FieldValue = any> extends UseFieldConfig<FieldValue> {
  name: string | (FieldValue extends any[] ? MetaArray<FieldValue[0]> : Meta<FieldValue>)
  onChange?(...params: any[]): void
  onFocus?(...params: any[]): void
  onBlur?(...params: any[]): void
}

export function usePecField<P>(props: UseFieldProps & P) {
  const {
    name,
    afterSubmit,
    allowNull,
    beforeSubmit,
    defaultValue,
    format,
    formatOnBlur,
    initialValue,
    isEqual,
    multiple,
    parse,
    subscription,
    type,
    validate,
    validateFields,
    value,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    ...rest
  } = props

  const formApi = useForm()

  const fieldName = resolveName(name)
  const field = useField(fieldName, props)

  const composedOnChange = useCallback(composeHandlers(field.input.onChange, onChange), [
    field.input.onChange,
    onChange,
  ])
  const composedOnFocus = useCallback(composeHandlers(field.input.onFocus, onFocus), [field.input.onFocus, onFocus])
  const composedOnBlur = useCallback(composeHandlers(field.input.onBlur, onBlur), [field.input.onBlur, onBlur])

  const resetToInitialValue = () => {
    field.input.onChange(field.meta.initial)
    formApi.resetFieldState(fieldName)
  }

  const resetToUndefined = () => {
    formApi.initialize((values) => ({
      ...setIn(values, fieldName, undefined),
    }))
  }

  return {
    formApi,
    ...rest,
    ...merge({}, field, {
      input: {
        onChange: composedOnChange,
        onFocus: composedOnFocus,
        onBlur: composedOnBlur,
      },
    }),
    tools: {
      /**
       * This function will reset the field's state (visited, touched and so on) and set field's value to the it's initial,
       * changing some field's state values (pristine, dirty and so on)
       */
      resetToInitialValue,
      /**
       * This function will reset the field's state (visited, touched and so on) and set field's value and initial value to the undefined,
       * changing some field's state values (pristine, dirty and so on)
       */
      resetToUndefined,
    },
  }
}

export function resolveName(name: string | MetaPath<any>) {
  if (typeof name === 'string') {
    return name
  } else if (name.absolutePath) {
    return name.absolutePath()
  } else {
    throw new Error(`Invalid field name "${name}"`)
  }
}

export function resolveValue<T>(values: any, meta: MetaPath<T> | MetaRoot<T>): T {
  if (meta instanceof MetaImpl) {
    return getIn(values, meta.absolutePath())
  } else {
    return values
  }
}
