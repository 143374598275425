/**
 * Função utilizada para separar uma string de dados clínícos
 * para serem tratadas separadamente
 *
 * @param dadosClinicosInput a string de dados clínicos a ser convertida
 * @returns um objeto contendo as devidas partes a serem utilizadas separadamente
 */

import { HistoricoDadosClinicosModel } from '../model/historicoModel'

export function convertDadosClinicos(dadosClinicosInput: string): HistoricoDadosClinicosModel[] {
  return dadosClinicosInput
    ?.split('|')
    .map((it) => it.trimAll())
    .filter((value, index, array) => array.indexOf(value) === index)
    .map((dadoClinico) => {
      const indexOfCodigo = dadoClinico.lastIndexOf('-')
      const indexOfTerminologia = dadoClinico.search(
        /\(CIAP2\)|\(CID10\)|\(CID10 Principal\)|\(CID10 Secundário 1\)|\(CID10 Secundário 2\)/g
      )
      const hasTerminologia = indexOfTerminologia !== -1

      const descricao = dadoClinico.substring(0, indexOfCodigo).trim()
      const codigo = dadoClinico
        .substring(indexOfCodigo + 1, hasTerminologia ? indexOfTerminologia : dadoClinico.length)
        .trim()
      const terminologia = hasTerminologia ? dadoClinico.substring(indexOfTerminologia).trim() : null

      return { descricao, codigo, terminologia }
    })
}
