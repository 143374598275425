import { HFlow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'

export function NewsModalBodyLinks() {
  const { OBSERVACAO_ENABLED, TERRITORIO_ENABLED, AGENDAMENTOS_PRE_NATAL_ENABLED } = useFlags()

  return (
    <HFlow hSpacing={2}>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Marcadores de consumo alimentar</Text>
        <Text component='li'>Busca ativa de vacinação</Text>
        <Text component='li'>Encaminhamento externo</Text>
        <Text component='li'>Adaptação do PEC para uso do estagiário</Text>
        {TERRITORIO_ENABLED && <Text component='li'>Reterritorialização</Text>}
        <Text component='li'>Registro tardio</Text>
      </VFlow>
      <VFlow vSpacing={0.2}>
        {OBSERVACAO_ENABLED && <Text component='li'>Observação do cidadão</Text>}
        <Text component='li'>Acompanhamento de cidadãos vinculados</Text>
        <Text component='li'>CADSUS - Nova Sincronização</Text>
        {AGENDAMENTOS_PRE_NATAL_ENABLED && (
          <Text component='li'>Facilidades no agendamento de consultas de pré-natal</Text>
        )}
      </VFlow>
    </HFlow>
  )
}
