import { Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { TipoEncaminhamentoExternoEnum } from 'graphql/types.generated'
import React from 'react'
import { encaminhamentoExternoRecord } from 'view/atendimentos/detail/historico/model/historicoModel'

export type TipoEncaminhamentoSelectModel = TipoEncaminhamentoExternoEnum

export interface TipoEncaminhamentoSelectFieldProps
  extends Omit<SelectFieldProps<TipoEncaminhamentoSelectModel>, 'items' | 'itemToString'> {}
const itemToString = (item: TipoEncaminhamentoSelectModel) => item && encaminhamentoExternoRecord[item]

const renderItem = (item: TipoEncaminhamentoSelectModel) => <Text>{item && encaminhamentoExternoRecord[item]}</Text>

const sortedItems = Object.values(TipoEncaminhamentoExternoEnum).sort((a, b) =>
  itemToString(a).localeCompare(itemToString(b))
)

export function TipoEncaminhamentoSelectField(props: TipoEncaminhamentoSelectFieldProps) {
  return (
    <SelectField<TipoEncaminhamentoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      items={sortedItems}
      {...props}
    />
  )
}
