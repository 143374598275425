/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { Button, Cell, DateRange, Grid, Heading, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import useFirebase from 'components/firebase/useFirebase'
import { CheckboxField, Form, FormDebouncedValueSpy, FormRenderProps, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/lotacaoAndEstagioField/LotacaoAndEstagioSelectField'
import { OrdemListaAtendimentoEnum, StatusAtendimento } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { ListaAtendimentoFilterPopperModel } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'
import { statusAtendimentoStyles } from 'view/atendimentos/list/listaAtendimentoStyles'

export interface ListaRegistroTardioFilterPopperModel
  extends Omit<ListaAtendimentoFilterPopperModel, 'somenteNaoFinalizados' | 'tiposServico' | 'equipes'> {
  statusAtendimento?: StatusAtendimento[]
  periodo: DateRange
  responsaveis?: LotacaoAndEstagioSelectFieldModel[]
  ordem?: OrdemListaAtendimentoEnum
}

const path = metaPath<ListaRegistroTardioFilterPopperModel>()

interface ListaRegistroTardioPopperProps {
  defaultPeriodo: DateRange
  initialValues?: ListaRegistroTardioFilterPopperModel
  isFilterEqual?: boolean
  onFechar(): void
  onSubmit(values: ListaRegistroTardioFilterPopperModel): void
  onClear(): void
  onChangeFilter(values: ListaRegistroTardioFilterPopperModel): void
}

export function ListaRegistroTardioFilterPopperForm(props: ListaRegistroTardioPopperProps) {
  const { defaultPeriodo, initialValues, isFilterEqual, onSubmit, onFechar, onClear, onChangeFilter } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_popper_LA')
  }

  const renderForm = (formProps: FormRenderProps<ListaRegistroTardioFilterPopperModel>) => {
    return (
      <Box
        style={css`
          width: 36rem;
          margin-top: 1rem;
        `}
      >
        <FormDebouncedValueSpy onChange={(formState) => onChangeFilter(formState.values)} wait={0} />

        <Grid gapVertical={1} gap={1} justifyContent='flex-end'>
          <Cell size={5}>
            <Grid gapVertical={0.5}>
              <Cell size={12}>
                <Heading level={5}>Status do registro</Heading>
              </Cell>
              <Cell
                size={12}
                style={css`
                  margin-left: -0.25rem;
                `}
              >
                <Grid gap={1} gapVertical={0.5}>
                  <Cell size={12}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender('Aguardando registro', statusAtendimentoStyles.aguardandoAtendimentoBox)}
                      value={StatusAtendimento.AGUARDANDO_ATENDIMENTO}
                    />
                  </Cell>
                  <Cell size={12}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender('Em registro', statusAtendimentoStyles.emAtendimentoBox)}
                      value={StatusAtendimento.EM_ATENDIMENTO}
                    />
                  </Cell>
                  <Cell size={12}>
                    <CheckboxField
                      name={path.statusAtendimento}
                      label={filterLabelRender('Registro realizado', statusAtendimentoStyles.atendimentoRealizadoBox)}
                      value={StatusAtendimento.ATENDIMENTO_REALIZADO}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
          <Cell size={7}>
            <Grid gapVertical={1}>
              <Cell size={12}>
                <HFlow alignItems='flex-end'>
                  <DateRangeField name={path.periodo} label='Período' maxDate={defaultPeriodo.endDate} />
                </HFlow>
              </Cell>
              <Cell size={12}>
                <LotacaoAndEstagioSelectField
                  label='Profissional'
                  name={path.responsaveis}
                  unidadeSaudeId={acesso?.unidadeSaude.id}
                  multiple
                />
              </Cell>
            </Grid>
          </Cell>

          <Cell alignSelf='flex-end'>
            <Button onClick={onFechar} size='small' skin='outline' kind='normal'>
              Fechar
            </Button>
          </Cell>

          <Cell alignSelf='flex-end'>
            <Tooltip text='Redefinir filtros para o padrão'>
              <Button kind='normal' size='small' onClick={handleOnClear} disabled={isFilterEqual}>
                <Icon
                  style={css`
                    padding-right: 0.5rem;
                  `}
                  icon='redo'
                />
                Voltar para padrão
              </Button>
            </Tooltip>
          </Cell>

          <Cell alignSelf='flex-end'>
            <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
              Filtrar
            </SubmitButton>
          </Cell>
        </Grid>
      </Box>
    )
  }
  return (
    <Form<ListaRegistroTardioFilterPopperModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      onSubmitSucceeded={onFechar}
    />
  )
}

const filterLabelRender = (nome: string, cssStyles: SerializedStyles) => (
  <HFlow hSpacing={0} justifyContent='center' alignItems='center'>
    <div
      css={css(
        cssStyles.styles,
        css`
          width: 0.5rem;
          height: 1.5rem;
          border-radius: 0.125rem;
          margin-right: 0.5rem;
        `
      )}
    />
    {nome}
  </HFlow>
)
