import SimplePeer, { SignalData, SimplePeerData } from 'simple-peer'

interface WebRtcPeerOptions extends SimplePeer.Options {
  onSignal?(data: SignalData): void
  onStream?(stream: MediaStream): void
  onData?(data: SimplePeerData): void
  onConnect?(): void
  onClose?(): void
  onError?(error: Error, peer: WebRtcPeer): void
}

export type WebRtcPeer = SimplePeer.Instance

export function createWebRtcPeer(options: WebRtcPeerOptions): WebRtcPeer {
  const { onSignal, onStream, onData, onConnect, onClose, onError, ...rest } = options

  const peer = new SimplePeer(rest)

  onSignal && peer.on('signal', onSignal)
  onStream && peer.on('stream', onStream)
  onData && peer.on('data', onData)
  onConnect && peer.on('connect', onConnect)
  onClose && peer.on('close', onClose)
  onError && peer.on('error', (error) => onError(error, peer))

  return peer
}
