import { ResultadoExameEspecializadoInput, ResultadoExameEspecifico } from 'graphql/types.generated'
import { formatNumber } from 'util/number'
import { parseNumber } from 'util/validation'

import { AllResultadoEspecificoValueType, ExameQueryModel, ResultadoExameEspecificoModel } from '../../model'
import { getResultadoExameEspecificoFormatOnSave, getResultadoExameEspecificoProperties } from '../../util'

export const convertResultadoExameMarcadoresBioquimicosToInput = (
  value: ResultadoExameEspecificoModel
): ResultadoExameEspecializadoInput => {
  const { resultado, exame } = value

  const { relatedDbEnum } = getResultadoExameEspecificoProperties(exame)

  return {
    procedimento: relatedDbEnum,
    valor: parseNumber(resultado),
  }
}

export const convertResultadoExamePrenatalToInput = (
  value: ResultadoExameEspecificoModel
): ResultadoExameEspecializadoInput => {
  const { resultado, exame } = value

  const { dpp, idadeGestacional } = resultado

  const { semanas, dias } = idadeGestacional || {}

  const { relatedDbEnum } = getResultadoExameEspecificoProperties(exame)

  return {
    procedimento: relatedDbEnum,
    dpp,
    igDias: dias,
    igSemanas: semanas,
  }
}

export const convertResultadoExamePuericulturaToInput = (
  value: ResultadoExameEspecificoModel
): ResultadoExameEspecializadoInput => {
  const { resultado, exame } = value

  const { relatedDbEnum } = getResultadoExameEspecificoProperties(exame)

  return {
    procedimento: relatedDbEnum,
    fichaComplementarPuericultura: resultado,
  }
}

export const convertResultadoExameMarcadoresBioquimicosValueToModel = (
  input: ResultadoExameEspecifico,
  exame: ExameQueryModel
): AllResultadoEspecificoValueType => {
  const { precision = 0 } = getResultadoExameEspecificoProperties(exame)

  // @diegoasmello fix cast https://github.com/laboratoriobridge/pec/issues/11424
  const value = formatNumber(input.valor, precision) as AllResultadoEspecificoValueType

  const formatOnLoad = getResultadoExameEspecificoFormatOnSave(exame)

  if (formatOnLoad) {
    const { resultado } = formatOnLoad({ resultado: value }, exame)
    return resultado
  }

  return value
}

export const convertResultadoExamePrenatalValueToModel = (
  input: ResultadoExameEspecifico
): AllResultadoEspecificoValueType => {
  const { dpp, igDias, igSemanas } = input

  // @diegoasmello fix cast https://github.com/laboratoriobridge/pec/issues/11424
  return {
    dpp,
    idadeGestacional: {
      dias: igDias,
      semanas: igSemanas,
    },
  } as AllResultadoEspecificoValueType
}

export const convertResultadoExamePuericulturaValueToModel = (
  input: ResultadoExameEspecifico
): AllResultadoEspecificoValueType => {
  const { resultadoExamePuericulturaEnum } = input

  // @diegoasmello fix cast https://github.com/laboratoriobridge/pec/issues/11424
  return resultadoExamePuericulturaEnum as AllResultadoEspecificoValueType
}
