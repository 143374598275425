/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { resolveName, usePecField } from 'components/form/final-form/useField'
import { TipoReceitaEnum } from 'graphql/types.generated'
import React, { useCallback } from 'react'
import { tipoReceitaRecord } from 'types/enums'
import { metaPath } from 'util/metaPath'

import { MedicamentoFormModel } from '../../model'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { MedicamentoImpressoInfoLabel } from './MedicamentoImpressoInfoLabel'
import { MedicamentoOnPrescricaoDigitalInfoLabel } from './MedicamentoOnPrescricaoDigitalInfoLabel'
import { PrescricaoBoxItem } from './PrescricaoBoxItem'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

interface EncaminharPrescricaoMedicamentoItemProps {
  medicamentosToPrint: MedicamentoFormModel[]
  medicamentoIdsSelecionado: ID[]
  onChange(checked: boolean, medicamento: MedicamentoFormModel): void
  disableReceitaControlada?: boolean
  isPrescricaoDigital?: boolean
}

export function EncaminharPrescricaoMedicamentoItem(props: EncaminharPrescricaoMedicamentoItemProps) {
  const {
    medicamentosToPrint,
    medicamentoIdsSelecionado,
    disableReceitaControlada = false,
    isPrescricaoDigital = false,
    onChange,
  } = props

  const {
    input: { value: alreadyPrintedValue },
  } = usePecField({ name: resolveName(path.alreadyPrinted) })

  const {
    input: { value: isOnPrescricaoDigitalValue },
  } = usePecField({ name: resolveName(path.medicamentoIdsOnPrescricaoDigital) })

  const isPrinted = useCallback((medicamentoId: ID) => !!alreadyPrintedValue?.includes(medicamentoId), [
    alreadyPrintedValue,
  ])

  const isOnPrescricaoDigital = useCallback(
    (medicamentoId: ID) => isOnPrescricaoDigitalValue?.includes(medicamentoId),
    [isOnPrescricaoDigitalValue]
  )

  const isSelecionado = useCallback((medicamentoId: ID) => medicamentoIdsSelecionado.includes(medicamentoId), [
    medicamentoIdsSelecionado,
  ])

  return (
    <VFlow
      vSpacing={0.5}
      style={css`
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      `}
    >
      {medicamentosToPrint.map((medicamento, index) => (
        <MedicamentoItem
          index={index}
          medicamento={medicamento}
          isPrescricaoDigital={isPrescricaoDigital}
          disableReceitaControlada={disableReceitaControlada}
          isOnPrescricaoDigital={isOnPrescricaoDigital}
          isPrinted={isPrinted}
          onChange={onChange}
          checked={isSelecionado}
        />
      ))}
    </VFlow>
  )
}

interface MedicamentoItemProps {
  medicamento: MedicamentoFormModel
  index: number
  isPrescricaoDigital: boolean
  disableReceitaControlada: boolean
  isOnPrescricaoDigital(medicamentoId: ID): boolean
  isPrinted(medicamentoId: ID): boolean
  onChange(checked: boolean, medicamento: MedicamentoFormModel): void
  checked(medicamentoId: ID): boolean
}

const MedicamentoItem = (props: MedicamentoItemProps) => {
  const {
    medicamento,
    index,
    isPrescricaoDigital,
    disableReceitaControlada,
    isOnPrescricaoDigital,
    isPrinted,
    onChange,
    checked,
  } = props

  const { principioAtivo, concentracao, tipoReceita } = resolveMedicamento(medicamento)

  const isMedicamentoAlreadyOnPrescricaoDigital = isPrescricaoDigital && isOnPrescricaoDigital(medicamento.id)
  const isDisabledMedicamentoReceitaControlada = disableReceitaControlada && tipoReceita !== TipoReceitaEnum.COMUM
  const isMedicamentoDisabled = isDisabledMedicamentoReceitaControlada || isMedicamentoAlreadyOnPrescricaoDigital

  return (
    <PrescricaoBoxItem key={medicamento.id} data-testid={`Medicamento${index}`}>
      <HFlow justifyContent='space-between'>
        <HFlow>
          <Tooltip
            text={
              isDisabledMedicamentoReceitaControlada
                ? 'Não é possível incluir medicamentos de uso controlado na prescrição digital'
                : isMedicamentoAlreadyOnPrescricaoDigital && 'Medicamento já incluído em uma prescrição digital'
            }
          >
            <span>
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, medicamento)}
                checked={!isMedicamentoDisabled && checked(medicamento.id)}
                disabled={isMedicamentoDisabled}
                data-testid={`Medicamento${index}Checkbox`}
              />
            </span>
          </Tooltip>

          <VFlow vSpacing={0.4}>
            <Text fontWeight='bold' data-testid={`PrincipioAtivo${index}`}>{`${principioAtivo} ${concentracao}`}</Text>
            <HFlow>
              {medicamento.usoContinuo && (
                <Tag
                  type='normal'
                  style={css`
                    align-self: center;
                    max-height: 1.5rem;
                  `}
                >
                  Uso contínuo
                </Tag>
              )}
              {tipoReceita !== TipoReceitaEnum.COMUM && (
                <Tag
                  type='info'
                  style={css`
                    align-self: center;
                    max-height: 1.5rem;
                  `}
                >
                  {tipoReceitaRecord[tipoReceita]}
                </Tag>
              )}
            </HFlow>
            <Text data-testid={`Posologia${index}`}>{medicamento.posologia}</Text>
          </VFlow>
        </HFlow>
        <VFlow
          vSpacing={0.25}
          style={css`
            align-items: end;
          `}
        >
          {isOnPrescricaoDigital(medicamento.id) && <MedicamentoOnPrescricaoDigitalInfoLabel />}
          {isPrinted(medicamento.id) && <MedicamentoImpressoInfoLabel />}
        </VFlow>
      </HFlow>
    </PrescricaoBoxItem>
  )
}

const resolveMedicamento = (medicamento: MedicamentoFormModel) => {
  const principioAtivo = medicamento.registroManual
    ? medicamento.principioAtivoText
    : medicamento.principioAtivoCombo.principioAtivo.nome

  const concentracao = !medicamento.registroManual
    ? medicamento.principioAtivoCombo.medicamento.concentracao
    : !!medicamento.concentracaoText
    ? medicamento.concentracaoText
    : ''

  const tipoReceita = medicamento.registroManual
    ? medicamento.tipoReceita.tipoReceitaEnum
    : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita

  return { principioAtivo, concentracao, tipoReceita }
}
