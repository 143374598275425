import { isUndefinedOrNull } from 'util/checks'

/* eslint-disable no-extend-native */
export {}

declare global {
  interface Array<T> {
    /**
     * Function to return the last element of the array
     *
     * @returns T
     */
    last(): T

    /**
     * Function to return the first element of the array
     *
     * @returns T
     */
    first(): T

    /**
     * Function to check if the array is not empty
     *
     * @returns if is not empty
     */
    isNotEmpty(): boolean

    /**
     * Function to check if the array is empty
     *
     * @returns if is empty
     */
    isEmpty(): boolean

    /**
     * Function to remove duplicate items in array specifying or not the object attribute to be compared
     *
     * @param valueToCompare optional function to specify the object attribute to be compared
     * @returns T[]
     */
    removeDuplicate(valueToCompare?: (value: T) => any): T[]

    /**
     * Function to remove null or undefined items in array
     *
     * @returns Array without null or undefined values
     */
    filterNotNull(): NonNullable<T>[]
  }
}

Array.prototype.last = function <T>(): T {
  const length = this.length
  if (length > 0) {
    return this[length - 1]
  } else return null
}

Array.prototype.first = function <T>(): T {
  if (this.length > 0) {
    return this[0]
  } else return null
}

Array.prototype.isEmpty = function (): boolean {
  return this.length === 0
}

Array.prototype.isNotEmpty = function (): boolean {
  return !this.isEmpty()
}

Array.prototype.removeDuplicate = function <T>(valueToCompare?: (i: T) => any): T[] {
  return this.filter((elementOfFilter, indexOfFilter) => {
    const indexOfFirstOccurrence = valueToCompare
      ? this.findIndex((elementoOfFind) => valueToCompare(elementOfFilter) === valueToCompare(elementoOfFind))
      : this.indexOf(elementOfFilter)

    return indexOfFilter === indexOfFirstOccurrence
  })
}

Array.prototype.filterNotNull = function <T>(): NonNullable<T>[] {
  return this.filter((item) => !isUndefinedOrNull(item))
}
