import { createValidator, required } from 'util/validation'

import { BuscaAtivaVacinacaoFormModel } from './model'

export const validator = createValidator<BuscaAtivaVacinacaoFormModel>({
  equipe: required,
  grupoAlvo: required,
  status: required,
  tipoVisualizacao: required,
})
