import { HFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { OrigemAtendimentoRecord } from 'types/enums'
import { humanizeAge } from 'util/date/humanize-age'
import { humanizeDate } from 'util/date/humanize-date'

import { IdadeOrigemAtendimento } from '../../../model/historicoModel'
import { isFichaAtendimento } from '../../../utils/historicoUtils'
import { OrigemAtendimentoObservacaoIcon } from './atendimento-observacao/OrigemAtendimentoObservacaoIcon'

interface HistoricoIdadeOrigemAtendimentoProps {
  idadeOrigemAtendimento: IdadeOrigemAtendimento
  isAtendObservacao?: boolean
}

export default function HistoricoIdadeOrigemAtendimento(props: HistoricoIdadeOrigemAtendimentoProps) {
  const { idadeOrigemAtendimento, isAtendObservacao } = props

  const ocorreuEm = humanizeDate(idadeOrigemAtendimento.dataAtendimento)
  const titleAtendimentoOcorreu = ocorreuEm === 'Hoje' ? 'Atendimento ocorreu' : 'Atendimento ocorreu há'
  const isFicha = isFichaAtendimento(idadeOrigemAtendimento.origemAtendimento)

  return (
    <HFlow hSpacing={0.25}>
      <HLabel title={`${titleAtendimentoOcorreu}:`}>{ocorreuEm}</HLabel>|
      <HFlow hSpacing={0.25} alignItems='center'>
        <HLabel title='Registros com origem:'>
          {OrigemAtendimentoRecord[idadeOrigemAtendimento.origemAtendimento]}
        </HLabel>

        {isAtendObservacao && isFicha && (
          <OrigemAtendimentoObservacaoIcon origemAtendimento={idadeOrigemAtendimento.origemAtendimento} />
        )}
      </HFlow>
      |
      <HLabel title='Idade do paciente no dia:'>
        {humanizeAge(idadeOrigemAtendimento.dataNascimentoCidadao, idadeOrigemAtendimento.dataAtendimento)}
      </HLabel>
    </HFlow>
  )
}
