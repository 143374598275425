import { FormControl, Text } from 'bold-ui'
import { EquipeSelectField, EquipeSelectFieldProps, EquipeSelectModel } from 'components/form'
import { resolveName } from 'components/form/final-form/useField'
import { Equipe } from 'graphql/types.generated'
import React from 'react'
import { Field } from 'react-final-form'

interface EquipeResponsavelFieldProps extends Omit<EquipeSelectFieldProps, 'onChange'> {
  equipe: Equipe
  hasAccessToTodasEquipes: boolean
  onChange?(equipe: EquipeSelectModel): void
}

export function EquipeResponsavelField(props: EquipeResponsavelFieldProps) {
  const { name, equipe, hasAccessToTodasEquipes, required, onChange, ...rest } = props

  const renderField = () => <Text>{equipe ? `${equipe.nome.titleCase()} | ${equipe.ine}` : 'Sem equipe'}</Text>

  return (
    <FormControl label='Equipe responsável' required={required}>
      {hasAccessToTodasEquipes ? (
        <EquipeSelectField name={name} onChange={onChange} required={required} {...rest} />
      ) : (
        <Field<EquipeSelectModel> name={resolveName(name)} initialValue={equipe} render={renderField} />
      )}
    </FormControl>
  )
}
