/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, HFlow, PagedTable, Tag, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { Fragment, useMemo, useState } from 'react'
import { emptyArray } from 'util/array'
import { formatDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { metaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { useEditableListField } from '../../components/EditableList'
import { Problema } from '../aside/types/ProblemaModel'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model'
import {
  AntecedentesProblemasResolvidosPageFilter,
  AntecedentesProblemasResolvidosTableFilter,
} from './AntecedentesProblemasResolvidosTableFilter'
import { convertProblemaToModel } from './converter'

export interface AntecedentesProblemasResolvidosTableProps {
  problemasResolvidos: Problema[]
}

export const AntecedentesProblemasResolvidosTable = (props: AntecedentesProblemasResolvidosTableProps) => {
  const { problemasResolvidos } = props

  const path = metaPath<SoapState>()
  const [tableState, setTableState] = useState<AntecedentesProblemasResolvidosPageFilter>({
    pageParams: { page: 0, size: 5 },
    query: '',
  })

  const {
    cidadao: { dataNascimento },
    tiposAtendimento: { isAtendimentoProcedimentos },
  } = useAtendimentoContext()

  const {
    input: { value: problemasRegistradosAgora = emptyArray },
  } = useEditableListField({ name: path.problemasECondicoes })

  const getTodosProblemasResolvidos = (
    problemasRegistradosAgora: ProblemaCondicaoModel[],
    problemasResolvidos: ProblemaCondicaoModel[]
  ) => {
    if (problemasRegistradosAgora) {
      const problemasResolvidosAgora = problemasRegistradosAgora.filter((item) => item.situacaoProblema === 'RESOLVIDO')
      return problemasResolvidos.concat(problemasResolvidosAgora)
    }
    return problemasResolvidos
  }

  const filteredItems = useFilter<ProblemaCondicaoModel, AntecedentesProblemasResolvidosPageFilter>({
    items: getTodosProblemasResolvidos(problemasRegistradosAgora, convertProblemaToModel(problemasResolvidos)),
    filter: tableState,
    filtersType: [filterTextTypeConfig],
  })

  const { tableProps: paginationProps, paginatedItems } = usePagination({ items: filteredItems })

  const { pageSize, ...rest } = useMemo(() => {
    return {
      ...paginationProps,
      pageSize: paginationProps.pageSize,
      size: paginationProps.pageSize,
      rows: paginatedItems,
    }
  }, [paginatedItems, paginationProps])

  const classes = useMemo(() => createStyles(), [])

  const renderInfoProblema = (values: ProblemaCondicaoModel) => {
    return (
      <div css={classes.tableItem}>
        <VFlow vSpacing={0}>
          <Text>
            {values.ciap && (
              <Fragment>
                <Text fontWeight='bolder'>{`${values.ciap.descricao} - ${values.ciap.codigo} `}</Text> (CIAP 2)
              </Fragment>
            )}
            {`${values.cid && values.ciap ? ' | ' : ''}`}
            {values.cid && (
              <Fragment>
                <Text fontWeight='bolder'>{`${values.cid.nome} - ${values.cid.codigo} `}</Text>
                (CID 10)
              </Fragment>
            )}
          </Text>

          <HFlow>
            {values.dataInicio?.data && (
              <HLabel title='Início'>
                {values.dataInicio.data
                  ? `${formatDate(values.dataInicio.data)} | ${humanizeAge(dataNascimento, values.dataInicio.data)}`
                  : '-'}
              </HLabel>
            )}
            {values.dataFim?.data && (
              <HLabel title='Fim'>
                {values.dataFim.data
                  ? `${formatDate(values.dataFim.data)} | ${humanizeAge(dataNascimento, values.dataFim.data)}`
                  : '-'}
              </HLabel>
            )}
          </HFlow>
        </VFlow>
      </div>
    )
  }

  const renderTag = () => {
    return <Tag type='success'> Resolvido </Tag>
  }

  const onFilterChange = (query: string) => {
    setTableState({ ...tableState, query: query })
  }

  return (
    <VFlow vSpacing={0.4}>
      <Heading level={3}>Antecedentes resolvidos da Lista de Problemas e Condições</Heading>
      <Alert type='info' inline>
        Esta seção apresenta apenas os problemas resolvidos de forma sucinta. Para informações detalhadas ou cadastro de
        problemas/condições em situação diferente de resolvido, acesse a lista de problemas/condições no menu lateral.
      </Alert>
      <TableBox
        css={css`
          margin-top: 0.6rem;
        `}
        header={
          <AntecedentesProblemasResolvidosTableFilter
            isAtendimentoProcedimentos={isAtendimentoProcedimentos}
            onChange={onFilterChange}
          />
        }
      >
        <PagedTable<ProblemaCondicaoModel>
          {...rest}
          size={pageSize}
          columns={[
            {
              name: 'problemaCondicao',
              header: 'Problema/Condição',
              align: 'left',
              render: renderInfoProblema,
              style: { width: '80%' },
            },
            {
              name: 'situacao',
              header: 'Situação',
              align: 'left',
              render: renderTag,
            },
          ]}
        />
      </TableBox>
    </VFlow>
  )
}

const metaFilter = metaPath<AntecedentesProblemasResolvidosPageFilter>()
const meta = metaPath<ProblemaCondicaoModel>()
const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.query,
  searchFields: [meta.ciap.codigo, meta.ciap.descricao, meta.cid.codigo, meta.cid.nome],
  removeTagsOnFilter: true,
}

const createStyles = () => ({
  tableItem: css`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `,
})
