import { AtendimentoProfissionalObservacao } from 'graphql/types.generated'

export const START_OBSERVACAO_REGISTRO_TARDIO_TOOLTIP =
  'Ao ativar o botão esse atendimento será contabilizado como uma observação. Sendo que o fluxo do registro tardio permanece o mesmo.'
export const START_OBSERVACAO_TOOLTIP =
  'Ao ativar o botão o cidadão permanecerá em observação. A partir desse momento, os episódios de cuidado pertencerão à mesma observação até o cidadão receber alta.'

export interface PlanoCuidadoLotacaoModel {
  id: ID
  iniciadoEm: number
  lotacao: {
    id: ID
    profissional: {
      id: ID
      nome: string
    }
  }
}

export interface PlanoCuidadoAtendimentoProfissionalObservacaoModel
  extends Omit<AtendimentoProfissionalObservacao, 'atendimentoProfissional' | 'atendimentoObservacao'> {
  atendimentoProfissional: {
    id: ID
    lotacao: {
      id: ID
      profissional: {
        id: ID
        nome: string
      }
      cbo: {
        id: ID
        nome: string
      }
    }
  }
  atendimentoObservacao: {
    id: ID
    iniciador: PlanoCuidadoLotacaoModel
  }
}

export interface PlanoCuidadoModel {
  id: ID
  atendimentoProfissionalObservacao?: PlanoCuidadoAtendimentoProfissionalObservacaoModel
  descricao: string
  dataDescricao: Instant
}
