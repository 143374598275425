import { ClassNames, HFlow, Tag, Text, useStyles, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Ellipsis } from 'components/Ellipsis'
import { SituacaoAgendadoEnum, StatusAtendimento } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React from 'react'
import { humanizeAge } from 'util/date/humanize-age'

import { isAtendimentoObservacaoByStatusAtendimento } from '../atendimento-individual/atendimento-observacao/util'
import AtendimentoListingItem from '../components/AtendimentoListingItem'
import { AtendimentoTags } from './AtendimentoTags'
import ListaAtendimentoItemActions from './ListaAtendimentoItemActions'
import { listaAtendimentoStylesRecord } from './listaAtendimentoStyles'
import { createItemStyles } from './listaUtils'
import { AtendimentoModel, statusRevisaoAtendimentoDescricaoRecord } from './model'

export interface ListaAtendimentoItemProps {
  atend: AtendimentoModel
}

export function ListaAtendimentoItem(props: ListaAtendimentoItemProps) {
  const { atend } = props
  const { classes } = useStyles(createItemStyles)
  const statusAtendimento = listaAtendimentoStylesRecord[atend.statusAtendimento]
  const isAtendimentoObservacao = isAtendimentoObservacaoByStatusAtendimento(atend.statusAtendimento)

  const shouldNotShowTagAgendamento = !!(
    atend.agendado?.situacao !== SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE &&
    atend.statusAtendimento !== StatusAtendimento.ATENDIMENTO_REALIZADO
  )

  const renderTime = (
    <VFlow vSpacing={0}>
      <HFlow hSpacing={0.25}>
        {!!atend.statusRevisaoAtendimento && (
          <Tag icon='penFilled' style={classes.revisarRetificarTag}>
            {statusRevisaoAtendimentoDescricaoRecord[atend.statusRevisaoAtendimento].descricao}
          </Tag>
        )}

        {!atend.finalizado && (
          <Tag icon='exclamationTriangleFilled' style={classes.naoFinalizadoTag}>
            {`Não finalizado - ${moment(atend.iniciadoEm).format('DD/MM/YYYY')}`}
          </Tag>
        )}
      </HFlow>
      <HFlow style={classes.hFlow}>
        <VFlow vSpacing={0}>
          <DateTime
            style={{ lineHeight: '1.35rem', fontWeight: 'bold' }}
            value={atend.ultimaAlteracaoStatusEm}
            format={
              atend.statusAtendimento === StatusAtendimento.NAO_AGUARDOU ||
              atend.statusAtendimento === StatusAtendimento.ATENDIMENTO_REALIZADO
                ? 'DD/MM/YYYY [-] HH:mm'
                : 'HH:mm'
            }
          />

          <Text>{statusAtendimento.descricao}</Text>
        </VFlow>

        <VFlow vSpacing={0}>
          <span className={classes.nomeCidadao}>{`${atend.cidadao.nomeSocial || atend.cidadao.nome} `}</span>
          <span className={classes.idadeCidadao}>
            {atend.cidadao.dataNascimento ? humanizeAge(atend.cidadao.dataNascimento) : '-'}
          </span>
        </VFlow>
      </HFlow>
    </VFlow>
  )

  const renderTags = (
    <AtendimentoTags
      tiposServico={atend.tiposServico}
      classificacaoRisco={atend.classificacaoRisco}
      horaAgendamento={shouldNotShowTagAgendamento ? null : atend.agendado?.horarioInicial}
      nomeProfissional={shouldNotShowTagAgendamento ? null : atend.agendado?.lotacaoAgendada?.profissional?.nome}
    />
  )

  const renderActions = (
    <ListaAtendimentoItemActions
      actions={atend.actions}
      atendId={atend.id}
      cidadaoId={atend.cidadao.id}
      prontuarioId={atend.prontuarioId}
      responsavelId={atend.responsavel?.id}
      statusAtendimento={atend.statusAtendimento}
      statusRevisaoAtendimento={atend.statusRevisaoAtendimento}
      isAtendimentoObservacao={isAtendimentoObservacao}
    />
  )

  return (
    <AtendimentoListingItem
      status={statusAtendimento}
      time={renderTime}
      tags={renderTags}
      lotacao={!isAtendimentoObservacao ? renderLotacao(atend) : renderObservacaoResponsavel(atend, classes)}
      actions={renderActions}
    />
  )
}

const renderLotacao = (atend: AtendimentoModel) => (
  <>
    {atend.responsavel && <Text fontWeight='bold'>{atend.responsavel.profissional.nome}</Text>}

    {!atend.responsavel && atend.equipe && (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'> {atend.equipe.nome} </Text>
        <Text>
          <b>Área </b> {atend.equipe.area}
        </Text>
      </VFlow>
    )}
  </>
)

const renderObservacaoResponsavel = (atend: AtendimentoModel, classes: ClassNames<string>) => {
  const responsavelAtendimento = atend.responsavel?.profissional
  const nomeResponsavelAtendimento = responsavelAtendimento?.nome
  const responsavelObservacao = atend.responsavelObservacao.profissional
  const nomeResponsavelObservacao = responsavelObservacao.nome

  if (responsavelAtendimento?.id === responsavelObservacao.id) {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>Encaminhado para o responsável</Text>
        <Ellipsis maxLines={2} title={nomeResponsavelObservacao}>
          Responsável: {nomeResponsavelObservacao}
        </Ellipsis>
      </VFlow>
    )
  } else if (isEmpty(responsavelAtendimento)) {
    return (
      <Ellipsis maxLines={2} title={nomeResponsavelObservacao}>
        <Text fontWeight='bold' style={classes.responsavelLabel}>
          Responsável:
        </Text>
        <Text>{nomeResponsavelObservacao}</Text>
      </Ellipsis>
    )
  } else {
    return (
      <VFlow vSpacing={0}>
        <Ellipsis maxLines={2} title={nomeResponsavelAtendimento}>
          <Text fontWeight='bold'>Encaminhado para {nomeResponsavelAtendimento}</Text>
        </Ellipsis>
        <Ellipsis maxLines={2} title={nomeResponsavelObservacao}>
          <Text>Responsável: {nomeResponsavelObservacao}</Text>
        </Ellipsis>
      </VFlow>
    )
  }
}
