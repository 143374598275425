/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Progress, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useAtmosphere from 'hooks/useAtmosphere'
import { useState } from 'react'

interface ReterritorializacaoProgressBarProps {
  progressToken: string
  changeToInitialState(): void
  renderProgressBar: boolean
}

type ReterritorializacaoTopicModel = { processed: number; total: number }

export function ReterritorializacaoProgressBar({
  progressToken,
  changeToInitialState,
  renderProgressBar,
}: ReterritorializacaoProgressBarProps) {
  const alert = useAlert()
  const theme = useTheme()
  const styles = createStyles(theme)
  const [progress, setProgress] = useState({ processed: 0, total: 0 })

  useAtmosphere<ReterritorializacaoTopicModel>({
    topic: `reterritorializar/${progressToken}`,
    onMessage: ({ processed, total }) => {
      const processedNormalized = processed > progress.processed ? processed : progress.processed
      setProgress({ processed: processedNormalized, total })
      if (processed === total) {
        changeToInitialState()
        alert('success', 'Imóveis atualizados com sucesso.')
      }
    },
  })

  return renderProgressBar ? (
    <VFlow vSpacing={0}>
      <Text css={styles.progressText}>{progress.processed ? 'Atualizando imóveis' : 'Aguardando na fila'}</Text>
      <HFlow style={styles.progressContainer}>
        <Progress max={progress.total} value={progress.processed} css={styles.progressBar} />
        <Text css={styles.progressText}>{`${progress.processed}/${progress.total}`}</Text>
      </HFlow>
    </VFlow>
  ) : null
}

const createStyles = (theme: Theme) => ({
  progressContainer: css`
    display: flex;
    padding: 0rem;
    width: 100%;
    align-items: center;
  `,
  progressBar: css`
    flex-grow: 1;
    margin-right: 0.5rem;
  `,

  progressText: css`
    flex-basis: auto;
    font-weight: bold;
  `,
})
