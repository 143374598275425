import { useFlagsQuery } from 'graphql/hooks.generated'
import React, { useContext } from 'react'

export interface FlagsContextModel {
  UNIFICACAO_BASE_ENABLED: Boolean
  GESTAO_CADASTRO_UNIFICACAO_BASE_ENABLED: Boolean
  IMPORTAR_IMUNOBIOLOGICO_ENABLED: Boolean
  TERRITORIO_ENABLED: Boolean
  VISUALIZACAO_TERRITORIO_ENABLED: Boolean
  BUSCA_ATIVA_VACINACAO_ENABLED: Boolean
  OBSERVACAO_ENABLED: Boolean
  AGENDAMENTOS_PRE_NATAL_ENABLED: Boolean
  LISTA_ESPERA_ENABLED: Boolean
  API_DADOS_DW_ENABLED: Boolean
  VIDEOCHAMADA_ENABLED: Boolean
  PRESCRICAO_DIGITAL_ENABLED: Boolean
  POLICLINICA_ENABLED: Boolean
  ENVIO_RNDS_ENABLED: Boolean
}

interface FlagsProviderProps {
  children: React.ReactNode
}

export const FlagsContext = React.createContext<FlagsContextModel>(undefined)

export function FlagsProvider({ children }: FlagsProviderProps) {
  const {
    data: { info: { flags } = {} },
  } = useFlagsQuery({
    fetchPolicy: 'cache-first',
  })

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
}

export const useFlags = () => useContext(FlagsContext)
