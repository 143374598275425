import { Theme } from 'bold-ui'
import { useScrollPosition } from 'bold-ui/lib/hooks'
import { SideMenuList } from 'components/layout/SideMenu/SideMenuList'
import { useFlags } from 'config/useFlagsContext'
import React, { CSSProperties } from 'react'
import { useLocalStyles } from 'view/atendimentos/atendimento-individual/AtendimentoIndividualView'

import { SideMenuNewsItem } from './SideMenuNewsItem'
import { SideMenuNewsSublink } from './SideMenuNewsSublink'
import { NEWS_ITEMS } from './SideMenuNewsValues'
import { useCalculaCabecalhoSideMenuNews } from './useCalculaCabecalhoSideMenuNews'

export const SideMenuNews = () => {
  const items = NEWS_ITEMS
  const { scrollY } = useScrollPosition()
  const passouCabecalho = useCalculaCabecalhoSideMenuNews()
  const { classes } = useLocalStyles(createStyles, passouCabecalho, scrollY)
  const { OBSERVACAO_ENABLED, TERRITORIO_ENABLED, AGENDAMENTOS_PRE_NATAL_ENABLED, ENVIO_RNDS_ENABLED } = useFlags()

  // Remover a constante da linha de baixo quando for remover a flag 'OBSERVACAO_ENABLED'
  const flaggedHashObservacao = !OBSERVACAO_ENABLED && '#observacao-5-1'
  // Remover a constate da linha de baixo quando for remover a flag 'TERRITORIO_ENABLED'
  const flaggedHashTerritorio = !TERRITORIO_ENABLED && '#reterritorializacao-5-1'
  // Remover a constate da linha de baixo quando for remover a flag 'AGENDAMENTOS_PRE_NATAL_ENABLED'
  const flaggedHashAgendamentoPreNatal = !AGENDAMENTOS_PRE_NATAL_ENABLED && '#agendamento-pre-natal-5-1'
  // Remover a constate da linha de baixo quando for remover a flag 'ENVIO_RNDS_ENABLED'
  const flaggedHashEnvioRnds = !ENVIO_RNDS_ENABLED && '#rnds-vacina-5-1'

  const flaggedHashList = [
    flaggedHashObservacao,
    flaggedHashTerritorio,
    flaggedHashAgendamentoPreNatal,
    flaggedHashEnvioRnds,
  ]

  return (
    <aside className={classes.aside}>
      <SideMenuList>
        {items.map((item, index) => {
          return (
            <SideMenuNewsItem
              title={item.title}
              to={item.to}
              key={item.title}
              hashchildren={item.hashChildren.filter((hash) => !flaggedHashList.includes(hash))}
              expandedDefault={index === 0}
            >
              {item.children
                .filter((child) => !flaggedHashList.includes(child.to))
                .map((subItem) => {
                  return <SideMenuNewsSublink to={subItem.to} title={subItem.title} key={subItem.title} />
                })}
            </SideMenuNewsItem>
          )
        })}
      </SideMenuList>
    </aside>
  )
}

const createStyles = (theme: Theme, passouCabecalho: boolean, scrollY: number) => ({
  aside: {
    width: '100%',
    maxWidth: '350px',
    paddingRight: '1.5rem',
    borderRight: `1px solid ${theme.pallete.divider}`,
    position: passouCabecalho ? 'fixed' : 'static',
    top: '1rem',
    height: `calc(100% - ${calcAsideHeightDiff(scrollY) + 'rem'})`,
    overflow: 'hidden auto',
    [theme.breakpoints.up('xl')]: {
      //1920
      width: passouCabecalho && 'calc(100% / 15 * 2.73)',
    },
    [theme.breakpoints.down('xl')]: {
      //1920
      width: passouCabecalho && 'calc(100% / 15 * 3.18)',
    },

    [theme.breakpoints.down('md')]: {
      //960
      width: passouCabecalho && 'calc(100% / 15 * 3.30)',
    },
    '::-webkit-scrollbar': {
      width: '0.3rem',
      height: '0.3rem',
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.pallete.gray.c40,
      borderRadius: theme.radius.tag,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.pallete.gray.c60,
    },
    '::-webkit-scrollbar-track': {
      background: theme.pallete.surface.main,
      borderRadius: theme.radius.tag,
      boxShadow: `inset 0.4rem 0.625rem 0.75rem ${theme.pallete.divider}`,
    },
  } as CSSProperties,
})

function calcAsideHeightDiff(scrollY: number): number {
  return Math.max(0, 6.1 / 16) + 1.5
}
