import { css } from '@emotion/core'
import {
  Alert,
  Cell,
  ExternalStyles,
  FormControl,
  Grid,
  HFlow,
  InfoLabel,
  Paper,
  Text,
  Theme,
  useStyles,
  VFlow,
} from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import { DecimalField, ErrorField, NumberField, RadioField } from 'components/form'
import { resolveValue } from 'components/form/final-form/useField'
import { TipoGlicemia } from 'graphql/types.generated'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { tipoGlicemiaRecord } from 'types/enums'
import { calcularIMC, calcularRiscoCardiovascular, estadoNutricional } from 'util/atendimento'
import { MetaPath } from 'util/metaPath'
import { formatNumber, parseNumber } from 'util/number'
import { isEmpty, isValid } from 'util/validation/Util'
import { meta as soapMeta } from 'view/atendimentos/atendimento-individual/model'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'
import { getSoapFieldLabelGroup } from 'view/atendimentos/detail/soap/validation-errors-modal/getSoapFieldLabelGroup'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

export interface MedicoesPanelModel {
  openedAccordions?: (string | number)[]
  peso?: string
  altura?: string
  perimetroCefalico?: string
  circunferenciaAbdominal?: string
  perimetroPanturrilha?: string
  pressaoArterialSistolica?: string
  pressaoArterialDiastolica?: string
  frequenciaRespiratoria?: string
  frequenciaCardiaca?: string
  temperatura?: string
  saturacaoO2?: string
  glicemia?: string
  tipoGlicemia?: TipoGlicemia
}

export interface MedicoesPanelProps {
  name: MetaPath<MedicoesPanelModel>
  cidadao: CidadaoAtendimento
  gestacoes: PeriodoGestacaoModel[]
  dataAtendimento: Date
  style?: ExternalStyles
}

export function MedicoesPanel(props: MedicoesPanelProps) {
  const {
    name,
    cidadao,
    gestacoes,
    dataAtendimento,
    style = css`
      margin: 0;
    `,
  } = props

  const { values: formValues, errors: formErrors } = useFormState({ subscription: { values: true, errors: true } })

  const values = resolveValue(formValues, name) || {}
  const errors = resolveValue(formErrors, name) || {}

  const { classes, theme } = useStyles(createStyles)

  const alertMessageAbdominal = useMemo(
    () => calcularRiscoCardiovascular(cidadao.sexo, parseNumber(values.circunferenciaAbdominal)),
    [cidadao.sexo, values.circunferenciaAbdominal]
  )

  const imc = useMemo(
    () =>
      isValid(errors.peso) &&
      isValid(errors.altura) &&
      calcularIMC(parseNumber(values.peso), parseNumber(values.altura)),
    [values.peso, values.altura, errors.peso, errors.altura]
  )

  const idadeDuranteMedicao = moment
    .duration(moment(dataAtendimento).diff(cidadao.dataNascimento, 'days'), 'days')
    .asYears()

  const msgEstadoNutricional = useMemo(
    () => imc && estadoNutricional(imc, cidadao.sexo, idadeDuranteMedicao, gestacoes, cidadao.identidadeGeneroDbEnum),
    [imc, cidadao.sexo, idadeDuranteMedicao, gestacoes, cidadao.identidadeGeneroDbEnum]
  )

  const {
    label,
    fields: {
      altura,
      circunferenciaAbdominal,
      frequenciaCardiaca,
      frequenciaRespiratoria,
      glicemia,
      perimetroCefalico,
      perimetroPanturrilha,
      peso,
      saturacaoO2,
      temperatura,
      tipoGlicemia,
    },
  } = getSoapFieldLabelGroup(soapMeta.objetivo.medicoes)

  return (
    <FormSectionAccordion name={name.openedAccordions} title={label} uuid='medicoes' style={style}>
      <div style={{ padding: '1rem' }}>
        <Grid alignItems='flex-start'>
          <Cell size={7}>
            <Paper style={{ padding: '0.625rem' }}>
              <Grid gap={1} alignItems='flex-end' justifyContent='flex-start'>
                <Cell xs={12} lg={8} flexGrow={1}>
                  <HFlow>
                    <DecimalField name={name.peso} label={peso.label} maxLength={7} precisao={3} />
                    <DecimalField name={name.altura} label={altura.label} maxLength={5} precisao={1} />
                    <VFlow>
                      <InfoLabel title='IMC'>
                        <Text component='span' fontSize={1.5} fontWeight='bold' fontStyle='normal'>
                          {imc ? formatNumber(imc) : '-'}
                        </Text>
                      </InfoLabel>
                    </VFlow>
                  </HFlow>
                </Cell>
                {msgEstadoNutricional && (
                  <Cell xs={12} lg={4}>
                    <Alert inline type={msgEstadoNutricional.alertType}>
                      {msgEstadoNutricional.message}
                    </Alert>
                  </Cell>
                )}
              </Grid>
            </Paper>
          </Cell>

          <Cell
            lg={1}
            style={{
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
            }}
          />

          <Cell xs={4} lg={3}>
            <DecimalField name={name.perimetroCefalico} label={perimetroCefalico.label} maxLength={5} precisao={1} />
          </Cell>

          <Cell xs={6} lg={7}>
            <Grid gap={1} alignItems='flex-end'>
              <Cell xs={9} lg={5}>
                <DecimalField
                  name={name.circunferenciaAbdominal}
                  label={circunferenciaAbdominal.label}
                  maxLength={5}
                  precisao={1}
                />
              </Cell>
              {alertMessageAbdominal && (
                <Cell xs={12} lg={5}>
                  <Alert inline type='warning'>
                    {alertMessageAbdominal}
                  </Alert>
                </Cell>
              )}
            </Grid>
          </Cell>

          <Cell size={1} />

          <Cell xs={4} lg={3}>
            <DecimalField
              name={name.perimetroPanturrilha}
              label={perimetroPanturrilha.label}
              maxLength={5}
              precisao={1}
            />
          </Cell>
        </Grid>

        <Grid style={classes.border}>
          <Cell xs={12} lg={4}>
            <FormControl label='Pressão arterial (mmHg)'>
              <HFlow>
                <NumberField name={name.pressaoArterialSistolica} label='' maxLength={3} />
                <Text component='p' fontSize={1.25}>
                  /
                </Text>
                <NumberField name={name.pressaoArterialDiastolica} label='' maxLength={3} />
              </HFlow>
            </FormControl>
          </Cell>

          <Cell xs={4} lg={3}>
            <NumberField name={name.frequenciaRespiratoria} label={frequenciaRespiratoria.label} maxLength={3} />
          </Cell>

          <Cell xs={3} lg={1} />

          <Cell xs={4} lg={3}>
            <NumberField name={name.frequenciaCardiaca} label={frequenciaCardiaca.label} maxLength={3} />
          </Cell>

          <Cell xs={4} lg={3}>
            <DecimalField name={name.temperatura} label={temperatura.label} maxLength={4} precisao={1} />
          </Cell>

          <Cell xs={3} lg={1} />

          <Cell xs={4} lg={3}>
            <NumberField name={name.saturacaoO2} label={saturacaoO2.label} maxLength={3} />
          </Cell>
        </Grid>

        <Grid style={classes.border}>
          <Cell size={4}>
            <NumberField name={name.glicemia} label={glicemia.label} maxLength={3} />
          </Cell>

          <Cell xs={12} lg={8}>
            <FormControl label={tipoGlicemia.label} required={!isEmpty(values.glicemia)}>
              <VFlow vSpacing={0.5}>
                <HFlow style={{ marginLeft: '-0.25rem' }}>
                  <RadioField
                    name={name.tipoGlicemia}
                    label={tipoGlicemiaRecord[TipoGlicemia.NAO_ESPECIFICADO]}
                    value={TipoGlicemia.NAO_ESPECIFICADO}
                    disabled={!values.glicemia}
                  />
                  <RadioField
                    name={name.tipoGlicemia}
                    label={tipoGlicemiaRecord[TipoGlicemia.JEJUM]}
                    value={TipoGlicemia.JEJUM}
                    disabled={!values.glicemia}
                  />
                  <RadioField
                    name={name.tipoGlicemia}
                    label={tipoGlicemiaRecord[TipoGlicemia.PREPRANDIAL]}
                    value={TipoGlicemia.PREPRANDIAL}
                    disabled={!values.glicemia}
                  />
                  <RadioField
                    name={name.tipoGlicemia}
                    label={tipoGlicemiaRecord[TipoGlicemia.POSPRANDIAL]}
                    value={TipoGlicemia.POSPRANDIAL}
                    disabled={!values.glicemia}
                  />
                </HFlow>
                <ErrorField name={name.tipoGlicemia} />
              </VFlow>
            </FormControl>
          </Cell>
        </Grid>
      </div>
    </FormSectionAccordion>
  )
}

const createStyles = (theme: Theme) => ({
  border: {
    borderTop: `0.063rem solid ${theme.pallete.gray.c80}`,
    marginTop: '1rem',
  },
})
