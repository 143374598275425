/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Text } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { format } from 'date-fns'
import { Fragment } from 'react'

import { InformacoesAdministrativas } from '../../../model/historicoModel'

interface HistoricoInfoAdminPanelProps {
  content: InformacoesAdministrativas
}

export default function HistoricoInfoAdminPanel({ content }: HistoricoInfoAdminPanelProps) {
  const hasDadosProfissionalPrincipal =
    content.nomeProfissionalPrincipal || content.cboProfissionalPrincipal || content.ineEquipePrincipal
  const hasDadosEstagiario = content.nomeEstagiario
  const hasDadosLocalAtendimento = content.cnesUnidadeSaudePrincipal || content.localAtendimento

  return (
    <Fragment>
      <hr
        css={css`
          margin: 1rem -1rem;
        `}
      />
      <div>
        <Grid gapVertical={0.5}>
          <Cell size={12}>
            <Heading level={3}>Informações administrativas</Heading>
          </Cell>
          <Cell size={12}>
            {hasDadosProfissionalPrincipal && (
              <HFlow hSpacing={0} style={styles.separator}>
                {content.nomeProfissionalPrincipal && (
                  <HLabel title='Profissional:'>{content.nomeProfissionalPrincipal}</HLabel>
                )}
                <Text>{content.cboProfissionalPrincipal?.titleCase()}</Text>
                {content.ineEquipePrincipal ? (
                  <Text>{`${content.nomeEquipePrincipal} - ${content.ineEquipePrincipal}`}</Text>
                ) : (
                  <Text>Sem equipe</Text>
                )}
              </HFlow>
            )}
          </Cell>
          {hasDadosEstagiario && (
            <Cell size={12}>
              <HFlow hSpacing={0} style={styles.separator}>
                <HLabel title='Estagiário:'>{content.nomeEstagiario}</HLabel>
                <Text>{content.cboEstagiario?.titleCase()}</Text>
                {content.nomeEquipeEstagiario ? (
                  <Text>{`${content.nomeEquipeEstagiario} - ${content.ineEquipeEstagiario}`}</Text>
                ) : (
                  <Text>Sem equipe</Text>
                )}
              </HFlow>
            </Cell>
          )}
          {content.stRegistroTardio && (
            <Cell size={12}>
              <Text fontStyle='italic'>
                {`Registro tardio registrado em ${format(content.dataCriacaoRegistro, "dd/MM/yyyy' às 'HH:mm")}`}
              </Text>
            </Cell>
          )}
          {(content.cpfCnsCuidador || content.tipoCuidador) && (
            <Cell size={12}>
              <HLabel title='Cuidador:'>
                <Text>
                  {`${content.tipoCuidador ?? ''}
                  ${
                    content.tipoCuidador && content.cpfCnsCuidador
                      ? ` - ${content.cpfCnsCuidador ?? ''}`
                      : `${content.cpfCnsCuidador ?? ''}`
                  }`}
                </Text>
              </HLabel>
            </Cell>
          )}
          {hasDadosLocalAtendimento && (
            <Cell size={12}>
              <HFlow hSpacing={0} style={styles.separator}>
                {content.cnesUnidadeSaudePrincipal && (
                  <HLabel title='CNES:'>
                    <Text>{`${content.nomeUnidadeSaudePrincipal} - ${content.cnesUnidadeSaudePrincipal}`}</Text>
                  </HLabel>
                )}
                {content.localAtendimento && (
                  <HLabel title='Local de atendimento:'>
                    <Text>{content.localAtendimento}</Text>
                  </HLabel>
                )}
                {content.codigoInep && (
                  <HLabel title='Local de atividade:'>
                    <Text>{`${content.nomeInep.titleCase()} - ${content.codigoInep}`}</Text>
                  </HLabel>
                )}
              </HFlow>
            </Cell>
          )}
          {content.stAtendimentoCompartilhado && (
            <Cell size={12}>
              <HFlow hSpacing={0} style={styles.separator}>
                {content.nomeProfissionalCompartilhado && (
                  <HLabel title='Atendimento compartilhado com:'>
                    <Text>{content.nomeProfissionalCompartilhado}</Text>
                  </HLabel>
                )}
                {content.cboProfissionalCompartilhado && (
                  <Text>{content.cboProfissionalCompartilhado.titleCase()}</Text>
                )}
                {content.ineEquipeCompartilhado && (
                  <Text>{`${content.nomeEquipeCompartilhado} - ${content.ineEquipeCompartilhado}`}</Text>
                )}
              </HFlow>
            </Cell>
          )}
        </Grid>
      </div>
    </Fragment>
  )
}

const styles = {
  separator: css`
    & > :not(:last-child):after {
      content: '|';
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  `,
}
