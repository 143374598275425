/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import { InfoIcon } from 'components/InfoIcon'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useField } from 'react-final-form'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'
import { hasProblemaCondicaoGravidezAltoRiscoAtivoOuLatente } from '../../avaliacao/components/problemas-condicoes/utils/verifications'
import { CIAP_GRAVIDEZ_ALTO_RISCO, CID_GRAVIDEZ_ALTO_RISCO } from '../model'
import { RiscoGravidezTag } from './RiscoGravidezTag'

interface RiscoGravidezMarkerProps {
  isAltoRisco: boolean
}

const TEXTO_CIAPS_ALTO_RISCO = `CIAP2: ${CIAP_GRAVIDEZ_ALTO_RISCO.join(', ')}`
const TEXTO_CIDS_ALTO_RISCO = `CID10: Grupo Z35 (${CID_GRAVIDEZ_ALTO_RISCO.join(' / ')})`

const INFO_ICON_TEXT =
  'Para alterar o risco da gestação no sistema, uma das condições abaixo deve ser incluída na Avaliação:'

const INFO_ICON_TEXT_ENFERMEIRO = INFO_ICON_TEXT + '\n\n' + TEXTO_CIAPS_ALTO_RISCO
const INFO_ICON_TEXT_MEDICO = INFO_ICON_TEXT_ENFERMEIRO + '\n\n' + TEXTO_CIDS_ALTO_RISCO

export function RiscoGravidezMarker(props: RiscoGravidezMarkerProps) {
  const { isAltoRisco } = props

  const {
    permissoes: { somenteCiap },
  } = useAtendimentoContext()

  const riscoGravidezMessage = somenteCiap ? INFO_ICON_TEXT_ENFERMEIRO : INFO_ICON_TEXT_MEDICO

  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(meta.avaliacao.problemasECondicoes.absolutePath(), {
    subscription: { value: true },
  })

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text fontWeight='bold'>Risco da gravidez</Text>
        <InfoIcon icon='infoCircleFilled' text={riscoGravidezMessage} tooltipStyle={styles.tooltip} />
      </HFlow>
      <RiscoGravidezTag
        isAltoRisco={isAltoRisco || hasProblemaCondicaoGravidezAltoRiscoAtivoOuLatente(problemasCondicoes)}
      />
    </VFlow>
  )
}

const styles = {
  tooltip: css`
    white-space: pre-wrap;
  `,
}
