import ApolloClient from 'apollo-client'
import createDecorator from 'final-form-calculate'
import { TipoAtendimentoProfissional, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { CidadaoAtendimentoContextModel } from 'hooks/atendimento-context/model'

import { Problema } from '../detail/soap/aside/types/ProblemaModel'
import { createAvaliacaoCalculations } from '../detail/soap/avaliacao/calculator'
import { createFinalizacaoAtendimentoCalculations } from '../detail/soap/finalizacao/calculator/calculator'
import { createObjetivoCalculations } from '../detail/soap/objetivo/calculator'
import { createPlanoCalculations, HasAccessProcedimentos } from '../detail/soap/plano/calculator'
import { createIdadeGestacionalCalculation } from '../detail/soap/pre-natal/calculator'
import { ProcedimentoAutomatico, RegistroAvaliacaoPuericultura } from '../model'
import { CiapCidPreNatal, meta } from './model'

export interface CidadaoCalculator
  extends Pick<
    CidadaoAtendimentoContextModel,
    'idadeEmAnos' | 'dataNascimento' | 'isGestante' | 'desejaInformarIdentidadeGenero' | 'sexo'
  > {
  problemasAtivosELatentes: Problema[]
}

export interface PermissionsCalculator {
  hasPermissionPreNatal: boolean
  hasPermissionAcompanhamentoPreNatal: boolean
  hasAccessProcedimentos: HasAccessProcedimentos
}

export interface AtendimentoIndividualCalculatorProps {
  apollo: ApolloClient<object>
  procedimentosAutomaticos: ProcedimentoAutomatico[]
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura
  isMedico: boolean
  isDentista: boolean
  tipoAtendimentoProfissional: TipoAtendimentoProfissional
  prontuarioId: ID
  ciapCidPreNatal: CiapCidPreNatal
  dataAtendimento: Instant
  cidadao: CidadaoCalculator
  tipoEstabelecimento: TipoEstabelecimentoEnum
  permissions: PermissionsCalculator
  POLICLINICA_ENABLED: Boolean
}

export const createAtendimentoIndividualCalculator = (props: AtendimentoIndividualCalculatorProps) => {
  const {
    apollo,
    procedimentosAutomaticos,
    registroAvaliacaoPuericultura,
    isMedico,
    isDentista,
    tipoAtendimentoProfissional,
    prontuarioId,
    ciapCidPreNatal,
    dataAtendimento,
    permissions: { hasAccessProcedimentos, hasPermissionAcompanhamentoPreNatal, hasPermissionPreNatal },
    cidadao,
    tipoEstabelecimento,
    POLICLINICA_ENABLED,
  } = props

  return createDecorator(
    ...createObjetivoCalculations(meta.objetivo, meta.avaliacao, cidadao.isGestante, hasPermissionPreNatal),
    ...createAvaliacaoCalculations(
      meta.objetivo,
      meta.avaliacao,
      registroAvaliacaoPuericultura,
      isMedico,
      apollo,
      prontuarioId,
      ciapCidPreNatal,
      cidadao.dataNascimento,
      dataAtendimento,
      hasPermissionPreNatal,
      cidadao.isGestante,
      cidadao.problemasAtivosELatentes
    ),
    ...createPlanoCalculations(
      meta.plano,
      meta.objetivo.atendimentosEspecificos.formAtivo,
      meta.objetivo.medicoes,
      meta.objetivo.atendimentosEspecificos.puericultura,
      procedimentosAutomaticos,
      cidadao.idadeEmAnos,
      hasAccessProcedimentos
    ),
    ...createFinalizacaoAtendimentoCalculations(
      meta.finalizacao,
      meta.avaliacao.problemasECondicoes,
      apollo,
      isMedico,
      isDentista,
      procedimentosAutomaticos,
      tipoAtendimentoProfissional,
      hasPermissionPreNatal,
      cidadao.idadeEmAnos,
      cidadao.desejaInformarIdentidadeGenero,
      cidadao.sexo,
      cidadao.isGestante,
      tipoEstabelecimento,
      POLICLINICA_ENABLED
    ),
    createIdadeGestacionalCalculation(
      meta.acompanhamentoPreNatal.idadeGestacional,
      meta.objetivo,
      apollo,
      prontuarioId,
      dataAtendimento,
      hasPermissionAcompanhamentoPreNatal
    )
  )
}
