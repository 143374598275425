/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, Heading, Theme, useTheme } from 'bold-ui'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { PageContainer } from 'components/layout/PageContainer'
import { useCallback } from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

import { CameraPreview } from './componentes/CameraPreview'
import { useEntrarEmVideochamada } from './hooks/useEntrarEmVideochamada'
import { LocalVideocallParticipant } from './model'

const meta = metaPath<SalaEsperaFormModel>()

interface SalaEsperaVideochamadaUsuariosExternosViewProps {
  videochamadaId: ID
  nomeProfissional: String
  cboProfissional: String

  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void

  onEntrar(selfData: LocalVideocallParticipant): void
}

interface SalaEsperaFormModel {
  nomeParticipante: string
}

const validator = createValidator<SalaEsperaFormModel>({
  nomeParticipante: [required],
})

export function SalaEsperaVideochamadaUsuariosExternosView(props: SalaEsperaVideochamadaUsuariosExternosViewProps) {
  const {
    videochamadaId,
    nomeProfissional,
    cboProfissional,
    onEntrar,
    audioEnabled,
    videoEnabled,
    setAudioEnabled,
    setVideoEnabled,
  } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const entrarEmVideochamada = useEntrarEmVideochamada({ videochamadaId, onEntrar })

  const handleEntrar = useCallback((values: SalaEsperaFormModel) => entrarEmVideochamada(values.nomeParticipante), [
    entrarEmVideochamada,
  ])

  const renderForm = ({ handleSubmit }: FormRenderProps<SalaEsperaFormModel>) => (
    <div css={styles.formContainer}>
      <Grid alignItems='center'>
        <Cell xs={8} md={6}>
          <TextField
            label='Nome do participante'
            name={meta.nomeParticipante}
            placeholder='Digite o nome do participante'
            required
          />
        </Cell>
        <Cell xs={8} md={6}>
          <FormControl label={<span>&nbsp;</span>} style={styles.form}>
            <SubmitButton handleSubmit={handleSubmit} kind='primary'>
              Entrar na chamada
            </SubmitButton>
          </FormControl>
        </Cell>
      </Grid>
    </div>
  )

  return (
    <PageContainer>
      <Grid gap={6} gapVertical={1} direction='row-reverse'>
        <Cell size={12}>
          <Heading level={1} style={styles.heading}>
            Videochamada e-SUS APS
          </Heading>
        </Cell>
        <Cell xs={12} sm={5}>
          <CameraPreview
            audioEnabled={audioEnabled}
            videoEnabled={videoEnabled}
            setAudioEnabled={setAudioEnabled}
            setVideoEnabled={setVideoEnabled}
          />
        </Cell>
        <Cell xs={12} sm={7}>
          <Grid>
            <Cell size={12}>
              <Heading level={2} style={styles.headingName}>
                Chamada com {nomeProfissional}
                {cboProfissional ? ` | ${cboProfissional}` : ''}
              </Heading>
            </Cell>
            <Cell size={12}>
              <Form<SalaEsperaFormModel> render={renderForm} onSubmit={handleEntrar} validate={validator} />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </PageContainer>
  )
}

const createStyles = (theme: Theme) => ({
  heading: css`
    margin-top: 2rem;
  `,
  headingName: css`
    padding: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `,
  formContainer: css`
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: 2px;
    padding: 1rem;
  `,
  form: css`
    display: flex;
    padding-top: 0.5rem;
    justify-content: right;
  `,
})
