import useLocalStorage from '@rehooks/local-storage'
import { Button, Icon, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { differenceInCalendarDays } from 'date-fns'
import {
  useListaAtendimentoPollingEnabledQuery,
  useListaAtendimentoPollingQuery,
  useListaAtendimentoQuery,
} from 'graphql/hooks.generated'
import { PageParams, StatusAtendimento } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import useLocalStorageState from 'hooks/useLocalStorageState'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import qs from 'qs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import Permissions from 'types/Permissions'
import { safeDateAsYyyyMmDd } from 'util/date/formatDate'
import AtendimentoListing from 'view/atendimentos/components/AtendimentoListing'
import { ListaAtendimentoFilterModel } from 'view/atendimentos/list/filter/ListaAtendimentoFilter'
import { ListaAtendimentoFilterPopperModel } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'
import { ListaAtendimentoFooter } from 'view/atendimentos/list/ListaAtendimentoFooter'
import { AtendimentosFilterModel } from 'view/atendimentos/list/model'
import { useListaRegistroTardioFilterDefault } from 'view/registro-tardio/filter/useListaRegistroTardioFilterDefault'

import { ListaRegistroTardioFilter } from './filter/ListaRegistroTardioFilter'
import ListaRegistroTardioForm from './ListaRegistroTardioForm'
import { ListaRegistroTardioItem } from './ListaRegistroTardioItem'

type AtendimentoTopicModel = { idUbs: number }

interface UrlParams {
  cidadaoId: string
}

export default function ListaRegistroTardioView() {
  const handleRejection = useErrorHandler()
  const session = useSession()
  const location = useLocation()
  const { getServerTimeNow } = useServerTime()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 5,
  })

  const filterDefault = useListaRegistroTardioFilterDefault()

  const currentServerTime = getServerTimeNow()

  const lastUpdateRef = useRef<Date>()

  const [state, setState] = useLocalStorageState<AtendimentosFilterModel>(
    `${session.data.acesso.id}/registro-tardio-state`,
    filterDefault
  )

  const [backupFilterStorage, setBackupFilterStorage, deleteBackupFilterStorage] = useLocalStorage<
    ListaAtendimentoFilterPopperModel
  >(`${session.data.acesso.id}/registro-tardio-filter-backup`)

  const [adicionarMode, setAdicionarMode] = useState(!!urlParams.cidadaoId)

  const { data: pollingEnabled } = useListaAtendimentoPollingEnabledQuery()

  const { refetch: refetchPolling } = useListaAtendimentoPollingQuery({
    variables: {
      time: new Date(state.lastUpdate).valueOf(),
    },
    skip: !pollingEnabled?.atendimentoPollingEnabled,
  })

  const { data, refetch } = useListaAtendimentoQuery({
    variables: {
      input: {
        query: state.filter.query,
        ordem: state.filter.ordem,
        somenteMeusAtendimentos: state.filter.somenteMeusAtendimentos,
        statusAtendimento:
          !state.filter.statusAtendimento || state.filter.statusAtendimento.length === 0
            ? [StatusAtendimento.EM_ATENDIMENTO, StatusAtendimento.AGUARDANDO_ATENDIMENTO]
            : state.filter.statusAtendimento || [],
        dataInicio: safeDateAsYyyyMmDd(state.filter.periodo.startDate),
        dataFim: safeDateAsYyyyMmDd(state.filter.periodo.endDate),
        responsaveis: state.filter.responsaveis?.map((value) => value.id),
        registroTardio: true,
        pageParams: state.pageParams,
      },
    },
  })

  const refetchLista = useCallback(() => {
    refetch()
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          lastUpdate: currentServerTime,
        }))
      })
      .catch(handleRejection)
  }, [currentServerTime, handleRejection, refetch, setState])

  useAtmosphere<AtendimentoTopicModel>({
    topic: `registroTardio/${acesso?.unidadeSaude.id}`,
    onMessage: (data) => data?.idUbs && refetchLista(),
  })

  const handleAdicionarCidadaoClick = () => {
    setAdicionarMode(!adicionarMode)
    analytics.logEvent('adicionar_cidadao_LRT')
  }

  const onChangeFilter = useCallback(
    (filter: ListaAtendimentoFilterModel) => {
      setState((prevState) => ({
        ...prevState,
        filter,
        pageParams: {
          ...prevState.pageParams,
          page: 0,
        },
        lastUpdate: currentServerTime,
      }))
    },
    [currentServerTime, setState]
  )

  const onChangeFooter = useCallback(
    (pageParams: PageParams) => {
      setState((prevState) => ({ ...prevState, pageParams }))
    },
    [setState]
  )

  const handleClear = useCallback(() => {
    deleteBackupFilterStorage()
    onChangeFilter(filterDefault.filter)
  }, [deleteBackupFilterStorage, filterDefault.filter, onChangeFilter])

  const updateBackupFilterStorage = useCallback(
    (values: ListaAtendimentoFilterPopperModel) => setBackupFilterStorage(values),
    [setBackupFilterStorage]
  )

  const resetFilterToInitialState = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        filter: {
          ...prevState?.filter,
          query: undefined,
        },
      } as AtendimentosFilterModel
    })
  }, [setState])

  useEffect(() => {
    deleteBackupFilterStorage()
    resetFilterToInitialState()
  }, [resetFilterToInitialState, deleteBackupFilterStorage])

  useEffect(() => {
    lastUpdateRef.current = state.lastUpdate
  }, [state.lastUpdate])

  useEffect(() => {
    if (pollingEnabled && pollingEnabled.atendimentoPollingEnabled) {
      let shouldFetch = true

      const timeout = setInterval(() => {
        if (shouldFetch) {
          shouldFetch = false
          refetchPolling({ time: new Date(lastUpdateRef.current).valueOf() })
            .then(({ data }) => data.atendimentoPolling && refetchLista())
            .finally(() => (shouldFetch = true))
            .catch(handleRejection)
        }
      }, 2 * 1000)

      return () => {
        clearInterval(timeout)
      }
    }
  }, [handleRejection, pollingEnabled, refetchLista, refetchPolling])

  useEffect(() => {
    if (!state.lastDailyUpdate || differenceInCalendarDays(currentServerTime, new Date(state.lastDailyUpdate)) > 0) {
      if (!isEmpty(backupFilterStorage)) {
        setBackupFilterStorage({ ...backupFilterStorage, periodo: filterDefault.filter.periodo })
      }

      setState((prevValue) => ({
        ...prevValue,
        filter: {
          ...prevValue.filter,
          periodo: filterDefault.filter.periodo,
        },
        lastDailyUpdate: currentServerTime,
      }))
    }
  }, [backupFilterStorage, filterDefault.filter.periodo, currentServerTime, setBackupFilterStorage, setState, state])

  const renderHeading = (
    <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
      <VFlow>
        <Button kind='primary' size='medium' onClick={handleAdicionarCidadaoClick}>
          <Icon icon={adicionarMode ? 'angleUp' : 'angleDown'} style={{ marginRight: '0.5rem' }} />
          {adicionarMode ? 'Cancelar adição' : 'Adicionar cidadão'}
        </Button>
        {adicionarMode && <ListaRegistroTardioForm serverTime={currentServerTime} cidadaoId={urlParams.cidadaoId} />}
      </VFlow>
    </CheckPermission>
  )

  /** TODO (RoyalFlush) filtros do registro tardio */
  const renderFilter = (
    <ListaRegistroTardioFilter
      filter={state.filter}
      filterBackup={backupFilterStorage}
      filterDefault={filterDefault.filter}
      onChangeFilter={onChangeFilter}
      updateFilterBackup={updateBackupFilterStorage}
      deleteFilterBackup={() => deleteBackupFilterStorage()}
      onClear={handleClear}
    />
  )

  /** TODO (RoyalFlush) Passar prop com o setState de abrir RegistroTardioAtendimentoPosteriorModal para o botão do item */
  return (
    <AtendimentoListing
      title='Registro tardio de atendimento'
      heading={renderHeading}
      filter={renderFilter}
      footer={
        <ListaAtendimentoFooter
          pageInfo={data.atendimentos?.pageInfo}
          pageParams={state.pageParams}
          onChange={onChangeFooter}
        />
      }
    >
      {data.atendimentos && data.atendimentos.content.length !== 0 ? (
        data.atendimentos.content.map((atend, key) => <ListaRegistroTardioItem key={key} atend={atend} />)
      ) : (
        <div style={{ marginTop: '0.5rem' }}> Nenhum resultado encontrado.</div>
      )}
    </AtendimentoListing>
  )
}
