/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Tag, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { confirm } from 'components/modals/confirm'
import { useAlterarVideochamadaMutation } from 'graphql/hooks.generated'
import { ConexaoQuery } from 'graphql/types.generated'
import { FormRenderProps } from 'react-final-form'
import { isHttpsConnection } from 'util/https'

interface ConfiguracoesVideochamadaBoxProps {
  videochamada: ConexaoQuery['conexao']['videochamadas']
  internetHabilitada: boolean

  load(): void
}

export function ConfiguracoesVideochamadaBox(props: ConfiguracoesVideochamadaBoxProps) {
  const { videochamada, internetHabilitada } = props
  const alert = useAlert()
  const [alterarvideochamada] = useAlterarVideochamadaMutation()

  const submitAlteracao = async (msg: String) => {
    await alterarvideochamada({
      variables: {
        input: {
          habilitado: !videochamada.habilitado,
        },
      },
    })
    alert('success', msg)
  }

  const handleSubmit = async () => {
    if (videochamada.habilitado) {
      const confirmed = await confirm({
        title: 'Deseja desabilitar as videochamadas?',
        confirmLabel: 'Desabilitar',
      })()

      if (confirmed) await submitAlteracao('Videochamadas desabilitadas com sucesso')
    } else {
      await submitAlteracao('Videochamadas habilitadas com sucesso')
    }
  }

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Box>
        <Grid>
          <Cell flexGrow={1}>
            <VFlow vSpacing={2.5}>
              <Heading level={3} component='span'>
                <HFlow hSpacing={0.5} alignItems='center'>
                  Videochamadas
                  <InfoIcon
                    icon='infoCircleFilled'
                    text='É necessário que o acesso à instalação seja feito usando o protocolo HTTPS para habilitar a funcionalidade de videochamadas.'
                    style={css`
                      max-width: 20rem;
                    `}
                  />
                </HFlow>
              </Heading>

              <Tooltip text={getButtonTooltip(videochamada.habilitado, internetHabilitada)}>
                <Button
                  kind='primary'
                  size='small'
                  onClick={formProps.handleSubmit}
                  disabled={!internetHabilitada || !isHttpsConnection}
                >
                  {videochamada.habilitado ? 'Desabilitar' : 'Habilitar'}
                </Button>
              </Tooltip>
            </VFlow>
          </Cell>
          <Cell>
            {videochamada.habilitado ? (
              <Tag type='success' icon='checkCircleFilled'>
                Habilitado
              </Tag>
            ) : (
              <Tag type='normal' icon='banFilled'>
                Desabilitado
              </Tag>
            )}
          </Cell>
        </Grid>
      </Box>
    )
  }

  return <Form onSubmit={handleSubmit} initialValues={videochamada} render={renderForm} />
}

function getButtonTooltip(videochamadaHabilitado: boolean, internetHabilitada: boolean): string {
  if (!videochamadaHabilitado) {
    if (!internetHabilitada) return 'Não pode ser habilitado sem conexão com a internet'
    if (!isHttpsConnection) return 'O acesso à instalação deve ser feito usando o protocolo HTTPS'
  }
  return ''
}
