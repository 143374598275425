import { Button, Cell, Grid, Icon, Tag, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Form, FormRenderProps } from 'components/form'
import { ProcedimentoSelectField, ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { getFieldError } from 'components/form/final-form/util'
import { GrupoProcedimentoEnum, TipoProcedimentoEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaArray, metaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'
import { EditableList, EditableRow, useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { showCodigoProcedimento } from '../../objetivo/resultados-exames/util'
import { getCbosIdsProcedimentos } from '../../plano/utils/planoUtils'

export interface ProcedimentoSigtapFieldModel extends SoapEditableItem {
  _id: ID
  procedimento: ProcedimentoSelectModel
  isAutomatico?: boolean
}

export interface ProcedimentoSigtapFieldProps<T> {
  name: MetaArray<ProcedimentoSigtapFieldModel>
  cidadao?: CidadaoAtendimento
  tipoProcedimento?: TipoProcedimentoEnum
  label?: string
  dataAtendimento: LocalDate
}

ProcedimentoSigtapField.defaultProps = {
  tipoProcedimento: TipoProcedimentoEnum.ADMINISTRATIVO,
  label: 'Procedimentos administrativos (SIGTAP)',
}

const path = metaPath<ProcedimentoSigtapFieldModel>()

export function ProcedimentoSigtapField<T = any>(props: ProcedimentoSigtapFieldProps<T>) {
  const { name, cidadao, tipoProcedimento, label, dataAtendimento } = props
  const { acesso } = useAcessoLotacaoOrEstagio()

  const {
    handleSubmit,
    removeItem,
    meta,
    input: { value },
  } = useEditableListField({
    name,
  })

  const procedimentoIsEqual = (procedimento: ProcedimentoSelectModel) => {
    return (value || []).find((o2) => procedimento.id === o2.procedimento.id)
  }

  const renderForm = (props: FormRenderProps<ProcedimentoSigtapFieldModel>) => {
    const handleChange = (newValue: ProcedimentoSelectModel) => {
      newValue && !procedimentoIsEqual(newValue) && props.form.submit()
    }

    return (
      <>
        <ProcedimentoSelectField
          name={path.procedimento}
          label={label}
          filtroPadrao={true}
          tipoProcedimento={tipoProcedimento}
          gruposProcedimento={[
            GrupoProcedimentoEnum.ACOES_PROMOCAO_PREVENCAO_SAUDE,
            GrupoProcedimentoEnum.FINALIDADE_DIAGNOSTICA,
            GrupoProcedimentoEnum.CLINICOS,
            GrupoProcedimentoEnum.CIRURGICO,
          ]}
          sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : undefined}
          dataNascimento={cidadao?.dataNascimento}
          dataAtendimento={dataAtendimento}
          cboIds={getCbosIdsProcedimentos(acesso)}
          itemIsEqual={procedimentoIsEqual}
          onChange={handleChange}
          loadItemsOnOpen={false}
          error={getFieldError(meta)}
        />
      </>
    )
  }

  return (
    <Grid gapVertical={0.5}>
      <Cell size={8}>
        <Form<ProcedimentoSigtapFieldModel> render={renderForm} onSubmit={handleSubmit} {...props} />
      </Cell>

      {value?.length > 0 && (
        <Cell size={12}>
          <EditableList>
            {value.map((item) => (
              <ProcedimentoRow key={item._id} model={item} onRemove={removeItem} />
            ))}
          </EditableList>
        </Cell>
      )}
    </Grid>
  )
}

interface ProcedimentoRowProps {
  model: ProcedimentoSigtapFieldModel

  onRemove(itemToRemove: ProcedimentoSigtapFieldModel): void
}

function ProcedimentoRow(props: ProcedimentoRowProps) {
  const { model, onRemove } = props

  const handleRemove = () => onRemove(model)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(model)

  return (
    <EditableRow editing={false}>
      <Grid style={{ margin: 0 }}>
        <Cell size={10} alignSelf='center'>
          {`${model.procedimento?.descricao} - ${showCodigoProcedimento(model.procedimento?.codigo)}`}
          {model.isAutomatico && <Tag style={{ marginLeft: '0.5rem' }}>Adicionado automaticamente</Tag>}
        </Cell>

        <Cell size={2} alignSelf='center' style={{ padding: '0.25rem 0', textAlign: 'right' }}>
          <Tooltip text={tooltipText}>
            <Button size='small' skin='ghost' onClick={handleRemove} disabled={isEditable}>
              <Icon icon='trashOutline' />
            </Button>
          </Tooltip>
        </Cell>
      </Grid>
    </EditableRow>
  )
}
