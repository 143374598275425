import { VFlow } from 'bold-ui'
import React from 'react'

import {
  AgendarProximasConsultasSection,
  AgendarProximasConsultasSectionProps,
} from './AgendarProximasConsultasSection'
import { ProximaConsultaTypeEnum } from './model'
import { SugestaoProximasConsultasSection } from './SugestaoProximasConsultasSection'

interface ProximasConsultasPanelProps extends AgendarProximasConsultasSectionProps {}

export function ProximasConsultasPanel(props: ProximasConsultasPanelProps) {
  return (
    <VFlow>
      <AgendarProximasConsultasSection {...props} />
      {/* TODO (eldorado #14457):  remover os dados mockados*/}
      <SugestaoProximasConsultasSection
        sugestoes={[
          { type: ProximaConsultaTypeEnum.PRE_NATAL, date: '10-21-1997' },
          { type: ProximaConsultaTypeEnum.PRE_NATAL, date: '10-21-1997' },
          { type: ProximaConsultaTypeEnum.PRE_NATAL, date: '10-21-1997' },
          { type: ProximaConsultaTypeEnum.PRE_NATAL, date: '10-21-1997' },
          { type: ProximaConsultaTypeEnum.PRE_NATAL, date: '10-21-1997' },
          { type: ProximaConsultaTypeEnum.PRE_NATAL, date: '10-21-1997' },
          { type: ProximaConsultaTypeEnum.PRE_NATAL, date: '10-21-1997' },
        ]}
      />
    </VFlow>
  )
}
