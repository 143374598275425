import { useAlert } from 'components/alert'
import { information } from 'components/modals/information'
import { GraphQLError } from 'graphql'
import { useEntrarEmVideochamadaMutation } from 'graphql/hooks.generated'
import { useCallback } from 'react'

import { LocalVideocallParticipant } from '../model'

interface EntrarEmVideochamadaOptions {
  videochamadaId: ID
  onEntrar?(selfData: LocalVideocallParticipant): void
}

export function useEntrarEmVideochamada(options: EntrarEmVideochamadaOptions): (nome: string) => Promise<void> {
  const { videochamadaId, onEntrar } = options

  const alert = useAlert()

  const [entrarEmVideochamada] = useEntrarEmVideochamadaMutation()

  return useCallback(
    (nome: string) =>
      entrarEmVideochamada({ variables: { id: videochamadaId, nome: nome } })
        .then(({ data: { entrarEmVideochamada: selfId } }) => {
          onEntrar?.({ id: selfId, name: nome })
        })
        .catch((err) => {
          if (!handleEntrarErrors(err.graphQLErrors)) alert('danger', 'Erro ao entrar em videochamada.')
        }),
    [videochamadaId, alert, entrarEmVideochamada, onEntrar]
  )
}

function handleEntrarErrors(errors: GraphQLError[]): boolean {
  let handled = false
  errors?.forEach((error) => {
    if (error.extensions?.classification === 'VideochamadaLotadaException') {
      handled = true
      information({
        title: 'Número máximo de participantes excedido',
        body: 'No momento, as videochamadas e-SUS APS podem ter apenas dois participantes simultâneos.',
        showCloseButton: true,
      })()
    }
  })
  return handled
}
