/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { NormalizedHighlighter } from 'components/highlight/NormalizedHighlighter'
import { HLabel } from 'components/HLabel'
import { confirm } from 'components/modals/confirm'
import { StatusInformation } from 'components/StatusInformation'
import { SituacaoProblema } from 'graphql/types.generated'
import { Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'
import { humanizeDate } from 'util/date/humanize-date'
import { ProblemaCondicaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/model'
import { isCiapW78 } from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/utils/verifications'

import { Problema } from '../../../soap/aside/types/ProblemaModel'
import { MainDetailAccordionHeaderRenderProps } from '../../main-detail-accordion/components/MainDetailAccordionHeader'
import { SituacaoProblemaTag } from '../../SituacaoProblemaTag'
import { ButtonsHeaderProblemaModal } from './components/ButtonsHeaderProblemaModal'

export interface HeaderAccordionProblemasModalProps extends MainDetailAccordionHeaderRenderProps<Problema> {
  highlight?: string
  showActions?: boolean
  isAntecedentes?: boolean
  onEdit?(problema: Problema): void
  onRemove?(problema: ProblemaCondicaoModel): void
}

export function HeaderProblemaModal(props: HeaderAccordionProblemasModalProps) {
  const { item, highlight, showActions, onEdit, onRemove, isAntecedentes } = props

  const {
    id,
    ciap,
    cid10,
    descricaoOutro,
    ultimaEvolucao,
    situacao,
    isRegistradoAgora,
    isAvaliadoAgora,
    isAutomatico,
  } = item

  const isCiapW78Automatico = isCiapW78(ciap?.codigo) && isAutomatico

  const isResolvido = situacao === SituacaoProblema.RESOLVIDO

  const { textStyles } = styles

  const humanizedStartDate = ultimaEvolucao.dataInicio && humanizeDate(ultimaEvolucao.dataInicio)
  const humanizedEndDate = ultimaEvolucao.dataFim && humanizeDate(ultimaEvolucao.dataFim)

  const handleExcluirClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    confirm({
      title: 'Deseja confirmar a exclusão do problema/condição?',
      onConfirm: () => onRemove({ _id: ultimaEvolucao.id }),
      confirmLabel: 'Excluir',
      depthLevel: 2,
      manageOverflow: false,
    })()
  }

  const handleEditarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onEdit(id)
  }

  return (
    <HFlow alignItems='center' justifyContent='space-between'>
      <HFlow alignItems='center'>
        <Icon icon='angleDown' name='accordionAngleIcon' />
        <VFlow vSpacing={0}>
          <Heading level={4}>
            {ciap && (
              <Fragment>
                <NormalizedHighlighter searchWords={highlight} textToHighlight={ciap.descricao} splitWords={false} />
                <Text style={textStyles}>
                  {' '}
                  (CIAP2{' '}
                  <NormalizedHighlighter
                    searchWords={highlight}
                    textToHighlight={ciap.codigo}
                    splitWords={false}
                  />) {separator(!!cid10 || !!descricaoOutro)}
                </Text>
              </Fragment>
            )}
            {cid10 && (
              <Fragment>
                <NormalizedHighlighter searchWords={highlight} textToHighlight={cid10.nome} splitWords={false} />
                <Text style={textStyles}>
                  {' '}
                  (CID10{' '}
                  <NormalizedHighlighter
                    searchWords={highlight}
                    textToHighlight={cid10.codigo}
                    splitWords={false}
                  />) {separator(!!descricaoOutro)}
                </Text>
              </Fragment>
            )}
            {descricaoOutro && (
              <NormalizedHighlighter searchWords={highlight} textToHighlight={descricaoOutro} splitWords={false} />
            )}
          </Heading>
          <HLabel title='Início:'>
            {ultimaEvolucao.dataInicio &&
              `${formatDate(ultimaEvolucao.dataInicio)} | ${
                humanizedStartDate === 'Hoje' ? humanizedStartDate : `Há ${humanizedStartDate}`
              }`}
          </HLabel>
          {isResolvido ? (
            <HLabel title='Fim:'>
              {ultimaEvolucao.dataFim &&
                `${formatDate(ultimaEvolucao.dataFim)} | ${
                  humanizedEndDate === 'Hoje' ? humanizedEndDate : `Há ${humanizedEndDate}`
                }`}
            </HLabel>
          ) : (
            ultimaEvolucao.atualizadoEm && (
              <HLabel title='Última avaliação:'>{formatDate(ultimaEvolucao.atualizadoEm)}</HLabel>
            )
          )}

          {isRegistradoAgora ? (
            <StatusInformation icon='clockOutline' text='Registrado agora' />
          ) : (
            isAvaliadoAgora && <StatusInformation icon='clockOutline' text='Avaliado agora' />
          )}
        </VFlow>
      </HFlow>
      <HFlow>
        {(!isResolvido || isRegistradoAgora || isAvaliadoAgora) && (
          <HFlow alignItems='flex-start'>
            <SituacaoProblemaTag situacao={situacao} />
          </HFlow>
        )}

        {showActions && (
          <ButtonsHeaderProblemaModal
            registradoAgora={isRegistradoAgora}
            isCiapW78Automatico={isCiapW78Automatico}
            isSituacaoDiferenteDeResolvido={!isResolvido}
            isAntecedentes={isAntecedentes}
            handleEditarClick={handleEditarClick}
            handleExcluirClick={handleExcluirClick}
          />
        )}
      </HFlow>
    </HFlow>
  )
}

const separator = (expression: boolean): string => (expression ? ' | ' : '')

const styles = {
  textStyles: css`
    font-weight: initial;
  `,
}
