/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonProps, Icon, Icons, Theme, Tooltip } from 'bold-ui'
import theme from 'config/theme'

interface AcaoVideochamadaButtonProps extends ButtonProps {
  icon: 'mic' | 'video'
  enabled: boolean
  unavailable: boolean
  onClick(): void
}

type IconOnOff = {
  iconOn: Icons
  iconOff: Icons
  unavailableTooltip: string
}

const acaoVideochamadaButtonRecord: Record<AcaoVideochamadaButtonProps['icon'], IconOnOff> = {
  mic: {
    iconOn: 'microphoneFilled',
    iconOff: 'microphoneOffFilled',
    unavailableTooltip: 'Nenhum dispositivo de entrada de áudio encontrado.',
  },
  video: {
    iconOn: 'videoFilled',
    iconOff: 'videoOffFilled',
    unavailableTooltip: 'Nenhum dispositivo de entrada de vídeo encontrado.',
  },
}

export function AcaoVideochamadaButton(props: AcaoVideochamadaButtonProps) {
  const { icon, onClick, enabled, unavailable, ...rest } = props
  const styles = createStyles(theme)

  return (
    <Tooltip text={unavailable ? acaoVideochamadaButtonRecord[icon].unavailableTooltip : ''}>
      <Button
        onClick={onClick}
        kind={enabled || unavailable ? 'normal' : 'danger'}
        style={[styles.button, enabled || unavailable ? styles.normalButton : null]}
        disabled={unavailable}
        size='small'
        {...rest}
      >
        <Icon
          size={1.5}
          color='white'
          icon={
            !enabled || unavailable
              ? acaoVideochamadaButtonRecord[icon].iconOff
              : acaoVideochamadaButtonRecord[icon].iconOn
          }
        />
      </Button>
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  button: css`
    border: none;
    padding: 0.5rem;
  `,
  normalButton: css`
    background-color: ${theme.pallete.gray.c30};
    &:not(:disabled):hover {
      background-color: ${theme.pallete.gray.c20};
    }
  `,
})
