import css from '@emotion/css'
import { Cell, Grid, HFlow, Icon, InfoLabel, TableFooter, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DateTime } from 'components/date'
import { HLabel } from 'components/HLabel'
import { Cpf, Telefone } from 'components/label'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import theme from 'config/theme'
import { useListaEsperaTableQuery } from 'graphql/hooks.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { humanizeDate } from 'util/date/humanize-date'
import { stringGrouper } from 'util/string/string-grouper'

import { ListaEsperaItem, problemaCondicaoRecord, tipoAtendimentoRecord } from './model'

const PLACEHOLDER = '—'

type ListaEsperaTopicModel = { idUbs: number }

interface ListaEsperaTableProps {
  unidadeSaudeId: ID
}

export default function ListaEsperaTable(props: ListaEsperaTableProps) {
  const { unidadeSaudeId } = props
  const match = useRouteMatch()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const {
    data: { listaEsperaByUnidadeSaudeId: listaEspera },
    refetch,
  } = useListaEsperaTableQuery({
    variables: {
      input: { unidadeSaudeId },
    },
  })

  useAtmosphere<ListaEsperaTopicModel>({
    topic: `listaespera/${acesso?.unidadeSaude.id}`,
    onMessage: (data) => data?.idUbs && refetch(),
  })

  const qtdCidadaosListados = listaEspera?.pageInfo?.totalElements

  const content = listaEspera?.content ?? []

  const { resetExpandedItems } = useAccordionControl({
    allowMultipleExpanded: true,
  })

  const { tableProps } = usePagination<ListaEsperaItem>({
    items: content,
    onChange: resetExpandedItems,
  })

  const renderActions = (item: ListaEsperaItem) => (
    <HFlow hSpacing={0.5} justifyContent='flex-end'>
      <Tooltip text='Remover da lista'>
        <ButtonLink
          size='small'
          skin='ghost'
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          to={`${match.url}/${item.id}/remover-cidadao/${item.cidadao.id}`}
        >
          {/* TODO Foundation #14580 esperando definição do icone */}
          <Icon icon='userTimes' />
        </ButtonLink>
      </Tooltip>

      <Tooltip text='Agendar consulta'>
        <ButtonLink
          size='small'
          skin='ghost'
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          to={`${match.url}`} // TODO Foundation #14711 redirecionar quando agendar consulta for integrada
        >
          <Icon icon='calendarOutline' />
        </ButtonLink>
      </Tooltip>
    </HFlow>
  )

  return (
    <Cell size={12}>
      <VFlow>
        <Text fontWeight='bold' fontSize={1}>
          {'cidadão'.pluralizeAndConcatValue(qtdCidadaosListados || 0)} na lista de gestão da garantia do acesso
        </Text>
        <TableBox>
          <AccordionDataTable<ListaEsperaItem>
            columns={[
              {
                name: 'dataEntrada',
                header: 'Data',
                render: (item) => <DateTime format='DD/MM/YYYY' value={item.dataEntrada} />,
              },
              {
                name: 'cidadao',
                header: 'Cidadão',
                render: renderCidadao,
              },
              {
                name: 'telefone',
                header: 'Telefone',
                render: renderTelefone,
              },
              {
                name: 'equipe',
                header: 'Equipe',
                render: renderEquipe,
              },
              {
                name: 'tipoAtendimento',
                header: 'Tipo de atendimento',
                render: (item) => <Text>{tipoAtendimentoRecord[item.tipoAtendimento]}</Text>,
              },
              {
                name: 'actions',
                render: renderActions,
                size: 2,
              },
            ]}
            rows={content}
            components={{ AccordionPanel: panelMotivoProcura }}
          />
          <TableFooter {...tableProps} />
        </TableBox>
      </VFlow>
    </Cell>
  )
}

const renderCidadao = (item: ListaEsperaItem) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{(item.cidadao.nomeSocial ?? item.cidadao.nome).titleCase()}</Text>
    <Text fontWeight='bold'>{humanizeDate(item.cidadao.dataNascimento)}</Text>
    {item.cidadao.cpf ? (
      <HLabel title='CPF'>
        <Cpf value={item.cidadao.cpf} />
      </HLabel>
    ) : item.cidadao.cns ? (
      <HLabel title='CNS'>{item.cidadao.cns}</HLabel>
    ) : (
      <Text>Cidadão sem CPF/CNS cadastrado</Text>
    )}
  </VFlow>
)

const renderTelefone = (item: ListaEsperaItem) => (
  <VFlow vSpacing={0.1}>
    <Telefone value={item.cidadao.telefoneContato} />
    <Telefone value={item.cidadao.telefoneCelular} />
    <Telefone value={item.cidadao.telefoneResidencial} />
  </VFlow>
)

const renderEquipe = (item: ListaEsperaItem) =>
  item.equipe ? (
    <VFlow vSpacing={0}>
      <InfoLabel title={item.equipe.nome} placeholder={PLACEHOLDER}>
        {item.equipe.area && <Text>{'Área ' + item.equipe.area}</Text>}
      </InfoLabel>
    </VFlow>
  ) : (
    <Text>Sem equipe</Text>
  )

const panelMotivoProcura = ({ row }) => {
  const vezesString = row.qtdCidadaoPresente > 1 ? 'vezes' : 'vez'
  return (
    <Grid
      style={css`
        background: ${theme.pallete.gray.c90};
        padding: 1rem;
      `}
    >
      <Cell size={12}>
        <Tag type='normal'>
          Presente {row.qtdCidadaoPresente} {vezesString} na lista
        </Tag>
      </Cell>
      <Cell size={12}>
        <VFlow vSpacing={0}>
          <InfoLabel title='Problemas e/ou condições' placeholder={PLACEHOLDER}>
            {row.problemasCondicoes &&
              stringGrouper(...row.problemasCondicoes.map((value) => problemaCondicaoRecord[value]))}
          </InfoLabel>
        </VFlow>
      </Cell>
      <Cell size={12}>
        <VFlow vSpacing={0}>
          <InfoLabel title='Motivo da consulta' placeholder={PLACEHOLDER}>
            {row.motivoEspera}
          </InfoLabel>
        </VFlow>
      </Cell>
    </Grid>
  )
}
