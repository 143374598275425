/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tabs, Theme, Tooltip, useTheme } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import useFirebase from 'components/firebase/useFirebase'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PecSwitch, PrivateRoute, TabLink } from 'components/route'
import { Lotacao } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment, useMemo } from 'react'
import { Redirect, useLocation } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import Permissions from 'types/Permissions'
import { emptyArray } from 'util/array'
import { calculateAge } from 'util/date/calculateAge'
import { isEmpty } from 'util/validation/Util'
import { AgendamentosCidadaoView } from 'view/agenda/cidadao/AgendamentosCidadaoView'
import { FolhaRostoView } from 'view/atendimentos/detail/folha-rosto/FolhaRostoView'
import { CidadaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { VacinacaoReadOnlyView } from 'view/atendimentos/detail/vacinacao/VacinacaoView'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { HistoricoAtendimentoView } from '../../atendimentos/detail/historico/HistoricoAtendimentoView'
import { grupoCboVisualizarProntuario } from '../acessos'
import {
  CidadaoTabSectionModel,
  visualizarAgendaAndProntuarioPermissions,
  visualizarCidadaoAndProntuarioPermissions,
} from '../types/CidadaoTabSectionModel'
import { CidadaoInformacoesView } from './CidadaoInformacoesView'
import { CidadaoUnificacoesView } from './CidadaoUnificacoesView'

export interface CidadaoTabSectionProps {
  cidadao: CidadaoTabSectionModel
}

export const CidadaoTabSection = (props: CidadaoTabSectionProps) => {
  const { cidadao } = props
  const { analytics } = useFirebase()
  const match = useRouteMatch()
  const location = useLocation()
  const theme = useTheme()
  const classes = createStyles(theme)

  const {
    data: { acesso, profissional },
  } = useSession({ fetchPolicy: 'cache-only' })

  const { acesso: acessoLotacaoOrEstagio } = useAcessoLotacaoOrEstagio()

  const { getServerTimeNow } = useServerTime()

  const dataReferencia = getServerTimeNow().valueOf()

  const idadeEmAnos = cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, dataReferencia).years

  const cidadaoFolhaRosto: CidadaoFolhaRosto = { ...cidadao, idadeEmAnos }

  const lotacao: LotacaoAtendimento = useMemo(() => ({ ...(acesso as Lotacao), profissional }), [acesso, profissional])

  const isGestante = !isEmpty(cidadao.prontuario?.preNatalAtivo)
  const hasProntuario = !!cidadao.prontuario
  const tooltip = !hasProntuario && 'Cidadão não possui prontuário'

  const renderInformacoes = () => (
    <PageContent fluid type='filled'>
      <CidadaoInformacoesView cidadao={cidadao} mostrarProntuario={true} />
    </PageContent>
  )

  const renderUnificacoes = () => (
    <PageContent fluid type='filled'>
      <CidadaoUnificacoesView idCidadao={cidadao.id} temUnificacoes={cidadao.unificado} />
    </PageContent>
  )

  const renderFolhaRosto = () => (
    <FolhaRostoView
      prontuarioId={cidadao.prontuario.id}
      cidadao={cidadaoFolhaRosto}
      lotacao={lotacao}
      isGestante={isGestante}
      gestacoes={cidadao.prontuario?.gestacoes || emptyArray}
    />
  )

  const renderHistorico = () => (
    <HistoricoAtendimentoView
      prontuarioId={cidadao.prontuario.id}
      cidadao={cidadao}
      unidadeSaudeCnes={acessoLotacaoOrEstagio?.unidadeSaude.cnes}
      isAtendimentoObservacao={false}
      isObservacaoAndResponsavel={false}
    />
  )

  const renderAgendamentos = () => (
    <PageContent fluid type='filled'>
      <AgendamentosCidadaoView />
    </PageContent>
  )
  const renderVacinacao = () => <VacinacaoReadOnlyView dataReferencia={Number(getServerTimeNow())} cidadao={cidadao} />

  return (
    <Fragment>
      <div css={classes.container}>
        <PageContainer fluid>
          <Tabs style={classes.tabs}>
            <TabLink to={`${match.url}/informacoes${location.search}`}>Informações</TabLink>
            <CheckPermission
              hasAnyCbo={[grupoCboVisualizarProntuario]}
              hasAnyPermission={[Permissions.visualizarProntuario]}
            >
              <Tooltip text={tooltip}>
                <TabLink to={`${match.url}/folha-rosto${location.search}`} disabled={!hasProntuario}>
                  Folha de rosto
                </TabLink>
              </Tooltip>
            </CheckPermission>
            <CheckPermission
              hasAnyCbo={[grupoCboVisualizarProntuario]}
              hasAnyPermission={[Permissions.visualizarProntuario]}
            >
              <Tooltip text={tooltip}>
                <TabLink
                  to={`${match.url}/historico${location.search}`}
                  onClick={() => analytics.logEvent('click_tab_cidadao_historico')}
                  disabled={!hasProntuario}
                >
                  Histórico
                </TabLink>
              </Tooltip>
            </CheckPermission>
            <CheckPermission
              hasAnyCbo={[grupoCboVisualizarProntuario]}
              hasAnyPermission={[Permissions.visualizarProntuario]}
            >
              <Tooltip text={tooltip}>
                <TabLink to={`${match.url}/vacinacao${location.search}`} disabled={!hasProntuario}>
                  Vacinação
                </TabLink>
              </Tooltip>
            </CheckPermission>
            <TabLink to={`${match.url}/unificacoes${location.search}`}>Unificações</TabLink>
            <CheckPermission permission={visualizarAgendaAndProntuarioPermissions}>
              <TabLink to={`${match.url}/agendamentos${location.search}`}>Agendamentos</TabLink>
            </CheckPermission>
          </Tabs>
        </PageContainer>
      </div>

      <PecSwitch>
        {<Redirect exact path={`${match.url}`} to={`${match.url}/informacoes${location.search}`} />}
        {hasProntuario && (
          <PrivateRoute
            path={`${match.url}/folha-rosto`}
            render={renderFolhaRosto}
            permission={Permissions.visualizarProntuario}
            cbos={grupoCboVisualizarProntuario}
          />
        )}
        {hasProntuario && (
          <PrivateRoute
            exact
            path={`${match.url}/historico`}
            render={renderHistorico}
            permission={grupoCboVisualizarProntuario}
            cbos={grupoCboVisualizarProntuario}
          />
        )}
        {hasProntuario && (
          <PrivateRoute
            exact
            path={`${match.path}/vacinacao`}
            render={renderVacinacao}
            permission={Permissions.visualizarProntuario}
            cbos={grupoCboVisualizarProntuario}
          />
        )}
        <PrivateRoute
          exact
          path={`${match.url}/informacoes`}
          render={renderInformacoes}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
        <PrivateRoute
          path={`${match.url}/informacoes${location.search}`}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
        <PrivateRoute
          exact
          path={`${match.url}/unificacoes`}
          render={renderUnificacoes}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
        <PrivateRoute
          exact
          path={`${match.path}/agendamentos`}
          render={renderAgendamentos}
          permission={visualizarAgendaAndProntuarioPermissions}
        />
      </PecSwitch>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  tabs: css`
    margin-top: 0.5rem;
    border-bottom-width: 0;
  `,
})
