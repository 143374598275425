import { Cell, Grid, InfoLabel } from 'bold-ui'
import React from 'react'
import { tipoEdemaRecord, tipoGravidezRecord } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'

import { DppPaper } from './components/DppPaper'
import { DumPaper } from './components/DumPaper'
import { IgcPaper } from './components/IgcPaper'
import { PreNatalBox } from './components/PreNatalBox'
import { RiscoGravidezMarker } from './components/RiscoGravidezMarker'
import { PreNatalViewModel } from './model'

interface PreNatalViewProps {
  value: PreNatalViewModel
}

const PLACEHOLDER = 'Não informado'

export const PreNatalView = (props: PreNatalViewProps) => {
  const {
    value: {
      tipoGravidez,
      alturaUterina,
      isAltoRisco,
      edema,
      movimentacaoFetal,
      gravidezPlanejada,
      batimentoCardiacoFetal,
    },
  } = props

  return (
    <PreNatalBox>
      <Grid gapVertical={1} gap={1}>
        <Cell size={2}>
          <InfoLabel title='Tipo de gravidez' placeholder={PLACEHOLDER}>
            {tipoGravidez && tipoGravidezRecord[tipoGravidez]}
          </InfoLabel>
        </Cell>

        <Cell size={2}>
          <InfoLabel title='Altura uterina' placeholder={PLACEHOLDER}>
            {alturaUterina ? `${alturaUterina} cm` : null}
          </InfoLabel>
        </Cell>

        <Cell size={8}>
          <RiscoGravidezMarker isAltoRisco={isAltoRisco} />
        </Cell>

        <Cell size={2}>
          <InfoLabel title='Edema' placeholder={PLACEHOLDER}>
            {!isUndefinedOrNull(edema) && tipoEdemaRecord[edema]}
          </InfoLabel>
        </Cell>

        <Cell size={2}>
          <InfoLabel title='Movimentação fetal' placeholder={PLACEHOLDER}>
            {!isUndefinedOrNull(movimentacaoFetal) && (movimentacaoFetal ? 'Sim' : 'Não')}
          </InfoLabel>
        </Cell>

        <Cell size={8}>
          <InfoLabel title='Gravidez planejada' placeholder={PLACEHOLDER}>
            {!isUndefinedOrNull(gravidezPlanejada) && (gravidezPlanejada ? 'Sim' : 'Não')}
          </InfoLabel>
        </Cell>

        <Cell size={12}>
          <InfoLabel title='Batimento cardíaco fetal' placeholder={PLACEHOLDER}>
            {batimentoCardiacoFetal ? `${batimentoCardiacoFetal} bpm` : null}
          </InfoLabel>
        </Cell>

        <Cell>
          <DumPaper />
        </Cell>

        <Cell>
          <DppPaper />
        </Cell>

        <Cell>
          <IgcPaper />
        </Cell>
      </Grid>
    </PreNatalBox>
  )
}
