/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Theme, useTheme, VFlow } from 'bold-ui'
import { useRemoverParticipanteVideochamadaMutation } from 'graphql/hooks.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { StreamPlayerVideochamada } from './componentes/StreamPlayerVideochamada'
import { VideoChamadaFooter } from './componentes/VideochamadaFooter'
import { useUserMedia } from './hooks/useUserMedia'
import { useWebRtc } from './hooks/useWebRtc'
import { LocalVideocallParticipant } from './model'

interface VideochamadaViewProps {
  selfData: LocalVideocallParticipant
  isOwner: boolean
  videochamadaId: ID
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
}

export function VideochamadaView(props: VideochamadaViewProps) {
  const { videochamadaId, selfData, audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled, isOwner } = props

  const [remoteVideoTrackAvailable, setRemoteVideoTrackAvailable] = useState(false)

  const localVideoRef = useRef<HTMLVideoElement>()
  const remoteVideoRef = useRef<HTMLVideoElement>()

  const theme = useTheme()
  const styles = createStyles(theme)
  const history = useHistory()

  const { stream: localStream, audioDeviceAvailable, videoDeviceAvailable } = useUserMedia({
    video: videoEnabled,
    audio: audioEnabled,
  })

  const [removerParticipanteVideochamada] = useRemoverParticipanteVideochamadaMutation()

  const handlePeerDisconnected = useCallback(
    (peerId: ID) =>
      isOwner && removerParticipanteVideochamada({ variables: { id: videochamadaId, participanteId: peerId } }),
    [isOwner, removerParticipanteVideochamada, videochamadaId]
  )

  const { remoteParticipants } = useWebRtc({
    selfData,
    roomId: videochamadaId,
    localStream,
    onPeerDisconnected: handlePeerDisconnected,
  })

  useEffect(() => {
    if (localVideoRef.current) localVideoRef.current.srcObject = localStream
  }, [localStream])

  useEffect(() => {
    if (remoteVideoRef.current && !!remoteParticipants[0])
      remoteVideoRef.current.srcObject = remoteParticipants[0].stream
    setRemoteVideoTrackAvailable(!!remoteParticipants[0]?.stream.getVideoTracks().length)
  }, [remoteParticipants])

  const handleVideochamadaEncerrada = useCallback(() => {
    history.push('/videochamada/encerrada')
  }, [history])

  useAtmosphere<boolean>({
    topic: `/public/videochamada/encerrou/${videochamadaId}`,
    onMessage: handleVideochamadaEncerrada,
  })

  return (
    <div css={styles.page}>
      <VFlow style={styles.container}>
        <HFlow justifyContent='center' style={styles.imageSection}>
          <StreamPlayerVideochamada
            localStream
            ref={localVideoRef}
            minimizada={!!remoteParticipants.length}
            videoDeviceAvailable={videoDeviceAvailable}
            videoEnabled={videoEnabled}
          />
          <StreamPlayerVideochamada
            ref={(ref) => {
              remoteVideoRef.current = ref
              if (remoteVideoRef.current && !!remoteParticipants[0])
                remoteVideoRef.current.srcObject = remoteParticipants[0].stream
            }}
            streamAtivo={!!remoteParticipants.length}
            nomeParticipante={remoteParticipants[0]?.name}
            videoEnabled={remoteVideoTrackAvailable}
          />
        </HFlow>
        <VideoChamadaFooter
          isOwner={isOwner}
          audioDeviceAvailable={audioDeviceAvailable}
          audioEnabled={audioEnabled}
          setAudioEnabled={setAudioEnabled}
          videoDeviceAvailable={videoDeviceAvailable}
          videoEnabled={videoEnabled}
          setVideoEnabled={setVideoEnabled}
          videochamadaId={videochamadaId}
        />
      </VFlow>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  page: css`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: ${theme.pallete.gray.c10};
  `,
  container: css`
    display: flex;
    justify-content: center;
    width: inherit;
    @media (max-width: 1200px) {
      padding: 0 0.5rem;
    }
  `,
  imageSection: css`
    position: relative;
  `,
})
