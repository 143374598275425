import { PivotTableProps } from 'components/pivot-table/PivotTable'

import { KeyMapping, keyMappings } from '../common/KeyMapping'
import { AtendimentoProfissional } from './AtendimentoProfissional'

export const atendimentoProfissionalKeyMapping: PivotTableProps<AtendimentoProfissional>['keyMapping'] = new Map<
  keyof AtendimentoProfissional,
  KeyMapping
>([
  ['uf', keyMappings.uf],
  ['municipio', keyMappings.municipio],
  ['unidadeSaude', keyMappings.unidadeSaude],
  ['equipe', keyMappings.equipe],
  ['tipoAtendimento', keyMappings.tipoAtendimento],
  ['nomeProfissional', keyMappings.nomeProfissional],
  ['origemAtendimento', keyMappings.origemAtendimento],
  ['turno', keyMappings.turno],
  ['mes', keyMappings.mes],
  ['sexo', keyMappings.sexo],
  ['dia', keyMappings.dia],
  ['diaDaSemana', keyMappings.diaDaSemana],
  ['hora', keyMappings.hora],
  ['tipoConsulta', keyMappings.tipoConsulta],
  ['atendimentoCompartilhadoLotacao', keyMappings.atendimentoCompartilhadoLotacao],
  ['categoriaProfissional', keyMappings.categoriaProfissional],
  ['semana', keyMappings.semana],
  ['faixaEtaria', keyMappings.faixaEtaria],
  ['cbo', keyMappings.cbo],
])
