import { Text, VFlow } from 'bold-ui'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { SEM_EQUIPE_ITEM } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { useTerritorioMicroareaQuery, useVisualizacaoTerritorialQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'

import { MicroareaLogradouroPagedList } from './components/list/MicroareaLogradouroPagedList'
import VisualizacaoTerritorialMicroareaTabs from './components/VisualizacaoTerritorialMicroareaTabs'
import { VisualizacaoTerritorialBuscaFormModel, VisualizacaoTerritorioFilter } from './model'
import { formatNumImovelCidadao } from './utils/visualizacaoTerritorialUtils'
import { VisualizacaoTerritorialBuscaForm } from './VisualizacaoTerritorialBuscaForm'

export function VisualizacaoTerritorialView() {
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const unidadeSaude = acesso?.unidadeSaude
  const equipe = acesso?.equipe
  const equipeine = equipe?.ine

  const territorioEquipe = {
    cnes: unidadeSaude.cnes,
    ine: (equipe ?? SEM_EQUIPE_ITEM)?.ine,
  }

  const [visualizacaoFilter, setVisualizacaoFilter] = useState<VisualizacaoTerritorioFilter>(territorioEquipe)

  const { data } = useVisualizacaoTerritorialQuery({
    variables: {
      input: {
        cnes: unidadeSaude.cnes,
        ine: equipeine,
        ...visualizacaoFilter,
      },
    },
  })

  const onBuscarEquipe = (formValues: VisualizacaoTerritorialBuscaFormModel) =>
    setVisualizacaoFilter({
      ...visualizacaoFilter,
      bairro: formValues.bairro,
      cep: formValues.cep,
      logradouro: formValues.logradouro,
      tipoLogradouroId: formValues.tipoLogradouro?.id,
    })

  const [currentMicroareaTab, setCurrentMicroareaTab] = useState<string>()

  const { handleAccordionItemClick, isExpanded, resetExpandedItems } = useAccordionControl({
    allowMultipleExpanded: true,
  })

  useEffect(() => {
    if (data.territorioEquipe?.microareas.length > 0) {
      setCurrentMicroareaTab(data.territorioEquipe?.microareas[0])
    }
  }, [data.territorioEquipe])

  const handleMicroareaTabClick = (microarea: string) => {
    setCurrentMicroareaTab(microarea)
    resetExpandedItems()
  }

  const { data: territorioMicroareaData, loading } = useTerritorioMicroareaQuery({
    variables: {
      input: {
        cnes: unidadeSaude.cnes,
        ine: equipeine,
        ...visualizacaoFilter,
        microarea: currentMicroareaTab,
      },
    },
    skip: currentMicroareaTab === undefined,
    fetchPolicy: 'network-only',
  })

  const territorioProps = {
    cnes: unidadeSaude.cnes,
    ine: equipeine,
    ...visualizacaoFilter,
    microarea: currentMicroareaTab,
  }

  return (
    <>
      <Breadcrumb title='Visualização do Território' />

      <PageHeaderSection title='Visualização do Território' />

      <PageContent type='filled'>
        <VFlow>
          <VisualizacaoTerritorialBuscaForm onSubmit={onBuscarEquipe} unidadeSaude={unidadeSaude} equipe={equipe} />
          <Text fontSize={1}>
            {formatNumImovelCidadao(data.territorioEquipe?.totalImoveis, data.territorioEquipe?.totalCidadaos)}
          </Text>

          {data.territorioEquipe?.microareas.isNotEmpty() ? (
            <VisualizacaoTerritorialMicroareaTabs
              microareas={data.territorioEquipe.microareas}
              currentMicroareaTab={currentMicroareaTab}
              onTabClick={handleMicroareaTabClick}
            />
          ) : (
            <Text fontWeight='normal' variant='h4' fontStyle='italic'>
              Não há cadastros de núcleos familiares ativos vinculados à esta equipe
            </Text>
          )}
        </VFlow>

        {loading ? (
          <LoadingIndicator />
        ) : (
          currentMicroareaTab && (
            <MicroareaLogradouroPagedList
              logradouros={territorioMicroareaData?.territorioMicroarea}
              territorioProps={territorioProps}
              onAccordionItemClick={handleAccordionItemClick}
              isExpanded={isExpanded}
              resetExpandedItems={resetExpandedItems}
              lotacaoId={acesso?.id}
              profissionalId={profissional?.id}
            />
          )
        )}
      </PageContent>
    </>
  )
}
