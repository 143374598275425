/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { catmatAcitretina, catmatIsotretinoina, catmatTalidomida, catmatTretinoina } from 'api/ImpressaoMedicamentos'
import { Button, HFlow, isEmpty, ModalFooter, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import useFirebase from 'components/firebase/useFirebase'
import { resolveName } from 'components/form/final-form/useField'
import { SexoEnum } from 'graphql/types.generated'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import {
  TermosOutrosMedicamentosImpressaoPrescricao,
  termosPrescricaoImpressao,
  TermosTalidomidaImpressaoPrescricao,
} from 'types/enums'
import { metaPath } from 'util/metaPath'

import { MedicamentoFormModel } from '../../model'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { convertMedicamentoToPrintDto } from '../../utils/prescricaoConverter'
import { hasIsotretinoinaTretinoinaAcitretina } from '../../utils/prescricaoUtils'
import { EncaminharPrescricaoMedicamentoItem } from '../components/EncaminharPrescricaoMedicamentoItem'
import TermosToPrintList from '../components/TermosToPrintList'
import { downloadMedicamentos } from './downloadMedicamentos'
import { ImpressaoMedicamentosInput, ImpressaoTermosDto } from './ImpressaoMedicamentosInput'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

export interface ImpressaoPrescricaoViewProps {
  medicamentos: MedicamentoFormModel[]
  atendimentoId: ID
  municipioId: ID
  cidadaoDataNascimento: LocalDate
  cidadaoSexo: SexoEnum
  onModalClose: () => void
}

export function ImpressaoPrescricaoView(props: ImpressaoPrescricaoViewProps) {
  const { medicamentos, atendimentoId, municipioId, cidadaoDataNascimento, cidadaoSexo, onModalClose } = props
  const [medicamentosToPrint] = useState<MedicamentoFormModel[]>(medicamentos)
  const [medicamentosSelected, setMedicamentosSelected] = useState<MedicamentoFormModel[]>(medicamentos)
  const [isLoading, setIsLoading] = useState(false)

  const [subtitleTermos, setSubtitleTermos] = useState('')
  const [termosSelected, setTermosSelected] = useState<ImpressaoTermosDto[]>([])

  const { analytics } = useFirebase()
  const alert = useAlert()

  const idadeCidadao = moment().diff(cidadaoDataNascimento, 'years')

  const talidomidas = useMemo(
    () =>
      medicamentosToPrint.filter((medicamento) => medicamento.principioAtivoCombo?.codigoCatMat === catmatTalidomida),
    [medicamentosToPrint]
  )

  const hasTalidomida = useMemo(() => !isEmpty(talidomidas), [talidomidas])

  const setTermos = useCallback(() => {
    const termos: ImpressaoTermosDto[] = []

    const isCidadaoMaior55 = idadeCidadao >= 55
    const isCidadaoFemMenor55 = cidadaoSexo === SexoEnum.FEMININO && idadeCidadao < 55
    const anexoV = termosPrescricaoImpressao[TermosTalidomidaImpressaoPrescricao.ANEXO_V]

    const filtroTalidomida = (termo: string) =>
      termo !== anexoV || (termo === anexoV && (cidadaoSexo === SexoEnum.MASCULINO || isCidadaoMaior55))

    if (hasTalidomida) {
      talidomidas.map((talidomida) =>
        Object.keys(TermosTalidomidaImpressaoPrescricao)
          .filter((termo) => filtroTalidomida(termo))
          .map((key: TermosTalidomidaImpressaoPrescricao) =>
            termos.push({
              idMedicamentoReferencia: talidomida.id,
              termo: key,
              principioAtivo: talidomida.principioAtivoCombo?.principioAtivo.nome,
              quantidade: talidomida.quantidade,
              duracaoTratamento: talidomida.duracao,
              unidadeMedidaDuracao: talidomida.escalaDuracao,
              dataInicioTratamento: talidomida.dataInicioTratamento,
            } as ImpressaoTermosDto)
          )
      )
    }

    if (hasIsotretinoinaTretinoinaAcitretina(medicamentosToPrint)) {
      if (isCidadaoFemMenor55) {
        termos.push({
          termo: termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XV],
        } as ImpressaoTermosDto)
      } else if (cidadaoSexo === SexoEnum.MASCULINO || isCidadaoMaior55) {
        termos.push({
          termo: termosPrescricaoImpressao[TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XVI],
        } as ImpressaoTermosDto)
      }
    }

    setTermosSelected(termos)
  }, [cidadaoSexo, hasTalidomida, idadeCidadao, medicamentosToPrint, talidomidas])

  useEffect(() => {
    const subtitleItems: string[] = []
    medicamentos.forEach((med) => {
      if (catmatAcitretina.includes(med.principioAtivoCombo?.codigoCatMat)) {
        subtitleItems.push('Acitretina')
      } else if (catmatIsotretinoina.includes(med.principioAtivoCombo?.codigoCatMat)) {
        subtitleItems.push('Isotretinoina')
      } else if (catmatTretinoina.includes(med.principioAtivoCombo?.codigoCatMat)) {
        subtitleItems.push('Tretinoina')
      }
    })
    setSubtitleTermos(subtitleItems.join(' / '))
    setTermos()
  }, [medicamentos, setTermos])

  const handleOnChangeMedicamentosSelected = (checked: boolean, medicamento: MedicamentoFormModel) => {
    if (checked) {
      setMedicamentosSelected([...medicamentosSelected, medicamento])
    } else {
      setMedicamentosSelected(medicamentosSelected.filter((med) => med.id !== medicamento.id))
    }
  }

  const handleOnChangeTermosSelected = (checked: boolean, termoChecked: ImpressaoTermosDto) => {
    if (checked) {
      setTermosSelected([...termosSelected, termoChecked])
    } else {
      setTermosSelected(termosSelected.filter((termoSelected) => termoSelected.termo !== termoChecked.termo))
    }
  }

  const renderFieldArray = (renderFieldArrayProps: FieldArrayRenderProps<ID, any>) => {
    const {
      fields: { push, value },
    } = renderFieldArrayProps

    const findAlreadyPrintedElement = (id: ID) => value?.includes(id)

    const handlePrint = () => {
      setIsLoading(true)

      const input: ImpressaoMedicamentosInput = {
        atendimentoId: atendimentoId,
        cidadaoDataNascimento: Date.parse(cidadaoDataNascimento),
        cidadaoSexo: cidadaoSexo,
        localidadeId: municipioId,
        isotretinoinaTretinoinaAcitretinaSub: subtitleTermos ?? null,
        medicamentos: medicamentosSelected.map((item) => convertMedicamentoToPrintDto(item)),
        termos: termosSelected,
      }

      downloadMedicamentos(input)
        .then(() => {
          setIsLoading(false)
          medicamentosSelected.forEach((medicamento) => {
            if (!findAlreadyPrintedElement(medicamento)) {
              push(medicamento.id)
            }
          })
          termosSelected.forEach((termo) => {
            if (!findAlreadyPrintedElement(termo.termo)) {
              push(termo.termo)
            }
          })
          onModalClose()
        })
        .catch(() => {
          alert('danger', `Erro ao imprimir prescrição de medicamentos.`)
          setIsLoading(false)
        })

      analytics.logEvent('click_imprimir_modal_impressao_prescricao')
    }

    return (
      <React.Fragment>
        <div
          css={css`
            margin-top: -1.5rem;
            padding: 0 2.25rem;
          `}
        >
          <Text>Selecione os medicamentos que deseja imprimir</Text>
          <EncaminharPrescricaoMedicamentoItem
            medicamentoIdsSelecionado={medicamentosSelected.map((med) => med.id)}
            medicamentosToPrint={medicamentosToPrint}
            onChange={handleOnChangeMedicamentosSelected}
          />
          {(hasTalidomida || hasIsotretinoinaTretinoinaAcitretina(medicamentosToPrint)) && (
            <React.Fragment>
              <Text
                css={css`
                  margin-bottom: 0.5rem;
                `}
              >
                Selecione os termos que deseja imprimir
              </Text>

              <TermosToPrintList
                cidadaoIdade={idadeCidadao}
                cidadaoSexo={cidadaoSexo}
                talidomidas={talidomidas}
                hasAcitretinaIsotretinoinaTretinoina={hasIsotretinoinaTretinoinaAcitretina(medicamentosToPrint)}
                acitretinaIsotretinoinaTretinoinaSub={subtitleTermos}
                onClickCheckbox={handleOnChangeTermosSelected}
                alreadyPrinted={findAlreadyPrintedElement}
              />
            </React.Fragment>
          )}
        </div>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onModalClose} style={styles.footerButton} data-testid='CancelarImprimirButton'>
              Cancelar
            </Button>
            <Button
              kind='primary'
              onClick={handlePrint}
              style={styles.footerButton}
              disabled={medicamentosSelected.length === 0 && termosSelected.length === 0}
              loading={isLoading}
              data-testid='ImprimirPrescricaoButton'
            >
              Imprimir
            </Button>
          </HFlow>
        </ModalFooter>
      </React.Fragment>
    )
  }

  return <FieldArray<ID> name={resolveName(path.alreadyPrinted)} render={renderFieldArray} />
}

const styles = {
  footerButton: css`
    width: 10rem;
    height: 3rem;
  `,
}
