import { useConfiguracaoVideochamadasQuery } from 'graphql/hooks.generated'

export interface VideochamadasConfiguration {
  videochamadasEnabled: boolean
}

//TODO (@RNG): Generalizar para hook que dá acesso a mais configurações do sistema (#14543)
export function useVideochamadasConfiguration(): VideochamadasConfiguration {
  const {
    loading: loadingConfiguracoes,
    data: {
      conexao: { videochamadas: { habilitado: isVideochamadaEnabled } } = { videochamadas: { habilitado: false } },
    },
  } = useConfiguracaoVideochamadasQuery()

  return !loadingConfiguracoes ? { videochamadasEnabled: isVideochamadaEnabled } : defaultConfiguration
}

const defaultConfiguration: VideochamadasConfiguration = {
  videochamadasEnabled: false,
}
