import useSession from 'components/auth/useSession'
import { Authorization } from 'components/auth/useSessionUtils'
import { AcessoCbo } from 'graphql/types.generated'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

export interface PrivateRouteProps extends RouteProps {
  permission: Authorization
  cbos?: AcessoCbo[]
  acceptEstagio?: boolean
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { permission, cbos, acceptEstagio = true, ...rest } = props

  const { checkAuthorization, hasCboAuth, isEstagio } = useSession({ fetchPolicy: 'cache-only' })

  return checkAuthorization(permission) &&
    (!isEstagio || acceptEstagio) &&
    (cbos === undefined || (cbos && hasCboAuth(cbos))) ? (
    <Route {...rest} />
  ) : (
    <Redirect to='/403' />
  )
}
