import { DefaultItemType, HFlow, Icon, Icons, Omit, Select, SelectMenuItem, SelectProps, Tooltip } from 'bold-ui'
import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { blue, green } from 'bold-ui/lib/styles/colors'
import theme from 'config/theme'
import React from 'react'

import { UseFieldProps, usePecField } from '../useField'
import { getFieldError } from '../util'

export type SelectFieldStatus = {
  icon: Icons
  color: string
}

export const selectFieldStatus = {
  SUCCESS: { icon: 'checkCircleOutline', color: green.c40 } as SelectFieldStatus,
  INFO: { icon: 'infoCircleOutline', color: blue.c40 } as SelectFieldStatus,
}

export type SelectFieldProps<T = DefaultItemType> = UseFieldProps<T> &
  Omit<SelectProps<T>, 'name' | 'defaultValue'> & {
    itemIsDisabled?(item: T): boolean
    customText?(item: T): string
    selectedItemTooltip?(item: T): string
    selectedItemStyle?: SelectFieldStatus | ((item: T) => SelectFieldStatus)
  }

export function SelectField<T = DefaultItemType>(props: SelectFieldProps<T>) {
  const { input, meta, itemIsDisabled, customText, selectedItemStyle, selectedItemTooltip, ...rest } = usePecField(
    props
  )

  const Item = (itemProps: any) => {
    return (
      <SelectItem
        isAlreadySelected={itemIsDisabled ?? props.itemIsEqual}
        selectedItemStyle={selectedItemStyle}
        selectedItemTooltip={selectedItemTooltip}
        customText={customText}
        {...itemProps}
      />
    )
  }

  return (
    <Select
      error={getFieldError(meta)}
      {...input}
      {...rest}
      multiple={props.multiple}
      components={props.itemIsEqual ? { ...props.components, Item } : props.components}
    />
  )
}

const SelectItem = (
  props: SelectDownshiftMenuProps<any> & {
    item: any
    index: number
    isAlreadySelected: Function
    customText: Function
    selectedItemTooltip?(item): string
    selectedItemStyle: SelectFieldStatus | ((item) => SelectFieldStatus)
  }
) => {
  const {
    renderItem,
    item,
    index,
    isAlreadySelected,
    customText,
    selectedItemStyle,
    selectedItemTooltip,
    downshift: { itemToString, highlightedIndex, getItemProps },
  } = props

  const defaultMenuItemProps = {
    selected: highlightedIndex === index,
  }

  const defaultDisabledMenuItemProps = {
    style: {
      cursor: 'not-allowed',
      '&:hover': {
        background: theme.pallete.primary.c100,
      },
    },
    ...defaultMenuItemProps,
  }

  const selected = isAlreadySelected(item)
  const selectedStyle = !!selectedItemStyle
    ? typeof selectedItemStyle === 'function'
      ? selectedItemStyle(item)
      : selectedItemStyle
    : selectFieldStatus.SUCCESS
  const selectedText = !!customText ? customText(item) : 'Adicionado'

  const menuItemProps = selected ? defaultDisabledMenuItemProps : defaultMenuItemProps
  const setOpacity = selected ? { style: { opacity: 0.5 } } : { style: {} }

  return (
    <SelectMenuItem
      {...menuItemProps}
      {...(selected ? getItemProps({ item, disabled: true }) : getItemProps({ item }))}
    >
      <HFlow alignItems='center' justifyContent='space-between'>
        <div {...setOpacity}>{renderItem ? renderItem(item) : itemToString(item)}</div>

        {selected && (
          <Tooltip text={selectedItemTooltip?.(item)}>
            <HFlow alignItems='center' hSpacing={0.2} style={{ color: selectedStyle.color }}>
              <Icon icon={selectedStyle.icon} size={1} style={{ display: 'block' }} />
              {selectedText}
            </HFlow>
          </Tooltip>
        )}
      </HFlow>
    </SelectMenuItem>
  )
}
