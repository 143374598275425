import useSession from 'components/auth/useSession'
import { differenceInMinutes } from 'date-fns'
import { useConfiguracaoCadsusQuery, useSobreQuery } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'

export const useNeedLoginGovBr = () => {
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const { data: session } = useSession()
  const {
    data: { info },
  } = useSobreQuery({ fetchPolicy: 'cache-first' })
  const { data: configCadsus } = useConfiguracaoCadsusQuery()

  const allConfigs =
    configCadsus.conexao?.internet.habilitado &&
    configCadsus.conexao?.cadsus.habilitado &&
    configCadsus.configuracaoCertificadoHabilitado?.cadsusHabilitado &&
    info?.govBREnabled

  return {
    hasAllConfigs: allConfigs,
    needLoginGovBr: allConfigs && !(differenceInMinutes(new Date(session.expDateAccessTokenGovBr), now) > 0),
  }
}
