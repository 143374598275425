import { PrivateRoute } from 'components/route'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { grupoCboPlanoCuidadoObservacao } from '../acessos'
import { HistoricoPlanoCuidadoModal } from './HistoricoPlanoCuidadoModal'
import { PlanoCuidadoLotacaoModel, PlanoCuidadoModel } from './model'

interface PlanoCuidadoRootViewProps {
  planosDeCuidado: PlanoCuidadoModel[]
  iniciador: PlanoCuidadoLotacaoModel
  loading: boolean
}

export const urlPlanosDeCuidado = '/historico-de-planos-de-cuidado'

export const PlanoCuidadoRootView = (props: PlanoCuidadoRootViewProps) => {
  const { planosDeCuidado, iniciador, loading } = props
  const match = useRouteMatch()
  const history = useHistory()

  return (
    <PrivateRoute path={`${match.url}${urlPlanosDeCuidado}`} permission={grupoCboPlanoCuidadoObservacao}>
      <HistoricoPlanoCuidadoModal
        planosDeCuidado={planosDeCuidado}
        iniciador={iniciador}
        loading={loading}
        onClose={() => history.push(match.url.replace(urlPlanosDeCuidado, ''))}
      />
    </PrivateRoute>
  )
}
