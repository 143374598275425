import { capitalize, titleCase } from 'util/strConversor'

export type KeyMapping = {
  keyName: string
  formatter?: (value: string) => string
  ordenator?: (a: string, b: string) => number
}
const INDISPONIVEL = 'Indisponível'
const SEM_CATEGORIA = 'Sem categoria'
const SEM_EQUIPE = 'Sem equipe'
const DIAS_SEMANA = new Map([
  ['Domingo', 1],
  ['Segunda-feira', 2],
  ['Terça-feira', 3],
  ['Quarta-feira', 4],
  ['Quinta-feira', 5],
  ['Sexta-feira', 6],
  ['Sábado', 7],
])
const MESES = new Map([
  ['Janeiro', 1],
  ['Fevereiro', 2],
  ['Março', 3],
  ['Abril', 4],
  ['Maio', 5],
  ['Junho', 6],
  ['Julho', 7],
  ['Agosto', 8],
  ['Setembro', 9],
  ['Outubro', 10],
  ['Novembro', 11],
  ['Dezembro', 12],
])
const FAIXA_ETARIA = new Map([
  ['Criança - menos de 1 ano', 1],
  ['Criança - 1 ano', 2],
  ['Criança - 2 anos', 3],
  ['Criança - 3 anos', 4],
  ['Criança - 4 anos', 5],
  ['Criança - 5 a 10 anos', 6],
  ['Adolescente - 11 a 14 anos', 7],
  ['Adolescente - 15 a 19 anos', 8],
  ['Adulto - 20 a 24 anos', 9],
  ['Adulto - 25 a 29 anos', 10],
  ['Adulto - 30 a 34 anos', 11],
  ['Adulto - 35 a 39 anos', 12],
  ['Adulto - 40 a 44 anos', 13],
  ['Adulto - 45 a 49 anos', 14],
  ['Adulto - 50 a 54 anos', 15],
  ['Adulto - 55 a 59 anos', 16],
  ['Idoso - 60 a 64 anos', 17],
  ['Idoso - 65 a 69 anos', 18],
  ['Idoso - 70 a 74 anos', 19],
  ['Idoso - 75 a 79 anos', 20],
  ['Idoso - 80 anos ou mais', 21],
  ['Não informado', 22],
])
const TURNO = new Map([
  ['Manhã', 1],
  ['Tarde', 2],
  ['Noite', 3],
  ['Não informado', 4],
])

export const keyMappings = {
  uf: {
    keyName: 'UF',
    formatter: (s: string) => s.capitalize(),
  },
  municipio: { keyName: 'Município', formatter: (s: string) => s.titleCase() },
  unidadeSaude: { keyName: 'Unidade de saúde', formatter: (s: string) => s.titleCase() },
  equipe: {
    keyName: 'Equipe',
    formatter: titleCase,
    ordenator: (a: string, b: string) => {
      if (a === SEM_EQUIPE) {
        return -1
      } else if (b === SEM_EQUIPE) {
        return 1
      } else {
        return a > b ? 1 : -1
      }
    },
  },
  tipoAtendimento: { keyName: 'Tipo de atendimento', formatter: titleCase },
  nomeProfissional: { keyName: 'Profissional', formatter: titleCase },
  origemAtendimento: { keyName: 'Origem do atendimento', formatter: capitalize },
  turno: {
    keyName: 'Turno',
    formatter: capitalize,
    ordenator: (a: string, b: string) => {
      return TURNO.get(a) > TURNO.get(b) ? 1 : -1
    },
  },
  mes: {
    keyName: 'Mês',
    formatter: titleCase,
    ordenator: (a: string, b: string) => {
      return MESES.get(a) > MESES.get(b) ? 1 : -1
    },
  },
  sexo: { keyName: 'Sexo', formatter: titleCase },
  dia: {
    keyName: 'Dia',
    formatter: titleCase,
    ordenator: (a: string, b: string) => {
      const dta = a.split('/')
      const dtb = b.split('/')
      if (dta[2] === dtb[2]) {
        if (dta[1] === dtb[1]) {
          return dta[0] > dtb[0] ? 1 : -1
        }
        return dta[1] > dtb[1] ? 1 : -1
      }
      return dta[2] > dtb[2] ? 1 : -1
    },
  },
  diaDaSemana: {
    keyName: 'Dia da semana',
    formatter: titleCase,
    ordenator: (a: string, b: string) => {
      return DIAS_SEMANA.get(a) > DIAS_SEMANA.get(b) ? 1 : -1
    },
  },
  hora: { keyName: 'Hora' },
  tipoConsulta: { keyName: 'Tipo de consulta', formatter: titleCase },
  atendimentoCompartilhadoLotacao: { keyName: 'Atendimento compartilhado' },
  categoriaProfissional: {
    keyName: 'Categoria profissional',
    formatter: (s: string) => s.capitalize(),
    ordenator: (a: string, b: string) => {
      if (a === SEM_CATEGORIA) {
        return -1
      } else if (b === SEM_CATEGORIA) {
        return 1
      } else {
        return a > b ? 1 : -1
      }
    },
  },
  semana: {
    keyName: 'Semana',
    formatter: (input: string) => input.capitalize(),
    ordenator: (a: string, b: string) => {
      const datesa = a.substring(10).replace(' a ', '/').split('/')
      const datesb = b.substring(10).replace(' a ', '/').split('/')

      const date1a = [datesa[0], datesa[1], datesa[2]]

      const date1b = [datesb[0], datesb[1], datesb[2]]

      if (date1a[2] === date1b[2]) {
        if (date1a[1] === date1b[1]) {
          return date1a[0] > date1b[0] ? 1 : -1
        }
        return date1a[1] > date1b[1] ? 1 : -1
      }
      return date1a[2] > date1b[2] ? 1 : -1
    },
  },
  faixaEtaria: {
    keyName: 'Faixa etária',
    ordenator: (a: string, b: string) => {
      return FAIXA_ETARIA.get(a) > FAIXA_ETARIA.get(b) ? 1 : -1
    },
  },
  nomeSituacaoAgendamento: { keyName: 'Tipo de falta', formatter: (s: string) => s.capitalize() },
  intervaloAgendamento: {
    keyName: 'Tempo entre agendamento e consulta',
    formatter: capitalize,
    ordenator: (a: string, b: string) => {
      if (a === INDISPONIVEL) {
        return -1
      } else if (b === INDISPONIVEL) {
        return 1
      } else {
        const num1 = Number(a.split(' ')[0])
        const num2 = Number(b.split(' ')[0])
        return num1 - num2
      }
    },
  },
  cbo: {
    keyName: 'CBO',
    formatter: (s: string) => s.capitalize(),
  },
}
