/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { usePagedTableProps } from 'components/table'
import { TitleGroup } from 'components/TitleGroup'
import { AcompanhamentoCidadaosVinculadosQuery, OrigemAtendimento } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { useFormState } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'
import { convertAcompanhamentoCidadaosVinculadosQueryModelToRowModel } from 'view/acompanhamento-cidadaos-vinculados/converter'

import { AcompanhamentoCidadaosVinculadosFilterModel } from '../AcompanhamentoCidadaosVinculadosFilter'
import { TagOrigemAcompanhamentoCidadaosVinculados } from '../TagOrigemAcompanhamentoCidadaosVinculados'
import { AcompanhamentoCidadaosVinculadosPagedTable } from './AcompanhamentoCidadaosVinculadosPagedTable'

interface AcompanhamentoCidadaosVinculadosTableViewProps {
  data: AcompanhamentoCidadaosVinculadosQuery
  loading: boolean
  onChange(filter: AcompanhamentoCidadaosVinculadosFilterModel): void
  handleExportarCSVClick(): void
  handleImprimirClick(): void
}

const RELATORIO_SEM_DADOS_MSG = 'Não existem dados para gerar este relatório.'

export function AcompanhamentoCidadaosVinculadosTableView(props: AcompanhamentoCidadaosVinculadosTableViewProps) {
  const { loading, data, onChange, handleExportarCSVClick, handleImprimirClick } = props

  const rows =
    data?.acompanhamentoCidadaosVinculados?.content?.map((row) =>
      convertAcompanhamentoCidadaosVinculadosQueryModelToRowModel(row)
    ) ?? []

  const pagedTableProps = usePagedTableProps({
    loading: loading && !data,
    result: data?.acompanhamentoCidadaosVinculados,
    onChange: onChange,
  })

  const { dirtySinceLastSubmit } = useFormState({
    subscription: { dirtySinceLastSubmit: true },
  })

  const showTable = !dirtySinceLastSubmit && !isUndefinedOrNull(data)

  const shouldDisableButtons = isEmpty(rows)
  const hintForDisabledButtons = shouldDisableButtons ? RELATORIO_SEM_DADOS_MSG : null

  return (
    showTable && (
      <PageContent loading={loading}>
        <VFlow vSpacing={1.5}>
          <TitleGroup title='Cidadãos encontrados' />
          <AcompanhamentoCidadaosVinculadosPagedTable pagedTableProps={pagedTableProps} rows={rows} />
          <VFlow vSpacing={0.5}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <TagOrigemAcompanhamentoCidadaosVinculados origem={OrigemAtendimento.PEC} /> - Cidadão sem cadastro nas
              fichas do CDS
            </HFlow>
            <HFlow hSpacing={0.5} alignItems='center'>
              <TagOrigemAcompanhamentoCidadaosVinculados origem={OrigemAtendimento.CDS} /> - Cidadão possui cadastro no
              CDS
            </HFlow>
          </VFlow>
          <HFlow justifyContent='flex-end'>
            <Tooltip text={hintForDisabledButtons}>
              <Button onClick={handleExportarCSVClick} disabled={shouldDisableButtons}>
                Exportar CSV
              </Button>
            </Tooltip>
            <Tooltip text={hintForDisabledButtons}>
              <Button kind='primary' onClick={handleImprimirClick} disabled={shouldDisableButtons}>
                <Icon icon='fileWithItemsOutline' style={styles.printButtonIcon} />
                Imprimir
              </Button>
            </Tooltip>
          </HFlow>
        </VFlow>
      </PageContent>
    )
  )
}

const styles = {
  printButtonIcon: css`
    margin-right: 0.5rem;
  `,
}
