import useAtmosphere from 'hooks/useAtmosphere'
import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload'
import { useCallback, useEffect, useRef, useState } from 'react'

import { LocalVideocallParticipant, RemoteVideocallParticipant } from '../model'
import { createWebRtcPeer, WebRtcPeer } from '../types/WebRtcPeer'
import { SendSignalCallback, useWebRtcSignalingServer, WebRtcSignal } from './useWebRtcSignalingServer'

interface RemoteParticipantsOptions {
  selfData: LocalVideocallParticipant
  roomId: ID
  localStream: MediaStream

  onPeerDisconnected?(peerId: ID): void
}

export function useWebRtc(props: RemoteParticipantsOptions) {
  const { selfData, roomId, localStream, onPeerDisconnected } = props

  const [remoteParticipants, setRemoteParticipants] = useState<RemoteVideocallParticipant[]>([])

  const peers = useRef<Map<string, WebRtcPeer>>(new Map())
  const streams = useRef<Map<string, RemoteVideocallParticipant>>(new Map())

  const handleIncomingStream = useCallback((participant: LocalVideocallParticipant, stream: MediaStream) => {
    streams.current.set(participant.id, { stream: stream, name: participant.name })
    stream.getTracks().forEach((track) =>
      track.addEventListener('mute', () => {
        setRemoteParticipants([...streams.current.values()])
      })
    )
    setRemoteParticipants([...streams.current.values()])
  }, [])

  const handlePeerClose = useCallback(
    (peerId: ID) => {
      streams.current.delete(peerId)
      peers.current.delete(peerId)
      setRemoteParticipants([...streams.current.values()])
      onPeerDisconnected?.(peerId)
    },
    [onPeerDisconnected]
  )

  const addNewPeer = useCallback(
    (participant: LocalVideocallParticipant, initiator: boolean, sendSignal: SendSignalCallback) => {
      if (
        selfData.id !== participant.id &&
        (!peers.current.has(participant.id) || peers.current.get(participant.id).destroyed)
      ) {
        const newPeer = createWebRtcPeer({
          initiator,
          trickle: false,
          onSignal: (signal) => sendSignal(participant.id, signal),
          onStream: (stream) => handleIncomingStream(participant, stream),
          onClose: () => handlePeerClose(participant.id),
          onError: (_, peer) => {
            peer?.destroy()
            handlePeerClose(participant.id)
          },
        })

        peers.current.set(participant.id, newPeer)

        if (localStream) newPeer.addStream(localStream)
      }
    },
    [handleIncomingStream, handlePeerClose, localStream, selfData]
  )

  const handleSignal = useCallback(
    ({ sender, data }: WebRtcSignal, sendSignal: SendSignalCallback) => {
      addNewPeer(sender, false, sendSignal)
      peers.current.get(sender.id)?.signal(data)
    },
    [addNewPeer]
  )

  const { sendSignal } = useWebRtcSignalingServer({ selfData, roomId, onSignal: handleSignal })

  const handleNewParticipant = useCallback(
    (newParticipant: LocalVideocallParticipant) => {
      addNewPeer(newParticipant, true, sendSignal)
    },
    [addNewPeer, sendSignal]
  )

  useAtmosphere<LocalVideocallParticipant>({
    topic: `public/videochamada/${roomId}/participantes`,
    onMessage: handleNewParticipant,
  })

  const lastLocalStream = useRef<MediaStream>()
  useEffect(() => {
    peers.current.forEach((peer) => {
      if (!peer.destroyed) {
        lastLocalStream.current && peer.removeStream(lastLocalStream.current)
        localStream && peer.addStream(localStream)
      }
    })
    lastLocalStream.current = localStream
  }, [localStream])

  const destroyAllPeers = useCallback(() => {
    peers.current.forEach((peer) => peer && !peer.destroyed && peer.destroy())
  }, [])

  //onDestroy
  useEffect(() => destroyAllPeers, [destroyAllPeers])
  useOnBeforeUnload(destroyAllPeers)

  return {
    remoteParticipants,
  }
}
