/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { forwardRef } from 'react'

import placeholderImage from '../images/placeholder.png'
import { LocalStreamPlayerVideochamadaProps } from './StreamPlayerVideochamada'

export const LocalStreamPlayerVideochamada = forwardRef<HTMLVideoElement, LocalStreamPlayerVideochamadaProps>(
  (props, ref) => {
    const { minimizada = false, videoDeviceAvailable = false, videoEnabled = false } = props

    return (
      <div css={[styles.cam, minimizada ? styles.camMinimizada : styles.camMaximizada]}>
        {!(videoDeviceAvailable && videoEnabled) && (
          <img src={placeholderImage} css={styles.video} alt='Imagem padrão para vídeo desabilitado' />
          // TODO@RNG imagem de videochamada padrão oficial será colocada na #14481
        )}
        <video
          autoPlay
          muted
          ref={ref}
          css={
            videoDeviceAvailable && videoEnabled
              ? styles.video
              : css`
                  visibility: hidden;
                `
          }
          playsInline
        />
        <Text component='p' style={styles.nome}>
          Você
        </Text>
      </div>
    )
  }
)

const styles = {
  cam: css`
    display: flex;
    justify-content: center;
    border-radius: 0.625rem;
    overflow: hidden;
  `,
  camMaximizada: css`
    width: max(80vw, 1012px);
    padding-top: min(56.25%, 80vh);
    height: 0;
    position: relative;
    max-width: 90.375rem;
  `,
  camMinimizada: css`
    z-index: 3;
    position: absolute;
    top: calc(100% - 185px);
    right: 2rem;
    width: 17.5rem;
    height: 10rem;
  `,
  video: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  `,
  nome: css`
    color: white;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    bottom: max(2%, 10px);
    left: 1rem;
  `,
}
