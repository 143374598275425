import { ListaEsperaItemCreateInput } from 'graphql/types.generated'

import { ListaEsperaFormModel } from './model'

export const convertListaEsperaFormModelToInput = (model: ListaEsperaFormModel): ListaEsperaItemCreateInput => ({
  cidadaoId: model.cidadao.id,
  equipeId: model.equipe?.id,
  tipoAtendimento: model.tipoAtendimento.value,
  problemasCondicoes: model.problemasCondicoes?.problemaCondicao || [],
  motivo: model.motivo,
})
