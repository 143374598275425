import { HFlow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import React from 'react'
import { identidadeGenero } from 'types/enums'

import { CidadaoUnificacao } from './UnificacaoBaseModel'

interface UnificacaoBaseCidadaoCardInfoProps {
  cidadaoUnificacao: CidadaoUnificacao
}

export default function UnificacaoCidadaoCardInfo(props: UnificacaoBaseCidadaoCardInfoProps) {
  const {
    cidadaoUnificacao: {
      cpf,
      cns,
      cidadao: { dataNascimento, sexo, identidadeGeneroDbEnum, nomeMae, localidadeNascimento },
      unificacaoBase: { nomeInstalacao },
    },
  } = props
  return (
    <>
      <VFlow vSpacing={0} style={{ width: 350 }}>
        <HFlow>
          <HLabel title='CPF'>{cpf ? <Cpf value={cpf} /> : '-'}</HLabel>
          <HLabel title='CNS'>{cns ? <Text>{cns}</Text> : '-'}</HLabel>
        </HFlow>
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>Data de nascimento</Text>
          <DateTime format='DD/MM/YYYY' value={dataNascimento} />
          <Age dateOfBirth={dataNascimento} />
        </HFlow>
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>Sexo</Text>
          <Text> {sexo}</Text>
          <Text fontWeight='bold'>{identidadeGeneroDbEnum ? 'Id. de gênero' : null}</Text>
          <Text>{identidadeGeneroDbEnum && identidadeGenero[identidadeGeneroDbEnum]}</Text>
        </HFlow>
      </VFlow>
      <VFlow vSpacing={0}>
        <HLabel title='Nome da mãe'>{nomeMae ? nomeMae.titleCase() : '-'}</HLabel>
        <HLabel title='Unidade responsável'>{nomeInstalacao.titleCase() ?? '-'}</HLabel>
        <HLabel title='Município de nascimento'>
          {localidadeNascimento?.nome
            ? `${localidadeNascimento?.nome.titleCase()} - ${localidadeNascimento?.uf?.sigla}`
            : '-'}
        </HLabel>
      </VFlow>
    </>
  )
}
