/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Tag, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LoadingIndicator } from 'components/loading'
import theme from 'config/theme'
import { useVisualizarCidadaosImovelQuery } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'

export function CidadaosImovelList({ imovelId }: { imovelId: ID }) {
  const {
    data: { imovel },
    loading,
  } = useVisualizarCidadaosImovelQuery({
    variables: {
      input: imovelId,
    },
    fetchPolicy: 'no-cache',
  })

  return loading ? (
    <LoadingIndicator />
  ) : (
    <VFlow>
      {imovel?.familias.map((familia) => (
        <VFlow vSpacing={0.25} key={familia.id}>
          <Fragment>
            <Text fontWeight='bold'>Prontuário familiar:</Text>
            <Text>{familia.numeroProntuarioFamiliar ? ` ${familia.numeroProntuarioFamiliar}` : ' -'}</Text>
          </Fragment>

          {familia.cidadaos.map((cidadao) => (
            <VFlow
              vSpacing={0.25}
              style={css`
                border: 1px solid ${theme.pallete.gray.c80};
                padding: 0.5rem 0.75rem;
              `}
              key={cidadao.id}
            >
              {(cidadao.cpf === familia.cpfCnsResponsavel || cidadao.cns === familia.cpfCnsResponsavel) && (
                <Tag icon='userFilled' style={styles.tag}>
                  Responsável familiar
                </Tag>
              )}
              <HFlow hSpacing={0.2}>
                <Text fontWeight='bold'>{(cidadao.nomeSocial ?? cidadao.nome).titleCase()}</Text>
                <Text>{` | ${cidadao.sexo.titleCase()}`}</Text>
              </HFlow>
              <HFlow>
                <Text>
                  {humanizeAge(cidadao.dataNascimento)} | Nasceu em
                  {` ${formatDate(cidadao.dataNascimento)}`}
                </Text>
                {cidadao.cpf ? (
                  <HLabel title='CPF'>
                    <Cpf value={cidadao.cpf} />
                  </HLabel>
                ) : (
                  <HLabel title='CNS'>{cidadao.cns}</HLabel>
                )}
              </HFlow>
            </VFlow>
          ))}
        </VFlow>
      ))}
    </VFlow>
  )
}

const styles = {
  tag: css`
    color: ${theme.pallete.primary.c40};
    background-color: transparent;
    border: 1px solid;
  `,
}
