/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, Modal, ModalBody, Text, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { formatHoursMinutes } from 'util/date/formatDate'

import { HistoricoPlanoCuidadoListItem } from './components/HistoricoPlanoCuidadoListItem'
import { PlanoCuidadoLotacaoModel, PlanoCuidadoModel } from './model'

interface HistoricoPlanoCuidadoModalProps {
  planosDeCuidado: PlanoCuidadoModel[]
  iniciador: PlanoCuidadoLotacaoModel
  loading: boolean
  onClose(): void
}

const modalTitle = 'Visualizar planos de cuidado'

export const HistoricoPlanoCuidadoModal = (props: HistoricoPlanoCuidadoModalProps) => {
  const { planosDeCuidado, iniciador, loading, onClose } = props

  return (
    <Modal open onClose={onClose}>
      <ModalBody>
        {loading ? (
          <VFlow vSpacing={1.5}>
            <Heading level={1}>{modalTitle}</Heading>

            <LoadingIndicator />
          </VFlow>
        ) : (
          <VFlow vSpacing={1.5}>
            <VFlow vSpacing={0}>
              <Heading level={1}>{modalTitle}</Heading>

              <Text>
                Observação iniciada pelo profissional {iniciador?.lotacao.profissional.nome} às{' '}
                {formatHoursMinutes(iniciador?.iniciadoEm)}
              </Text>
            </VFlow>

            <VFlow vSpacing={0.5}>
              {planosDeCuidado?.map((planoDeCuidado) => (
                <HistoricoPlanoCuidadoListItem key={planoDeCuidado.id} planoDeCuidado={planoDeCuidado} />
              ))}
            </VFlow>
          </VFlow>
        )}
      </ModalBody>
    </Modal>
  )
}
