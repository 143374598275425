import { HFlow, Omit, SelectHelperMenuItem, Text, useLocale, VFlow } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { HLabel } from 'components/HLabel'
import { CiapSelectFieldDocument } from 'graphql/hooks.generated'
import { CiapQueryInput, CiapSelectFieldQuery, CiapSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type CiapSelectFieldModel = CiapSelectFieldQuery['ciaps']['content'][0]

export interface CiapSelectFieldProps
  extends Omit<SelectFieldProps<CiapSelectFieldModel>, 'items' | 'itemToString'>,
    Pick<
      CiapQueryInput,
      | 'sexo'
      | 'capitulosIncluidos'
      | 'capitulosExcluidos'
      | 'excluirCIAPsDAB'
      | 'ciapComCid10Encaminhamento'
      | 'idadeCidadaoEmAnos'
    > {
  fetchCID10Encaminhamento?: boolean
}

export function CiapSelectField(props: CiapSelectFieldProps) {
  const {
    sexo,
    capitulosIncluidos,
    capitulosExcluidos,
    excluirCIAPsDAB,
    ciapComCid10Encaminhamento,
    idadeCidadaoEmAnos,
    fetchCID10Encaminhamento = false,
    ...rest
  } = props

  const locale = useLocale()

  const { skipping, ...asyncProps } = useAsyncQuerySelect<
    CiapSelectFieldModel,
    CiapSelectFieldQuery,
    CiapSelectFieldQueryVariables
  >({
    query: CiapSelectFieldDocument,
    extractItems: (data) => data?.ciaps?.content,
    variables: (inputQuery: string): CiapSelectFieldQueryVariables => ({
      input: {
        query: inputQuery,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
        sexo,
        capitulosIncluidos,
        capitulosExcluidos,
        excluirCIAPsDAB,
        ciapComCid10Encaminhamento,
        idadeCidadaoEmAnos,
      },
      fetchCID10Encaminhamento,
    }),
    skip: (inputString) => inputString?.trim()?.length < 2,
    debounceTime: 500,
  })

  const itemToString = (item: CiapSelectFieldModel) => item && `${item.descricao} - ${item.codigo}`

  const renderItem = (item: CiapSelectFieldModel) => (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>{item.descricao}</Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Código</Text>
        {item.codigo}
      </HFlow>
      {item.inclui && <HLabel title='Inclui:'>{item.inclui}</HLabel>}
      {item.exclui && <HLabel title='Exclui:'>{item.exclui}</HLabel>}
    </VFlow>
  )

  return (
    <SelectField<CiapSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para buscar um CIAP 2.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
      }}
      {...asyncProps}
      {...rest}
    />
  )
}
