import { useErrorHandler } from 'components/error'
import { usePrinter } from 'components/print'
import { PrinterType } from 'components/print/model'
import { useHistoricoAuditImpressaoEscutaMutation, useImpressaoEscutaInicialLazyQuery } from 'graphql/hooks.generated'
import { ImpressaoEscutaInicialQuery, Medicao } from 'graphql/types.generated'
import { calculateAge } from 'util/date/calculateAge'
import { ImpressaoEscutaInicial } from 'view/atendimentos/escuta-inicial/impressao/ImpressaoEscutaInicial'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

type ImpressaoEscutaInicialModel = ImpressaoEscutaInicialQuery['historicoAtendimentoPec']

export const useImpressaoEscutaInicialHistorico = (atendProfId: ID) => {
  const { printPDF } = usePrinter()
  const handleRejection = useErrorHandler()
  const [audit] = useHistoricoAuditImpressaoEscutaMutation({ variables: { atendProfId } })

  const [executeQuery] = useImpressaoEscutaInicialLazyQuery({
    variables: {
      input: {
        idAtend: atendProfId,
        isOrigemPec: true,
      },
    },
    onCompleted: (data) => downloadEscutaInicialHistorico(data.historicoAtendimentoPec),
  })

  const downloadEscutaInicialHistorico = (historicoEscuta: ImpressaoEscutaInicialModel) => {
    if (!historicoEscuta) return null
    const converterMedicoes = () => {
      const medicao = historicoEscuta?.medicoes?.[0] || ({} as Medicao)
      const pressaoArterial = medicao.valorPressaoArterial?.split('/')
      return {
        peso: medicao.valorPeso?.toString(),
        altura: medicao.valorAltura?.toString(),
        perimetroCefalico: medicao.valorPerimetroCefalico?.toString(),
        circunferenciaAbdominal: medicao.valorCircunferenciaAbdominal?.toString(),
        perimetroPanturrilha: medicao.perimetroPanturrilha?.toString(),
        pressaoArterialSistolica: pressaoArterial?.[0],
        pressaoArterialDiastolica: pressaoArterial?.[1],
        frequenciaRespiratoria: medicao.valorFrequenciaRespiratoria?.toString(),
        frequenciaCardiaca: medicao.valorFrequenciaCardiaca?.toString(),
        temperatura: medicao.valorTemperatura?.toString(),
        saturacaoO2: medicao.valorSaturacaoO2?.toString(),
        glicemia: medicao.valorGlicemia?.toString(),
        tipoGlicemia: medicao.tipoGlicemia,
      }
    }

    const idadeEmAnos =
      historicoEscuta?.atendimento.cidadao.dataNascimento &&
      calculateAge(historicoEscuta?.atendimento.cidadao.dataNascimento, historicoEscuta?.iniciadoEm).years

    const motivoConsulta = {
      texto: historicoEscuta.motivoConsulta.texto,
      ciap: historicoEscuta.motivoConsulta.ciaps[0].ciap,
    }

    const cidadaoAtendimento: CidadaoAtendimento = {
      ...historicoEscuta.atendimento.cidadao,
      idadeEmAnos,
    }

    const docBody = ImpressaoEscutaInicial({
      isPreAtendimento: false,
      cidadao: cidadaoAtendimento,
      dataAtendimento: new Date(historicoEscuta.iniciadoEm),
      motivoConsulta: motivoConsulta,
      medicoes: converterMedicoes(),
      procedimentos: historicoEscuta.procedimentos,
      classificacaoRisco: historicoEscuta.classificacaoRisco,
    })

    const infoProfissional: PrinterType = {
      acesso: historicoEscuta.lotacao,
      profissional: historicoEscuta.lotacao.profissional,
    }

    printPDF(
      {
        docBody,
        documentTitle: 'EscutaInicial',
        session: infoProfissional,
      },
      handleRejection,
      audit
    )
  }
  return { downloadEscutaInicial: executeQuery }
}
