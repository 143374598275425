import { SituacaoProblema } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { Problema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'
import { CIAP_W78_CODIGO, TipoPreNatal } from 'view/atendimentos/detail/soap/pre-natal/model'
import {
  isCiapGravidezAltoRisco,
  isCiapOuCidDeEncerramentoGestacao,
  isCiapOuCidDeGestacao,
  isCiapOuCidDePuerperio,
  isCidGravidezAltoRisco,
} from 'view/atendimentos/detail/soap/pre-natal/util'

import { ProblemaCondicaoModel } from '../model'
import * as verifications from './verifications'

export function isCiapW78(codigoCiap: string) {
  return codigoCiap === CIAP_W78_CODIGO
}

export const isEvolucao = (problemaCondicao?: ProblemaCondicaoModel): boolean =>
  !isUndefinedOrNull(problemaCondicao?.problemaCondicaoEvoluir)

export const isAutomatico = (problemaCondicao: ProblemaCondicaoModel): boolean => problemaCondicao.isAutomatico === true

export function isProblemaCondicaoDePreNatal(problemaCondicao: ProblemaCondicaoModel, isGestante: boolean) {
  if (problemaCondicao && problemaCondicao.situacaoProblema !== SituacaoProblema.RESOLVIDO) {
    if (isEvolucao(problemaCondicao) && isProblemaCondicaoGravidezAltoRisco(problemaCondicao)) {
      return isGestante
    } else {
      return isProblemaCondicaoDeGestacao(problemaCondicao)
    }
  }

  return false
}

export function hasProblemaCondicaoDePreNatal(problemasCondicoes: ProblemaCondicaoModel[], isGestante: boolean) {
  return (
    problemasCondicoes &&
    problemasCondicoes.some((problemaCondicao) => isProblemaCondicaoDePreNatal(problemaCondicao, isGestante))
  )
}

export function isProblemaCondicaoDeGestacao(problemaCondicao: ProblemaCondicaoModel) {
  return isCiapOuCidDeGestacao(problemaCondicao?.ciap?.codigo, problemaCondicao?.cid?.codigo)
}

export const isProblemaCondicaoEvolucaoGravidezAltoRisco = (problemaCondicao?: ProblemaCondicaoModel) =>
  isProblemaCondicaoGravidezAltoRisco(problemaCondicao) && isEvolucao(problemaCondicao)

export function hasProblemaCondicaoEvolucaoGravidezAltoRisco(problemasCondicoes?: ProblemaCondicaoModel[]) {
  return problemasCondicoes && problemasCondicoes.some(isProblemaCondicaoEvolucaoGravidezAltoRisco)
}

export function hasProblemaCondicaoDeGestacao(problemasCondicoes?: ProblemaCondicaoModel[]) {
  return problemasCondicoes && problemasCondicoes.some(isProblemaCondicaoDeGestacao)
}

export function isProblemaCondicaoComCiapW78(problemaCondicao: ProblemaCondicaoModel) {
  return problemaCondicao && isCiapW78(problemaCondicao.ciap?.codigo)
}

export function isProblemaCondicaoComCiapW78Ativo(problemaCondicao?: ProblemaCondicaoModel) {
  return (
    problemaCondicao &&
    isProblemaCondicaoComCiapW78(problemaCondicao) &&
    problemaCondicao.situacaoProblema === SituacaoProblema.ATIVO
  )
}

export function isProblemaCondicaoComCiapW78Automatico(problemaCondicao: ProblemaCondicaoModel) {
  return verifications.isProblemaCondicaoComCiapW78(problemaCondicao) && verifications.isAutomatico(problemaCondicao)
}

export function hasProblemaCondicaoComCiapW78Ativo(problemasCondicoes?: ProblemaCondicaoModel[]) {
  return problemasCondicoes && problemasCondicoes.some(isProblemaCondicaoComCiapW78Ativo)
}

export function isProblemaCondicaoComCiapW78Resolvido(problemaCondicao?: ProblemaCondicaoModel) {
  return (
    problemaCondicao &&
    isProblemaCondicaoComCiapW78(problemaCondicao) &&
    problemaCondicao.situacaoProblema === SituacaoProblema.RESOLVIDO
  )
}

export function hasProblemaCondicaoComCiapW78(problemasCondicoes?: ProblemaCondicaoModel[]) {
  return problemasCondicoes && problemasCondicoes.some(isProblemaCondicaoComCiapW78)
}

export function hasProblemaCondicaoComCiapW78Automatico(problemasCondicoes?: ProblemaCondicaoModel[]) {
  return problemasCondicoes && problemasCondicoes.some(verifications.isProblemaCondicaoComCiapW78Automatico)
}

export function hasProblemaCondicaoComCiapW78Resolvido(problemasCondicoes?: ProblemaCondicaoModel[]) {
  return problemasCondicoes && problemasCondicoes.some(isProblemaCondicaoComCiapW78Resolvido)
}

const isProblemaComCiapW78Ativo = (problemaCidadao?: Problema) =>
  isProblemaComCiapW78(problemaCidadao) && problemaCidadao.situacao === SituacaoProblema.ATIVO

export const hasProblemaComCiapW78Ativo = (problemasCidadao?: Problema[]) =>
  problemasCidadao && problemasCidadao.some(isProblemaComCiapW78Ativo)

export const isProblemaComCiapW78 = (problemaCidadao?: Problema) =>
  problemaCidadao && isCiapW78(problemaCidadao.ciap?.codigo)

export const hasProblemaNaoEvolucaoPuerperio = (problemasCondicoes?: ProblemaCondicaoModel[]): boolean =>
  problemasCondicoes &&
  problemasCondicoes.some(
    (problemaCondicao) =>
      isCiapOuCidDePuerperio(problemaCondicao.ciap?.codigo, problemaCondicao.cid?.codigo) &&
      !verifications.isEvolucao(problemaCondicao)
  )

export const isProblemaCondicaoGravidezAltoRisco = (problemaCondicao?: ProblemaCondicaoModel) =>
  problemaCondicao &&
  (isCiapGravidezAltoRisco(problemaCondicao.ciap?.codigo) || isCidGravidezAltoRisco(problemaCondicao.cid?.codigo))

export const hasProblemaCondicaoNaoEvolucaoGravidezAltoRisco = (problemasCondicoes?: ProblemaCondicaoModel[]) =>
  problemasCondicoes &&
  problemasCondicoes.some(
    (problemaCondicao) => isProblemaCondicaoGravidezAltoRisco(problemaCondicao) && !isEvolucao(problemaCondicao)
  )

export const hasProblemaCondicaoGravidezAltoRisco = (problemasCondicoes?: ProblemaCondicaoModel[]) =>
  problemasCondicoes && problemasCondicoes.some(isProblemaCondicaoGravidezAltoRisco)

export const isProblemaCondicaoGravidezAltoRiscoAtivoOuLatente = (problemaCondicao?: ProblemaCondicaoModel) =>
  problemaCondicao &&
  verifications.isProblemaCondicaoGravidezAltoRisco(problemaCondicao) &&
  problemaCondicao.situacaoProblema !== SituacaoProblema.RESOLVIDO

export const hasProblemaCondicaoGravidezAltoRiscoAtivoOuLatente = (problemasCondicoes?: ProblemaCondicaoModel[]) =>
  problemasCondicoes && problemasCondicoes.some(isProblemaCondicaoGravidezAltoRiscoAtivoOuLatente)

export const isProblemaCondicaoEncerraGestacao = (problemaCondicao?: ProblemaCondicaoModel) =>
  problemaCondicao && isCiapOuCidDeEncerramentoGestacao(problemaCondicao?.ciap?.codigo, problemaCondicao?.cid?.codigo)

export const isProblemaNaoEvolucaoEncerraGestacao = (problemaCondicao?: ProblemaCondicaoModel) =>
  isProblemaCondicaoEncerraGestacao(problemaCondicao) && !isEvolucao(problemaCondicao)

export const hasProblemaNaoEvolucaoEncerraGestacao = (problemasCondicoes?: ProblemaCondicaoModel[]): boolean =>
  problemasCondicoes && problemasCondicoes.some(isProblemaNaoEvolucaoEncerraGestacao)

const countProblemasCondicoesWithCondition = (
  condition: (problemaCondicao: ProblemaCondicaoModel, isGestante?: boolean) => boolean,
  problemasCondicoes: ProblemaCondicaoModel[],
  isGestante?: boolean
) =>
  problemasCondicoes.reduce(
    (totalProblemas: number, problemaCondicao: ProblemaCondicaoModel) =>
      condition(problemaCondicao, isGestante) ? ++totalProblemas : totalProblemas,
    0
  )

export const hasOnlyOneProblemaNaoEvolucaoEncerraGestacao = (problemasCondicoes: ProblemaCondicaoModel[]) =>
  countProblemasCondicoesWithCondition(isProblemaNaoEvolucaoEncerraGestacao, problemasCondicoes) === 1

export const hasOnlyOneProblemaCondicaoDePreNatal = (
  problemasCondicoes: ProblemaCondicaoModel[],
  isGestante: boolean
) => countProblemasCondicoesWithCondition(isProblemaCondicaoDePreNatal, problemasCondicoes, isGestante) === 1

export const isSituacaoProblemaResolvidoDesabilitadaPreNatal = (
  isCiapW78: boolean,
  isEvolucao: boolean,
  isEdicao?: boolean,
  isLPC?: boolean,
  tipoPreNatal?: TipoPreNatal
) => {
  const isCiapW78NaAvaliacao = isCiapW78 && !isLPC
  const isPrimeiroOuContinuacaoAtendimentoPreNatal =
    tipoPreNatal === TipoPreNatal.CONTINUACAO_PRE_NATAL || tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL
  const isCiapW78SendoCriadoNaAvaliacao = isCiapW78NaAvaliacao && !isEdicao && !isEvolucao
  const isCiapW78SendoEditadoNaAvaliacao = isCiapW78NaAvaliacao && isEdicao
  return (
    (isCiapW78SendoEditadoNaAvaliacao && isPrimeiroOuContinuacaoAtendimentoPreNatal) || isCiapW78SendoCriadoNaAvaliacao
  )
}
