import { Alert, Text, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import theme from 'config/theme'
import clearTypename from 'graphql/clearTypename'
import { usePayloadCadastrosDuplicadosQuery } from 'graphql/hooks.generated'
import { CidadaoUnificacaoBaseInput, PageParams } from 'graphql/types.generated'
import React, { useCallback, useState } from 'react'

import { UnificacaoBaseCidadaoFooter } from './UnificacaoBaseCidadaoFooter'
import { UnificacaoBaseCidadaoListItem } from './UnificacaoBaseCidadaoListItem'
import { CidadaoUnificacao } from './UnificacaoBaseModel'

export function UnificacaoBaseView() {
  const [cidadaosUnificaveis, setCidadaosUnificaveis] = useState<[CidadaoUnificacao][0][]>()
  const [inputState, setInputState] = useState<CidadaoUnificacaoBaseInput>({ pageParams: { size: 10 } })
  const nomesInstalacoesSet = new Set(cidadaosUnificaveis?.map((cidadao) => cidadao.unificacaoBase.nomeInstalacao))

  const {
    data: { getPayloadCadastrosDuplicados: cadastrosDuplicados },
  } = usePayloadCadastrosDuplicadosQuery({
    variables: {
      input: inputState,
    },
    onCompleted: () => {
      setCidadaosUnificaveis(clearTypename(cadastrosDuplicados?.content) as [CidadaoUnificacao][0][])
    },
  })

  const onChangeFooter = useCallback(
    (pageParams: PageParams) => {
      setInputState((prevState) => ({ ...prevState, pageParams }))
    },
    [setInputState]
  )

  return (
    <>
      <PageHeaderSection title='Unificação de bases' />
      <VFlow style={{ borderBottom: `1px solid ${theme.pallete.divider}` }}>
        <PageContainer>
          <Alert type='info' inline style={{ marginBottom: '1.5rem' }}>
            Os cadastros listados abaixo são oriundos do processo de Unificação de Bases. São cidadãos cadastrados na
            instalação que realizou o envio e que já estavam na instalação central do município. Por se tratarem de
            cidadãos duplicados, é recomendável a análise e unificação dos registros. <br />
            <b>Instalação central do município: </b>
            {/*TODO Delorean - query resgate nome instalação central*/}
            {nomesInstalacoesSet.size > 1 ? 'Instalação Central' : 'Nenhum envio realizado'}. <br />
            <b>
              {nomesInstalacoesSet.size > 1
                ? 'Instalações que realizaram os envios: '
                : 'Instalação que realizou o envio: '}
            </b>
            {nomesInstalacoesSet.join(', ')}.
          </Alert>
        </PageContainer>
      </VFlow>
      <PageContent type='transparent' style={{ textAlign: 'center' }}>
        {cidadaosUnificaveis ? (
          cidadaosUnificaveis.map((cidadaosUnificaveis) => {
            return <UnificacaoBaseCidadaoListItem cidadaoUnificacao={cidadaosUnificaveis} />
          })
        ) : (
          <Text fontStyle='italic'>Não há cadastros duplicados a serem exibidos.</Text>
        )}
        <UnificacaoBaseCidadaoFooter pageInfo={cadastrosDuplicados?.pageInfo} onChange={onChangeFooter} />
      </PageContent>
    </>
  )
}
