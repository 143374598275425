import { SelectField, SelectFieldProps } from 'components/form'
import { TipoAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  TipoAtendimentoHistoricoClinico,
  TipoAtendimentoSelectFieldQuery,
  TipoAtendimentoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type TipoAtendimentoSelectModel = TipoAtendimentoSelectFieldQuery['tipoAtendimentoHistorico'][0]
interface TipoAtendimentoSelectFieldProps
  extends Omit<SelectFieldProps<TipoAtendimentoSelectModel>, 'items' | 'itemToString'> {}

interface TipoAtendimentoRecordModel {
  id: ID
  value: string
}

export function TipoAtendimentoSelectField(props: TipoAtendimentoSelectFieldProps) {
  const asyncProps = useAsyncQuerySelect<
    TipoAtendimentoSelectModel,
    TipoAtendimentoSelectFieldQuery,
    TipoAtendimentoSelectFieldQueryVariables
  >({
    query: TipoAtendimentoSelectFieldDocument,
    extractItems: (data) => {
      return data?.tipoAtendimentoHistorico
    },
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
      },
    }),
  })

  return <SelectField<TipoAtendimentoSelectModel> itemToString={itemToString} {...asyncProps} {...props} />
}

const itemToString = (item: TipoAtendimentoSelectModel) => {
  return tipoAtendimentoRecord[item]?.value
}

export const tipoAtendimentoRecord: Partial<Record<TipoAtendimentoHistoricoClinico, TipoAtendimentoRecordModel>> = {
  [TipoAtendimentoHistoricoClinico.ATENDIMENTO_DOMICILIAR]: {
    id: 0,
    value: 'Atendimento domiciliar',
  },
  [TipoAtendimentoHistoricoClinico.ATIVIDADE_COLETIVA]: {
    id: 1,
    value: 'Atividade coletiva',
  },
  [TipoAtendimentoHistoricoClinico.AVALIACAO_ELEGIBILIDADE]: {
    id: 2,
    value: 'Avaliação de elegibilidade',
  },
  [TipoAtendimentoHistoricoClinico.CONSULTA]: {
    id: 3,
    value: 'Consulta',
  },
  [TipoAtendimentoHistoricoClinico.ATENDIMENTO_ODONTOLOGICO]: {
    id: 4,
    value: 'Consulta odontológica',
  },
  [TipoAtendimentoHistoricoClinico.ESCUTA_INICIAL]: {
    id: 5,
    value: 'Escuta inicial / pré-atendimento',
  },
  [TipoAtendimentoHistoricoClinico.CONSUMO_ALIMENTAR]: {
    id: 6,
    value: 'Marcadores de consumo alimentar',
  },
  [TipoAtendimentoHistoricoClinico.PRE_NATAL]: {
    id: 7,
    value: 'Pré-Natal',
  },
  [TipoAtendimentoHistoricoClinico.ATENDIMENTO_PROCEDIMENTOS]: {
    id: 8,
    value: 'Procedimento',
  },
  [TipoAtendimentoHistoricoClinico.PUERICULTURA]: {
    id: 9,
    value: 'Puericultura',
  },
  [TipoAtendimentoHistoricoClinico.PUERPERIO]: {
    id: 10,
    value: 'Puerpério',
  },
  [TipoAtendimentoHistoricoClinico.ZIKA]: {
    id: 11,
    value: 'Síndrome neurológica por zika / microcefalia',
  },
  [TipoAtendimentoHistoricoClinico.VACINACAO]: {
    id: 12,
    value: 'Vacinação',
  },
  [TipoAtendimentoHistoricoClinico.VISITA_DOMICILIAR]: {
    id: 13,
    value: 'Visita domiciliar e territorial',
  },
  [TipoAtendimentoHistoricoClinico.OBSERVACAO]: {
    id: 14,
    value: 'Observação',
  },
}
