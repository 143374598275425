/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, Theme, useTheme } from 'bold-ui'
import { HTMLAttributes, useEffect, useRef } from 'react'

export function Card(props: HTMLAttributes<HTMLDivElement>) {
  const ref = useRef<HTMLDivElement>()
  const theme = useTheme()
  const { cardStyle } = createStyles(theme)

  useEffect(() => {
    const elem = ref.current
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        elem.click()
      }
    }

    elem.addEventListener('keydown', handleKeyDown)
    return () => elem.removeEventListener('keydown', handleKeyDown)
  }, [ref])

  return <div ref={ref} css={cardStyle} tabIndex={0} {...props} data-testid='Acesso.card' />
}

const createStyles = (theme: Theme) => ({
  cardStyle: css`
    background-color: ${theme.pallete.surface.main};
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 4px;
    cursor: pointer;
    padding: 1rem;
    box-shadow: ${theme.shadows.outer['10']};
    transition: all 0.2s ease;
    &:hover {
      box-shadow: ${theme.shadows.outer['40']};
    }
    &:focus {
      outline: none;
      box-shadow: ${focusBoxShadow(theme)};
    }
    &:active {
      box-shadow: ${theme.shadows.inner['10']};
    }
  `,
})
