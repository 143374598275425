import { Calculation } from 'final-form-calculate'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { AtendimentoVacinacaoState } from 'view/atendimentos/atendimento-vacinacao/model'
import { EscutaInicialState } from 'view/atendimentos/escuta-inicial/model'

import { AgendarConsultaPanelModel } from '../components/AgendarConsultaPanel'

export const createAgendarConsultaCalculations = (name: MetaPath<AgendarConsultaPanelModel>): Calculation[] => [
  {
    field: name.lotacao.absolutePath(),
    updates: {
      [name.data.absolutePath()]: (
        lotacaoValue: string,
        allValues: SoapState | EscutaInicialState | AtendimentoVacinacaoState
      ) =>
        lotacaoValue &&
        (isSoapState(allValues)
          ? allValues.finalizacao.agendamentoConsulta.data
          : isEscutaState(allValues)
          ? allValues.agendamentoConsulta.data
          : isVacinacaoState(allValues) && allValues.finalizacaoVacinacao.agendamentoConsulta.data),
      [name.isForaUbs.absolutePath()]: (
        lotacaoValue: string,
        allValues: SoapState | EscutaInicialState | AtendimentoVacinacaoState
      ) => !lotacaoValue && isSoapState(allValues) && undefined,
    },
  },
  {
    field: name.data.absolutePath(),
    updates: {
      [name.horario.absolutePath()]: (
        dataValue: string,
        allValues: SoapState | EscutaInicialState | AtendimentoVacinacaoState
      ) =>
        dataValue &&
        (isSoapState(allValues)
          ? allValues.finalizacao.agendamentoConsulta.horario
          : isEscutaState(allValues)
          ? allValues.agendamentoConsulta.horario
          : isVacinacaoState(allValues) && allValues.finalizacaoVacinacao.agendamentoConsulta.horario),
    },
  },
  {
    field: name.isForaUbs.absolutePath(),
    updates: {
      [name.localAtendimento.absolutePath()]: (
        isForaUbsValue: boolean,
        allValues: SoapState | EscutaInicialState | AtendimentoVacinacaoState
      ) => !isForaUbsValue && isSoapState(allValues) && undefined,
    },
  },
]

const isSoapState = (value: SoapState): value is SoapState => !!value?.finalizacao?.agendamentoConsulta
const isEscutaState = (value: EscutaInicialState): value is EscutaInicialState => !!value?.agendamentoConsulta
const isVacinacaoState = (value: AtendimentoVacinacaoState): value is AtendimentoVacinacaoState =>
  !!value?.finalizacaoVacinacao?.agendamentoConsulta
