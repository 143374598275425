import { AtendimentosQueryInput, StatusAtendimento } from 'graphql/types.generated'
import { partition } from 'lodash'
import { safeDateAsYyyyMmDd } from 'util/date/formatDate'

import { isAtendimentoObservacaoByStatusAtendimento } from '../atendimento-individual/atendimento-observacao/util'
import { ListaAtendimentoFilterModel } from './filter/ListaAtendimentoFilter'
import {
  AtendimentoModel,
  AtendimentosFilterModel,
  AtendimentosNaoFinalizadosPeriodo,
  OBSERVACAO_STATUS_TO_TOGGLE,
  STATUS_SOMENTE_NAO_FINALIZADOS,
} from './model'

export const convertListaAtendimentoFilterToInput = (
  state: AtendimentosFilterModel,
  naoFinalizadosPeriodo: AtendimentosNaoFinalizadosPeriodo
): AtendimentosQueryInput => {
  const somenteNaoFinalizados = state.filter.somenteNaoFinalizados

  return {
    query: state.filter.query,
    ordem: state.filter.ordem,
    somenteMeusAtendimentos: state.filter.somenteMeusAtendimentos,
    statusAtendimento: filterStatusAtendimento(state.filter),
    dataInicio: somenteNaoFinalizados
      ? safeDateAsYyyyMmDd(naoFinalizadosPeriodo.inicio)
      : safeDateAsYyyyMmDd(state.filter.periodo.startDate),
    dataFim: somenteNaoFinalizados
      ? safeDateAsYyyyMmDd(naoFinalizadosPeriodo.fim)
      : safeDateAsYyyyMmDd(state.filter.periodo.endDate),
    tiposServico: state.filter.tiposServico?.map((value) => value.id),
    responsaveis: state.filter.responsaveis?.map((value) => value.id),
    equipes: state.filter.equipes?.map((value) => value.id),
    registroTardio: false,
    pageParams: state.pageParams,
  }
}

const filterStatusAtendimento = (filter: ListaAtendimentoFilterModel): StatusAtendimento[] => {
  if (filter.somenteNaoFinalizados && (!filter.statusAtendimento || filter.statusAtendimento.length === 0)) {
    return STATUS_SOMENTE_NAO_FINALIZADOS
  } else {
    let newStatusAtendimento = [...(filter.statusAtendimento ?? [])]

    OBSERVACAO_STATUS_TO_TOGGLE.forEach(([statusAtendimento, statusObservacao]) => {
      toggleStatusObservacao(newStatusAtendimento, statusAtendimento, statusObservacao)
    })

    return newStatusAtendimento
  }
}

const toggleStatusObservacao = (
  statusSelected: StatusAtendimento[],
  statusAtendimento: StatusAtendimento,
  statusObservacao: StatusAtendimento
) => {
  const indexOfObservacao = statusSelected.indexOf(statusObservacao)

  const hasStatusAtendimento = statusSelected.includes(statusAtendimento)
  const hasStatusObservacao = indexOfObservacao > -1

  if (hasStatusAtendimento && !hasStatusObservacao) {
    statusSelected.push(statusObservacao)
  } else if (hasStatusObservacao) {
    statusSelected.splice(indexOfObservacao, 1)
  }
}

export const partitionAtendimentos = (atendimentos: AtendimentoModel[] = []) => {
  if (atendimentos?.length > 0) {
    const [atendimentosEmObservacao, restAtendimentos] = partition<AtendimentoModel>(atendimentos, (atendimento) =>
      isAtendimentoObservacaoByStatusAtendimento(atendimento.statusAtendimento)
    )

    return { atendimentosEmObservacao, restAtendimentos }
  }

  return { atendimentosEmObservacao: [], restAtendimentos: [] }
}
