import { Button, Cell, Grid, Icon, PagedTable, TabItem, Tabs, Tooltip } from 'bold-ui'
import { ChartRangeSelector, ChartRangeSelectorProps, DateRange } from 'components/chart'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { Medicao, PageParams } from 'graphql/types.generated'
import { noop } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useMemo, useState } from 'react'
import { formatNumber } from 'util/number'
import { sexoByIdentidadeGenero } from 'util/sexoByIdentidadeGenero'
import { OBSERVACAO_DISABLE_REMOVE_TOOLTIP_TEXT } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { PeriodoGestacaoModel } from '../types/PeriodoGestacaoModel'
import { GraficoAlturaView } from './graficos-antropometria/GraficoAlturaView'
import { GraficoImcView } from './graficos-antropometria/GraficoImcView'
import { GraficoPerimetroCefalicoView } from './graficos-antropometria/GraficoPerimetroCefalicoView'
import { GraficoPesoView } from './graficos-antropometria/GraficoPesoView'
import { MedicaoAnteriorForm, MedicaoAnteriorFormModel } from './MedicaoAnteriorForm'
import { renderDataMedicao, renderMedicao } from './renderMedicao'

enum ChartTab {
  Peso,
  Altura,
  Imc,
  PerimetroCefalico,
  AlturaUterina,
}

const MEDICOES_ANTROPOMETRIA: any[keyof Medicao] = [
  'valorPeso',
  'valorAltura',
  'valorImc',
  'valorPerimetroCefalico',
  'valorCircunferenciaAbdominal',
  'perimetroPanturrilha',
]

export interface HistoricoMedicoesAntropometriaViewProps {
  cidadao: CidadaoMedicoes
  medicoes: ReadonlyArray<HistoricoMedicaoModel>
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
  isAtendimentoObservacao: boolean
  dataAtendimento?: Instant
  canEditOrAdd?: boolean
  onAddMedicaoAnterior?: (medicao: MedicaoAnteriorFormModel) => void
  onRemoveMedicaoAnterior?: (id: ID) => void
}

export function HistoricoMedicoesAntropometriaView(props: HistoricoMedicoesAntropometriaViewProps) {
  const {
    medicoes,
    cidadao,
    dataAtendimento,
    gestacoes,
    canEditOrAdd,
    isAtendimentoObservacao,
    onAddMedicaoAnterior = noop,
    onRemoveMedicaoAnterior = noop,
  } = props

  const { options, defaultOption } = useMemo(() => getRangeSelectorProps(cidadao.dataNascimento), [
    cidadao.dataNascimento,
  ])
  const [dataRange, setDataRange] = useState(options[defaultOption])
  const [currentChartTab, setCurrentChartTab] = useState(ChartTab.Peso)

  const medicoesExibir = medicoes.filter((med) => MEDICOES_ANTROPOMETRIA.some((e) => med[e]))

  const sexoCidadao = sexoByIdentidadeGenero(cidadao.sexo, cidadao.identidadeGeneroDbEnum)

  const [tableState, setTableState] = useState<{ pageParams?: PageParams }>({
    pageParams: { page: 0, size: 5, sort: !medicoes.find((m) => m.tipoMedicao === 'anterior') && ['-dataMedicao'] },
  })

  const { page: currentPage, size: itensPerPage } = tableState.pageParams
  const totalElements = medicoesExibir?.length ?? 0
  const totalPages = Math.ceil(totalElements / itensPerPage)

  const handleRemove = (row: HistoricoMedicaoModel) => {
    onRemoveMedicaoAnterior(row.id)
  }

  const content = medicoesExibir?.slice(currentPage * itensPerPage, (currentPage + 1) * itensPerPage) ?? []
  const medicoesPage = {
    content: content,
    pageInfo: {
      number: currentPage,
      size: itensPerPage,
      totalPages: totalPages,
      totalElements: medicoesExibir.length,
      first: currentPage === 0,
      last: currentPage === totalPages - 1,
      numberOfElements: content.length,
    },
  }

  const tableProps = usePagedTableProps({
    loading: false,
    onChange: setTableState,
    result: medicoesPage,
  })

  const renderButtons = (row: HistoricoMedicaoModel) => {
    return (
      <Tooltip
        text={
          row.tipoMedicao === 'anterior'
            ? 'Excluir'
            : row.tipoMedicao === 'atual'
            ? 'Medições do atendimento atual devem ser alteradas no SOAP.'
            : !isAtendimentoObservacao
            ? 'Medições registradas em outros atendimentos não podem ser excluídas.'
            : OBSERVACAO_DISABLE_REMOVE_TOOLTIP_TEXT
        }
      >
        <Button
          type='button'
          kind='normal'
          skin='ghost'
          disabled={row.tipoMedicao !== 'anterior'}
          size='small'
          onClick={() => handleRemove(row)}
        >
          <Icon icon='trashOutline' />
        </Button>
      </Tooltip>
    )
  }

  return (
    <Grid>
      <Cell size={12}>
        {canEditOrAdd !== false && (
          <MedicaoAnteriorForm
            dataNascimento={cidadao.dataNascimento}
            dataAtendimento={dataAtendimento}
            onAddMedicaoAnterior={onAddMedicaoAnterior}
          />
        )}
      </Cell>
      <Cell size={12}>
        <TableBox>
          <PagedTable<HistoricoMedicaoModel>
            {...tableProps}
            columns={[
              {
                header: 'Data da medição',
                name: 'dataMedicao',
                render: renderDataMedicao,
                style: { width: '6rem' },
              },
              {
                header: 'Peso (kg)',
                name: 'valorPeso',
                render: renderMedicao((med) => formatNumber(med.valorPeso, 2)),
                style: { width: '5.5rem' },
              },
              {
                header: 'Altura (cm)',
                name: 'valorAltura',
                render: renderMedicao((med) => formatNumber(med.valorAltura, 1)),
                style: { width: '4rem' },
              },
              {
                header: 'IMC (kg/m²)',
                name: 'valorImc',
                render: renderMedicao((med) => formatNumber(med.valorImc, 2)),
                style: { width: '4rem' },
              },
              {
                header: 'Per. cefálico (cm)',
                name: 'valorPerimetroCefalico',
                render: renderMedicao((med) => formatNumber(med.valorPerimetroCefalico, 1)),
                style: { width: '7rem' },
              },
              {
                header: 'Circ. abdominal (cm)',
                name: 'valorCircunferenciaAbdominal',
                render: renderMedicao((med) => formatNumber(med.valorCircunferenciaAbdominal, 1)),
                style: { width: '8.2rem' },
              },
              {
                header: 'Per. panturrilha (cm)',
                name: 'perimetroPanturrilha',
                render: renderMedicao((med) => formatNumber(med.perimetroPanturrilha, 1)),
                style: { width: '8rem' },
              },
              {
                name: 'buttons',
                render: renderButtons,
                style: { textAlign: 'right', width: '3.5rem' },
              },
            ]}
          />
        </TableBox>
      </Cell>
      <Cell size={12}>
        <Tabs>
          <TabItem active={currentChartTab === ChartTab.Peso} onClick={() => setCurrentChartTab(ChartTab.Peso)}>
            Peso
          </TabItem>
          <TabItem active={currentChartTab === ChartTab.Altura} onClick={() => setCurrentChartTab(ChartTab.Altura)}>
            Altura
          </TabItem>
          <TabItem active={currentChartTab === ChartTab.Imc} onClick={() => setCurrentChartTab(ChartTab.Imc)}>
            IMC
          </TabItem>
          <TabItem
            active={currentChartTab === ChartTab.PerimetroCefalico}
            onClick={() => setCurrentChartTab(ChartTab.PerimetroCefalico)}
          >
            Perímetro cefálico
          </TabItem>
          <ChartRangeSelector
            label='Faixa etária'
            style={{ marginLeft: 'auto' }}
            options={options}
            defaultOption={defaultOption}
            onChange={setDataRange}
          />
        </Tabs>
      </Cell>
      <Cell size={12}>
        {currentChartTab === ChartTab.Peso && (
          <GraficoPesoView
            medicoes={medicoesExibir}
            dataNascimento={moment(cidadao.dataNascimento)}
            dataRange={dataRange as DateRange}
            sexo={cidadao.sexo}
          />
        )}
        {currentChartTab === ChartTab.Altura && (
          <GraficoAlturaView
            medicoes={medicoesExibir}
            dataNascimento={moment(cidadao.dataNascimento)}
            dataRange={dataRange as DateRange}
            sexo={sexoCidadao}
          />
        )}
        {currentChartTab === ChartTab.Imc && (
          <GraficoImcView
            medicoes={medicoesExibir}
            dataNascimento={moment(cidadao.dataNascimento)}
            dataRange={dataRange as DateRange}
            sexo={sexoCidadao}
            gestacoes={gestacoes}
          />
        )}
        {currentChartTab === ChartTab.PerimetroCefalico && (
          <GraficoPerimetroCefalicoView
            dataRange={dataRange as DateRange}
            medicoes={medicoesExibir}
            dataNascimento={moment(cidadao.dataNascimento)}
            sexo={sexoCidadao}
          />
        )}
      </Cell>
    </Grid>
  )
}

function getRangeSelectorProps(dataNascimentoCidadao: string): ChartRangeSelectorProps<Moment> {
  const momentNascimento = moment(dataNascimentoCidadao)
  const momentNow = moment()
  const idade = moment.duration(momentNow.diff(momentNascimento))

  const ranges: { [x: string]: DateRange } = {}
  ranges['0 a 2 anos'] = {
    init: momentNascimento,
    end: momentNascimento.clone().add(24, 'month'),
    step: { amount: 2, unit: 'month' },
  }
  let defaultRange = '0 a 2 anos'

  if (idade.asYears() > 2) {
    ranges['2 a 5 anos'] = {
      init: momentNascimento.clone().add(24, 'month').add(1, 'second'),
      end: momentNascimento.clone().add(60, 'month'),
      step: { amount: 2, unit: 'month' },
    }
    defaultRange = '2 a 5 anos'
  }

  if (idade.asYears() > 5) {
    ranges['5 a 10 anos'] = {
      init: momentNascimento.clone().add(60, 'month').add(1, 'second'),
      end: momentNascimento.clone().add(120, 'month'),
      step: { amount: 4, unit: 'month' },
    }
    defaultRange = '5 a 10 anos'
  }

  if (idade.asYears() > 10) {
    ranges['10 a 15 anos'] = {
      init: momentNascimento.clone().add(120, 'month').add(1, 'second'),
      end: momentNascimento.clone().add(180, 'month'),
      step: { amount: 4, unit: 'month' },
    }
    defaultRange = '10 a 15 anos'
  }

  if (idade.asYears() > 15) {
    ranges['15 a 19 anos'] = {
      init: momentNascimento.clone().add(180, 'month').add(1, 'second'),
      end: momentNascimento.clone().add(228, 'month'),
      step: { amount: 4, unit: 'month' },
    }
    defaultRange = '15 a 19 anos'
  }

  if (idade.asYears() > 19) {
    ranges['19 a 30 anos'] = {
      init: momentNascimento.clone().add(228, 'month').add(1, 'second'),
      end: momentNascimento.clone().add(30, 'year'),
      step: { amount: 6, unit: 'month' },
    }
    defaultRange = '19 a 30 anos'
  }

  if (idade.asYears() > 30) {
    for (let i = 30; idade.asYears() > i; i += 10) {
      const rangeLabel = `${i} a ${i + 10} anos`
      ranges[rangeLabel] = {
        init: momentNascimento.clone().add(i, 'year').add(1, 'second'),
        end: momentNascimento.clone().add(i + 10, 'year'),
        step: { amount: 6, unit: 'month' },
      }
      defaultRange = rangeLabel
    }
  }

  return { options: ranges, defaultOption: defaultRange }
}
