/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import api from 'api'
import { Alert, Button, HFlow, Icon, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { SubmitButton } from 'components/form'
import { HLabel } from 'components/HLabel'
import { Telefone } from 'components/label'
import { useCidadaoInformacoesContatoQuery } from 'graphql/hooks.generated'
import { SexoEnum, TipoReceitaEnum } from 'graphql/types.generated'
import { useForceRender } from 'hooks/useForceRender'
import React, { useRef, useState } from 'react'
import { useField } from 'react-final-form'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { metaPath } from 'util/metaPath'

import { MedicamentoFormModel } from '../../model'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { convertMedicamentoToPrintDto } from '../../utils/prescricaoConverter'
import { EncaminharPrescricaoMedicamentoItem } from '../components/EncaminharPrescricaoMedicamentoItem'
import { ImpressaoMedicamentosInput } from '../impressao/ImpressaoMedicamentosInput'
import { TelefoneCelularUpdateForm } from './TelefoneCelularUpdateField'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

export interface PrescricaoDigitalViewProps {
  medicamentos: MedicamentoFormModel[]
  atendimentoId: ID
  municipioId: ID
  cidadaoId: ID
  cidadaoSexo: SexoEnum
  cidadaoDataNascimento: string
  onModalClose: () => void
}

export const PrescricaoDigitalView = (props: PrescricaoDigitalViewProps) => {
  const {
    medicamentos,
    atendimentoId,
    municipioId,
    cidadaoId,
    cidadaoSexo,
    cidadaoDataNascimento,
    onModalClose,
  } = props

  const {
    input: { value: prescricoesGeradasIds },
  } = useField<ID>(path.medicamentoIdsOnPrescricaoDigital.absolutePath())

  const [medicamentosSelected, setMedicamentosSelected] = useState<MedicamentoFormModel[]>(() =>
    medicamentos.filter(
      (medicamento) =>
        (medicamento.registroManual
          ? medicamento.tipoReceita.tipoReceitaEnum
          : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita) === TipoReceitaEnum.COMUM &&
        !prescricoesGeradasIds.includes(medicamento.id)
    )
  )
  const { analytics } = useFirebase()
  const handleRejection = useErrorHandler()
  const forceRender = useForceRender()
  const alertUpdateTelefoneSucceedRef = useRef(false)
  const [isEditingTelefone, setIsEditingTelefone] = useState(false)

  const { data: infoCidadao } = useCidadaoInformacoesContatoQuery({
    variables: { id: cidadaoId },
  })

  const [email, telefoneCelular] = [infoCidadao?.contatoCidadao?.email, infoCidadao?.contatoCidadao?.telefoneCelular]

  const handleTelefoneCelularChange = (newTelefoneCelular: string) => {
    analytics.logEvent('click_atualizar_telefone_prescricao_digital')

    if (newTelefoneCelular !== telefoneCelular) {
      alertUpdateTelefoneSucceedRef.current = true
    }

    setIsEditingTelefone(false)
  }

  const handleOnAlertClose = () => {
    alertUpdateTelefoneSucceedRef.current = false
    forceRender()
  }

  const handleOnEditarTelefoneClick = () => {
    analytics.logEvent('click_editar_telefone_prescricao_digital')

    setIsEditingTelefone(true)
  }

  const handleOnChangeMedicamentosSelected = (checked: boolean, medicamento: MedicamentoFormModel) => {
    checked
      ? setMedicamentosSelected([...medicamentosSelected, medicamento])
      : setMedicamentosSelected(medicamentosSelected.filter((item) => item.id !== medicamento.id))
  }

  const statusGerarButton = getStatusGerarButon({
    hasTelefoneCelular: !!telefoneCelular,
    isEditingTelefone: isEditingTelefone,
    medicamentosSelectedLength: medicamentosSelected.length,
  })

  const renderFieldArray = (renderFieldArrayProps: FieldArrayRenderProps<ID, any>) => {
    const {
      fields: { push, value },
    } = renderFieldArrayProps

    const isMedicamentoOnPrescricao = (id: ID) => value?.includes(id)

    const handleGerarClicked = () => {
      analytics.logEvent('click_gerar_prescricao_digital')

      const input: ImpressaoMedicamentosInput = {
        atendimentoId: atendimentoId,
        cidadaoDataNascimento: Date.parse(cidadaoDataNascimento),
        cidadaoSexo: cidadaoSexo,
        localidadeId: municipioId,
        medicamentos: medicamentosSelected.map((item) => convertMedicamentoToPrintDto(item)),
      }

      return api.prescricaoDigital
        .imprimirPrescricaoDigital(input)
        .then((response) => {
          window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
          medicamentosSelected.forEach((medicamento) => {
            if (!isMedicamentoOnPrescricao(medicamento)) {
              push(medicamento.id)
            }
          })
          setMedicamentosSelected([])
        })
        .catch(handleRejection)
    }

    return (
      <React.Fragment>
        <div
          css={css`
            margin-top: -1.5rem;
            padding: 0 2.25rem;
          `}
        >
          <VFlow vSpacing={0.5}>
            {!!alertUpdateTelefoneSucceedRef.current && (
              <Alert inline type='success' onCloseClick={handleOnAlertClose}>
                Telefone celular atualizado com sucesso.
              </Alert>
            )}
            <Alert inline type='info'>
              A prescrição será assinada eletronicamente via Gov.br e será enviada ao cidadão <b>via SMS</b> no telefone
              celular abaixo. Verifique atentamente os dados informados antes de gerar a prescrição digital.
            </Alert>
            {isEditingTelefone ? (
              <TelefoneCelularUpdateForm
                cidadaoId={cidadaoId}
                emailCidadao={email}
                onSubmitSuccess={handleTelefoneCelularChange}
                initialValues={{ telefoneCelular }}
              />
            ) : (
              <HFlow hSpacing={0.25} alignItems='center'>
                <HLabel title='Telefone celular:'>
                  <Telefone value={telefoneCelular} />
                </HLabel>
                <Tooltip text='Editar'>
                  <Button size='small' skin='ghost' onClick={handleOnEditarTelefoneClick}>
                    <Icon icon='penOutline' />
                  </Button>
                </Tooltip>
              </HFlow>
            )}
            <Text>Selecione os medicamentos que deseja incluir na prescrição digital</Text>
            <EncaminharPrescricaoMedicamentoItem
              medicamentosToPrint={medicamentos}
              medicamentoIdsSelecionado={medicamentosSelected.map((item) => item.id)}
              onChange={handleOnChangeMedicamentosSelected}
              disableReceitaControlada
              isPrescricaoDigital
            />
          </VFlow>
        </div>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onModalClose} style={styles.footerButton} data-testid='CancelarPrescricaoDigitalButton'>
              Cancelar
            </Button>
            <Tooltip text={statusGerarButton?.tooltip}>
              <SubmitButton
                kind='primary'
                handleSubmit={handleGerarClicked}
                style={styles.footerButton}
                disabled={statusGerarButton?.disabled}
                data-testid='GerarPrescricaoButton'
              >
                Gerar
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </React.Fragment>
    )
  }

  return <FieldArray<ID> name={path.medicamentoIdsOnPrescricaoDigital.absolutePath()} render={renderFieldArray} />
}

const styles = {
  footerButton: css`
    width: 10rem;
    height: 3rem;
  `,
}

const getStatusGerarButon = ({
  medicamentosSelectedLength,
  hasTelefoneCelular,
  isEditingTelefone,
}: {
  medicamentosSelectedLength: number
  hasTelefoneCelular: boolean
  isEditingTelefone: boolean
}): {
  disabled: boolean
  tooltip: string
} => {
  if (medicamentosSelectedLength === 0) {
    return {
      disabled: true,
      tooltip: 'Nenhum medicamento selecionado',
    }
  } else if (!hasTelefoneCelular) {
    return {
      disabled: true,
      tooltip: 'Telefone celular não cadastrado',
    }
  } else if (isEditingTelefone) {
    return {
      disabled: true,
      tooltip: 'Telefone celular não atualizado',
    }
  }
}
