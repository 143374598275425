import api from 'api'
import { Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { FileUploaderComponent } from 'components/upload/FileUploaderComponent'
import React from 'react'

export function ImportacaoImunobiologicoView() {
  const alert = useAlert()

  const upload = (data: FormData) => {
    return api.importacaoImunobiologico
      .importar(data)
      .then(() => {
        alert('success', 'Arquivo importado com sucesso')
      })
      .catch(() => {
        alert('danger', 'Falha na importação do arquivo')
      })
  }

  return (
    <>
      <Breadcrumb title='Importação de imunobiológico' />

      <PageHeaderSection title='Importação de imunobiológico' />
      <PageContent>
        <VFlow>
          <FileUploaderComponent uploadRequest={upload} />
          <Text>Selecione um arquivo no formato JSON para a importação de imunobiológico</Text>
        </VFlow>
      </PageContent>
    </>
  )
}
