import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { AtendimentoIndividualViewQuery, TipoAtendimentoEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { AtendimentoVacinacaoState } from '../atendimento-vacinacao/model'
import { LembreteFormModel } from '../detail/components/modals/lembretes/components/LembreteForm'
import { MedicamentoInterromperFormModel } from '../detail/components/modals/medicamentos/InterromperTratamentoModal'
import { MedicaoAnteriorFormModel } from '../detail/components/modals/medicoes/MedicaoAnteriorForm'
import { AntecedentesFormModel } from '../detail/soap/antecedentes/AntecedentesForm'
import { AvaliacaoFormModel } from '../detail/soap/avaliacao/AvaliacaoForm'
import { ProblemaCondicaoModel } from '../detail/soap/avaliacao/components/problemas-condicoes/model'
import { FinalizacaoAtendimentoFormModel } from '../detail/soap/finalizacao'
import { ObjetivoFormModel } from '../detail/soap/objetivo'
import { PlanoFormModel } from '../detail/soap/plano/PlanoForm'
import { AcompanhamentoPreNatalModel, PreNatalFormModel } from '../detail/soap/pre-natal/model'
import { SubjetivoFormModel } from '../detail/soap/subjetivo'

export type TipoServicoModel = AtendimentoIndividualViewQuery['atendimento']['tiposServico'][number]

export const TIPOS_ATENDIMENTO_CONCLUEM_AGENDAMENTO = [
  TipoAtendimentoEnum.CONSULTA_AGENDADA_CUIDADO_CONTINUADO,
  TipoAtendimentoEnum.CONSULTA,
]

export interface SoapState {
  openedAccordions?: (string | number)[]
  antecedentes?: AntecedentesFormModel
  subjetivo?: SubjetivoFormModel
  objetivo?: ObjetivoFormModel
  avaliacao?: AvaliacaoFormModel
  plano?: PlanoFormModel
  finalizacao?: FinalizacaoAtendimentoFormModel
  imprimirAtendimento?: boolean
  lastSaved?: Date
  medicoesAnteriores?: MedicaoAnteriorFormModel[]
  medicamentosInterromper?: MedicamentoInterromperFormModel[]
  lembretes?: LembreteFormModel[]
  vacinacao?: AtendimentoVacinacaoState
  preNatal?: PreNatalFormModel
  acompanhamentoPreNatal?: AcompanhamentoPreNatalModel
  problemasECondicoes?: ProblemaCondicaoModel[]
}

export const meta = metaPath<SoapState>()

export interface CiapCidPreNatal {
  ciap: CiapSelectFieldModel
  cid: CidSelectFieldModel
}

export interface SoapEditableItem {
  editingId?: ID
}
