import { blue, orange, pink, purple, turquoise } from 'bold-ui/lib/styles/colors'
import { TipoLogradouroSelectModel } from 'components/form'
import { TerritorioMicroareaQuery } from 'graphql/types.generated'
import { FamiliasTerritorio } from 'view/reterritorializacao/model'

import { ReactComponent as AbrigoIcone } from '../../images/visualizacao-territorial/abrigo-icone.svg'
import { ReactComponent as ApartamentoIcone } from '../../images/visualizacao-territorial/apartamento-icone.svg'
import { ReactComponent as CasaIcone } from '../../images/visualizacao-territorial/casa-icone.svg'
import { ReactComponent as DomicilioIcone } from '../../images/visualizacao-territorial/casa-icone.svg'
import { ReactComponent as ComercioIcone } from '../../images/visualizacao-territorial/comercio-icone.svg'
import { ReactComponent as CrecheIcone } from '../../images/visualizacao-territorial/creche-icone.svg'
import { ReactComponent as DelegaciaIcone } from '../../images/visualizacao-territorial/delegacia-icone.svg'
import { ReactComponent as EscolaIcone } from '../../images/visualizacao-territorial/escola-icone.svg'
import { ReactComponent as EstabelecimentoReligiosoIcone } from '../../images/visualizacao-territorial/estabelecimento-religioso-icone.svg'
import { ReactComponent as InstituicaoLongaPermanenciaIcone } from '../../images/visualizacao-territorial/longa-permanencia-idosos-icone.svg'
import { ReactComponent as OutrosImoveisIcone } from '../../images/visualizacao-territorial/outros-imoveis.svg'
import { ReactComponent as PontoEstrategicoIcone } from '../../images/visualizacao-territorial/ponto-estrategico-icone.svg'
import { ReactComponent as TerrenoBaldioIcone } from '../../images/visualizacao-territorial/terreno-baldio-icone.svg'
import { ReactComponent as UnidadePrisionalIcone } from '../../images/visualizacao-territorial/unidade-prisional-icone.svg'
import { ReactComponent as UnidadeSocioEducativaIcone } from '../../images/visualizacao-territorial/unidade-socioeducativa-icone.svg'

export interface VisualizacaoTerritorialBuscaFormModel {
  cnes: string
  ine: string
  bairro?: string
  cep?: string
  tipoLogradouro?: TipoLogradouroSelectModel
  logradouro?: string
}

export type TerritorioMicroareaQueryResult = TerritorioMicroareaQuery['territorioMicroarea']
export type TerritorioMicroareaItemModel = TerritorioMicroareaQueryResult['logradouros']['content'][0]

export interface TipoImovelListModel {
  IconImovel: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  fill: string
  tooltip: string
  tipoResponsavel: TipoResponsavelEnum
}

export interface TerritorioModel {
  cnes: string
  microarea: string
  ine: string
  cep?: string
}

export interface VisualizacaoTerritorioFilter extends Omit<VisualizacaoTerritorialBuscaFormModel, 'tipoLogradouro'> {
  tipoLogradouroId?: ID
}

export type TipoDomicilioTag = 'Casa' | 'Apartamento' | 'Cômodo' | 'Outro'

export type TipoLocalizacaoTag = 'Urbana' | 'Rural'

export interface ResponsavelTecnico {
  nomeResponsavelTecnico: string
  contatoResponsavelTecnico?: string
  cnsResponsavelTecnico?: string
  cargoResponsavelTecnico?: string
}
export interface ImovelModel {
  imovelId: ID
  uuidOrigemImovel: ID
  tipoDomicilioId?: ID
  tipoCdsImovelId: ID
  cep: string
  complemento?: string
  responsavelTecnico?: ResponsavelTecnico
  numero: string
  familias: FamiliasTerritorio[]
  diasDesdeUltimaVisita: number
  numeroMoradores: number
  logradouro: string
  bairro: string
  telefoneResidencial: string
  telefoneContato: string
  tipoLocalizacaoId: ID
  nomeTipoLogradouro: string
}

export enum TipoDomicilioEnum {
  CASA = 85,
  APARTAMENTO = 86,
  COMODO = 87,
  OUTRO = 88,
}

export enum TipoImovelEnum {
  DOMICILIO = 1,
  COMERCIO = 2,
  TERRENO_BALDIO = 3,
  PONTO_ESTRATEGICO = 4,
  ESCOLA = 5,
  CRECHE = 6,
  ABRIGO = 7,
  INST_LONGA_PERMANENCIA_IDOSOS = 8,
  UNIDADE_PRISIONAL = 9,
  UNIDADE_SOCIOEDUCATIVA = 10,
  DELEGACIA = 11,
  ESTABELECIMENTO_RELIGIOSO = 12,
  OUTROS = 99,
}

export enum TipoLocalizacaoEnum {
  URBANA = 83,
  RURAL = 84,
}

export enum TipoResponsavelEnum {
  FAMILIAR = 1,
  TECNICO = 2,
  SEM_RESPONSAVEL = 3,
}

export const tipoImovelRecord: Record<TipoImovelEnum, TipoImovelListModel> = {
  [TipoImovelEnum.DOMICILIO]: {
    IconImovel: DomicilioIcone,
    fill: blue.c60,
    tooltip: 'Domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoImovelEnum.COMERCIO]: {
    IconImovel: ComercioIcone,
    fill: orange.c60,
    tooltip: 'Comércio',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.TERRENO_BALDIO]: {
    IconImovel: TerrenoBaldioIcone,
    fill: purple.c40,
    tooltip: 'Terreno baldio',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.PONTO_ESTRATEGICO]: {
    IconImovel: PontoEstrategicoIcone,
    fill: orange.c60,
    tooltip: 'Ponto estratégico',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.ESCOLA]: {
    IconImovel: EscolaIcone,
    fill: turquoise.c60,
    tooltip: 'Escola',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.CRECHE]: {
    IconImovel: CrecheIcone,
    fill: turquoise.c60,
    tooltip: 'Creche',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.ABRIGO]: {
    IconImovel: AbrigoIcone,
    fill: pink.c40,
    tooltip: 'Abrigo',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.INST_LONGA_PERMANENCIA_IDOSOS]: {
    IconImovel: InstituicaoLongaPermanenciaIcone,
    fill: pink.c40,
    tooltip: 'Inst. Longa permanência para idosos',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.UNIDADE_PRISIONAL]: {
    IconImovel: UnidadePrisionalIcone,
    fill: blue.c20,
    tooltip: 'Unidade priosional',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.UNIDADE_SOCIOEDUCATIVA]: {
    IconImovel: UnidadeSocioEducativaIcone,
    fill: blue.c20,
    tooltip: 'Unidade Socioeducativa',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.DELEGACIA]: {
    IconImovel: DelegaciaIcone,
    fill: blue.c20,
    tooltip: 'Delegacia',
    tipoResponsavel: TipoResponsavelEnum.TECNICO,
  },
  [TipoImovelEnum.ESTABELECIMENTO_RELIGIOSO]: {
    IconImovel: EstabelecimentoReligiosoIcone,
    fill: orange.c60,
    tooltip: 'Estabelecimento religioso',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
  [TipoImovelEnum.OUTROS]: {
    IconImovel: OutrosImoveisIcone,
    fill: '',
    tooltip: 'Outros imóveis',
    tipoResponsavel: TipoResponsavelEnum.SEM_RESPONSAVEL,
  },
}

export const domicilioTipoImovelRecord: Record<TipoDomicilioEnum, TipoImovelListModel> = {
  [TipoDomicilioEnum.APARTAMENTO]: {
    IconImovel: ApartamentoIcone,
    fill: blue.c60,
    tooltip: 'Apartamento',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoDomicilioEnum.CASA]: {
    IconImovel: CasaIcone,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoDomicilioEnum.COMODO]: {
    IconImovel: CasaIcone,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
  [TipoDomicilioEnum.OUTRO]: {
    IconImovel: CasaIcone,
    fill: blue.c60,
    tooltip: 'Casa/domicílio',
    tipoResponsavel: TipoResponsavelEnum.FAMILIAR,
  },
}

export const domicilioTipoRecord: Record<TipoDomicilioEnum, TipoDomicilioTag> = {
  [TipoDomicilioEnum.CASA]: 'Casa',
  [TipoDomicilioEnum.APARTAMENTO]: 'Apartamento',
  [TipoDomicilioEnum.COMODO]: 'Cômodo',
  [TipoDomicilioEnum.OUTRO]: 'Outro',
}

export const localizacaoTipoRecord: Record<TipoLocalizacaoEnum, TipoLocalizacaoTag> = {
  [TipoLocalizacaoEnum.URBANA]: 'Urbana',
  [TipoLocalizacaoEnum.RURAL]: 'Rural',
}

export const IMOVEIS_COM_RESPONSAVEL_TECNICO = [
  TipoImovelEnum.ABRIGO,
  TipoImovelEnum.INST_LONGA_PERMANENCIA_IDOSOS,
  TipoImovelEnum.UNIDADE_PRISIONAL,
  TipoImovelEnum.UNIDADE_SOCIOEDUCATIVA,
]

export const OUTROS_IMOVEIS = [
  TipoImovelEnum.COMERCIO,
  TipoImovelEnum.TERRENO_BALDIO,
  TipoImovelEnum.PONTO_ESTRATEGICO,
  TipoImovelEnum.ESCOLA,
  TipoImovelEnum.CRECHE,
  TipoImovelEnum.ABRIGO,
  TipoImovelEnum.INST_LONGA_PERMANENCIA_IDOSOS,
  TipoImovelEnum.UNIDADE_PRISIONAL,
  TipoImovelEnum.UNIDADE_SOCIOEDUCATIVA,
  TipoImovelEnum.DELEGACIA,
  TipoImovelEnum.ESTABELECIMENTO_RELIGIOSO,
  TipoImovelEnum.OUTROS,
]
