import { Button, Heading, Text, VFlow } from 'bold-ui'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { SugestaoProximaConsultaCard, SugestaoProximaConsultaCardProps } from './SugestaoProximaConsultaCard'

interface SugestaoProximasConsultasSectionProps {
  sugestoes: SugestaoProximaConsultaCardProps[]
}

export function SugestaoProximasConsultasSection(props: SugestaoProximasConsultasSectionProps) {
  const { sugestoes } = props

  const [verMais, setVerMais] = useState(false)

  const hasNoSugestoes = sugestoes.isEmpty()
  if (hasNoSugestoes) return null

  const sugestoesToShow = verMais ? sugestoes : sugestoes.slice(0, 5)

  return (
    <VFlow vSpacing={0.5}>
      <Heading level={3}>Sugestão próximas consultas</Heading>
      {sugestoesToShow.map((sugestao) => (
        <SugestaoProximaConsultaCard key={uuidv4()} type={sugestao.type} date={sugestao.date} />
      ))}
      <Button
        skin='ghost'
        size='small'
        onClick={() => {
          setVerMais(!verMais)
        }}
      >
        <Text>{verMais ? 'Ver menos' : 'Ver mais'}</Text>
      </Button>
    </VFlow>
  )
}
