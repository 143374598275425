import { Cell, Grid, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { ResultadoExame, SolicitacaoExame } from 'graphql/types.generated'
import React from 'react'
import { Fragment } from 'react'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { showCodigoProcedimento } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util'

interface HistoricoPlanoPecExamesSolicitadosPanelProps {
  examesSolicitados?: SolicitacaoExame[]
}

export default function HistoricoPlanoPecExamesSolicitadosPanel({
  examesSolicitados,
}: HistoricoPlanoPecExamesSolicitadosPanelProps) {
  return (
    <HistoricoDetailTable hasLines title='Exame'>
      <VFlow>
        {examesSolicitados
          .sort((a, b) => b.id.localeCompare(a.id))
          .map((solicitacaoExame: SolicitacaoExame) => (
            <Grid gap={2} key={solicitacaoExame.id}>
              {solicitacaoExame.exames.map(({ id, exame, observacao }: ResultadoExame) => (
                <Fragment key={id}>
                  <Cell size={12}>
                    <Text>
                      <Text fontWeight='bold'>{exame.descricao}</Text> - {showCodigoProcedimento(exame.codigo)}
                    </Text>
                  </Cell>
                  {observacao && (
                    <Cell size={12}>
                      <HLabel title='Observação do exame:'>{observacao}</HLabel>
                    </Cell>
                  )}
                </Fragment>
              ))}
              {solicitacaoExame.cid10 && (
                <Cell size={12}>
                  <Text>
                    {solicitacaoExame.cid10.nome} - {solicitacaoExame.cid10.codigo} (CID10)
                  </Text>
                </Cell>
              )}
              <Cell size={12}>
                <HLabel title='Justificativa do procedimento:'>{solicitacaoExame.justificativa}</HLabel>
              </Cell>
              {solicitacaoExame.observacoes && (
                <Cell size={12}>
                  <HLabel title='Observações:'>{solicitacaoExame.observacoes}</HLabel>
                </Cell>
              )}
            </Grid>
          ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
