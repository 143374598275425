import {
  AtendimentoObservacaoViewQuery,
  AtendimentoProfissionalPreNatal,
  Medicao,
  PreNatal,
  StatusAtendimento,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { convertAvaliacaoQueryToForm } from 'view/atendimentos/detail/soap/avaliacao/converter'
import { convertFinalizacaoQueryToForm } from 'view/atendimentos/detail/soap/finalizacao'
import { convertObjetivoQueryToForm } from 'view/atendimentos/detail/soap/objetivo'
import { convertPlanoQueryToForm } from 'view/atendimentos/detail/soap/plano/converter'
import { PreNatalViewModel } from 'view/atendimentos/detail/soap/pre-natal/model'
import { convertSubjetivoQueryToForm } from 'view/atendimentos/detail/soap/subjetivo'

import { SoapEditableItem, SoapState } from '../model'
import { isSoapEditableItem, SoapEditableModules } from '../util'
import { AtendimentoObservacaoModel, OBSERVACAO_DISABLE_REMOVE_TOOLTIP_TEXT, ResponsavelObservacaoModel } from './model'

export interface AtendimentoProfissionalObservacaoInput {
  finalizadoEm: Instant
  tipo: TipoAtendimentoProfissional
  lotacao: {
    id: ID
  }
  estagio: {
    id: ID
  }
  atendimentoProfissionalObservacao?: {
    atendimentoObservacao: {
      responsavel: ResponsavelObservacaoModel
    }
  }
}

interface AtendimentoProfissionalPreNatalModel extends Omit<AtendimentoProfissionalPreNatal, 'preNatal'> {
  preNatal?: Omit<PreNatal, 'problema'>
}

type AtendimentProfissionalObservacaoModel = AtendimentoObservacaoViewQuery['atendimento']['atendimentoProfissional']

interface MedicaoModel extends Omit<Medicao, 'atendimentoProfissional' | 'dataMedicao'> {}

const LISTA_ATENDIMENTO_OBSERVACAO_STATUS = [StatusAtendimento.AGUARDANDO_OBSERVACAO, StatusAtendimento.EM_OBSERVACAO]

export const isLotacaoResponsavelPelaObservacao = (
  lotacaoId: ID,
  responsavelId: ID,
  estagioId: ID,
  isEmProcessoRevisao: boolean
): boolean => {
  if (isUndefinedOrNull(lotacaoId) || isUndefinedOrNull(responsavelId)) {
    return false
  }

  return (isEmProcessoRevisao && estagioId ? estagioId : lotacaoId) === responsavelId
}

export const getAtendimentoObservacaoModel = (
  atendimentoProfissional: AtendimentoProfissionalObservacaoInput,
  isEmProcessoRevisao: boolean,
  isAcessoEstagio: boolean
): AtendimentoObservacaoModel => {
  const isAtendimentoObservacao = !isUndefinedOrNull(atendimentoProfissional?.atendimentoProfissionalObservacao)

  const lotacaoId = atendimentoProfissional?.lotacao?.id
  const estagioId = atendimentoProfissional?.estagio?.id
  const responsavelId =
    atendimentoProfissional?.atendimentoProfissionalObservacao?.atendimentoObservacao?.responsavel?.id

  const isObservacaoAndResponsavel =
    isAtendimentoObservacao &&
    !isAcessoEstagio &&
    isLotacaoResponsavelPelaObservacao(lotacaoId, responsavelId, estagioId, isEmProcessoRevisao)
  const isObservacaoAndAuxiliar = isAtendimentoObservacao && !isObservacaoAndResponsavel
  const responsavelObservacao = isAtendimentoObservacao
    ? atendimentoProfissional?.atendimentoProfissionalObservacao?.atendimentoObservacao?.responsavel
    : null

  const isObservacaoEmAndamento = isAtendimentoObservacao && !isUndefinedOrNull(atendimentoProfissional?.finalizadoEm)

  return {
    isAtendimentoObservacao,
    isObservacaoAndResponsavel,
    isObservacaoAndAuxiliar,
    isObservacaoEmAndamento,
    responsavelObservacao,
  }
}

export const isAtendimentoObservacaoByStatusAtendimento = (statusAtendimento: StatusAtendimento): boolean => {
  return LISTA_ATENDIMENTO_OBSERVACAO_STATUS.includes(statusAtendimento)
}

export const getEditableItemRemoveTooltipText = <T extends SoapEditableItem>(
  item: T,
  defaultTooltipText: string = 'Excluir',
  editableItemTooltipText: string = OBSERVACAO_DISABLE_REMOVE_TOOLTIP_TEXT
): { isEditable: boolean; tooltipText: string } => {
  const isEditable = isSoapEditableItem(item)
  const tooltipText = isEditable ? editableItemTooltipText : defaultTooltipText

  return {
    isEditable,
    tooltipText,
  }
}

export const extractObservacaoEditableModules = (
  atendimentoProfissional: AtendimentProfissionalObservacaoModel
): SoapEditableModules => {
  return {
    evolucaoSubjetivo: atendimentoProfissional?.evolucaoSubjetivo,
    evolucaoObjetivo: atendimentoProfissional?.evolucaoObjetivo,
    evolucaoAvaliacao: atendimentoProfissional?.evolucaoAvaliacao,
    evolucaoPlano: atendimentoProfissional?.evolucaoPlano,
    orientacoes: atendimentoProfissional?.orientacoes,
    atestados: atendimentoProfissional?.atestados,
    encaminhamentos: atendimentoProfissional?.encaminhamentos,
    encaminhamentosEspecializados: atendimentoProfissional?.encaminhamentosEspecializados,
    marcadorConsumoAlimentar: atendimentoProfissional?.marcadorConsumoAlimentar,
    resultadosExames: atendimentoProfissional?.examesResultados,
    solicitacoesExames: atendimentoProfissional?.requisicoesExames,
    receitasMedicamento: atendimentoProfissional?.receitaMedicamento,
    evolucoesProblema: atendimentoProfissional?.evolucaoProblema,
    finalizacao: {
      tipoAtendimento: atendimentoProfissional?.tipoAtendimento,
      condutas: atendimentoProfissional?.condutas,
      racionalidadeSaude: atendimentoProfissional?.racionalidadeSaude,
      lotacaoAtendimentoCompartilhado: atendimentoProfissional?.lotacaoAtendimentoCompartilhado,
      notificacoesCasoSuspeito: atendimentoProfissional?.notificacoesCasoSuspeito,
      procedimentosAtendimento: atendimentoProfissional?.procedimentosAtendimento,
    },
  }
}

export const extractPreNatalFromAtendimentoProfissional = (atendimentoProfissional: {
  medicoes?: MedicaoModel[]
  atendimentoProfissionalPreNatal?: AtendimentoProfissionalPreNatalModel
}): PreNatalViewModel => {
  const medicaoDesteAtendimento = atendimentoProfissional?.medicoes?.find((medicao) => !medicao.medicaoAnterior)

  return {
    id: atendimentoProfissional?.atendimentoProfissionalPreNatal?.preNatal.id,
    alturaUterina: medicaoDesteAtendimento?.valorAlturaUterina,
    batimentoCardiacoFetal: medicaoDesteAtendimento?.valorBatimentoCardiacoFetal,
    edema: atendimentoProfissional?.atendimentoProfissionalPreNatal?.tipoEdema,
    gravidezPlanejada: atendimentoProfissional?.atendimentoProfissionalPreNatal?.gravidezPlanejada,
    movimentacaoFetal: atendimentoProfissional?.atendimentoProfissionalPreNatal?.movimentacaoFetal,
    tipoGravidez: atendimentoProfissional?.atendimentoProfissionalPreNatal?.preNatal?.tipoGravidez?.tipoGravidez,
    isAltoRisco: atendimentoProfissional?.atendimentoProfissionalPreNatal?.preNatal?.altoRisco,
  }
}

export const initialValueObservacao = (
  prevValue: Partial<SoapState>,
  dataReferencia: Instant,
  tipoAtendimento: TipoAtendimentoProfissional,
  data: SoapEditableModules
): Partial<SoapState> => {
  const {
    evolucaoSubjetivo,
    evolucaoObjetivo,
    evolucaoAvaliacao,
    evolucaoPlano,
    finalizacao,
    evolucoesProblema,
    resultadosExames,
    solicitacoesExames,
    orientacoes,
    receitasMedicamento,
    encaminhamentos,
    encaminhamentosEspecializados,
    atestados,
    marcadorConsumoAlimentar,
  } = data

  return {
    ...prevValue,
    subjetivo: convertSubjetivoQueryToForm(prevValue.subjetivo, evolucaoSubjetivo),
    objetivo: convertObjetivoQueryToForm(prevValue.objetivo, tipoAtendimento, {
      evolucaoObjetivo,
      resultadosExames,
      marcadorConsumoAlimentar,
    }),
    avaliacao: convertAvaliacaoQueryToForm(prevValue.avaliacao, {
      evolucaoAvaliacao,
      evolucoesProblema,
    }),
    plano: convertPlanoQueryToForm(prevValue.plano, {
      evolucaoPlano,
      solicitacoesExames,
      orientacoes,
      receitasMedicamento,
      encaminhamentos,
      encaminhamentosEspecializados,
      atestados,
      dataReferencia,
    }),
    finalizacao: convertFinalizacaoQueryToForm(prevValue.finalizacao, finalizacao),
  }
}
