import { Omit } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { SideMenuItemConfig } from 'components/layout/SideMenu/SideMenu'
import { getSideMenuItems, SideMenuConfiguration } from 'components/layout/SideMenu/SideMenuItems'
import { FlagsContextModel, useFlags } from 'config/useFlagsContext'
import { partition } from 'lodash'
import React from 'react'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { SelectField, SelectFieldProps } from '../../final-form/SelectField'

type ItemType = Required<Pick<SideMenuItemConfig, 'title' | 'to' | 'permission' | 'acceptEstagio'>>

export interface ModuloSelectFieldProps extends Omit<SelectFieldProps<ItemType>, 'items' | 'itemToString'> {}

export const ModuloSelectField = (props: ModuloSelectFieldProps) => {
  const { hasAuthorization, isEstagio } = useSession()
  const flags = useFlags()
  const config = useVideochamadasConfiguration()
  const authorizedItems: ItemType[] = getItems(flags, config).filter(
    (item) => hasAuthorization(item.permission) && (!isEstagio || item.acceptEstagio)
  )

  const itemToString = (item: ItemType) => item && item.title

  return <SelectField<ItemType> clearable={false} items={authorizedItems} itemToString={itemToString} {...props} />
}

export const createItems = (menuItems: SideMenuItemConfig[]): ItemType[] => {
  const createdItems: ItemType[] = []

  const [itemsWithoutChildren, itemsWithChildren] = partition(menuItems, (item) => !item.children)

  itemsWithoutChildren.forEach((item) => {
    createdItems.push({
      title: item.title,
      permission: item.permission,
      to: item.to,
      acceptEstagio: item.acceptEstagio ?? true,
    })
  })

  itemsWithChildren.forEach((item) => {
    item.children.forEach((child) => {
      const groupName = child.group
      child.items.forEach((innerItem) => {
        if (innerItem) {
          const title = groupName ? `${item.title}/${groupName}/${innerItem.title}` : `${item.title}/${innerItem.title}`
          createdItems.push({
            title,
            permission: innerItem.permission,
            to: innerItem.to,
            acceptEstagio: innerItem.acceptEstagio ?? true,
          })
        }
      })
    })
  })

  return createdItems
}

export const getItems = (flags: FlagsContextModel, config: SideMenuConfiguration) => {
  return createItems(getSideMenuItems(flags, config))
}
