/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Text, Theme } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import { useEncerrarVideochamadaMutation } from 'graphql/hooks.generated'
import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload'
import { Fragment, useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { AcaoVideochamadaButton } from './AcaoVideochamadaButton'
import { CompartilharChamadaModal } from './CompartilharChamadaModal'

type VideoChamadaFooterProps = {
  isOwner: boolean
  audioEnabled: boolean
  audioDeviceAvailable: boolean
  setAudioEnabled: (newVal: boolean) => void
  videoEnabled: boolean
  videoDeviceAvailable: boolean
  setVideoEnabled: (newVal: boolean) => void
  videochamadaId: string
}

export function VideoChamadaFooter(props: VideoChamadaFooterProps) {
  const {
    isOwner,
    audioDeviceAvailable,
    audioEnabled,
    setAudioEnabled,
    videoDeviceAvailable,
    videoEnabled,
    setVideoEnabled,
    videochamadaId,
  } = props

  const styles = createStyles(theme)

  const history = useHistory()
  const match = useRouteMatch()
  const handleRejection = useErrorHandler()
  const [encerrarVideoChamada] = useEncerrarVideochamadaMutation()

  const [isCompartilharChamadaModalOpen, setCompartilharChamadaModalOpen] = useState(false)

  const sairVideochamada = () => history.push(`${match.url}/sair`)

  const encerrarVideochamadaConfirm = useCallback(
    () =>
      confirm({
        title: `Deseja encerrar chamada?`,
        body: 'A chamada será encerrada para todos os participantes.',
        type: 'danger',
        confirmLabel: 'Encerrar Videochamada',
        cancelLabel: 'Voltar',
        onConfirm: () =>
          encerrarVideoChamada({ variables: { id: videochamadaId } })
            .then(() => {
              history.push('/videochamada/encerrada?owner=true')
            })
            .catch(handleRejection),
      })(),
    [encerrarVideoChamada, videochamadaId, handleRejection, history]
  )

  const handleBeforeUnload = useCallback(() => {
    encerrarVideoChamada({ variables: { id: videochamadaId } }).catch(handleRejection)
  }, [encerrarVideoChamada, handleRejection, videochamadaId])
  useOnBeforeUnload(handleBeforeUnload)

  return (
    <Fragment>
      <CompartilharChamadaModal
        open={isCompartilharChamadaModalOpen}
        onClose={() => setCompartilharChamadaModalOpen(false)}
      />
      <div css={styles.container}>
        <HFlow
          justifyContent='flex-start'
          style={css`
            flex: 5;
          `}
        >
          <Button onClick={() => setCompartilharChamadaModalOpen(true)} kind='primary'>
            <Icon
              icon='link'
              style={css`
                margin-right: 0.5rem;
              `}
            />
            Compartilhar chamada
          </Button>
        </HFlow>
        <HFlow
          justifyContent='center'
          alignItems='center'
          style={css`
            flex: 2;
          `}
        >
          <AcaoVideochamadaButton
            unavailable={!audioDeviceAvailable}
            enabled={audioDeviceAvailable && audioEnabled}
            onClick={() => setAudioEnabled(!audioEnabled)}
            icon='mic'
          />
          <AcaoVideochamadaButton
            unavailable={!videoDeviceAvailable}
            enabled={videoDeviceAvailable && videoEnabled}
            onClick={() => setVideoEnabled(!videoEnabled)}
            icon='video'
          />
        </HFlow>
        <HFlow
          justifyContent='flex-end'
          style={css`
            flex: 5;
          `}
        >
          <Text style={styles.infoText}>Ao apertar, o anfitrião via PEC encerra a chamada para todos.</Text>
          <Button
            kind='danger'
            size='small'
            style={styles.closeButton}
            onClick={isOwner ? encerrarVideochamadaConfirm : sairVideochamada}
          >
            <Icon icon='phoneFilled' color='white' style={styles.closeButtonIcon} />
            Encerrar chamada
          </Button>
        </HFlow>
      </div>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    flex: 1;
    max-width: 90.375rem;
    display: flex;
    justify-content: space-between;
  `,
  infoText: css`
    color: ${theme.pallete.gray.c100};
    font-size: ${theme.typography.sizes.button};
    width: 12.5rem;
    text-align: end;
    align-self: center;
    margin-right: 1rem;
  `,
  closeButton: css`
    padding: 0.875rem 1rem;
  `,
  closeButtonIcon: css`
    margin-right: 0.625rem;
  `,
})
