/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import { CheckboxField, TextField } from 'components/form'
import { ListaEsperaProblemaCondicaoEnum } from 'graphql/types.generated'
import { useCallback } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { problemaCondicaoRecord } from '../model'

export interface ProblemasCondicoesCheckFieldModel {
  problemaCondicao: ListaEsperaProblemaCondicaoEnum[]
  descricaoOutro?: string
}

interface ProblemasCondicoesCheckFieldProps {
  name: MetaPath<ProblemasCondicoesCheckFieldModel>
}

export function ProblemasCondicoesCheckField(props: ProblemasCondicoesCheckFieldProps) {
  const { name } = props

  const {
    input: { value, onChange },
  } = useField<ProblemasCondicoesCheckFieldModel>(name.absolutePath())
  const handleClearDescricao = useCallback(() => onChange({ ...value, descricao: null }), [value, onChange])

  return (
    <VFlow vSpacing={0.5}>
      <Text fontWeight='bold'>Problemas ou condições</Text>
      <div css={styles.container}>
        {Object.keys(ListaEsperaProblemaCondicaoEnum).map((key) => {
          const isFieldOutros = key === ListaEsperaProblemaCondicaoEnum.OUTROS
          return (
            <HFlow key={key}>
              <CheckboxField
                name={name.problemaCondicao}
                value={key}
                label={problemaCondicaoRecord[key]}
                style={styles.checkBox}
                onClick={isFieldOutros && handleClearDescricao}
              />
              {isFieldOutros && (
                <TextField
                  name={name.descricaoOutro}
                  disabled={!value.problemaCondicao?.includes(ListaEsperaProblemaCondicaoEnum.OUTROS)}
                  placeholder='Descrição'
                />
              )}
            </HFlow>
          )
        })}
      </div>
    </VFlow>
  )
}

const styles = {
  container: css`
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-auto-flow: row;
    grid-gap: 0 0.5rem;
    align-items: center;
  `,
  checkBox: css`
    padding: 0.25rem 0;
  `,
}
