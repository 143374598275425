/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, FormRenderProps, SubmitButton } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectField } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { metaPath } from 'util/metaPath'
import { ImunobiologicoSelectField } from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'

import { BuscaAtivaVacinacaoFilterPopperFormModel } from '../model'

export const meta = metaPath<BuscaAtivaVacinacaoFilterPopperFormModel>()

interface BuscaAtivaVacinacaoFilterPopperProps {
  isTipoVisualizacaoCovid: boolean
  initialValues?: BuscaAtivaVacinacaoFilterPopperFormModel
  closeFilterPopper(): void
  clearFilterPopper(): void
  applyFilterPopper(BuscaAtivaVacinacaoFilterPopperFormModel): void
}

export default function BuscaAtivaVacinacaoFilterPopper({
  isTipoVisualizacaoCovid,
  initialValues,
  closeFilterPopper,
  clearFilterPopper,
  applyFilterPopper,
}: BuscaAtivaVacinacaoFilterPopperProps) {
  const handleApplyFilters = (formValues: BuscaAtivaVacinacaoFilterPopperFormModel) => applyFilterPopper(formValues)

  const renderForm = ({ form, handleSubmit }: FormRenderProps<BuscaAtivaVacinacaoFilterPopperFormModel>) => (
    <Box
      style={css`
        width: 100%;
        max-width: 24rem;
      `}
    >
      <Grid>
        <Cell size={12}>
          <ImunobiologicoSelectField
            name={meta.imunobiologicos}
            label='Imunobiológico'
            placeholder='Todos os imunobiológicos'
            semRegras
            onlyCalendarioVacinal={!isTipoVisualizacaoCovid}
            isOnlyCovid={isTipoVisualizacaoCovid}
            multiple
          />
        </Cell>
        <Cell size={12}>
          <DoseImunobiologicoVacinacaoSelectField
            name={meta.doses}
            label='Dose'
            placeholder='Todas as doses'
            onlyCalendarioVacinal={!isTipoVisualizacaoCovid}
            multiple
            semRegras
          />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='small' onClick={closeFilterPopper}>
              Fechar
            </Button>
            <Button
              onClick={() => {
                form.reset()
                clearFilterPopper()
              }}
              size='small'
            >
              Limpar filtros
            </Button>
            <SubmitButton kind='primary' size='small' handleSubmit={handleSubmit}>
              Aplicar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    </Box>
  )

  return (
    <Form<BuscaAtivaVacinacaoFilterPopperFormModel>
      render={renderForm}
      onSubmit={(values) => handleApplyFilters(values)}
      initialValues={initialValues}
    />
  )
}
