/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps, NumberField, SubmitButton, TextAreaField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { useCidadaoQuery, useRemoverCidadaoListaEsperaMutation } from 'graphql/hooks.generated'
import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { metaPath } from 'util/metaPath'

import { MotivoSaidaRadioGroupField } from '../components/MotivoSaidaRadioGroupField'
import { ListaEsperaRemocaoModel } from '../model'
import { convertModelToInput } from './convert'
import { removerCidadaoListaEsperaValidator } from './validator'

const name = metaPath<ListaEsperaRemocaoModel>()

interface UrlParams {
  listaEsperaId: ID
  cidadaoId: ID
}

export default function ListaEsperaRemoverCidadaoModalForm() {
  const { listaEsperaId, cidadaoId } = useParams<UrlParams>()
  const history = useHistory()
  const errorHandler = useErrorHandler()
  const [remove] = useRemoverCidadaoListaEsperaMutation()

  const validator = useMemo(removerCidadaoListaEsperaValidator, [])

  const { data } = useCidadaoQuery({
    fetchPolicy: 'cache-first',
    variables: { id: cidadaoId },
  })

  if (!data?.cidadao) {
    return null
  }

  const goBack = () => history.push('/garantia-acesso')

  const handleSubmit = (values: ListaEsperaRemocaoModel) => {
    const valuesInput = { ...values, listaEsperaId }
    return remove({ variables: { input: { ...convertModelToInput(valuesInput) } } })
      .then(goBack)
      .catch(errorHandler)
  }

  const renderForm = (formProps: FormRenderProps<ListaEsperaRemocaoModel>) => {
    const values = formProps.values
    const cidadaoNaoRespondeu = values.motivoSaida === ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO

    return (
      <Modal onClose={goBack} open style={styles.modal}>
        <ModalBody>
          <VFlow>
            <Heading level={1}>Remover cidadão da lista</Heading>
            <Grid>
              <Cell size={12}>
                <Heading level={3}>{(data.cidadao.nomeSocial ?? data.cidadao.nome).titleCase()}</Heading>
                <VFlow vSpacing={0}>
                  <HFlow>
                    <HLabel title='CPF'>
                      <Cpf value={data.cidadao.cpf} />
                    </HLabel>
                    <HLabel title='CNS'>{data.cidadao.cns}</HLabel>
                  </HFlow>
                </VFlow>
              </Cell>
              <Cell size={12}>
                <MotivoSaidaRadioGroupField name={name.motivoSaida} label='Motivo da remoção' required />
              </Cell>
              <Cell size={6}>
                <NumberField
                  name={name.qtdTentativasContato}
                  label='Número de tentativas de contato'
                  title='Número de tentativas de contato'
                  disabled={!cidadaoNaoRespondeu}
                  required={cidadaoNaoRespondeu}
                  maxLength={4}
                />
              </Cell>
              <Cell size={12}>
                <TextAreaField
                  name={name.observacoes}
                  required={values.motivoSaida === ListaEsperaMotivoSaidaEnum.OUTROS}
                  label='Observações'
                  maxLength={200}
                  style={styles.observacao}
                  title='Observações'
                />
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack} title='Cancelar' size='medium' style={styles.button}>
              Cancelar
            </Button>
            <SubmitButton
              title='Salvar'
              size='medium'
              kind='primary'
              handleSubmit={formProps.handleSubmit}
              style={styles.button}
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<ListaEsperaRemocaoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      onSubmitFailed={() => {}}
      validate={validator}
    />
  )
}

const styles = {
  modal: css`
    height: 37rem;
    width: 37rem;
  `,
  observacao: css`
    height: 3.375rem;
    resize: none;
  `,
  button: css`
    height: 3rem;
    width: 9rem;
  `,
}
