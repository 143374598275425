import { DateRange } from 'bold-ui'
import { subDays, subYears } from 'date-fns'
import { HistoricoFiltrosAvancadosQueryInput, HistoricoQueryInput } from 'graphql/types.generated'
import { ExibirPorHistoricoAtendimentoFilterEnum } from 'types/enums'

import { HistoricoAtendimentoFilterFormModel } from '../../model/historicoFilterFormModel'

export const convertToInput = (filtros: HistoricoAtendimentoFilterFormModel, dataAtendimento: Date) => {
  return {
    pageParams: filtros.pageParams,
    cidadaoId: filtros.cidadaoId,
    cidadaoCpf: filtros.cidadaoCpf,
    profissionalCpf: filtros.somenteMeusAtendimentos ? filtros.profissionalCpf : null,
    somenteMeusAtendimentos: !!filtros.somenteMeusAtendimentos,
    tiposAtendimento: filtros.tiposAtendimento,
    unidadeSaudeCnes: filtros.unidadeSaudeCnes,
    equipeIne: filtros.equipeIne,
    sortDirection: filtros?.sortDirection,
    filtrosAvancados: {
      cod2002Cbos: filtros?.filtrosAvancados?.cbos?.map((item) => item.cbo2002),
      ineEquipes: filtros?.filtrosAvancados?.equipes?.map((item) => item.ine),
      cpfProfissionais: filtros?.filtrosAvancados?.profissionais?.map((item) => item.cpf),
      periodo: convertPeriodoExibicaoToPeriodo(
        dataAtendimento,
        filtros.periodoExibicao,
        filtros?.filtrosAvancados?.periodo
      ),
      codCiaps2: filtros?.filtrosAvancados?.ciaps2?.map((item) => item.codigo),
      codCids10: filtros?.filtrosAvancados?.cids10?.map((item) => item.codigo),
      subtiposAtendimento: filtros?.filtrosAvancados?.subtiposAtendimento,
    } as HistoricoFiltrosAvancadosQueryInput,
  } as HistoricoQueryInput
}

const convertPeriodoExibicaoToPeriodo = (
  dataAtendimento: Date,
  periodoExibicao: ExibirPorHistoricoAtendimentoFilterEnum,
  periodoPersonalizado?: DateRange
): DateRange => {
  const today = dataAtendimento
  switch (periodoExibicao) {
    case ExibirPorHistoricoAtendimentoFilterEnum.ULTIMOS_TRINTA_DIAS:
      return { startDate: subDays(today, 30), endDate: today } as DateRange
    case ExibirPorHistoricoAtendimentoFilterEnum.ULTIMOS_SESSENTA_DIAS:
      return { startDate: subDays(today, 60), endDate: today } as DateRange
    case ExibirPorHistoricoAtendimentoFilterEnum.ULTIMOS_NOVENTA_DIAS:
      return { startDate: subDays(today, 90), endDate: today } as DateRange
    case ExibirPorHistoricoAtendimentoFilterEnum.ULTIMO_ANO:
      return { startDate: subYears(today, 1), endDate: today } as DateRange
    case ExibirPorHistoricoAtendimentoFilterEnum.PERSONALIZADO:
      return periodoPersonalizado
    default:
      return null
  }
}
