import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import {
  CidadaoAtendimentoSelectField,
  DateField,
  Form,
  FormRenderProps,
  LocalAtendimentoSelectField,
  SubmitButton,
  TimeField,
} from 'components/form'
import { RegistroTardioJustificativaSelectField } from 'components/form/field/select/JustificativaRegistroTardioSelectField/RegistroTardioJustificativaSelectField'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/lotacaoAndEstagioField/LotacaoAndEstagioSelectField'
import { subDays } from 'date-fns'
import { FormApi } from 'final-form'
import { useCidadaoQuery, useSalvarRegistroTardioMutation } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'
import { REGISTRO_TARDIO_DEFAULT_DATE_DIFF } from 'view/registro-tardio/filter/useListaRegistroTardioFilterDefault'
import { REGISTRO_TARDIO_PATH } from 'view/registro-tardio/RegistroTardioRootView'

import { calculator } from './calculator'
import { DayDelimiterTooltip } from './components/DayDelimiterTooltip'
import { convertModelToInput } from './converter'
import { RegistroTardioFormModel } from './model'
import { isLocalAtendimentoUbs, validator } from './validator'

const path = metaPath<RegistroTardioFormModel>()

export interface ListaRegistroTardioFormProps {
  serverTime: Date
  cidadaoId?: ID
}

export default function ListaRegistroTardioForm(props: ListaRegistroTardioFormProps) {
  const { serverTime, cidadaoId } = props

  const alert = useAlert()
  const history = useHistory()
  const handleRejection = useErrorHandler()
  const {
    data: { acesso, profissional },
  } = useSession()
  const { hasAuthorization, acesso: acessoLotacaoOrEstagio } = useAcessoLotacaoOrEstagio()

  const canAtender = hasAuthorization(Permissions.visualizarListaDeAtendimento.registrarAtendimento)

  const [salvarRegistroTardio] = useSalvarRegistroTardioMutation()

  const { data: cidadaoData } = useCidadaoQuery({
    fetchPolicy: 'cache-first',
    variables: { id: cidadaoId },
    skip: !cidadaoId,
  })
  const minDate = subDays(serverTime, REGISTRO_TARDIO_DEFAULT_DATE_DIFF)

  const lotacao = useMemo(
    () => (canAtender ? ({ ...acesso, profissional: { ...profissional } } as LotacaoAndEstagioSelectFieldModel) : null),
    [acesso, canAtender, profissional]
  )

  const initialValues: RegistroTardioFormModel = useMemo(
    () => ({
      cidadao: cidadaoId && cidadaoData?.cidadao && { ...cidadaoData.cidadao, presenteListaAtendimento: false },
      lotacao,
    }),
    [cidadaoData, cidadaoId, lotacao]
  )

  const handleSubmit = (values: RegistroTardioFormModel, formApi: FormApi) =>
    salvarRegistroTardio({
      variables: { input: convertModelToInput(values) },
    })
      .then((res) => {
        alert('success', `Cidadão foi adicionado com sucesso.`)
        formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
        setTimeout(formApi.reset)
        history.push(REGISTRO_TARDIO_PATH)
        return res
      })
      .catch(handleRejection)

  const renderForm = (formProps: FormRenderProps<RegistroTardioFormModel>) => {
    const { values } = formProps

    const justificativaDisabled = !values.localAtendimento || !isLocalAtendimentoUbs(values.localAtendimento.id)
    return (
      <Grid>
        <Cell size={4}>
          <CidadaoAtendimentoSelectField
            name={path.cidadao}
            label='Cidadão'
            required
            ativo
            obito={false}
            verificarPresenteNaListaAtendimento={false}
            addCidadaoCallbackUrl={REGISTRO_TARDIO_PATH}
          />
        </Cell>
        {!canAtender && (
          <Cell size={4}>
            <LotacaoAndEstagioSelectField
              name={path.lotacao}
              label='Profissional'
              unidadeSaudeId={acessoLotacaoOrEstagio?.unidadeSaude.id}
              canWriteRegistroTardio
              required
            />
          </Cell>
        )}
        <Cell size={2}>
          <DateField
            name={path.dataAtendimento}
            label='Data do Atendimento'
            required
            minDate={minDate}
            maxDate={serverTime}
            calendarProps={{
              renderDay: (day: Date) => <DayDelimiterTooltip day={day} minDate={minDate} maxDate={serverTime} />,
            }}
          />
        </Cell>
        <Cell size={2}>
          <TimeField name={path.horaAtendimento} label='Hora' required placeholder='hh:mm' />
        </Cell>
        {canAtender && <Cell size={2} />}
        <Cell size={4}>
          <LocalAtendimentoSelectField name={path.localAtendimento} required />
        </Cell>
        <Cell size={4}>
          <RegistroTardioJustificativaSelectField
            name={path.justificativa}
            label='Justificativa'
            required={!justificativaDisabled}
            disabled={justificativaDisabled}
          />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='medium' onClick={formProps.form.reset}>
              Limpar campos
            </Button>
            <SubmitButton size='medium' handleSubmit={formProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  const validate = useMemo(() => validator(minDate, canAtender), [canAtender, minDate])

  return (
    <Form<RegistroTardioFormModel>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
      validate={validate}
      decorators={[calculator]}
    />
  )
}
