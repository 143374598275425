/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { forwardRef } from 'react'

import placeholderImage from '../images/placeholder.png'
import { RemoteStreamPlayerVideochamadaProps } from './StreamPlayerVideochamada'

export const RemoteStreamPlayerVideochamada = forwardRef<HTMLVideoElement, RemoteStreamPlayerVideochamadaProps>(
  (props, ref) => {
    const { streamAtivo = false, videoEnabled = false, nomeParticipante } = props

    return (
      streamAtivo && (
        <div css={styles.cam}>
          {!videoEnabled && (
            <img src={placeholderImage} css={styles.video} alt='Imagem padrão para vídeo desabilitado' />
            // TODO@RNG imagem de videochamada padrão oficial será colocada na #14481
          )}
          <video
            autoPlay
            ref={ref}
            css={
              videoEnabled
                ? styles.video
                : css`
                    visibility: hidden;
                  `
            }
            playsInline
          />
          <Text component='p' style={styles.nome}>
            {nomeParticipante}
          </Text>
        </div>
      )
    )
  }
)

const styles = {
  cam: css`
    display: flex;
    justify-content: center;
    border-radius: 0.625rem;
    overflow: hidden;
    width: max(80vw, 1012px);
    padding-top: min(56.25%, 80vh);
    height: 0;
    position: relative;
    max-width: 90.375rem;
  `,
  video: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  `,
  nome: css`
    color: white;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    bottom: max(2%, 10px);
    left: 1rem;
  `,
}
