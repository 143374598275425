import { PageParams } from 'api/schema'
import { ListaAtendimentoQuery, StatusAtendimento, StatusRevisaoAtendimento } from 'graphql/types.generated'

import { ListaAtendimentoFilterModel } from './filter/ListaAtendimentoFilter'

export const DIFF_DAYS_SOMENTE_NAO_FINALIZADOS = 7

export const STATUS_SOMENTE_NAO_FINALIZADOS = [
  StatusAtendimento.AGUARDANDO_ATENDIMENTO,
  StatusAtendimento.EM_ATENDIMENTO,
  StatusAtendimento.EM_ESCUTA_INICIAL,
  StatusAtendimento.AGUARDANDO_OBSERVACAO,
  StatusAtendimento.EM_OBSERVACAO,
]

export const statusRevisaoAtendimentoDescricaoRecord: Record<StatusRevisaoAtendimento, { descricao: string }> = {
  AGUARDANDO_REVISAO: { descricao: 'Aguardando revisão' },
  EM_REVISAO: { descricao: 'Em revisão' },
  EM_RETIFICACAO: { descricao: 'Em retificação' },
  AGUARDANDO_RETIFICACAO: { descricao: 'Aguardando retificação' },
}

export const OBSERVACAO_STATUS_TO_TOGGLE = [
  [StatusAtendimento.AGUARDANDO_ATENDIMENTO, StatusAtendimento.AGUARDANDO_OBSERVACAO],
  [StatusAtendimento.EM_ATENDIMENTO, StatusAtendimento.EM_OBSERVACAO],
  [StatusAtendimento.NAO_AGUARDOU, StatusAtendimento.EVADIU_OBSERVACAO],
]

export type AtendimentoModel = ListaAtendimentoQuery['atendimentos']['content'][0]

export type AtendimentosNaoFinalizadosPeriodo = {
  inicio: Date
  fim: Date
}

export interface AtendimentosFilterModel {
  filter: ListaAtendimentoFilterModel
  pageParams: PageParams
  lastUpdate: Date
  lastDailyUpdate: Date
}
