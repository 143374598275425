import atmosphere from 'atmosphere.js'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import useAtmosphereTransportCache from './useAtmosphereTransportCache'

interface AtmosphereVariables<T> {
  topic: string
  onMessage?(body: T, response, push: (message: T) => void): any
}

const url = `${window.location.protocol}//${window.location.host}/atmosphere/`

export default <T>({ topic, onMessage }: AtmosphereVariables<T>) => {
  const {
    currentTransport,
    lastSuccessfulTransport,
    setCurrentTransport,
    setlastSuccessfulTransport,
  } = useAtmosphereTransportCache()

  let connected = useRef(false)

  const disconnect = useCallback(() => {
    connected.current = false
    atmosphere.unsubscribeUrl(url + topic)
  }, [topic])

  const config = useMemo(() => {
    const config = {
      url: url + topic,
      transport: lastSuccessfulTransport ?? currentTransport,
      fallbackTransport: undefined,
      contentType: 'application/json; charset=UTF-8',
      trackMessageLength: true,
      connectTimeout: 5000,
      reconnectInterval: 500,
      onOpen: () => {
        setlastSuccessfulTransport(currentTransport)
        connected.current = true
      },
      onClose: () => (connected.current = false),
      onReconnect: () => (connected.current = true),
      onTransportFailure: () => setCurrentTransport('long-polling'),
    }
    return config
  }, [currentTransport, lastSuccessfulTransport, setCurrentTransport, setlastSuccessfulTransport, topic])

  const subsocket = useMemo(() => {
    disconnect()
    return atmosphere.subscribe(config)
  }, [config, disconnect])

  const push = useCallback(
    (message: T) => {
      subsocket.push(JSON.stringify(message))
    },
    [subsocket]
  )

  useEffect(() => {
    subsocket.request.onMessage = (request) =>
      connected && onMessage && request.responseBody && onMessage(JSON.parse(request.responseBody), request, push)
  }, [connected, onMessage, push, subsocket.request])

  // called on destroy component
  useEffect(() => () => disconnect(), [disconnect])

  return {
    connected: connected.current,
    push,
  }
}
