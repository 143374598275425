/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, FormControl, VFlow } from 'bold-ui'
import { TextAreaField } from 'components/form'
import {
  ProcedimentoOdontoSelectField,
  ProcedimentoOdontoSelectModel,
} from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { resolveName } from 'components/form/final-form/useField'
import { usePartesBucaisQuery } from 'graphql/hooks.generated'
import { ParteBucalEnum, SituacaoFaceEnum, SituacaoRaizEnum, TipoParteBucalEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useField } from 'react-final-form'
import { emptyArray } from 'util/array'
import { metaPath } from 'util/metaPath'

import { MAX_LENGTH_OBS_ODONTO } from '../../../model'
import { getDentes, getDentesComDados, getParteBucal, isDenteDeciduo, possuiLesaoDeFurca } from '../../../util'
import CondicoesDenteField from '../components/CondicoesDenteField'
import ReplicarItemSelectField, {
  convertParteBucalEnumToReplicarItem,
  ReplicarItemSelectFieldModel,
} from '../components/ReplicarItemSelectField'
import { condicoesDeLesaoDeFurca } from '../model'
import { DenteFormModel } from './DenteFormPopper'
import { DenteFieldModel } from './DentesField'

const meta = metaPath<DenteFormModel>()

interface DenteFormFieldsProps {
  parteBucalEnum: ParteBucalEnum
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  dentesValues: Record<ParteBucalEnum, DenteFieldModel>
  isProtese: boolean
  isProteseTotalSuperior: boolean
  isProteseTotalInferior: boolean
}

const tiposPartesBucais = [TipoParteBucalEnum.DENTE]

export const DenteFormFields = (props: DenteFormFieldsProps) => {
  const {
    parteBucalEnum,
    cidadaoDataNascimento,
    dataAtendimento,
    dentesValues,
    isProtese,
    isProteseTotalSuperior,
    isProteseTotalInferior,
  } = props

  const {
    input: { value: condicoesSelecionadas },
  } = useField<(SituacaoRaizEnum | SituacaoFaceEnum)[]>(resolveName(meta.condicoesDente), {
    subscription: { value: true },
  })
  const {
    input: { value: procedimentosSelecionados },
  } = useField<ProcedimentoOdontoSelectModel[]>(resolveName(meta.procedimentos), { subscription: { value: true } })
  const {
    input: { value: replicarCondicoes },
  } = useField<ReplicarItemSelectFieldModel[]>(resolveName(meta.replicarCondicoes), { subscription: { value: true } })

  const safeProcedimentosSelecionados = procedimentosSelecionados || emptyArray

  const { data } = usePartesBucaisQuery({
    variables: {
      input: {
        tiposPartesBucais,
        procedimentos: safeProcedimentosSelecionados.map((item) => item.id) ?? [],
        pageParams: { size: 2000 },
      },
    },
    skip: isEmpty(safeProcedimentosSelecionados),
  })

  const allDentesAsReplicarItems = useMemo(
    () => convertParteBucalEnumToReplicarItem(getDentes().filter((item) => item !== parteBucalEnum)),
    [parteBucalEnum]
  )
  const temCondicoes = !!condicoesSelecionadas.length
  const temProcedimentos = !!procedimentosSelecionados?.length

  const itemsReplicarNaoPossuemLesaoDeFurca =
    (replicarCondicoes || []).map((item) => !possuiLesaoDeFurca(item.value) && item.value).filter((i) => !!i) ?? []

  const showLesaoFurcaAlert =
    condicoesDeLesaoDeFurca.some((item) => condicoesSelecionadas.includes(item)) &&
    !!itemsReplicarNaoPossuemLesaoDeFurca?.length

  const lesaoFurcaAlertMessage = `Lesão de furca não será replicada para dente(s) ${itemsReplicarNaoPossuemLesaoDeFurca
    ?.map((item) => getParteBucal(item).number)
    .sort((a, b) => (a > b ? 1 : -1))
    .join(', ')}`

  const isDeciduo = isDenteDeciduo(parteBucalEnum)

  const dentesValidosParaReplicarCondicao = allDentesAsReplicarItems.filter(
    (item) => item.value !== parteBucalEnum && isDenteDeciduo(item.value) === isDeciduo
  )
  const flattedPartesBucais = data?.partesBucais?.content?.map((item) => item.parteBucalEnum)

  const dentesValidosParaReplicarProcedimentos =
    flattedPartesBucais?.filter(
      (item) =>
        flattedPartesBucais.filter((parteBucal) => parteBucal === item).length === safeProcedimentosSelecionados.length
    ) ?? []

  const procedimentosSelecionadosIds = safeProcedimentosSelecionados.map((procedSelecionado) => procedSelecionado.id)

  const dentesComMesmosProcedimentos = Object.entries(dentesValues)
    .filter(([parteBucal, values]) => {
      const procedIds = values.procedimentos?.map((proced) => proced.id) ?? []

      return (
        parteBucal !== parteBucalEnum &&
        procedimentosSelecionadosIds.every((procedSelecionadoId) => procedIds.includes(procedSelecionadoId) ?? false)
      )
    })
    .map(([parteBucal]) => parteBucal)

  return (
    <VFlow>
      <CondicoesDenteField name={meta.condicoesDente} parteBucalEnum={parteBucalEnum} protese={isProtese} />

      <VFlow vSpacing={0.5}>
        <ReplicarItemSelectField
          label='Replicar problemas/condições para outros dentes'
          name={meta.replicarCondicoes}
          items={dentesValidosParaReplicarCondicao}
          itemsDisabled={getDentesComDados(dentesValues, isProteseTotalSuperior, isProteseTotalInferior)}
          disabled={!temCondicoes}
        />

        {showLesaoFurcaAlert && (
          <Alert inline type='info' styles={{ wrapper: styles.lesaoFurcaAlertWrapper }}>
            {lesaoFurcaAlertMessage}
          </Alert>
        )}
      </VFlow>

      <ProcedimentoOdontoSelectField
        name={meta.procedimentos}
        label='Procedimentos (Evolução odontológica)'
        multiple
        dataNascimento={cidadaoDataNascimento}
        dataAtendimento={dataAtendimento}
        parteBucal={parteBucalEnum}
      />

      <ReplicarItemSelectField
        label='Replicar procedimentos para outros dentes'
        name={meta.replicarProcedimentos}
        disabledCustomText={(item) =>
          dentesComMesmosProcedimentos.includes(item.value)
            ? 'Já possui este(s) procedimento(s)'
            : 'Procedimento(s) inválido(s) para este dente'
        }
        items={allDentesAsReplicarItems}
        itemIsDisabled={(item) =>
          !dentesValidosParaReplicarProcedimentos?.includes(item.value) ||
          dentesComMesmosProcedimentos.includes(item.value)
        }
        disabled={!temProcedimentos}
      />

      <FormControl label='Observações dos procedimentos'>
        <TextAreaField
          name={meta.observacoes[0]}
          maxLength={MAX_LENGTH_OBS_ODONTO}
          placeholder='Insira informações adicionais sobre os procedimentos realizados'
          style={styles.textArea}
          disabled={!temProcedimentos}
        />
      </FormControl>
    </VFlow>
  )
}

const styles = {
  lesaoFurcaAlertWrapper: css`
    width: 100%;
  `,
  textArea: css`
    resize: vertical;
  `,
}
