import { css } from '@emotion/core'
import { HFlow, Tag, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { ButtonLink } from 'components/route'
import theme from 'config/theme'
import React from 'react'

import UnificacaoCidadaoCardInfo from './UnificacaoBaseCidadaoCardInfo'
import { CidadaoUnificacao } from './UnificacaoBaseModel'

interface UnificacaoBaseCidadaoListItemProps {
  cidadaoUnificacao: CidadaoUnificacao
}

export const UnificacaoBaseCidadaoListItem = (props: UnificacaoBaseCidadaoListItemProps) => {
  const {
    cidadaoUnificacao: {
      unificacaoBase,
      cidadao: { nome, nomeSocial, ativo, faleceu, unificado },
    },
    cidadaoUnificacao,
  } = props

  return (
    <>
      <Box style={styles.list}>
        <HFlow style={{ justifyContent: 'space-between' }}>
          <HFlow hSpacing={1}>
            <VFlow vSpacing={0.25}>
              <HFlow hSpacing={0.5} alignItems='baseline'>
                {nomeSocial ? (
                  <span>
                    <Text fontSize={1} fontWeight='bold'>
                      {nomeSocial.titleCase()}
                    </Text>
                    {nomeSocial && <Text fontWeight='bold'> (Nome social)</Text>}
                    {nomeSocial && <Text>{' | ' + nome.titleCase()}</Text>}
                  </span>
                ) : (
                  <Text fontSize={1} fontWeight='bold'>
                    {nome.titleCase()}
                  </Text>
                )}
                {!ativo && <Tag>Inativo</Tag>}
                {faleceu && <Tag>Óbito</Tag>}
                {unificado && <Tag>Cadastro unificado</Tag>}
                {/*TODO Delorean*/}
                {unificacaoBase && <Tag>Origem de base unificada</Tag>}
              </HFlow>
              <HFlow>
                <UnificacaoCidadaoCardInfo cidadaoUnificacao={cidadaoUnificacao} />
              </HFlow>
            </VFlow>
          </HFlow>
          <HFlow alignItems='flex-end' hSpacing={0.5}>
            <ButtonLink kind='primary' skin='outline' size='small' to={`/unificacaoCadastro/`}>
              Sugerir unificação
            </ButtonLink>
          </HFlow>
        </HFlow>
      </Box>
    </>
  )
}

const styles = {
  list: css`
    border: 1px solid ${theme.pallete.gray.c70};
    text-align: left;
    margin: 10px 0;
  `,
}
