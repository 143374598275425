/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import theme from 'config/theme'
import { useCallback, useState } from 'react'
import { EvolucaoProblema, Problema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'
import { ProblemaCondicaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/model'
import {
  ProblemasCondicoesForm,
  ProblemasCondicoesFormProps,
} from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/ProblemasCondicoesForm'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { MainDetailAccordion } from '../../../main-detail-accordion/MainDetailAccordion'
import { BodyProblemaModal } from '../BodyProblemaModal'
import { HeaderProblemaModal } from '../HeaderProblemaModal'

interface AccordionProblemasLPCProps
  extends Pick<ProblemasCondicoesFormProps, 'problemasCondicoesAvaliados' | 'problemasCondicoesAnteriores'> {
  prontuarioId: ID
  problemas: Problema[]
  problemasCidadao: Problema[]
  dataAtendimento: Instant
  somenteCiap: boolean
  isAntecedentes?: boolean
  cidadao: CidadaoAtendimento
  findFormModel(problemaId: ID): ProblemaCondicaoModel
  onEdit(value: ProblemaCondicaoModel): void
  onRemove(value: ProblemaCondicaoModel): void
}

export const AccordionProblemasLPC = (props: AccordionProblemasLPCProps) => {
  const {
    prontuarioId,
    problemasCidadao,
    problemas,
    dataAtendimento,
    somenteCiap,
    cidadao,
    isAntecedentes,
    findFormModel,
    onEdit,
    onRemove,
    ...formProps
  } = props

  const [problemasEmEdicao, setProblemasEmEdicao] = useState<Set<string>>(new Set<string>())

  const isEditing = useCallback((problemaId: ID) => problemasEmEdicao.has(problemaId), [problemasEmEdicao])

  const { handleAccordionItemClick, isExpanded } = useAccordionControl({ allowMultipleExpanded: true })

  const removeProblemaEmEdicao = (problemaId: ID) => {
    problemasEmEdicao.delete(problemaId)
    setProblemasEmEdicao(new Set<string>(problemasEmEdicao))
    handleAccordionItemClick(problemaId)
  }

  const handleSubmit = (values: ProblemaCondicaoModel) => {
    onEdit(values)
    removeProblemaEmEdicao(values._id)
  }

  const handleEditClick = (problemaId: ID) => {
    if (!isEditing(problemaId)) {
      setProblemasEmEdicao(new Set<string>(problemasEmEdicao.add(problemaId)))
      !isExpanded(problemaId) && handleAccordionItemClick(problemaId)
    }
  }

  const renderFormBody = (item: Problema) => (
    <div
      css={css`
        margin: 0.5rem 1rem;
      `}
    >
      <ProblemasCondicoesForm
        edicao
        isLPC
        isAntecedentes={isAntecedentes}
        initialValues={findFormModel(item.id)}
        prontuarioId={prontuarioId}
        dataAtendimento={dataAtendimento}
        problemasAtivosLatentes={problemasCidadao}
        style={{ dataIdadeField: styles.dataIdadeField }}
        onSubmit={handleSubmit}
        onCancel={() => removeProblemaEmEdicao(item.id)}
        initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
        {...formProps}
      />
    </div>
  )

  return (
    <MainDetailAccordion<EvolucaoProblema, Problema>
      items={problemas}
      renderHeader={(renderProps) => (
        <HeaderProblemaModal
          isAntecedentes={isAntecedentes}
          onEdit={handleEditClick}
          onRemove={onRemove}
          showActions
          {...renderProps}
        />
      )}
      renderDetailBody={BodyProblemaModal}
      formProps={{ isEditing, renderFormBody }}
      onHeaderClick={handleAccordionItemClick}
      isExpanded={isExpanded}
      isHeaderDisabled={isEditing}
    />
  )
}

const styles = {
  dataIdadeField: {
    hFlow: css`
      width: 100%;
      padding: 0.5rem;
      ${theme.breakpoints.down('lg')} {
        grid-gap: 0.25rem;
      }
    `,
    dateField: css`
      min-width: 8.5rem;
    `,
    idadeField: css`
      min-width: 10.25rem;
    `,
  },
}
