import { DateRange, MonthRangePicker } from 'bold-ui'
import { MonthRangePickerProps, ReferenceMonthRange } from 'bold-ui/lib/components/MonthRangePicker/MonthRangePicker'
import React from 'react'

import { UseFieldProps, usePecField } from '../final-form/useField'
import { getFieldError } from '../final-form/util'

export type MonthRangeFieldProps = UseFieldProps<DateRange> & Omit<MonthRangePickerProps, 'name'>

export default function MonthRangeField(props: MonthRangeFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  return <MonthRangePicker error={getFieldError(meta)} {...input} {...rest} value={toMonthRange(input.value)} />
}

function toMonthRange(range: DateRange): ReferenceMonthRange {
  let start = null
  let end = null
  if (range.startDate != null) {
    start = { month: range.startDate.getMonth(), year: range.startDate.getFullYear() }
  }
  if (range.endDate != null) {
    end = { month: range.endDate.getMonth(), year: range.endDate.getFullYear() }
  }
  return { start, end }
}
