import { alert } from 'components/alert'
import { FORM_ERROR } from 'final-form'
import { ErrorObject } from 'util/validation'

interface HandleErrorProps {
  error: any
  suppressNotificationError?: boolean
  suppressValidationError?: boolean
}

export const handleError = (props: HandleErrorProps) => {
  const { error, suppressNotificationError = false, suppressValidationError: supressValidationError = false } = props

  if (error?.graphQLErrors) {
    const firstError = error.graphQLErrors[0]
    if (!supressValidationError && isValidationError(firstError)) {
      return handleValidationError(firstError)
    }

    error.graphQLErrors.forEach((graphqlError) => onGraphQLError(graphqlError, suppressNotificationError))
  }

  if (error?.networkError) {
    onNetworkError(error.networkError)
  }

  if (!error?.graphQLErrors && !error?.networkError) {
    onGenericError(error)
  }
}

const handleValidationError = (error: any) => {
  const validationError = getValidationError(error)

  if (typeof validationError === 'string') {
    return { [FORM_ERROR]: validationError }
  } else {
    return validationError
  }
}

export const isValidationError = (error: any) => {
  return !!error?.extensions?.validationError
}

export const getValidationError = (graphQLError: any): ErrorObject<any> | string | null => {
  return graphQLError.extensions.validationError
}

/**
 * itera sobre o validationError e, caso haja mais de um erro, retorna msg default
 * (Existem campos preenchidos de forma incorreta.).
 * Se houver apenas um erro retorna a mensagem recebida do validator
 */
export const getValidationErrorMessage = (graphQLError: any): string | object | null => {
  let validationError = getValidationError(graphQLError)

  while (typeof validationError !== 'string') {
    if (Object.keys(validationError).length === 1) {
      validationError = Object.values(validationError)[0]
    } else {
      return graphQLError.message
    }
  }

  return validationError
}

const onGraphQLError = (error: any, suppressNotification: boolean) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[GraphQL error]', error)
  }

  if (!suppressNotification) {
    if (isValidationError(error)) {
      alert('danger', getValidationErrorMessage(error))
    } else if (error.message) {
      alert('danger', error.message)
    } else {
      alert('danger', 'Ocorreu um erro inesperado')
      throw error
    }
  }
}

const onNetworkError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[Network error]', error)
  }

  alert('danger', 'Não foi possível se conectar ao servidor. Cheque sua conexão e tente novamente.')
}

const onGenericError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[Error]', error)
  }

  alert('danger', error?.response?.data ?? error?.message ?? error)
}
