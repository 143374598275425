/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { CepField, Form, FormRenderProps, SubmitButton, TextField, TipoLogradouroSelectField } from 'components/form'
import { SEM_EQUIPE_ITEM } from 'components/form/field/select/EquipeSelectField/EquipeSelectField'
import { Equipe, UnidadeSaude } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { VisualizacaoTerritorialBuscaFormModel } from './model'
import { validateVisualizacaoTerritorialForm } from './validator'

const meta = metaPath<VisualizacaoTerritorialBuscaFormModel>()

interface VisualizacaoTerritorialFormProps {
  onSubmit(formValues: VisualizacaoTerritorialBuscaFormModel): void
  unidadeSaude: UnidadeSaude
  equipe: Equipe
}

export function VisualizacaoTerritorialBuscaForm({ onSubmit, unidadeSaude, equipe }: VisualizacaoTerritorialFormProps) {
  const equipeTerritorio = equipe ?? SEM_EQUIPE_ITEM

  const renderForm = ({ handleSubmit, form }: FormRenderProps<VisualizacaoTerritorialBuscaFormModel>) => {
    return (
      <VFlow vSpacing={4}>
        <Grid wrap>
          <Cell size={4}>
            <VFlow vSpacing={0.2}>
              <Text variant='h4' fontWeight='bold'>
                Unidade responsável
              </Text>
              <Text variant='h4' fontWeight='normal'>
                {unidadeSaude.nome}
              </Text>
            </VFlow>
          </Cell>
          <Cell size={3}>
            <VFlow vSpacing={0.2}>
              <Text variant='h4' fontWeight='bold'>
                Equipe
              </Text>
              <Text variant='h4' fontWeight='normal'>{`${equipeTerritorio?.nome} ${
                equipeTerritorio?.ine !== SEM_EQUIPE_ITEM.ine ? `| ${equipeTerritorio?.ine}` : ''
              }`}</Text>
            </VFlow>
          </Cell>
          <Cell size={5} />
          <Cell size={4}>
            <TextField name={meta.bairro} label='Bairro' maxLength={72} />
          </Cell>
          <Cell size={2}>
            <CepField name={meta.cep} label='CEP' />
          </Cell>
          <Cell size={6} />
          <Cell size={3}>
            <TipoLogradouroSelectField name={meta.tipoLogradouro} label='Tipo de logradouro' />
          </Cell>
          <Cell size={5}>
            <TextField name={meta.logradouro} label='Logradouro' maxLength={72} />
          </Cell>
          <Cell size={1} />
          <Cell size={3}>
            <HFlow hSpacing={0.5}>
              <SubmitButton
                size='small'
                handleSubmit={handleSubmit}
                style={css`
                  margin-top: 1.45rem;
                `}
              >
                <Icon
                  icon='zoomOutline'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Buscar
              </SubmitButton>
              <Button
                onClick={form.reset}
                size='small'
                style={css`
                  margin-top: 1.45rem;
                `}
              >
                Limpar busca
              </Button>
            </HFlow>
          </Cell>
        </Grid>
        <Fragment>
          {equipeTerritorio === SEM_EQUIPE_ITEM ? (
            <Text variant='h2' fontWeight='bold'>
              Sem Equipe
            </Text>
          ) : (
            <HFlow>
              <Text variant='h2' fontWeight='bold'>
                Equipe
              </Text>
              <Text variant='h2' fontWeight='normal'>
                {equipeTerritorio?.nome} | {equipeTerritorio?.ine}
              </Text>
            </HFlow>
          )}
        </Fragment>
      </VFlow>
    )
  }

  const initialValues = useMemo(() => ({ cnes: unidadeSaude.cnes, ine: equipeTerritorio.ine }), [
    equipeTerritorio.ine,
    unidadeSaude.cnes,
  ])

  return (
    <Form<VisualizacaoTerritorialBuscaFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={validateVisualizacaoTerritorialForm}
      initialValues={initialValues}
    />
  )
}
