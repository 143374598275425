import { isPromise } from 'bold-ui'
import { FormApi, FormState } from 'final-form'
import createFocusOnErrorDecorator from 'final-form-focus'
import React, { useCallback, useRef } from 'react'
import {
  AnyObject,
  Form as FinalForm,
  FormProps as FinalFormProps,
  FormRenderProps as FinalFormRenderProps,
} from 'react-final-form'
import { isObjectDeepNullOrUndefined } from 'util/object'

export type FormRenderProps<FormValues = AnyObject> = FinalFormRenderProps<FormValues>

export type ResultType = object | Promise<object | undefined> | undefined | void

export interface FormProps<FormValues> extends FinalFormProps<FormValues> {
  focusOnError?: boolean
  transformResult?(
    result: ResultType,
    supressNotificationError?: boolean,
    suppressValidationError?: boolean
  ): ResultType
  onSubmitSucceeded?(formState: FormState<FormValues>): void
  onSubmitFailed?(formState: FormState<FormValues>): void
  resetFormToInitialValues?: boolean
  suppressNotificationError?: boolean
  suppressValidationError?: boolean
}

const focusOnErrorDecorator = createFocusOnErrorDecorator()

export function Form<FormValues extends object = any>(props: FormProps<FormValues>) {
  const {
    focusOnError,
    transformResult,
    onSubmitFailed,
    onSubmitSucceeded,
    onSubmit,
    render,
    resetFormToInitialValues = false,
    suppressNotificationError = false,
    suppressValidationError = false,
    ...rest
  } = props

  const resetForm = useRef(false)
  resetForm.current = resetFormToInitialValues

  const renderForm = useCallback(
    (formRenderProps: FormRenderProps<FormValues>) => {
      const { form, handleSubmit } = formRenderProps

      if (resetForm.current) {
        setTimeout(() => form.getRegisteredFields().forEach((field) => form.resetFieldState(field)))
        setTimeout(form.reset)
        resetForm.current = false
      }

      const handleSubmitWrapper = (event) => {
        if (!suppressNotificationError && onSubmitFailed && !isObjectDeepNullOrUndefined(form.getState().errors)) {
          setTimeout(() => onSubmitFailed(form.getState()))
        }
        return handleSubmit(event)
      }

      return (
        <>
          {render({
            ...formRenderProps,
            handleSubmit: handleSubmitWrapper,
          })}
        </>
      )
    },
    [onSubmitFailed, render, suppressNotificationError]
  )

  const formOnSubmit = useCallback(
    (values: FormValues, form: FormApi<FormValues>) => {
      const emitSubmitEvents = (submitResult: ResultType, form: FormApi<FormValues>) => {
        if (!submitResult && onSubmitSucceeded) {
          setTimeout(() => onSubmitSucceeded(form.getState()))
        }

        if (submitResult && onSubmitFailed && !suppressNotificationError) {
          setTimeout(() => onSubmitFailed(form.getState()))
        }
      }

      const result = onSubmit(values, form)
      let ret = transformResult(result, suppressNotificationError, suppressValidationError)

      if (isPromise(ret)) {
        ret = ret.then((res) => {
          emitSubmitEvents(res, form)
          return res
        })
      } else {
        emitSubmitEvents(ret, form)
      }

      return ret
    },
    [onSubmit, onSubmitFailed, onSubmitSucceeded, suppressNotificationError, suppressValidationError, transformResult]
  )

  const decorators = props.decorators ?? []
  if (focusOnError) {
    decorators.push(focusOnErrorDecorator)
  }

  return <FinalForm<FormValues> {...rest} onSubmit={formOnSubmit} render={renderForm} decorators={decorators} />
}

Form.defaultProps = {
  focusOnError: true,
  decorators: [],
  transformResult: (result) => result,
} as Partial<FormProps<any>>
