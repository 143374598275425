import { HFlow, Omit, SelectEmptyItem, SelectHelperMenuItem, Tag, useLocale, VFlow } from 'bold-ui'
import { defaultComponents as selectMenuDefaultComponents } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { SelectField, SelectFieldProps } from 'components/form'
import {
  DEFAULT_SELECT_PAGE_PARAM,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LotacoesAgendaSelectFieldDocument } from 'graphql/hooks.generated'
import {
  AcessoCbo,
  LotacoesAgendaSelectFieldQuery,
  LotacoesAgendaSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React, { useCallback } from 'react'

export type LotacaoAgendaSelectFieldModel = LotacoesAgendaSelectFieldQuery['lotacoesAgenda']['content'][0]

export interface LotacaoAgendaSelectFieldProps
  extends Omit<SelectFieldProps<LotacaoAgendaSelectFieldModel>, 'items' | 'itemToString'> {
  loadItemsOnOpen?: boolean
  isAgendaAd: boolean
  somenteCadastrarAgendamento?: boolean
  cbosAcesso?: AcessoCbo[]
}

LotacaoAgendaSelectField.defaultProps = {
  loadItemsOnOpen: true,
} as Partial<LotacaoAgendaSelectFieldProps>

const extractItems = (data: LotacoesAgendaSelectFieldQuery) => data?.lotacoesAgenda.content
const itemToString = (item: LotacaoAgendaSelectFieldModel) => item?.profissional.nome
const renderItem = (item: LotacaoAgendaSelectFieldModel) => (
  <VFlow vSpacing={0}>
    <b>
      {item.profissional.nome} - <Cpf value={item.profissional.cpf} />
    </b>
    <HLabel title='CBO '>{`${item.cbo.nome} - ${item.cbo.cbo2002}`}</HLabel>
    <HLabel title='Equipe '> {(item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'}</HLabel>
    <HFlow>
      {!item.ativo && <Tag>Inativo</Tag>}
      {!item.hasConfiguracaoAgenda && <Tag>Sem agenda</Tag>}
    </HFlow>
  </VFlow>
)

export function LotacaoAgendaSelectField(props: LotacaoAgendaSelectFieldProps) {
  const { loadItemsOnOpen, isAgendaAd, somenteCadastrarAgendamento, cbosAcesso, ...rest } = props

  const locale = useLocale()

  const skip = useCallback((inputString) => !loadItemsOnOpen && inputString?.trim()?.length < 2, [loadItemsOnOpen])
  const variables = useCallback(
    (query: string) => ({
      input: {
        query,
        isAgendaAd,
        somenteCadastrarAgendamento,
        cbosAcesso,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          size: DEFAULT_SELECT_SIZE + 1,
          sort: ['nome'],
        },
      },
    }),
    [cbosAcesso, isAgendaAd, somenteCadastrarAgendamento]
  )

  const { skipping, ...asyncRestProps } = useAsyncQuerySelect<
    LotacaoAgendaSelectFieldModel,
    LotacoesAgendaSelectFieldQuery,
    LotacoesAgendaSelectFieldQueryVariables
  >({
    query: LotacoesAgendaSelectFieldDocument,
    extractItems,
    variables,
    skip,
    debounceTime: 500,
  })

  return (
    <SelectField<LotacaoAgendaSelectFieldModel>
      renderItem={renderItem}
      itemToString={itemToString}
      placeholder='Busque um profissional pelo seu nome, CNS, equipe ou CBO'
      components={{
        Item: ({ index, ...rest }) =>
          index < DEFAULT_SELECT_SIZE ? (
            <selectMenuDefaultComponents.Item index={index} {...rest} />
          ) : (
            <MaisItensSelectMenuItem />
          ),
        EmptyItem: () =>
          !loadItemsOnOpen ? (
            <SelectHelperMenuItem>
              {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
            </SelectHelperMenuItem>
          ) : (
            <SelectEmptyItem />
          ),
      }}
      {...asyncRestProps}
      {...rest}
    />
  )
}

const MaisItensSelectMenuItem = () => (
  <SelectHelperMenuItem
    onClick={(ev) => {
      ev.stopPropagation()
      ev.preventDefault()
    }}
  >
    Mostrando somente os primeiros {DEFAULT_SELECT_SIZE} resultados. Para ver mais resultados refine sua busca.
  </SelectHelperMenuItem>
)
