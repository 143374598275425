import { HFlow, Text } from 'bold-ui'
import { EquipeSelectModel, SelectField, SelectFieldProps } from 'components/form'
import { EquipeSelectFieldDocument } from 'graphql/hooks.generated'
import { EquipeSelectFieldQuery, EquipeSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { capitalize } from 'util/strConversor'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface EquipeSelectFieldProps extends Omit<SelectFieldProps<EquipeSelectModel>, 'items' | 'itemToString'> {
  unidadeSaudeId: ID
  mostrarInativas?: boolean
  showSemEquipeOption?: boolean
}

export const SEM_EQUIPE_ITEM = {
  id: '0',
  nome: 'Sem Equipe',
  ine: '-',
  tipoEquipe: {
    id: '',
    nome: '',
    sigla: '',
  },
}

const itemToString = (option: EquipeSelectModel) => option && option.nome

const renderItem = (item: EquipeSelectModel) => (item.id === '0' ? renderItemSemEquipe() : renderItemComEquipe(item))

const renderItemComEquipe = (item: EquipeSelectModel) => (
  <>
    <Text fontWeight='bold'>
      {capitalize(item.nome)} – {item.tipoEquipe.sigla}
    </Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>INE:</Text>
      {item.ine}
    </HFlow>
  </>
)

const renderItemSemEquipe = () => <Text fontWeight='bold'>Sem Equipe</Text>

export function EquipeSelectField(props: EquipeSelectFieldProps) {
  const { unidadeSaudeId, mostrarInativas, showSemEquipeOption, ...rest } = props

  const asyncProps = useAsyncQuerySelect<EquipeSelectModel, EquipeSelectFieldQuery, EquipeSelectFieldQueryVariables>({
    query: EquipeSelectFieldDocument,
    extractItems: (data) => [
      ...(showSemEquipeOption ? [SEM_EQUIPE_ITEM] : []),
      ...(data?.unidadeSaude?.equipes?.content ?? []),
    ],
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        mostrarInativas,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
      unidadeSaudeId,
    }),
  })

  return (
    <SelectField<EquipeSelectModel> itemToString={itemToString} renderItem={renderItem} {...asyncProps} {...rest} />
  )
}
