/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Modal } from 'bold-ui'
import { resolveName } from 'components/form/final-form/useField'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import { useCallback, useMemo, useRef, useState } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'

import { MedicamentoFormModel } from '../model'
import { PrescricaoMedicamentoPlanoModel } from '../PrescricaoMedicamentoView'
import AdicionarPrescricaoFieldArray from './AdicionarPrescricaoFieldArray'

export interface AdicionarPrescricaoModalProps {
  name: MetaPath<PrescricaoMedicamentoPlanoModel>
  atendimentoId: ID
  medicamentosToReplicate: MedicamentoFormModel[]
  onChangeMedicamentosToReplicate(medicamentos: MedicamentoFormModel[]): void
  onClearMedicamentosToReplicate(): void
}

export default function AdicionarPrescricaoModal(props: AdicionarPrescricaoModalProps) {
  const {
    name,
    medicamentosToReplicate,
    atendimentoId,
    onChangeMedicamentosToReplicate,
    onClearMedicamentosToReplicate,
  } = props
  const history = useHistory()
  const match = useRouteMatch()
  const prescricaoModalRef = useRef<HTMLDivElement>()
  const [medicamentoToEdit, setMedicamentoToEdit] = useState<MedicamentoFormModel>(
    medicamentosToReplicate ? medicamentosToReplicate[0] : null
  )

  const scrollModal = () => prescricaoModalRef.current?.scrollTo(0, 0)

  const onCloseModal = useCallback(() => {
    history.push(match.url.replace('/prescricao-medicamentos/adicionar', ''))
    if (!isEmpty(medicamentosToReplicate)) {
      onClearMedicamentosToReplicate()
    }
  }, [history, match.url, medicamentosToReplicate, onClearMedicamentosToReplicate])

  const handleOnCloseModal = useCallback(() => {
    if (medicamentosToReplicate && !isEmpty(medicamentosToReplicate)) {
      confirm({
        type: 'primary',
        title: 'Deseja fechar a prescrição de medicamentos?',
        body:
          'Ao fechar a prescrição sem revisar e salvar todos os medicamentos, os medicamentos que não foram salvos serão perdidos.',
        onConfirm: () => {
          medicamentoToEdit && setMedicamentoToEdit(null)
          onCloseModal()
        },
        confirmLabel: 'Continuar',
        cancelLabel: 'Cancelar',
        depthLevel: 2,
        manageOverflow: false,
      })()
    } else {
      onCloseModal()
    }
  }, [medicamentoToEdit, medicamentosToReplicate, onCloseModal])

  const renderFieldArray = useCallback(
    (fieldArrayRenderProps: FieldArrayRenderProps<MedicamentoFormModel, any>) => {
      return (
        <AdicionarPrescricaoFieldArray
          onChangeMedicamentosToReplicate={onChangeMedicamentosToReplicate}
          onClearMedicamentosToReplicate={onClearMedicamentosToReplicate}
          onCloseModal={handleOnCloseModal}
          setMedicamentoToEdit={setMedicamentoToEdit}
          medicamentoToEdit={medicamentoToEdit}
          medicamentosToReplicate={medicamentosToReplicate}
          atendimentoId={atendimentoId}
          scrollModal={scrollModal}
          {...fieldArrayRenderProps}
        />
      )
    },
    [
      atendimentoId,
      handleOnCloseModal,
      medicamentoToEdit,
      medicamentosToReplicate,
      onChangeMedicamentosToReplicate,
      onClearMedicamentosToReplicate,
    ]
  )

  const modalContent = useMemo(() => <FieldArray name={resolveName(name.medicamentos)} render={renderFieldArray} />, [
    name.medicamentos,
    renderFieldArray,
  ])

  return (
    <Modal
      open={true}
      containerRef={prescricaoModalRef}
      onClose={handleOnCloseModal}
      closeOnBackdropClick={false}
      style={styles.mainContainer}
    >
      {modalContent}
    </Modal>
  )
}

const styles = {
  mainContainer: css`
    background-color: ${theme.pallete.surface.background};
    min-width: 64rem;
    max-height: 95vh;
    position: relative;
  `,
}
