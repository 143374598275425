import { Button, HFlow, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, RichTextField, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

import AtestadoModel from '../components/AtestadoModel'
import { atestadoEmBrancoFormValidator } from './validator'

export interface AtestadoEmBrancoFormProps {
  initialValues?: AtestadoModel
  onSubmit(values: AtestadoModel): void
  isDirtForm?(): void
}

export const name = metaPath<AtestadoModel>()

export default function AtestadoEmBrancoForm(props: AtestadoEmBrancoFormProps) {
  const { initialValues, onSubmit, isDirtForm } = props
  const alert = useAlert()

  const handleSubmit = (values: AtestadoModel, formApi: FormApi) => {
    onSubmit({ ...values, tipo: TipoAtestadoEnum.EM_BRANCO })
    setTimeout(formApi.reset)
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    alert('success', 'Atestado salvo com sucesso.')
  }

  const handleOnFailed = () => alert('danger', 'Existem campos obrigatórios não preenchidos.')

  const handleCancel = (formProps: FormRenderProps<AtestadoModel>) => {
    if (formProps.dirty) {
      confirm({
        title: 'Deseja cancelar a inclusão?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar inclusão',
        confirmLabel: 'Sim, cancelar inclusão',
        onConfirm: () => {
          alert('success', 'Cadastro de atestado cancelado com sucesso.')
          formProps.form.reset()
        },
      })()
    } else {
      alert('success', 'Cadastro de atestado cancelado com sucesso.')
    }
    formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
  }

  const renderForm = (formProps: FormRenderProps<AtestadoModel>) => {
    return (
      <VFlow>
        <RichTextField
          name={name.descricao}
          maxLength={4000}
          placeholder='Insira conteúdo do atestado'
          height={7}
          label='Conteúdo'
          required
          onChange={isDirtForm}
        />

        <HFlow justifyContent='flex-end'>
          <Button size='small' onClick={() => handleCancel(formProps)}>
            Cancelar
          </Button>
          <SubmitButton type='button' size='small' kind='primary' handleSubmit={formProps.handleSubmit} throttleSubmit>
            Salvar atestado
          </SubmitButton>
        </HFlow>
      </VFlow>
    )
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleSubmit}
      onSubmitFailed={handleOnFailed}
      validate={atestadoEmBrancoFormValidator}
    />
  )
}
