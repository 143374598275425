import { Alert, HFlow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { ReactComponent as MarcadoresConsumoAlimentar } from 'images/historico/icone-consumo-alimentar.svg'
import { ReactComponent as PreNatal } from 'images/historico/icone-pre-natal.svg'
import { ReactComponent as Acompanhamentos } from 'images/side-menu/acompanhamentos.svg'
import { ReactComponent as BuscaAtiva } from 'images/side-menu/busca-ativa.svg'
import { ReactComponent as Atendimento } from 'images/side-menu/lista-de-atendimento.svg'
import { ReactComponent as Reterritorializacao } from 'images/side-menu/merge-do-cidadao.svg'
import { ReactComponent as Estagiario } from 'images/side-menu/profissionais.svg'
import { ReactComponent as Observacao } from 'images/side-menu/profissionais.svg'
import { ReactComponent as RegistroTardio } from 'images/side-menu/registro-tardio.svg'
import React from 'react'

import AcompanhamentoCidadaosVinculadosGif from '../../images/novidades/cincoUm/acompanhamento_cidadaos_vinculados.gif'
import AgendamentoPaused from '../../images/novidades/cincoUm/agendamento_paused.png'
import AgendamentoPreNatalPaused from '../../images/novidades/cincoUm/agendamento_pre_natal_paused.png'
import AgendamentoPreNatalSugestoes from '../../images/novidades/cincoUm/agendamento_pre_natal_sugestoes.png'
import AgendamentoPreNatalGif from '../../images/novidades/cincoUm/agendamento_pre_natal.gif'
import Agendamento from '../../images/novidades/cincoUm/agendamento.gif'
import AgendarAtendimentoPaused from '../../images/novidades/cincoUm/agendar_atendimento_paused.png'
import AgendarAtendimento from '../../images/novidades/cincoUm/agendar_atendimento.gif'
import AguardandoRevisao from '../../images/novidades/cincoUm/aguardando_revisao_estagiario.png'
import BotaoObservacaoCidadao from '../../images/novidades/cincoUm/botao_observacao_cidadao.png'
import BuscaAtivaVacinacaoCalendario from '../../images/novidades/cincoUm/busca_ativa_vacinacao_calendario.png'
import BuscaAtivaVacinacaoFiltrosAvancados from '../../images/novidades/cincoUm/busca_ativa_vacinacao_covid_filtros.png'
import BuscaAtivaVacinacaoCovid from '../../images/novidades/cincoUm/busca_ativa_vacinacao_covid.png'
import LotacaoEstagiarioPaused from '../../images/novidades/cincoUm/cadastro_lotacao_estagiario_paused.png'
import LotacaoEstagiario from '../../images/novidades/cincoUm/cadastro_lotacao_estagiario.gif'
import Certificados from '../../images/novidades/cincoUm/certificados.png'
import EmRetificacaoEstagiario from '../../images/novidades/cincoUm/em_retificacao_estagiario.png'
import EmRevisaoEstagiario from '../../images/novidades/cincoUm/em_revisao_estagiario.png'
import EncaminhamentoExterno from '../../images/novidades/cincoUm/encaminhamento_externo.png'
import LoginGovBr from '../../images/novidades/cincoUm/login_gov_br_button.gif'
import MCAAtendimentoPaused from '../../images/novidades/cincoUm/mca-atend-paused.png'
import MCAAtendimentoGIF from '../../images/novidades/cincoUm/mca-atend.gif'
import MCAHistoricoPaused from '../../images/novidades/cincoUm/mca-historico-paused.png'
import MCAHistoricoGIF from '../../images/novidades/cincoUm/mca-historico.gif'
import PlanoCuidado from '../../images/novidades/cincoUm/plano_cuidado.png'
import ProfissionalResponsavel from '../../images/novidades/cincoUm/profissional_responsavel.png'
import RegistroTardioAntigo from '../../images/novidades/cincoUm/registro_tardio_antigo.png'
import RegistroTardioNovo from '../../images/novidades/cincoUm/registro_tardio_novo.png'
import RegistroTardioPaused from '../../images/novidades/cincoUm/registro_tardio_paused.png'
import RegistroTardioGif from '../../images/novidades/cincoUm/registro_tardio.gif'
import BuscarReterritorializacao from '../../images/novidades/cincoUm/reterritorializacao_busca.png'
import SelecaoReterritorializacao from '../../images/novidades/cincoUm/reterritorializacao_selecao.png'
import RevisaoEstagiarioPaused from '../../images/novidades/cincoUm/revisao_atendimento_estagiario_paused.png'
import RevisaoEstagiario from '../../images/novidades/cincoUm/revisao_atendimento_estagiario.gif'
import VisualizarPlano from '../../images/novidades/cincoUm/visualizar_plano.png'
import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoUm() {
  const { OBSERVACAO_ENABLED, TERRITORIO_ENABLED, AGENDAMENTOS_PRE_NATAL_ENABLED } = useFlags()

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Módulos com novidades:
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Marcadores de consumo alimentar
          </Text>
          <Text fontSize={0.875} component='li'>
            Busca ativa de vacinação
          </Text>
          <Text fontSize={0.875} component='li'>
            Encaminhamento externo
          </Text>
          <Text fontSize={0.875} component='li'>
            Adaptação do PEC para uso do estagiário
          </Text>
          {TERRITORIO_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Reterritorialização
            </Text>
          )}
        </VFlow>
        <VFlow vSpacing={0.2}>
          {OBSERVACAO_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Observação do cidadão
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Registro tardio
          </Text>
          <Text fontSize={0.875} component='li'>
            Acompanhamento de cidadãos vinculados
          </Text>
          {AGENDAMENTOS_PRE_NATAL_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Facilidades no agendamento de consultas de pré-natal
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            CADSUS - Nova Sincronização
          </Text>
        </VFlow>
      </HFlow>
      <NewsHeadingSection
        title='Marcadores de consumo alimentar'
        icon={<MarcadoresConsumoAlimentar style={{ marginRight: '0.25rem', width: '2rem', height: '2rem' }} />}
        id='mca-5-1'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Agora é possível registrar o marcador de consumo alimentar do cidadão diretamente no atendimento. A opção
            foi incluída no Objetivo do SOAP em quase todos os tipos de atendimento e o formulário é apresentado de
            acordo com a idade do cidadão.
          </Text>
          <NewsGif
            src={MCAAtendimentoGIF}
            pausedSrc={MCAAtendimentoPaused}
            alt='Gif mostrando o cadastro de uma lotação de estágio'
          />
          <br />
          <Text fontSize={0.875} component='li'>
            As informações de consumo alimentar registradas no atendimento também são apresentadas no histórico e
            impressão. É possível reconhecer um atendimento com esse registro através da etiqueta “Marcadores de consumo
            alimentar".
          </Text>
          <NewsGif
            src={MCAHistoricoGIF}
            pausedSrc={MCAHistoricoPaused}
            alt='Gif mostrando o cadastro de uma lotação de estágio'
          />
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection
        title='Busca ativa de vacinação'
        icon={<BuscaAtiva style={{ marginRight: '0.25rem', width: '2rem', height: '2rem' }} />}
        id='busca-ativa-vacinacao-5-1'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            A funcionalidade permite realizar a{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Busca ativa de vacinação
            </Text>{' '}
            retornando uma listagem com os dados dos cidadãos que possuem doses de imunobiológicos{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Atrasadas
            </Text>{' '}
            ou{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              No prazo
            </Text>{' '}
            para serem aplicadas, considerando as vacinas do{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              calendário vacinal
            </Text>{' '}
            e as doses das vacinas de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Covid-19
            </Text>{' '}
            com status de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Aplicadas
            </Text>{' '}
            nos cidadãos. O profissional poderá também realizar a exportação do relatório da busca em formato{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              CSV
            </Text>{' '}
            para manipulação dos dados.
          </Text>
          <Text fontSize={0.875} component='li'>
            O resultado na listagem é{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              filtrado
            </Text>{' '}
            a partir dos seguintes campos:
            <ul>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Grupo-alvo
                </Text>
                : deve filtrar os cidadãos pelos grupos-alvos do{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  calendário vacinal
                </Text>{' '}
                ou que estejam na faixa etária selecionada para as{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  vacinas de Covid-19
                </Text>
                ;
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Status da vacina
                </Text>
                : para as vacinas do{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  calendário vacinal
                </Text>
                , é possível filtrar os imunobiológicos e suas respectivas doses a partir dos status{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  Atrasada
                </Text>{' '}
                (vacinas não tomadas dentro do calendário vacinal) ou{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  No prazo
                </Text>{' '}
                (vacinas não tomadas do calendário vacinal que estão dentro do prazo para aplicação). Para as{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  vacinas da Covid-19
                </Text>
                , é possível filtrar os imunobiológicos e suas respectivas doses pelo status{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  Aplicada
                </Text>
                .
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Imunobiológico
                </Text>
                : a busca é filtrada pelos cidadãos que contém as vacinas{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  Atrasadas e No prazo{' '}
                </Text>
                (para vacinas do calendário vacinal) ou{' '}
                <Text fontSize={0.875} fontWeight='bold'>
                  Aplicadas
                </Text>{' '}
                (para vacinas de Covid-19) do(s) imunobiológico(s) selecionado(s) no campo.
              </Text>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Dose
                </Text>
                : a busca é filtrada pelos cidadãos que contém as vacinas com a(s) dose(s) selecionada(s) no campo.
              </Text>
            </ul>
          </Text>
          <Text fontSize={0.875} component='li'>
            Estão disponíveis para seleção os grupos alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              "Crianças (0 a 9 anos)"
            </Text>{' '}
            e{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              "Adolescentes (10 a 19 anos)"
            </Text>
            .
          </Text>
          <Text fontSize={0.875} component='li'>
            Para gerar a listagem com os filtros selecionados, basta clicar no botão{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              "Buscar cidadãos"
            </Text>
            , que deve ser exibida com as seguintes colunas:{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Cidadão, Idade, Endereço, e Status da vacina (Atrasada ou No prazo para o Calendário vacinal e Aplicada
              para as vacinas de Covid-19)
            </Text>
            .
          </Text>
          <Text fontSize={0.875} component='li'>
            A listagem pode ainda ser{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              ordenada
            </Text>{' '}
            pelas colunas: Cidadão, Idade.
          </Text>
          <Carousel>
            <CarouselItem image={BuscaAtivaVacinacaoCalendario} alt='Print tipo de visualizacao calendario' />
            <CarouselItem image={BuscaAtivaVacinacaoCovid} alt='Print tipo de visualizacao covid' />
            <CarouselItem image={BuscaAtivaVacinacaoFiltrosAvancados} alt='Print filtros avançados para busca ativa' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Encaminhamento externo'
        icon={<Atendimento style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='encaminhamento-5-1'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Agora é possível registrar novos tipos de encaminhamento externo na seção de Plano dos atendimentos. Foram
            incluídas as opções:
            <ul>
              <li>CAPS;</li>
              <li>Urgência;</li>
              <li>Internação hospitalar;</li>
              <li>Serviço de Atenção Domiciliar;</li>
              <li>Intersetorial.</li>
            </ul>
          </Text>
          <NewsImg src={EncaminhamentoExterno} alt='Print de Encaminhamento externo' />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Adaptação do PEC para uso do estagiário'
        icon={<Estagiario style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='estagiario-5-1'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            A nova funcionalidade permite que estagiários utilizem o PEC e tenham os atendimentos validados pelo
            supervisor antes da finalização. Foram inseridos os seguintes fluxos no sistema para adaptá-lo ao uso do
            estagiário:
            <ul>
              <li>Login individual para o estagiário;</li>
              <li>Cadastro de uma lotação de estágio com um respectivo supervisor;</li>
              <br></br>
              <NewsGif
                src={LotacaoEstagiario}
                pausedSrc={LotacaoEstagiarioPaused}
                alt='Gif mostrando o cadastro de uma lotação de estágio'
              />{' '}
              <br></br>
              <li>
                Retificação dos atendimentos realizados pelo estagiário antes do início da revisão pelo supervisor;
              </li>
              <li>Revisão dos atendimentos feitos pelo estagiário antes da finalização;</li>
              <br></br>
              <NewsGif
                src={RevisaoEstagiario}
                pausedSrc={RevisaoEstagiarioPaused}
                alt='Gif mostrando a revisão de um atendimento feito pelo estagiário'
              />{' '}
              <br></br>
              <li>Atualizações no Histórico para apresentar atendimentos realizados por estagiários;</li>
              <li>Novos status na Lista de Atendimento para suportar as funcionalidades.</li>
              <Carousel>
                <CarouselItem image={AguardandoRevisao} alt='Print do status aguardando revisão' />
                <CarouselItem image={EmRetificacaoEstagiario} alt='Print do status em retificação' />
                <CarouselItem image={EmRevisaoEstagiario} alt='Print do status em revisão' />
              </Carousel>
            </ul>
          </Text>
        </VFlow>
      </NewsHeadingSection>

      {TERRITORIO_ENABLED && (
        <NewsHeadingSection
          title='Reterritorialização'
          id='reterritorializacao-5-1'
          icon={<Reterritorializacao style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875}>
              <ul>
                <Text fontSize={0.875} component='li'>
                  Agora é possível atualizar rapidamente o território dos imóveis e seus respectivos núcleos familiares.
                  Com esta nova funcionalidade, a atualização das informações que determinam a responsabilidade de
                  acompanhamento no território poderá ser feita de uma forma rápida e consolidada, sem a necessidade de
                  se atualizar ficha por ficha.
                </Text>
                <Text fontSize={0.875} component='li'>
                  Acesse a seção "Gestão de Cadastros" e a aba de Reterritorialização. Nesta tela basta realizar uma
                  busca pelos imóveis desejados.
                </Text>
                <br />
                <NewsImg src={BuscarReterritorializacao} alt='Print reterritorializacao busca' />
                <br />
                <Text fontSize={0.875} component='li'>
                  Aí então basta selecionar na lista quais deles devem ser reterritorializados, informar os novos dados
                  e clicar em "Atualizar imóveis".
                </Text>
                <br />
                <NewsImg src={SelecaoReterritorializacao} alt='Print reterritorializacao seleção' />
                <br />
                <Text fontSize={0.875} component='li'>
                  Este processo preserva automaticamente os núcleos familiares envolvidos, gerando novas fichas de
                  atualização assim que um novo lote de fichas for gerado e enviado.
                </Text>
              </ul>
            </Text>
          </VFlow>
        </NewsHeadingSection>
      )}

      {OBSERVACAO_ENABLED && (
        <NewsHeadingSection
          title='Observação do cidadão'
          icon={<Observacao style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
          id='observacao-5-1'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Agora é possível registrar no prontuário que um cidadão deve ficar em observação. Para tanto é necessário
              ativar o botão “Cidadão em observação” que irá liberar um novo fluxo no sistema:
            </Text>
            <NewsImg src={BotaoObservacaoCidadao} alt='Print botão “Cidadão em observação”' />
            <br></br>
            <Text fontSize={0.875} component='li'>
              Na lista de atendimentos os cidadãos em observação ficarão em uma seção separada;
            </Text>
            <Text fontSize={0.875} component='li'>
              O profissional que iniciou a observação é considerado o profissional responsável, sendo este o único que
              poderá finalizar o atendimento de observação;
            </Text>
            <NewsImg src={ProfissionalResponsavel} alt='Print profissional responsável”' />
            <br></br>
            <Text fontSize={0.875} component='li'>
              Os outros profissionais que participarem da observação são considerados profissionais auxiliares, pois o
              foco desses profissionais é estabilizar o cidadão de acordo com o plano de cuidado prescrito pelo
              profissional responsável. Sendo que, os atendimentos dos profissionais auxiliares terão o mesmo formato
              que um atendimento de procedimentos com as seguintes mudanças:
              <ul>
                <Text fontSize={0.875} component='li'>
                  A cada ponto de contato que o profissional tiver com o cidadão dentro de uma observação deve ser
                  possível registrar uma medição;
                </Text>
                <Text fontSize={0.875} component='li'>
                  O histórico de plano de cuidados deve ser exibido no Plano do SOAP, contendo todos os planos de
                  cuidado realizados pelos profissionais da saúde para aquele atendimento de observação;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Nos atendimentos do profissional responsável todas as informações se mantêm, sendo possível realizar
                  mais de uma medição, além de adicionar o histórico de plano de cuidados no Plano do SOAP;
                </Text>
              </ul>
            </Text>
            <Carousel>
              <CarouselItem image={PlanoCuidado} alt='Print plano de cuidado' />
              <CarouselItem image={VisualizarPlano} alt='Print visualização plano cuidado' />
            </Carousel>
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Acompanhamento de cidadãos vinculados'
        icon={<Acompanhamentos style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='acompanhamento-cidadaos-vinculados-5-1'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Com o{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              novo acompanhamento
            </Text>{' '}
            é possível buscar, imprimir e exportar todos os cidadãos que são acompanhados pelas equipes da unidade de
            saúde, até mesmo aqueles que não pertencem a um núcleo familiar, ou seja, unidades que não utilizam o CDS,
            poderão visualizar os cidadãos no relatório, desde que em seu cadastro esteja informada a equipe responsável
            pelo cidadão.
          </Text>
          <Text fontSize={0.875} component='li'>
            O{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Acompanhamento de cidadãos vinculados
            </Text>{' '}
            fica em um novo menu de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Acompanhamentos
            </Text>
            , agrupado ao{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Acompanhamento de condições de saúde
            </Text>
            .
          </Text>
          <Text fontSize={0.875} component='li'>
            Por padrão, os profissionais com os perfis Coordenador da UBS, ACS, TSB, Outros profissionais de nível
            superior NASF, Outros profissionais de nível superior, Outros profissionais de nível médio técnico, ASB,
            Auxiliar ou técnico de enfermagem, Cirurgião dentista, Enfermeiro, Farmacêutico e Médico terão acesso ao
            novo relatório. Com exceção do Coordenador da UBS, os demais profissionais só visualizam cidadãos
            relacionados às suas respectivas equipes.
          </Text>
          <NewsImg
            src={AcompanhamentoCidadaosVinculadosGif}
            alt='Gif mostrando o funcionamento do acompanhamento de cidadãos vinculados'
          />
          <br />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Redesign do módulo Registro tardio'
        icon={<RegistroTardio style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
        id='registro-tardio-5-1'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              redesenhado
            </Text>{' '}
            a fim de facilitar a sua utilização, mantendo estrutura do atendimento individual;
          </Text>
          <Carousel>
            <CarouselItem image={RegistroTardioAntigo} alt='Layout antigo do registro tardio' />
            <CarouselItem image={RegistroTardioNovo} alt='Layout novo do registro tardio' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Permissão de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              agendamento do Registro tardio
            </Text>{' '}
            quando a justificativa for{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              fora da UBS
            </Text>{' '}
            através do módulo da agenda;
          </Text>
          <NewsGif
            src={AgendarAtendimento}
            pausedSrc={AgendarAtendimentoPaused}
            alt='Demonstração da funcionalidade de agendamento do registro tardio com a justificativa de "Fora da UBS" através do módulo da agenda.'
          />
          <Text fontSize={0.875} component='li'>
            Permissão de registros de atendimentos de até{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              7 dias anteriores a data atual
            </Text>{' '}
            que não puderam ser registrados no dia do atendimento;
          </Text>
          <Text fontSize={0.875} component='li'>
            Possibilidade de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              cancelar registro
            </Text>{' '}
            na própria listagem caso ele tenha excedido o prazo ou possua outro mais recente que ele;
          </Text>
          <Text fontSize={0.875} component='li'>
            Ao tentar realizar um registro com atendimento mais recente, são exibidas{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              informações do atendimento posterior
            </Text>{' '}
            em uma modal;
          </Text>
          <NewsGif
            src={RegistroTardioGif}
            pausedSrc={RegistroTardioPaused}
            alt='Demonstração das funcionalidades de registro de atendimentos realizados até 7 dias anteriores a data atual, cancelamento de registro por tempo excedido e exibição de informações de atendimentos mais recentes. '
          />
          <Text fontSize={0.875} component='li'>
            Possibilidade de agendar um atendimento Fora da UBS na{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              finalização do atendimento
            </Text>
            .
          </Text>
          <NewsGif
            src={Agendamento}
            pausedSrc={AgendamentoPaused}
            alt='Demonstração de funcionalidade de agendamento de atendimento Fora da UBS na finalização do atendimento.'
          />
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='CADSUS - Nova Sincronização' id='cadsus-sinc-5-1'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Agora, para ter acesso ao serviço é necessário que o Administrador Municipal configure o certificado digital
            do município. Além disso, o usuário do CADSUS precisará realizar o login no gov.br no próprio PEC para
            usufruir todos os serviços disponíveis.
          </Text>
          <NewsImg src={Certificados} alt='Imagem mostrando tela de Certificados no módulo Gestão Municipal' />
          <Text fontSize={0.875} component='li'>
            <Text fontStyle='italic'>Importante:</Text> Caso o município já tenha um certificado com a RNDS configurada,
            será necessário solicitar a habilitação do novo serviço do CADSUS para ele. O PEC aceita apenas um
            certificado, ou seja, para acessar a RNDS e o CADSUS, o mesmo certificado deverá ter ambos os serviços
            habilitados.
          </Text>
          <NewsImg
            src={LoginGovBr}
            alt='Gif mostrando o funcionamento do botão de login com gov br na tela de cadastro de cidadão'
          />
        </VFlow>
      </NewsHeadingSection>
      {AGENDAMENTOS_PRE_NATAL_ENABLED && (
        <NewsHeadingSection
          title='Facilidades no agendamento de consultas de pré-natal.'
          icon={<PreNatal style={{ marginRight: '0.25rem', width: '32px', height: '32px' }} />}
          id='agendamento-pre-natal-5-1'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Com a nova funcionalidade de agendamentos, durante um atendimento de pré-natal, será possível realizar o{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                agendamento da próxima consulta para acompanhamento de pré-natal e a consulta odontológica de pré-natal
              </Text>
              . O PEC irá{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                sugerir a data e lotação de profissional
              </Text>
              , sendo que a pessoa responsável pelo atendimento tem liberdade para alterar essas informações conforme
              disponibilidade das partes envolvidas.
            </Text>
            <Text fontSize={0.875} component='li'>
              Para o acompanhamento de pré-natal, a data da próxima consulta é sugerida conforme a idade gestacional,
              respeitando as cadências estipuladas na caderneta da gestante, enquanto a lotação de profissional alterna
              entre profissionais com CBO de médico e enfermeiro.
            </Text>
            <Text fontSize={0.875} component='li'>
              Para as consultas odontológicas, é sugerida uma consulta a cada trimestre da gestação, enquanto a lotação
              de profissional sugerida será de profissional com CBO de dentista.
            </Text>
            <NewsGif
              src={AgendamentoPreNatalGif}
              pausedSrc={AgendamentoPreNatalPaused}
              alt='Demonstração de funcionalidade de agendamento de acompanhamento de pré-natal.'
            />
            <Text fontSize={0.875} component='li'>
              Ainda durante os atendimentos de pré-natal, serão apresentadas as{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                sugestões de quais serão todas as datas
              </Text>{' '}
              que deverão ocorrer os{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                próximos acompanhamentos de pré-natal
              </Text>
              , bem como um{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                atendimento odontológico
              </Text>{' '}
              a cada trimestre da gestação.
            </Text>
            <NewsImg
              src={AgendamentoPreNatalSugestoes}
              alt='Imagem exibindo as sugestões de datas para os próximos acompanhamentos de pré-natal e atendimentos odontológicos'
            />
          </VFlow>
        </NewsHeadingSection>
      )}
    </VFlow>
  )
}
