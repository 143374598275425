import {
  TipoAtendimentoEnum,
  TipoAtendimentoProfissional,
  TipoConsultaOdontoEnum,
  TipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import {
  ProcedimentosAutomaticoAtendimentoOdonto,
  ProcedimentosAutomaticoAtendimentoPoliclinica,
  ProcedimentosAutomaticosAtendimentoIndividual,
} from 'types/enums'

export const calculateProcedimentoAutomaticoToAdd = (
  tipoAtendimentoProfisisonal: TipoAtendimentoProfissional,
  isMedico: boolean,
  isDentista: boolean,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  tipoAtendimento: TipoAtendimentoEnum,
  POLICLINICA_ENABLED: Boolean,
  tipoConsulta?: TipoConsultaOdontoEnum
) => {
  switch (tipoAtendimentoProfisisonal) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO: {
      return calculateProcedimentoAutomaticoOdontologico(
        tipoEstabelecimento,
        tipoAtendimento,
        tipoConsulta,
        isDentista,
        POLICLINICA_ENABLED
      )
    }
    default: {
      return calculateProcedimentoAutomaticoIndividual(
        tipoEstabelecimento,
        tipoAtendimento,
        isMedico,
        POLICLINICA_ENABLED
      )
    }
  }
}

export const calculateProcedimentoAutomaticoIndividual = (
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  tipoAtendimento: TipoAtendimentoEnum,
  isMedico: boolean,
  POLICLINICA_ENABLED: Boolean
) => {
  const isUrgencia = tipoAtendimento === TipoAtendimentoEnum.URGENCIA
  switch (tipoEstabelecimento) {
    case TipoEstabelecimentoEnum.POLICLINICA: {
      if (POLICLINICA_ENABLED) {
        return isUrgencia
          ? ProcedimentosAutomaticoAtendimentoPoliclinica.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
          : isMedico
          ? ProcedimentosAutomaticoAtendimentoPoliclinica.CONSULTA_MEDICA_EM_ATENCAO_ESPECIALIZADA
          : ProcedimentosAutomaticoAtendimentoPoliclinica.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
      } else {
        return isUrgencia
          ? ProcedimentosAutomaticosAtendimentoIndividual.ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA
          : isMedico
          ? ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_MEDICA_EM_ATENCAO_BASICA
          : ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
      }
    }
    case TipoEstabelecimentoEnum.UBS: {
      return isUrgencia
        ? ProcedimentosAutomaticosAtendimentoIndividual.ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA
        : isMedico
        ? ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_MEDICA_EM_ATENCAO_BASICA
        : ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
    }
    default: {
      // Não existem procedimentos automaticos
      return null
    }
  }
}

export const calculateProcedimentoAutomaticoOdontologico = (
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  tipoAtendimento: TipoAtendimentoEnum,
  tipoConsulta: TipoConsultaOdontoEnum,
  isDentista: boolean,
  POLICLINICA_ENABLED: Boolean
) => {
  if (tipoConsulta === TipoConsultaOdontoEnum.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA) {
    return ProcedimentosAutomaticoAtendimentoOdonto.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA
  }
  switch (tipoEstabelecimento) {
    case TipoEstabelecimentoEnum.CEO: {
      return tipoAtendimento !== TipoAtendimentoEnum.URGENCIA
        ? ProcedimentosAutomaticoAtendimentoOdonto.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
        : ProcedimentosAutomaticoAtendimentoOdonto.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
    }
    case TipoEstabelecimentoEnum.POLICLINICA: {
      if (POLICLINICA_ENABLED) {
        return isDentista && tipoAtendimento === TipoAtendimentoEnum.URGENCIA
          ? ProcedimentosAutomaticoAtendimentoOdonto.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
          : ProcedimentosAutomaticoAtendimentoOdonto.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
      } else {
        return tipoAtendimento !== TipoAtendimentoEnum.URGENCIA
          ? ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
          : ProcedimentosAutomaticosAtendimentoIndividual.ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA
      }
    }
    case TipoEstabelecimentoEnum.UBS: {
      return tipoAtendimento !== TipoAtendimentoEnum.URGENCIA
        ? ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
        : ProcedimentosAutomaticosAtendimentoIndividual.ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA
    }
  }
}
