import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

/**
 * based on https://github.com/WebDevSimplified/useful-custom-react-hooks/blob/main/src/8-useStorage/useStorage.js
 */

export function useLocalStorage<T = any>(
  key: string,
  checkVersionCompatibility?: boolean,
  defaultValue?: T,
  modifier?: (value: T) => T
) {
  return useStorage(key, defaultValue, window.localStorage, modifier, checkVersionCompatibility)
}

export function useSessionStorage<T = any>(
  key: string,
  checkVersionCompatibility?: boolean,
  defaultValue?: T,
  modifier?: (value: T) => T
) {
  return useStorage(key, defaultValue, window.sessionStorage, modifier, checkVersionCompatibility)
}

function useStorage<T = any>(
  key: string,
  defaultValue: T,
  storageObject: Storage,
  modifier?: (value: T) => T,
  checkVersionCompatibility?: boolean
): [T, Dispatch<SetStateAction<T>>, () => void] {
  const [value, setValue] = useState<T>(() => {
    const jsonValue = storageObject.getItem(key)
    const __defaultValue = typeof defaultValue === 'function' ? defaultValue() : defaultValue

    if (jsonValue != null) {
      let parsedValue = JSON.parse(jsonValue)
      parsedValue =
        checkVersionCompatibility && parsedValue.__version !== process.env.REACT_APP_VERSION
          ? __defaultValue
          : parsedValue.item
      return modifier ? modifier(parsedValue) : parsedValue
    } else {
      return __defaultValue
    }
  })

  useEffect(() => {
    if (value === undefined) storageObject.removeItem(key)
    else storageObject.setItem(key, JSON.stringify({ item: value, __version: process.env.REACT_APP_VERSION }))
  }, [key, value, storageObject])

  const remove = useCallback(() => {
    storageObject.removeItem(key)
    setValue(undefined)
  }, [key, storageObject])

  return [value, setValue, remove]
}
