import { Cell, Grid, Heading, HFlow, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { HLabel } from 'components/HLabel'
import { Cpf, Telefone } from 'components/label'
import { ButtonLink } from 'components/route'
import React from 'react'
import Permissions from 'types/Permissions'

import { ResumoCidadaoModel } from '../../model'

export interface ResumoCidadaoProps {
  cidadao: ResumoCidadaoModel
}

export function BoxResumoCidadao({ cidadao }: ResumoCidadaoProps) {
  const { hasAuthorization } = useSession({ fetchPolicy: 'cache-only' })

  const podeVisualizarCidadao = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao)

  return (
    <Box>
      <Grid gapVertical={0}>
        <Cell size={12}>
          <Heading level={3}>{(cidadao.nomeSocial ?? cidadao.nome)?.titleCase()}</Heading>
        </Cell>
        <Cell size={podeVisualizarCidadao ? 9 : 12}>
          <VFlow vSpacing={0}>
            <HFlow>
              <HLabel title='CPF'>
                <Cpf value={cidadao.cpf} />
              </HLabel>
              <HLabel title='CNS'>{cidadao.cns}</HLabel>
            </HFlow>
            <HLabel title='Data de nascimento'>
              <DateTime format='DD/MM/YYYY' value={cidadao.dataNascimento} />{' '}
              <Age dateOfBirth={cidadao.dataNascimento} />
            </HLabel>
            <HLabel title='Telefone'>
              {cidadao.contato?.telefoneCelular && <Telefone value={cidadao.contato.telefoneCelular} />}
              {cidadao.contato?.telefoneCelular &&
                (cidadao.contato?.telefoneResidencial || cidadao.contato?.telefoneContato) &&
                ' | '}
              {cidadao.contato?.telefoneResidencial && <Telefone value={cidadao.contato.telefoneResidencial} />}
              {cidadao.contato?.telefoneResidencial && cidadao.contato?.telefoneContato && ' | '}
              {cidadao.contato?.telefoneContato && <Telefone value={cidadao.contato.telefoneContato} />}
            </HLabel>
          </VFlow>
        </Cell>
        {podeVisualizarCidadao && (
          <Cell size={3} alignSelf='center'>
            <ButtonLink to={`/cidadao/${cidadao.id}`} skin='outline' kind='primary' size='small'>
              Ver cadastro completo
            </ButtonLink>
          </Cell>
        )}
      </Grid>
    </Box>
  )
}
