/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, DataTable, Grid, HFlow, Icon, Spinner, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { useUltimosCincoArquivosQuery } from 'graphql/hooks.generated'
import { ArquivoIdentificadorEnum, ArquivoStatusEnum, StatusArquivo } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { Fragment, useEffect, useState } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { downloadFile } from 'util/downloadFile'

import { downloadArquivo } from './downloadsArquivo'
import { ArquivoFormato, ArquivoModel, arquivoStatusEnumToString } from './model'

interface RelatoriosRecentesProps {
  fileName: string
  identificadorModuloArquivo: ArquivoIdentificadorEnum
  atmospherePath: string
  shouldRefetch: boolean
  usuarioId: ID
  setShouldRefetch: (boolean) => void
}

export const RelatoriosRecentes = (props: RelatoriosRecentesProps) => {
  const { fileName, identificadorModuloArquivo, atmospherePath, shouldRefetch, usuarioId, setShouldRefetch } = props

  const [mostrarMais, setMostrarMais] = useState<boolean>(false)

  const { data, loading, refetch: refetchArquivos } = useUltimosCincoArquivosQuery({
    variables: {
      input: identificadorModuloArquivo,
    },
    onCompleted: () => setShouldRefetch(false),
  })

  useEffect(() => {
    if (shouldRefetch) {
      refetchArquivos()
    }
  }, [shouldRefetch, refetchArquivos])

  useAtmosphere<ArquivoFormato>({
    topic: `${atmospherePath}/${usuarioId}`,
    onMessage: (responseBody) => {
      if (responseBody.arquivo !== null) {
        const arquivo = new Uint8Array(responseBody.arquivo)
        downloadFile(new Blob([arquivo]), `${fileName}.${responseBody.formato.toLocaleLowerCase()}`)
      }
      refetchArquivos()
      setShouldRefetch(false)
    },
  })

  const theme = useTheme()
  const styles = createStyles(theme)

  const handleDownload = (item: StatusArquivo) => {
    if (item.status === ArquivoStatusEnum.PRONTO) downloadArquivo(item.id, fileName, item.formato.toLocaleLowerCase())
  }
  const ultimosCincoArquivos = !loading ? data?.ultimosCincoArquivos : []
  const first = ultimosCincoArquivos.isNotEmpty() ? [ultimosCincoArquivos[0]] : []

  const renderStatusArquivo = (item: ArquivoModel) => (
    <HFlow>
      {item.status === ArquivoStatusEnum.EM_PROCESSAMENTO ? (
        <Fragment>
          <Spinner size={0.8} borderWidth={2} style={[styles.spinner, styles.processandoText]} />
          <Text style={styles.processandoText}>{arquivoStatusEnumToString[item.status]}</Text>
        </Fragment>
      ) : item.status === ArquivoStatusEnum.PRONTO ? (
        <Fragment>
          <Icon icon='checkCircleOutline' style={styles.successIcon} size={1} />
          <Text style={styles.successIcon}>{arquivoStatusEnumToString[item.status]}</Text>
        </Fragment>
      ) : (
        <Fragment>
          <Icon icon='banOutline' style={styles.errorIcon} size={1} />
          <Text style={styles.errorIcon}>{arquivoStatusEnumToString[item.status]}</Text>
        </Fragment>
      )}
    </HFlow>
  )

  return (
    <Fragment>
      <Text fontWeight='bold' fontSize={1}>
        Relatórios em processamento/processados (7 dias)
      </Text>
      <Grid>
        <Cell size={6} style={styles.cell} alignSelf='flex-start'>
          <VFlow vSpacing={0}>
            <DataTable<ArquivoModel>
              rows={mostrarMais ? ultimosCincoArquivos : first}
              columns={[
                {
                  name: 'data da requisição',
                  header: 'Data da requisição',
                  render: (item) => formatDateAndHoursMinutes(item.dataInicioGerado),
                },
                {
                  name: 'tipo',
                  header: 'Tipo',
                  render: (item) => item.formato,
                },
                {
                  name: 'status de processamento',
                  header: 'Status de processamento',
                  render: renderStatusArquivo,
                },
                {
                  name: 'download',
                  header: '',
                  render: (item) => (
                    <Button
                      type='button'
                      skin='ghost'
                      size='small'
                      disabled={item.status !== ArquivoStatusEnum.PRONTO}
                      onClick={() => handleDownload(item)}
                    >
                      <Tooltip
                        text={
                          item.status === ArquivoStatusEnum.PRONTO
                            ? 'Download'
                            : 'Disponível apenas para relatórios gerados'
                        }
                      >
                        <Icon icon='download' />
                      </Tooltip>
                    </Button>
                  ),
                },
              ]}
            />

            <HFlow style={styles.footer}>
              <Button
                type='button'
                skin='ghost'
                size='small'
                onClick={() => {
                  setMostrarMais(!mostrarMais)
                }}
                style={styles.mostrarMais}
              >
                <Icon icon={mostrarMais ? 'angleUp' : 'angleDown'} />
                <Text>{mostrarMais ? 'Mostrar menos' : 'Mostrar mais'}</Text>
              </Button>
            </HFlow>
          </VFlow>
        </Cell>
      </Grid>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  cell: css`
    padding-top: 1rem;
    padding-left: 0rem;
  `,
  dataTable: css`
    padding-bottom: 0rem;
  `,
  footer: css`
    display: flex;
    align-items: stretch;
    border-left: 1px solid ${theme.pallete.divider};
    border-right: 1px solid ${theme.pallete.divider};
    border-bottom: 1px solid ${theme.pallete.divider};
    padding-top: 0rem;
    padding-left: 1rem;
    height: 2.25rem;
  `,
  spinner: css`
    margin-top: 0.1rem;
    margin-left: 0.13rem;
    margin-right: 0.07rem;
  `,
  successIcon: css`
    color: ${theme.pallete.status.success.main};
  `,
  errorIcon: css`
    color: ${theme.pallete.status.danger.main};
  `,
  processandoText: css`
    color: ${theme.pallete.primary.main};
  `,
  mostrarMais: css`
    padding-top: 0.35rem;
  `,
})
