import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { useFlags } from 'config/useFlagsContext'
import { GrupoAlvoVacinacaoEnum } from 'graphql/types.generated'
import React from 'react'

export interface GrupoAlvoVacinacaoSelectModel {
  id: ID
  enum: GrupoAlvoVacinacaoEnum
  descricao: string
}

export interface GrupoAlvoVacinacaoSelectFieldProps
  extends Omit<SelectFieldProps<GrupoAlvoVacinacaoSelectModel>, 'items' | 'itemToString'> {
  isTipoBuscaAtivaCovid: boolean
}

export function GrupoAlvoVacinacaoSelectField(props: GrupoAlvoVacinacaoSelectFieldProps) {
  const { isTipoBuscaAtivaCovid, ...rest } = props
  const itemToString = (item: GrupoAlvoVacinacaoSelectModel) => item?.descricao
  const { BUSCA_ATIVA_VACINACAO_ENABLED } = useFlags()

  return (
    <SelectField<GrupoAlvoVacinacaoSelectModel>
      items={items(!!BUSCA_ATIVA_VACINACAO_ENABLED, isTipoBuscaAtivaCovid)}
      itemToString={itemToString}
      {...rest}
    />
  )
}

// TODO (Royal-Flush): Adulto e Idoso por enquanto apenas para tipo de Busca Ativa Covid
const items = (flag: boolean, isTipoBuscaAtivaCovid: boolean) => [
  { id: 1, enum: GrupoAlvoVacinacaoEnum.CRIANCAS, descricao: 'Crianças (0 a 9 anos)' },
  { id: 2, enum: GrupoAlvoVacinacaoEnum.ADOLESCENTE, descricao: 'Adolescentes (10 a 19 anos)' },
  ...(isTipoBuscaAtivaCovid
    ? [
        { id: 3, enum: GrupoAlvoVacinacaoEnum.ADULTO, descricao: 'Adultos (20 a 59 anos)' },
        { id: 4, enum: GrupoAlvoVacinacaoEnum.IDOSO, descricao: 'Idosos (60 anos ou mais)' },
      ]
    : []),
  ...(flag ? [{ id: 5, enum: GrupoAlvoVacinacaoEnum.GESTANTE, descricao: 'Gestantes' }] : []),
]
