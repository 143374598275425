import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { createValidator, ErrorObject, required } from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import { ManterCidadaoLista } from '../components/DesfechoAtendimentoPanel'
import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'
import { agendarConsultaValidator } from './validatorAgendamento'
import { finalizacaoOdontoValidator } from './validatorOdonto'

export const finalizacaoIndividualValidator = (
  hasEncaminhamentoExterno: boolean,
  isStartObservacao: boolean,
  isAtendimentoObservacao: boolean,
  getServerTimeNow: () => Date
) =>
  createValidator<FinalizacaoAtendimentoFormModel>(
    {
      tipoAtendimento: [required],
      agendamentoConsulta: agendarConsultaValidator(getServerTimeNow),
    },
    (values: FinalizacaoAtendimentoFormModel, errors: ErrorObject<FinalizacaoAtendimentoFormModel>) => {
      if (
        (isEmpty(values.fichasNotificacaoCasoSuspeito) &&
          !hasEncaminhamentoExterno &&
          !isStartObservacao &&
          !isAtendimentoObservacao) ||
        ((isStartObservacao || isAtendimentoObservacao) &&
          values.desfechoAtendimento?.manterCidadaoLista === ManterCidadaoLista.NAO)
      ) {
        errors.conduta = required(values.conduta)
      }
      return errors
    }
  )

const desfechoProcedimentosValidator = () => null

const desfechoObservacaoValidator = () => null

export const finalizacaoAtendimentoValidator = (
  tipoAtendProf: TipoAtendimentoProfissional,
  isDemandaEspontanea: boolean,
  hasEncaminhamentoExterno: boolean,
  isStartObservacao: boolean,
  isAtendimentoObservacao: boolean,
  getServerTimeNow: () => Date
) => {
  switch (tipoAtendProf) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO: {
      return finalizacaoOdontoValidator(isDemandaEspontanea, hasEncaminhamentoExterno, getServerTimeNow)
    }
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS: {
      return desfechoProcedimentosValidator()
    }
    case TipoAtendimentoProfissional.OBSERVACAO: {
      return desfechoObservacaoValidator()
    }
    default: {
      return finalizacaoIndividualValidator(
        hasEncaminhamentoExterno,
        isStartObservacao,
        isAtendimentoObservacao,
        getServerTimeNow
      )
    }
  }
}
