import { HFlow, Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { CboSelectFieldDocument } from 'graphql/hooks.generated'
import { Cbo, CboSelectFieldQuery, CboSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type CboSelectModel = Cbo

export interface CboSelectFieldProps extends Omit<SelectFieldProps<CboSelectModel>, 'items' | 'itemToString'> {
  mostrarIndisponivelLotacao?: boolean
  codigoCboSupervisor?: string
}

export function CboSelectField(props: CboSelectFieldProps) {
  const { codigoCboSupervisor, mostrarIndisponivelLotacao, ...rest } = props

  const asyncProps = useAsyncQuerySelect<CboSelectModel, CboSelectFieldQuery, CboSelectFieldQueryVariables>({
    query: CboSelectFieldDocument,
    extractItems: (data) => data?.cbos?.content.map((item) => item.cbo),
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        mostrarIndisponivelLotacao,
        cboSupervisor: codigoCboSupervisor,
        pageParams: pageParams,
      },
    }),
  })

  const itemToString = (op: CboSelectModel) => op?.nome
  const renderItem = (item: CboSelectModel) => (
    <>
      <Text fontWeight='bold'>{item.nome}</Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Código:</Text>
        {item.cbo2002}
      </HFlow>
    </>
  )

  return <SelectField<CboSelectModel> itemToString={itemToString} renderItem={renderItem} {...asyncProps} {...rest} />
}

const pageParams = {
  ...DEFAULT_SELECT_PAGE_PARAM,
  sort: ['nome'],
}
