import { HFlow, PagedTable, Tag, Text } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useCboTableQuery } from 'graphql/hooks.generated'
import { CbosQueryInput, CboTableQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import { CboTableDropdown } from 'view/cbo/list/CboTableDropdown'
import { CboTableFilter } from 'view/cbo/list/CboTableFilter'

import { capitalize } from '../../../util/strConversor'
import { CboEditComponent } from './CboEditComponent'

export type CboTableItemDto = CboTableQuery['cbos']['content'][0]

export default function CboTable() {
  const [filter, setFilter] = useState<CbosQueryInput>({
    mostrarIndisponivelLotacao: false,
    pageParams: {
      sort: ['nome'],
    },
  })

  const {
    data: { cbos },
    refetch,
    loading,
  } = useCboTableQuery({ variables: { input: filter } })

  const renderNome = (row: CboTableItemDto) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text>{capitalize(row.cbo.nome)}</Text>
        {!row.disponivelLotacao && <Tag>Indisponível para lotação</Tag>}
      </HFlow>
    )
  }

  const renderCbo = (row: CboTableItemDto) => {
    return (
      <HFlow hSpacing={0.5}>
        <Text>{row.cbo.cbo2002}</Text>
      </HFlow>
    )
  }

  const renderDuracao = (row: CboTableItemDto) => {
    return (
      <HFlow hSpacing={0.5}>
        <Text>{row.duracao || row.duracaoPadrao}</Text>
      </HFlow>
    )
  }

  const renderButtons = (row: CboTableItemDto) => {
    return (
      <HFlow justifyContent='flex-end' hSpacing={0}>
        <CboEditComponent reload={refetch} item={row} />
        <CboTableDropdown row={row} afterMutate={refetch} />
      </HFlow>
    )
  }

  const tableProps = usePagedTableProps({
    loading,
    result: cbos,
    onChange: setFilter,
  })

  return (
    <TableBox header={<CboTableFilter initialValues={filter} onChange={setFilter} />}>
      <PagedTable<CboTableItemDto>
        {...tableProps}
        data-testid='CboTable'
        columns={[
          { name: 'nome', header: 'Nome', render: renderNome, sortable: true },
          { name: 'cbo2002', header: 'Código', render: renderCbo, sortable: true },
          { name: 'duracao', header: 'Duração do agendamento', render: renderDuracao, sortable: true },
          { name: 'buttons', render: renderButtons, style: { textAlign: 'right' } },
        ]}
      />
    </TableBox>
  )
}
