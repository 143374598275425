import { ArquivoStatusEnum, StatusArquivo } from 'graphql/types.generated'

export interface ArquivoModel extends Omit<StatusArquivo, '__typename'> {}

export const arquivoStatusEnumToString: Record<ArquivoStatusEnum, string> = {
  [ArquivoStatusEnum.EM_PROCESSAMENTO]: 'Em processamento',
  [ArquivoStatusEnum.PRONTO]: 'Pronto',
  [ArquivoStatusEnum.ERRO]: 'Erro',
}

export interface ArquivoFormato {
  arquivo: number[]
  formato: string
}
