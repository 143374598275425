/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  Tag,
  VFlow,
} from 'bold-ui'
import { InfoIcon } from 'components/InfoIcon'
import { Cep, Telefone } from 'components/label'
import { differenceInCalendarDays } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import { useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { numeroTotalCidadaosImovel } from 'view/reterritorializacao/utils/reterritorializacao'
import {
  domicilioTipoImovelRecord,
  domicilioTipoRecord,
  ImovelModel,
  localizacaoTipoRecord,
  TipoImovelEnum,
  tipoImovelRecord,
  TipoResponsavelEnum,
} from 'view/visualizacaoTerritorial/model'
import { redirectToAtualizarImovelCds } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { CidadaosImovelList } from './CidadaosImovelList'

interface ModalImovelProps extends ModalProps {
  imovel: ImovelModel
  lotacaoId: ID
  profissionalId: ID
}

export function ModalImovel(props: ModalImovelProps) {
  const {
    imovel: {
      uuidOrigemImovel,
      cep,
      complemento,
      numero,
      diasDesdeUltimaVisita,
      familias,
      tipoCdsImovelId,
      tipoDomicilioId,
      numeroMoradores,
      logradouro,
      bairro,
      telefoneResidencial,
      telefoneContato,
      tipoLocalizacaoId,
      nomeTipoLogradouro,
      imovelId,
    },
    lotacaoId,
    profissionalId,
    onClose,
    ...rest
  } = props

  const { IconImovel, fill, tipoResponsavel } =
    tipoCdsImovelId !== TipoImovelEnum.DOMICILIO
      ? tipoImovelRecord[tipoCdsImovelId]
      : domicilioTipoImovelRecord[tipoDomicilioId]

  const tagDomicilio = domicilioTipoRecord[tipoDomicilioId]
  const tagLocalizacao = localizacaoTipoRecord[tipoLocalizacaoId]

  const numeroTotalCidadaos = useMemo(() => numeroTotalCidadaosImovel(familias), [familias])
  const { getServerTimeNow } = useServerTime()

  const style = createStyles(fill)

  const history = useHistory()
  const match = useRouteMatch()

  return (
    <Modal size='small' closeOnBackdropClick onClose={onClose} {...rest}>
      <ModalBody>
        <HFlow alignItems='center' hSpacing={0.5}>
          <IconImovel css={style.icon} />
          <VFlow vSpacing={0}>
            <Heading level={5}>Domicílio</Heading>
            <Heading level={1}>
              {`${nomeTipoLogradouro} ${logradouro}`.titleCase()} nº {numero ? numero : 'S/N'}
              {complemento ? ` | ${complemento.titleCase()}` : ''}
            </Heading>
          </VFlow>
        </HFlow>
        <HFlow
          hSpacing={0.2}
          style={css`
            margin-left: 3rem;
            margin-top: 0.5rem;
          `}
        >
          {tipoDomicilioId && <Tag style={style.tag}>{tagDomicilio}</Tag>}
          {tipoLocalizacaoId && <Tag style={style.tag}>{tagLocalizacao}</Tag>}
          <Tag style={style.tag}>
            Nº de moradores: {tipoResponsavel === TipoResponsavelEnum.TECNICO ? numeroMoradores : numeroTotalCidadaos}
          </Tag>
        </HFlow>

        <VFlow
          style={css`
            margin-top: 1rem;
          `}
        >
          <Grid>
            <Cell size={3}>
              <InfoLabel title='CEP'>
                <Cep value={cep} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Bairro'>{bairro?.titleCase()}</InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Telefone'>
                <Telefone value={telefoneResidencial ?? telefoneContato} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <HFlow>
                <InfoLabel title='Última visita'>
                  {diasDesdeUltimaVisita
                    ? getTextoDiasDesdeUltimaVisita(getServerTimeNow(), diasDesdeUltimaVisita)
                    : 'desconhecida'}
                </InfoLabel>
                <InfoIcon
                  icon='infoCircleFilled'
                  text='Data da visita mais recente realizada para o imóvel em si, a família ou qualquer um dos moradores.'
                />
              </HFlow>
            </Cell>
            <Cell size={12}>
              <CidadaosImovelList imovelId={imovelId} />
            </Cell>
          </Grid>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button
            title='Atualizar imóvel'
            kind='normal'
            onClick={() => redirectToAtualizarImovelCds(history, match, uuidOrigemImovel, profissionalId, lotacaoId)}
          >
            Atualizar imóvel
          </Button>
          <Button title='Fechar' kind='primary' onClick={onClose}>
            Fechar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const getTextoDiasDesdeUltimaVisita = (now: Date, ultimaVisita: Instant) =>
  'dia'.pluralizeAndConcatValue(differenceInCalendarDays(now, ultimaVisita))

const createStyles = (iconFill: string) => ({
  tag: css`
    margin-right: 0.5rem;
  `,
  icon: css`
    fill: ${iconFill};
    width: 2.5rem;
    height: 2.5rem;
  `,
})
