/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading } from 'bold-ui'
import { EquipeSelectField, Form, NumberField, SEM_EQUIPE_ITEM, UnidadeSaudeSelectField } from 'components/form'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/lotacaoAndEstagioField/LotacaoAndEstagioSelectField'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { useAtualizarImoveisMutation } from 'graphql/hooks.generated'
import { UnidadeSaude } from 'graphql/types.generated'
import { FormRenderProps } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'

import { createAtualizaImoveisFormCalculator } from './calculator'
import { AtualizacaoImoveisModal } from './components/AtualizacaoImoveisModal'
import { AtualizacaoImoveisFormModel } from './model'
import { validateAtualizacaoImoveisForm } from './validator'

interface AtualizacaoImoveisProps {
  changeToProcessingState(): void
  unidadeSaude: UnidadeSaude
  checkedRowItems: { imoveisId: ID[]; numeroCidadaos: number }
  disabled?: boolean
  progressToken: string
}
const meta = metaPath<AtualizacaoImoveisFormModel>()

export const ReterritorializacaoAtualizacaoForm = ({
  changeToProcessingState,
  unidadeSaude,
  checkedRowItems: { numeroCidadaos, imoveisId },
  disabled,
  progressToken,
}: AtualizacaoImoveisProps) => {
  const [atualizarImoveis] = useAtualizarImoveisMutation()

  const onHandleSubmit = (formValues: AtualizacaoImoveisFormModel, formApi: FormApi) => {
    confirm({
      title: <Heading level={2}> Confirmar atualização dos imóveis?</Heading>,
      body: <AtualizacaoImoveisModal numeroImoveis={imoveisId.length} numeroCidadaos={numeroCidadaos} />,
      onConfirm: async () => {
        formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
        setTimeout(formApi.reset)
        changeToProcessingState()
        await atualizarImoveis({
          variables: {
            input: {
              novaMicroarea: formValues.novaMicroarea,
              lotacaoId: formValues.lotacao.id,
              imoveisId: imoveisId,
              progressToken: progressToken,
            },
          },
        })
      },
    })()
  }
  const semImoveisEncontrados = imoveisId.length === 0

  const renderForm = ({ handleSubmit, values }: FormRenderProps<AtualizacaoImoveisFormModel>) => {
    return (
      <Grid
        style={css`
          width: 100%;
          margin-bottom: 1rem;
        `}
      >
        <Cell size={3}>
          <UnidadeSaudeSelectField
            name={meta.unidadeSaude}
            label='Unidade de Saúde'
            defaultValue={unidadeSaude}
            disabled={disabled}
            required
          />
        </Cell>
        <Cell size={2}>
          <EquipeSelectField
            name={meta.equipe}
            unidadeSaudeId={values.unidadeSaude?.id}
            disabled={disabled || isUndefinedOrNull(values?.unidadeSaude)}
            label='Equipe'
            showSemEquipeOption
            required
          />
        </Cell>
        <Cell size={3}>
          <LotacaoAndEstagioSelectField
            name={meta.lotacao}
            label='Profissional'
            unidadeSaudeId={values.unidadeSaude?.id}
            equipeId={values.equipe?.id}
            disabled={disabled || isUndefinedOrNull(values?.equipe)}
            semEquipe={values.equipe?.id === SEM_EQUIPE_ITEM.id}
            required
          />
        </Cell>
        <Cell size={2}>
          <NumberField
            name={meta.novaMicroarea}
            label='Microárea'
            maxLength={2}
            minLength={2}
            disabled={disabled}
            required
          />
        </Cell>
        <Cell size={2}>
          <Button
            kind='primary'
            size='small'
            style={css`
              margin-top: 1.5rem;
            `}
            onClick={handleSubmit}
            disabled={disabled || isSubmitButtonDisable(values, semImoveisEncontrados)}
          >
            Atualizar imóveis
          </Button>
        </Cell>
      </Grid>
    )
  }

  return (
    <Form<AtualizacaoImoveisFormModel>
      render={renderForm}
      validate={validateAtualizacaoImoveisForm}
      onSubmit={onHandleSubmit}
      decorators={decorators}
    />
  )
}

const decorators = [createAtualizaImoveisFormCalculator(meta)]

const isSubmitButtonDisable = (values: AtualizacaoImoveisFormModel, semImoveisEncontrados: boolean) =>
  !(values.novaMicroarea && values.unidadeSaude && values.lotacao && values.equipe) || semImoveisEncontrados
