import { ListaEsperaItemUpdateInput } from 'graphql/types.generated'

import { ListaEsperaRemocaoModel } from '../model'

export const convertModelToInput = (values: ListaEsperaRemocaoModel): ListaEsperaItemUpdateInput => ({
  listaEsperaId: values.listaEsperaId,
  motivoSaida: values.motivoSaida,
  tentativasContato: values.qtdTentativasContato,
  observacaoSaida: values.observacoes,
})
