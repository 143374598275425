import { EquipeSelectModel } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectFieldModel } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { PageParams, TipoVisualizacaoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { ImunobiologicoSelectModel } from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'

import { GrupoAlvoVacinacaoSelectModel } from './component/GrupoAlvoVacinacaoSelectField'
import { StatusDoseImunoSelectModel } from './component/StatusDoseImunoSelectField'

export const BUSCA_ATIVA_VACINACAO_PATH = 'busca-ativa-vacinacao'

export const metaBuscaAtivaVacinacao = metaPath<BuscaAtivaVacinacaoFormModel>()

export interface BuscaAtivaVacinacaoFormModel {
  equipe?: EquipeSelectModel
  grupoAlvo?: GrupoAlvoVacinacaoSelectModel
  status?: StatusDoseImunoSelectModel
  microareas?: string[]
  filtrosAvancados?: BuscaAtivaVacinacaoFilterPopperFormModel
  tipoVisualizacao?: TipoVisualizacaoBuscaAtivaVacinacaoEnum
  pageParams?: PageParams
}

export interface BuscaAtivaVacinacao {
  idCidadao: ID
  nomeCidadao: string
  nomeSocialCidadao?: string
  cpfCidadao?: string
  cnsCidadao?: string
  dataNascimentoCidadao: LocalDate
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  vacinasAtrasadas?: ImunobiologicoBuscaAtiva[]
  vacinasNoPrazo?: ImunobiologicoBuscaAtiva[]
  vacinasAplicadas?: ImunobiologicoBuscaAtiva[]
  endereco?: string
}

export interface ImunobiologicoBuscaAtiva {
  cidadaoId: ID
  dataAplicacaoVacina?: LocalDate
  nome: string
  siglaDose: string
}

export const tipoVisualizacaoRecord: Record<TipoVisualizacaoBuscaAtivaVacinacaoEnum, string> = {
  CALENDARIO_VACINAL: 'Calendário vacinal',
  COVID_19: 'Covid-19',
}

export interface BuscaAtivaVacinacaoFilterPopperFormModel {
  imunobiologicos: ImunobiologicoSelectModel[]
  doses: DoseImunobiologicoVacinacaoSelectFieldModel[]
}
