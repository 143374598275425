/** @jsx jsx */
import { jsx } from '@emotion/core'
import { forwardRef } from 'react'

import { LocalStreamPlayerVideochamada } from './LocalStreamPlayerVideochamada'
import { RemoteStreamPlayerVideochamada } from './RemoteStreamPlayerVideochamada'

export interface LocalStreamPlayerVideochamadaProps {
  minimizada?: boolean
  videoDeviceAvailable?: boolean
  videoEnabled?: boolean
}
export interface RemoteStreamPlayerVideochamadaProps {
  streamAtivo?: boolean
  videoEnabled?: boolean
  nomeParticipante: string
}

type StreamPlayerVideochamadaProps =
  | (LocalStreamPlayerVideochamadaProps & { readonly localStream: true })
  | (RemoteStreamPlayerVideochamadaProps & { readonly localStream?: false })

export const StreamPlayerVideochamada = forwardRef<HTMLVideoElement, StreamPlayerVideochamadaProps>(
  ({ localStream, ...rest }, ref) => {
    if (localStream === true)
      return <LocalStreamPlayerVideochamada ref={ref} {...(rest as LocalStreamPlayerVideochamadaProps)} />
    else return <RemoteStreamPlayerVideochamada ref={ref} {...(rest as RemoteStreamPlayerVideochamadaProps)} />
  }
)
