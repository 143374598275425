/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, Heading, HFlow, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Breadcrumb } from 'components/breadcrumb'
import { CheckboxField, Form, FormRenderProps } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { useCriarVideochamadaMutation } from 'graphql/hooks.generated'
import { Fragment, useState } from 'react'
import { isHttpsConnection } from 'util/https'
import { metaPath } from 'util/metaPath'

import { CameraPreview } from './componentes/CameraPreview'

const meta = metaPath<CriarVideochamadaFormModel>()
interface CriarVideochamadaFormModel {
  cidadaoAutorizou: boolean
}

export function CriarVideochamadaView() {
  const alert = useAlert()

  const [audioEnabled, setAudioEnabled] = useState(true)
  const [videoEnabled, setVideoEnabled] = useState(true)

  const [criarVideochamada] = useCriarVideochamadaMutation()

  const handleCriarVideochamadaSubmit = async (model: CriarVideochamadaFormModel) => {
    if (!isHttpsConnection) {
      alert('danger', 'Não é possível iniciar uma videochamada em um acesso sem HTTPS.')
    } else if (!model.cidadaoAutorizou) {
      alert('danger', 'O cidadão precisa autorizar a teleinterconsulta.')
    } else {
      try {
        const {
          data: { criarVideochamada: videochamada },
        } = await criarVideochamada()

        window.open(
          `/videochamada/${videochamada.id}?video=${videoEnabled}&audio=${audioEnabled}`,
          'videochamada',
          `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}, noreferrer`
        )
      } catch {
        alert('danger', 'Erro ao criar videochamada.')
      }
    }
  }

  const renderForm = ({ values, handleSubmit }: FormRenderProps<CriarVideochamadaFormModel>) => (
    <VFlow>
      <Heading level={2}>Teleinterconsulta</Heading>
      <Text>
        As videochamadas e-SUS APS permitem que os profissionais do PEC realizem teleinterconsultas com profissionais
        externos através de um canal oficial de comunicação.
      </Text>
      <Box>
        <HFlow>
          <FormControl label='Autorização do cidadão' required>
            <CheckboxField
              name={meta.cidadaoAutorizou}
              label='O cidadão está ciente e concorda com a realização da teleinterconsulta.'
            />
          </FormControl>
          <div css={styles.buttonContainer}>
            <Button kind='primary' onClick={handleSubmit} disabled={!values.cidadaoAutorizou}>
              Iniciar chamada
            </Button>
          </div>
        </HFlow>
      </Box>
    </VFlow>
  )

  return (
    <Fragment>
      <Breadcrumb title='Videochamadas' />
      <PageHeaderSection title='Videochamadas e-SUS APS' />
      <PageContent>
        <Box style={styles.container}>
          <Grid>
            <Cell size={6}>
              <Form<CriarVideochamadaFormModel> render={renderForm} onSubmit={handleCriarVideochamadaSubmit} />
            </Cell>
            <Cell size={1} />
            <Cell size={5}>
              <CameraPreview
                audioEnabled={audioEnabled}
                videoEnabled={videoEnabled}
                setAudioEnabled={setAudioEnabled}
                setVideoEnabled={setVideoEnabled}
              />
            </Cell>
          </Grid>
        </Box>
      </PageContent>
    </Fragment>
  )
}

const styles = {
  container: css`
    padding: 2.5rem;
  `,
  buttonContainer: css`
    display: flex;
    align-items: end;
  `,
}
