import { Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date/DateTime'
import { IdadeGestacionalAcompanhamentoPreNatal } from 'graphql/types.generated'
import React from 'react'
import { calculateDpp } from 'util/date/calculateDpp'
import { STATUS_DUM_ATENDIMENTO_ATUAL } from 'view/atendimentos/detail/components/modals/medicoes/model'

import { RiscoGravidezTag } from '../../../pre-natal/components/RiscoGravidezTag'
import { PreNatalCardContentModel } from '../../../pre-natal/model'
import { formatIdadeGestacional } from '../../../pre-natal/util'
import { ContentCardSection } from '../../components/ContentCardSection'
import { PreNatalCardItems } from './PreNatalCardItems'
import { TagGestacaoEncerrada } from './TagGestacaoEncerrada'

interface PreNatalCardProps {
  content: PreNatalCardContentModel
  loading: boolean
  isAltoRisco: boolean
  tipoGravidez: string
  hasTipoGravidezAtendimento: boolean
  dataUltimaConsultaOdontoGestante: LocalDate
  readOnly: boolean
  idadeGestacional?: IdadeGestacionalAcompanhamentoPreNatal
}

function renderDum(dataUltimaMenstruacao: Date, hasDumAtendimento: boolean) {
  return (
    <PreNatalCardItems<Date>
      label='DUM'
      value={dataUltimaMenstruacao}
      content={<DateTime format='DD/MM/YYYY' value={dataUltimaMenstruacao} />}
      isRegistradoAgora={hasDumAtendimento}
    />
  )
}

function renderIdadeGestacional(idadeGestacional: IdadeGestacionalAcompanhamentoPreNatal, isRegistradoAgora: boolean) {
  const fraseIgSemanasDias = formatIdadeGestacional(idadeGestacional?.dias)

  return (
    <PreNatalCardItems<string>
      label={idadeGestacional?.isEcografica ? 'IG eco.' : 'IG cron.'}
      value={fraseIgSemanasDias}
      content={fraseIgSemanasDias}
      isRegistradoAgora={isRegistradoAgora}
    />
  )
}

function renderDppCronologica(dataUltimaMenstruacao: Date, hasDumAtendimento: boolean) {
  const { dpp } = calculateDpp(dataUltimaMenstruacao)

  return (
    <PreNatalCardItems<Date>
      label='DPP cronológica'
      value={dpp}
      content={<DateTime format='DD/MM/YYYY' value={dpp} />}
      isRegistradoAgora={hasDumAtendimento}
    />
  )
}

function renderDppEcografica(dataProvavelPartoEcografica: Date, hasdppEcoAtendimento: boolean) {
  return (
    <PreNatalCardItems<Date>
      label='DPP ecográfica'
      value={dataProvavelPartoEcografica}
      content={<DateTime format='DD/MM/YYYY' value={dataProvavelPartoEcografica} />}
      isRegistradoAgora={hasdppEcoAtendimento}
    />
  )
}

function renderTipoGravidez(tipoGravidez: string, hasTipoGravidezAtendimento: boolean) {
  return (
    <PreNatalCardItems<string>
      label='Tipo de gravidez'
      value={tipoGravidez}
      content={tipoGravidez}
      isRegistradoAgora={hasTipoGravidezAtendimento}
    />
  )
}

export function PreNatalCard(props: PreNatalCardProps) {
  const {
    dataUltimaConsultaOdontoGestante,
    content,
    loading,
    tipoGravidez,
    hasTipoGravidezAtendimento,
    isAltoRisco,
    readOnly,
    idadeGestacional,
  } = props

  const hasDumAtendimentoAtual = STATUS_DUM_ATENDIMENTO_ATUAL.has(content.origemDum)

  return (
    <ContentCardSection isLoading={loading} messageLoading='Carregando dados do pré-natal'>
      <VFlow vSpacing={0.5}>
        {!readOnly && <TagGestacaoEncerrada />}
        <RiscoGravidezTag isAltoRisco={isAltoRisco} />
        {renderDum(content.dum, hasDumAtendimentoAtual)}
        {renderIdadeGestacional(
          idadeGestacional,
          idadeGestacional?.isEcografica ? content.hasExamesPreNatalAtendimento : hasDumAtendimentoAtual
        )}
        {renderDppCronologica(content.dum, hasDumAtendimentoAtual)}
        {renderDppEcografica(content.dataProvavelPartoEcografica, content.hasdppEcoAtendimento)}
        {renderTipoGravidez(tipoGravidez, hasTipoGravidezAtendimento)}
        <Text>
          <strong>Consultas: </strong>
          {content?.quantidadeConsultas + ' | Última em '}
          <DateTime format='DD/MM/YYYY' value={content?.dataUltimaConsulta} />
        </Text>
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>Última consulta odontológica:</Text>
          {dataUltimaConsultaOdontoGestante ? (
            <DateTime format='DD/MM/YYYY' value={dataUltimaConsultaOdontoGestante} />
          ) : (
            <Text>Consulta não realizada</Text>
          )}
        </VFlow>
      </VFlow>
    </ContentCardSection>
  )
}
