import { SelectField, SelectFieldProps } from 'components/form'
import { resolveName } from 'components/form/final-form/useField'
import { MicroareasSelectFieldDocument } from 'graphql/hooks.generated'
import { MicroareasSelectFieldQuery, MicroareasSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { EquipeSelectModel } from 'view/ad/componente/EquipeAdSelectField'

import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface MicroareaSelectFieldProps extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {
  metaEquipe: MetaPath<EquipeSelectModel>
}

const itemToString = (item: string) => item

export function MicroareaSelectField(props: MicroareaSelectFieldProps) {
  const { metaEquipe, ...rest } = props

  const {
    input: { value: equipe },
  } = useField<EquipeSelectModel>(resolveName(metaEquipe), { subscription: { value: true } })

  const { onFilterChangeWithoutRefetch, ...asyncProps } = useAsyncQuerySelect<
    string,
    MicroareasSelectFieldQuery,
    MicroareasSelectFieldQueryVariables
  >({
    query: MicroareasSelectFieldDocument,
    extractItems: (data) => data?.microareas,
    variables: () => ({ equipeResponsavelId: equipe?.id }),
    itemToString,
  })

  return (
    <SelectField<string>
      {...rest}
      {...asyncProps}
      multiple
      itemToString={itemToString}
      onFilterChange={onFilterChangeWithoutRefetch}
    />
  )
}
