import { AcessoCbo } from 'graphql/types.generated'
import { useCallback } from 'react'
import { Permission } from 'types/Permissions'

import useSession from './useSession'
import { Authorization } from './useSessionUtils'

interface CboAndResourceAuthorization {
  hasAnyCbo: AcessoCbo[][]
  hasAnyPermission: Permission[]
}

interface ResourceAuthorization {
  permission: Authorization
}

type CheckPermissionProps = (ResourceAuthorization | CboAndResourceAuthorization) & {
  children: any
  acceptEstagio?: boolean
}

const CheckPermission = (props: CheckPermissionProps) => {
  const { acceptEstagio = true, children } = props
  const { checkAuthorization, isEstagio } = useSession({ fetchPolicy: 'cache-only' })

  const hasAuthorization = useCallback(
    (props: CheckPermissionProps) => {
      if (!acceptEstagio && isEstagio) {
        return false
      } else if (isCboAndResourceAuthorization(props)) {
        return props.hasAnyPermission.some(checkAuthorization) && props.hasAnyCbo.some(checkAuthorization)
      } else {
        return checkAuthorization(props.permission)
      }
    },
    [acceptEstagio, checkAuthorization, isEstagio]
  )

  return hasAuthorization(props) ? children : null
}

export default CheckPermission

const isCboAndResourceAuthorization = (x: any): x is CboAndResourceAuthorization => x.hasAnyCbo
