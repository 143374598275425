/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Cep } from 'components/label'
import { LoadingIndicator } from 'components/loading'
import { useVisualizarImoveisQuery } from 'graphql/hooks.generated'
import { TerritorioLogradouroQueryInput } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { Fragment, useMemo, useState } from 'react'
import { FamiliasTerritorio } from 'view/reterritorializacao/model'
import {
  getNomesResponsaveisFamiliares,
  getTooltipResponsaveisFamiliares,
  numeroTotalCidadaosImovel,
} from 'view/reterritorializacao/utils/reterritorializacao'
import {
  domicilioTipoImovelRecord,
  OUTROS_IMOVEIS,
  TerritorioMicroareaItemModel,
  TerritorioModel,
  TipoDomicilioEnum,
  TipoImovelEnum,
  tipoImovelRecord,
  TipoResponsavelEnum,
} from 'view/visualizacaoTerritorial/model'
import { ImovelModel } from 'view/visualizacaoTerritorial/model'
import { getTextoDiasDesdeUltimaVisita } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { ModalImovel } from './ModalImovel'
import { ModalOutrosImoveis } from './ModalOutrosImoveis'

interface MicroareaLogradouroListItemBodyProps {
  rowItem: TerritorioMicroareaItemModel
  territorioProps: TerritorioModel
  isExpanded: boolean
  lotacaoId: ID
  profissionalId: ID
}

interface NomeResponsavelFieldProps {
  familias: FamiliasTerritorio[]
  nomeResponsavelTecnico: string
  tipoResponsavel: TipoResponsavelEnum
}

interface ImovelCardItem extends ImovelModel {
  lotacaoId: ID
  profissionalId: ID
}

export default function MicroareaLogradouroListItemBody(props: MicroareaLogradouroListItemBodyProps) {
  const {
    rowItem: { bairro, logradouro, nomeTipoLogradouro },
    territorioProps: { cnes, ine, microarea, cep },
    isExpanded,
    lotacaoId,
    profissionalId,
  } = props

  const [queryInput, setQueryInput] = useState<TerritorioLogradouroQueryInput>()

  const {
    data: { territorioLogradouro },
    loading,
  } = useVisualizarImoveisQuery({
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'network-only',
    skip: !isExpanded,
  })

  useMemo(() => {
    if (isExpanded) {
      setQueryInput({
        bairro,
        logradouro,
        nomeTipoLogradouro,
        cnes,
        microarea,
        ine,
        cep,
      })
    }
  }, [bairro, cep, cnes, ine, isExpanded, logradouro, microarea, nomeTipoLogradouro])

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0} style={styles.containerLogradouro} onClick={(e) => e.stopPropagation()}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        territorioLogradouro?.map((value) => (
          <LogradouroImovelItemBody
            key={value.id}
            imovelId={value.id}
            lotacaoId={lotacaoId}
            profissionalId={profissionalId}
            uuidOrigemImovel={value.uuidOrigemImovel}
            cep={value.cep}
            complemento={value.complemento}
            numero={value.numero}
            responsavelTecnico={value.responsavelTecnico}
            tipoCdsImovelId={value.tipoCdsImovelId}
            tipoDomicilioId={value?.ultimaFichaProcessada.tipoDomicilioId}
            diasDesdeUltimaVisita={value.dataUltimaVisita}
            familias={value.familias}
            numeroMoradores={value?.ultimaFichaProcessada?.numeroMoradores}
            logradouro={logradouro}
            nomeTipoLogradouro={nomeTipoLogradouro}
            bairro={bairro}
            telefoneResidencial={value.telefoneResidencial}
            telefoneContato={value.telefoneContato}
            tipoLocalizacaoId={value?.ultimaFichaProcessada?.tipoLocalizacaoId}
          />
        ))
      )}
    </VFlow>
  )
}

const LogradouroImovelItemBody = React.memo((props: ImovelCardItem) => {
  const {
    cep,
    complemento,
    numero,
    diasDesdeUltimaVisita,
    responsavelTecnico,
    familias,
    tipoCdsImovelId,
    tipoDomicilioId,
    numeroMoradores,
    lotacaoId,
    profissionalId,
  } = props

  const { getServerTimeNow } = useServerTime()
  const numeroTotalCidadaos = numeroTotalCidadaosImovel(familias)
  const [isModalOpen, setModalOpen] = useState(false)

  const { IconImovel, tooltip, fill, tipoResponsavel } =
    +tipoCdsImovelId !== TipoImovelEnum.DOMICILIO
      ? tipoImovelRecord[+tipoCdsImovelId]
      : domicilioTipoImovelRecord[tipoDomicilioId ? +tipoDomicilioId : TipoDomicilioEnum.CASA]

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <HFlow
      style={css`
        padding: 0.5rem 1rem;
      `}
    >
      <Tooltip text={tooltip}>
        <IconImovel
          css={css`
            fill: ${fill};
            margin-top: 1.35rem;
          `}
        />
      </Tooltip>

      <Grid style={styles.imovelCardItem}>
        <Cell
          size={12}
          style={css`
            padding-top: 0.5rem;
          `}
        >
          <Text fontWeight='bold' variant='h3'>
            nº {numero} {complemento ? ` | ${complemento}` : ''}
          </Text>
        </Cell>
        <Cell size={2}>
          <Text>
            CEP <Cep value={cep} />
          </Text>
        </Cell>
        <Cell size={3}>
          <Text fontWeight='bold'> Última visita </Text>
          <Text>
            {diasDesdeUltimaVisita
              ? getTextoDiasDesdeUltimaVisita(getServerTimeNow(), diasDesdeUltimaVisita)
              : 'desconhecida'}
          </Text>
        </Cell>
        {tipoResponsavel !== TipoResponsavelEnum.SEM_RESPONSAVEL ? (
          <Fragment>
            <Cell
              size={4}
              style={css`
                white-space: break-spaces;
              `}
            >
              {tipoResponsavel === TipoResponsavelEnum.TECNICO ? (
                <Text fontWeight='bold'> Responsável técnico </Text>
              ) : (
                <Text fontWeight='bold'> Responsável familiar </Text>
              )}
              <NomeResponsavelField
                familias={familias}
                nomeResponsavelTecnico={responsavelTecnico?.nomeResponsavelTecnico}
                tipoResponsavel={tipoResponsavel}
              />
            </Cell>
            <Cell size={2}>
              <Text fontWeight='bold'> Nº de cidadãos </Text>
              {tipoResponsavel === TipoResponsavelEnum.TECNICO ? numeroMoradores : numeroTotalCidadaos}
            </Cell>
          </Fragment>
        ) : (
          <Cell size={6} />
        )}
        <Cell size={1}>
          <Button skin='ghost' kind='primary' onClick={() => setModalOpen(true)} style={styles.button}>
            <Icon icon='zoomOutline' />
          </Button>
        </Cell>
      </Grid>

      {isModalOpen && OUTROS_IMOVEIS.includes(+tipoCdsImovelId) ? (
        <ModalOutrosImoveis
          imovel={props}
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          lotacaoId={lotacaoId}
          profissionalId={profissionalId}
        />
      ) : (
        <ModalImovel
          imovel={props}
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          lotacaoId={lotacaoId}
          profissionalId={profissionalId}
        />
      )}
    </HFlow>
  )
})

const createStyles = (theme: Theme) => ({
  containerLogradouro: css`
    & > :not(:last-child) {
      border-bottom: 1px solid ${theme.pallete.gray.c70};
    }
    scroll-behavior: smooth;
    overflow: auto;
    white-space: nowrap;
    max-height: 25rem;
  `,
  imovelCardItem: css`
    width: 65rem;
    align-items: center;
  `,
  button: css`
    color: ${theme.pallete.gray.c20};
    padding: 0.5rem;
    margin-left: 3rem;
  `,
})

const NomeResponsavelField = (props: NomeResponsavelFieldProps) => {
  const { familias, nomeResponsavelTecnico, tipoResponsavel } = props

  if (tipoResponsavel === TipoResponsavelEnum.TECNICO) {
    return <Text>{nomeResponsavelTecnico ?? 'desconhecido'}</Text>
  } else if (tipoResponsavel === TipoResponsavelEnum.FAMILIAR) {
    return <ResponsavelTooltipAndText familias={familias} />
  }
}

const ResponsavelTooltipAndText = (props: { familias: FamiliasTerritorio[] }) => {
  const { familias } = props

  return (
    <Tooltip text={getTooltipResponsaveisFamiliares(familias)} placement='bottom-end'>
      <Text>{familias.length !== 0 ? getNomesResponsaveisFamiliares(familias) : 'não informado'}</Text>
    </Tooltip>
  )
}
