/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { useEffect, useRef } from 'react'

import { AcaoVideochamadaButton } from '../componentes/AcaoVideochamadaButton'
import { useUserMedia } from '../hooks/useUserMedia'
import placeholderImage from '../images/placeholder.png'

interface CameraPreviewProps {
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
}

export function CameraPreview(props: CameraPreviewProps) {
  const { audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled } = props

  const localVideoRef = useRef<HTMLVideoElement>()

  const { stream: localStream, audioDeviceAvailable, videoDeviceAvailable } = useUserMedia({
    video: videoEnabled,
    audio: audioEnabled,
  })

  useEffect(() => {
    if (localVideoRef.current) localVideoRef.current.srcObject = localStream
  }, [localStream])

  return (
    <div css={styles.cameraAndButtons}>
      {!localStream?.getVideoTracks().length ? (
        <img src={placeholderImage} alt='Imagem padrão' width='100%' height='auto' />
      ) : (
        <video playsInline muted ref={localVideoRef} autoPlay width='100%' />
      )}
      <HFlow style={styles.buttons}>
        <AcaoVideochamadaButton
          unavailable={!audioDeviceAvailable}
          enabled={audioDeviceAvailable && audioEnabled}
          onClick={() => setAudioEnabled(!audioEnabled)}
          icon='mic'
        />
        <AcaoVideochamadaButton
          unavailable={!videoDeviceAvailable}
          enabled={videoDeviceAvailable && videoEnabled}
          onClick={() => setVideoEnabled(!videoEnabled)}
          icon='video'
        />
      </HFlow>
    </div>
  )
}

const styles = {
  cameraAndButtons: css`
    position: relative;
  `,
  buttons: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3rem;
    z-index: 1;
    height: 0;
  `,
}
