import React from 'react'

import { CondutaOdontoPanel, CondutaOdontoPanelProps } from './components/CondutaOdontoPanel'
import { CondutaPanel, CondutaPanelProps } from './components/CondutaPanel'
import { FinalizacaoAtendimentoFormProps } from './FinalizacaoAtendimentoForm'

interface FinalizacaoAtendimentoCondutaPanelProps
  extends Pick<FinalizacaoAtendimentoFormProps, 'isAtendimentoOdonto' | 'isAtendimentoProcedimentos' | 'name'>,
    Omit<CondutaPanelProps, 'name'>,
    Omit<CondutaOdontoPanelProps, 'name'> {}

export function FinalizacaoAtendimentoCondutaPanel(props: FinalizacaoAtendimentoCondutaPanelProps) {
  const {
    isAtendimentoProcedimentos,
    isAtendimentoOdonto,
    tipoConsulta,
    hasEncaminhamentoExterno,
    hasNotificacaoCasoSuspeito,
    isStartObservacao,
    name,
  } = props

  if (isAtendimentoOdonto)
    return (
      <CondutaOdontoPanel
        name={name.condutasOdonto}
        tipoConsulta={tipoConsulta}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
      />
    )

  if (!isAtendimentoProcedimentos)
    return (
      <CondutaPanel
        name={name.conduta}
        hasNotificacaoCasoSuspeito={hasNotificacaoCasoSuspeito}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
        isStartObservacao={isStartObservacao}
      />
    )

  return null
}
