/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { useSugestaoAgendamentoQuery } from 'graphql/hooks.generated'
import { AcessoCbo } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { MetaPath } from 'util/metaPath'

import { AgendarConsultaSectionModel } from '../AgendarConsultaSection'
import { AgendarProximaConsultaCard } from './AgendarProximaConsultaCard'
import { ProximaConsultaTypeEnum } from './model'

export interface AgendarProximasConsultasSectionModel {
  proximaConsultaPreNatal?: AgendarConsultaSectionModel
  proximaConsultaOdontologica?: AgendarConsultaSectionModel
}

export interface AgendarProximasConsultasSectionProps {
  name: MetaPath<AgendarProximasConsultasSectionModel>
  prontuarioId?: ID
}

export function AgendarProximasConsultasSection(props: AgendarProximasConsultasSectionProps) {
  const { name, prontuarioId } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  const {
    data: { sugestoesProfissionais },
    loading,
  } = useSugestaoAgendamentoQuery({
    variables: { prontuarioId },
    skip: !prontuarioId,
    fetchPolicy: 'cache-first',
  })
  // TODO (eldorado #14728) preencher as sugestoes profissionais no form

  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const dataSugeridaPrenatal = now
  const dataSugeridaConsultaOdontologica = now
  const hasDataSugerida = dataSugeridaPrenatal || dataSugeridaConsultaOdontologica

  if (!hasDataSugerida) return null

  //TODO (eldorado #14803) Utilizar um indicador de loading enquanto estiver carregando

  return loading ? null : (
    <VFlow vSpacing={0.5}>
      <Heading level={3}>
        <HFlow alignItems='center' hSpacing={0.5}>
          Agendar próximas consultas
          <Tooltip text='Não foram realizados agendamentos, as datas abaixo são apenas sugestões'>
            <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.infoIcon} />
          </Tooltip>
        </HFlow>
      </Heading>
      <Text>Clique em agendar para definir o horário e detalhes do agendamento</Text>
      {dataSugeridaPrenatal && (
        <AgendarProximaConsultaCard
          name={name.proximaConsultaPreNatal}
          type={ProximaConsultaTypeEnum.PRE_NATAL}
          dataSugerida={now}
          lotacaoSugerida={sugestoesProfissionais?.preNatal}
          cbosAcesso={CBOS_MEDICO_OU_ENFERMEIRO}
        />
      )}
      {dataSugeridaConsultaOdontologica && (
        <AgendarProximaConsultaCard
          name={name.proximaConsultaOdontologica}
          type={ProximaConsultaTypeEnum.ODONTOLOGICO}
          dataSugerida={now}
          lotacaoSugerida={sugestoesProfissionais?.odontologico}
          cbosAcesso={CBOS_DENTISTA}
        />
      )}
      <Alert type='info'>É necessário finalizar o atendimento para agendar a consulta.</Alert>
    </VFlow>
  )
}

const CBOS_DENTISTA = [AcessoCbo.CIRURGIAO_DENTISTA]
const CBOS_MEDICO_OU_ENFERMEIRO = [AcessoCbo.MEDICO, AcessoCbo.ENFERMEIRO]

const createStyles = (theme: Theme) => ({
  infoIcon: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
  profissionalDescription: css`
    color: ${theme.pallete.gray.c20};
  `,
})
