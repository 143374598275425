import {
  Alert,
  Button,
  Cell,
  FormControl,
  Grid,
  Heading,
  HeadingSection,
  HFlow,
  Icon,
  Text,
  Theme,
  useStyles,
  VFlow,
} from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import {
  CheckboxField,
  EquipeSelectField,
  EquipeSelectModel,
  NumberField,
  RadioField,
  SubmitButton,
} from 'components/form'
import { AgeRangeField } from 'components/form/field/AgeRangeField'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  CiapCidSelectField,
  CiapCidSelectModel,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectField } from 'components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import { GrupoCondicaoEnum } from 'graphql/types.generated'
import React, { useCallback } from 'react'
import { FormRenderProps } from 'react-final-form'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, required } from 'util/validation'

import { GrupoCondicaoSelectModel } from '../../components/form/field/select/GrupoCondicaoSelectField/GrupoCondicaoSelectField'
import AcompanhamentoCondicaoSaudeFormModel, { AgeRangeFilter } from './AcompanhamentoCondicaoSaudeFormModel'
import GrupoCondicaoBuscaRapida from './components/GrupoCondicaoBuscaRapida'
import SexoIdentidadeGeneroButtonGroup from './components/SexoIdentidadeGeneroButtonGroup'

export interface SearchFooterProps {
  onCleanFilters?(): void
  handleSubmit(event?: React.SyntheticEvent<HTMLFormElement>): void
}

const path = metaPath<AcompanhamentoCondicaoSaudeFormModel>()

export const validate = createValidator<AcompanhamentoCondicaoSaudeFormModel>({}, (form) => {
  const errors: ErrorObject<AcompanhamentoCondicaoSaudeFormModel> = {}

  if (AgeRangeFilter.OUTRA === form?.faixaEtariaFilter) {
    errors.faixaEtaria = required(form.faixaEtaria)
  }

  return errors
})

export default function AcompanhamentoCondicaoSaudeForm(props: FormRenderProps<AcompanhamentoCondicaoSaudeFormModel>) {
  const value = props.values
  const { hasAuthorization } = useSession()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const isCoordenador = hasAuthorization(Permissions.acompanhamentos.condicoesDeSaude.todasAsEquipes)

  props.form.change('unidadeResponsavelId', acesso?.unidadeSaude?.id)
  props.form.change('equipeResponsavelId', acesso?.equipe?.id)
  props.form.change('isCoordenador', isCoordenador)

  const handleChangeCiapCid = (ciapCidList: CiapCidSelectModel[]) => {
    if ((!ciapCidList || ciapCidList.length === 0) && (!value.grupoCondicao || value.grupoCondicao.length === 0)) {
      props.form.change(path.ativoListaProblema.alias, false)
    }
  }

  const handleAgeRangeChange = () => {
    props.form.change(path.faixaEtaria.alias, undefined)
  }

  const handleOnChangeGrupoCond = (grupos: GrupoCondicaoSelectModel[]) => {
    if (grupos) {
      if (grupos.find((item) => GrupoCondicaoEnum.DIABETES === item.grupoCondicao)) {
        !value.diabetesCheck && props.form.change(path.diabetesCheck.alias, !value.diabetesCheck)
      } else {
        value.diabetesCheck && props.form.change(path.diabetesCheck.alias, !value.diabetesCheck)
      }
      if (grupos.find((item) => GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL === item.grupoCondicao)) {
        !value.hipertensaoCheck && props.form.change(path.hipertensaoCheck.alias, !value.hipertensaoCheck)
      } else {
        value.hipertensaoCheck && props.form.change(path.hipertensaoCheck.alias, !value.hipertensaoCheck)
      }
      if (grupos.find((item) => GrupoCondicaoEnum.OBESIDADE === item.grupoCondicao)) {
        !value.obesidadeCheck && props.form.change(path.obesidadeCheck.alias, !value.obesidadeCheck)
      } else {
        value.obesidadeCheck && props.form.change(path.obesidadeCheck.alias, !value.obesidadeCheck)
      }
      if (grupos.find((item) => GrupoCondicaoEnum.GRAVIDEZ === item.grupoCondicao)) {
        !value.gravidezCheck && props.form.change(path.gravidezCheck.alias, !value.gravidezCheck)
      } else {
        value.gravidezCheck && props.form.change(path.gravidezCheck.alias, !value.gravidezCheck)
      }
    }
    if ((!grupos || grupos.length === 0) && (!value.problemasCondicoes || value.problemasCondicoes.length === 0)) {
      props.form.change(path.ativoListaProblema.alias, false)
    }
  }

  const canCheckApenasProblemasCondicoesAtivas =
    (!value.grupoCondicao || value.grupoCondicao.length === 0) &&
    (!value.problemasCondicoes || value.problemasCondicoes.length === 0)

  const handleClearCheckbox = useCallback(
    (numberOfGroups: number) => {
      numberOfGroups === 0 &&
        canCheckApenasProblemasCondicoesAtivas &&
        props.form.change(path.ativoListaProblema.alias, false)
    },
    [canCheckApenasProblemasCondicoesAtivas, props.form]
  )

  function equipeChange(equipe: EquipeSelectModel[]) {
    if (!equipe || equipe.length === 0) {
      props.form.change(path.microarea.alias, undefined)
      props.form.change(path.foraarea.alias, undefined)
      props.form.change(path.naoinformada.alias, undefined)
    }
  }

  return (
    <VFlow vSpacing={2}>
      <Grid wrap>
        <Cell size={3} style={{ marginBottom: '1rem' }}>
          <FormControl label='Unidade responsável'>
            <Text>{acesso?.unidadeSaude?.nome.titleCase()}</Text>
          </FormControl>
        </Cell>
        <Cell size={6} style={{ marginBottom: '1rem' }}>
          <FormControl label='Equipe responsável'>
            {isCoordenador ? (
              <EquipeSelectField
                name={path.equipeResponsavel}
                unidadeSaudeId={value?.unidadeResponsavelId}
                onChange={equipeChange}
              />
            ) : (
              <Text>
                {acesso?.equipe ? `${acesso?.equipe?.nome.titleCase()} | ${acesso.equipe.ine}` : 'Sem equipe'}
              </Text>
            )}
          </FormControl>
        </Cell>
        <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude.problemasECondicoes}>
          <Cell size={12}>
            <Heading level={2}>Problemas e condições</Heading>
            <Alert inline type='info' style={{ marginTop: '0.5rem', marginRight: '4.5rem' }}>
              A busca retorna apenas cidadãos que possuem todas as condições selecionadas e ativas na lista de problemas
              ou avaliadas no SOAP e fichas de atendimento individual.
            </Alert>
          </Cell>
          <Cell size={12}>
            <GrupoCondicaoBuscaRapida
              path={path}
              value={value}
              onChange={props.form.change}
              handleClearCheckbox={handleClearCheckbox}
            />
          </Cell>
          <Cell size={6} style={{ marginTop: '0.5rem' }}>
            <GrupoCondicaoSelectField
              label='Grupos de condições prioritários'
              name={path.grupoCondicao}
              multiple
              placeholder='Selecione grupos de condições prioritários'
              onChange={handleOnChangeGrupoCond}
            />
          </Cell>
          <Cell size={6} style={{ marginTop: '0.5rem' }}>
            <CiapCidSelectField
              label='CIAP2 e CID10'
              name={path.problemasCondicoes}
              multiple
              placeholder='Selecione outros CIAP2 e CID10'
              onChange={handleChangeCiapCid}
            />
          </Cell>
          <Cell size={12}>
            <CheckboxField
              name={path.ativoListaProblema}
              label='Buscar apenas problemas / condições ativas na lista de problemas e condições.'
              disabled={canCheckApenasProblemasCondicoesAtivas}
            />
          </Cell>
        </CheckPermission>
        <Cell size={12}>
          <Heading level={2}>Informações do cidadão</Heading>
        </Cell>
        <Cell size={6}>
          <SexoIdentidadeGeneroButtonGroup onChange={props.form.change} />
        </Cell>
        <Cell size={4} style={{ paddingTop: 'calc(3rem + 2px)' }}>
          <DateRangeField
            name={path.periodoUltimoAtendimento}
            label='Período do último atendimento individual'
            startPlaceholder='Data inicial'
            finalPlaceholder='Data final'
            maxDate={new Date()}
          />
        </Cell>
        <Cell size={12} style={{ paddingBottom: 0 }}>
          <HeadingSection title='Faixa etária' level={5} />
        </Cell>
        <Cell size={4} style={{ paddingTop: 0 }}>
          <VFlow vSpacing={0.5}>
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.TODAS_FAIXAS}
              label='Todas as faixas'
              onChange={handleAgeRangeChange}
            />
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.CRIANCA}
              label='Criança (0 a 10 anos)'
              onChange={handleAgeRangeChange}
            />
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.ADOLESCENTE}
              label='Adolescente (11 a 19 anos)'
              onChange={handleAgeRangeChange}
            />
          </VFlow>
        </Cell>
        <Cell size={8} style={{ paddingTop: 0 }}>
          <VFlow vSpacing={0.5}>
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.ADULTO}
              label='Adulto (20 a 59 anos)'
              onChange={handleAgeRangeChange}
            />
            <RadioField
              name={path.faixaEtariaFilter}
              value={AgeRangeFilter.IDOSO}
              label='Idoso (60 anos ou mais)'
              onChange={handleAgeRangeChange}
            />
            <HFlow style={{ maxWidth: '28rem' }}>
              <div>
                <RadioField
                  name={path.faixaEtariaFilter}
                  value={AgeRangeFilter.OUTRA}
                  label='Outra'
                  onChange={handleAgeRangeChange}
                />
              </div>
              <AgeRangeField name={path.faixaEtaria} disabled={value?.faixaEtariaFilter !== AgeRangeFilter.OUTRA} />
            </HFlow>
          </VFlow>
        </Cell>
        <Cell size={12}>
          <HFlow alignItems='flex-end'>
            <NumberField
              disabled={
                value?.microarea === 'FA' || value?.microarea === '--' || (isCoordenador && !value?.equipeResponsavel)
              }
              style={{ maxWidth: '8rem' }}
              name={path.microarea}
              label='Microárea'
              maxLength={2}
            />
            <CheckboxField
              disabled={isCoordenador && !value?.equipeResponsavel}
              name='foraarea'
              label='Fora de área'
              checked={value?.microarea === 'FA'}
              onChange={(e) => props.form.change(path.microarea.alias, e.target.checked ? 'FA' : undefined)}
            />
            <CheckboxField
              disabled={isCoordenador && !value?.equipeResponsavel}
              name='naoinformada'
              label='Não informada'
              checked={value?.microarea === '--'}
              onChange={(e) => props.form.change(path.microarea.alias, e.target.checked ? '--' : undefined)}
            />
          </HFlow>
        </Cell>
      </Grid>
      <SearchFooter handleSubmit={props.handleSubmit} onCleanFilters={props.form.reset} />
    </VFlow>
  )
}

export const SearchFooter = (props: SearchFooterProps) => {
  const { classes } = useStyles(createStyles)

  return (
    <div className={classes.container}>
      <Button onClick={props.onCleanFilters}>Limpar filtros</Button>
      <SubmitButton handleSubmit={props.handleSubmit} data-testid='BuscarCidadaosButton'>
        <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
        Buscar cidadãos
      </SubmitButton>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 0',
    '& > *:not(:last-child)': {
      marginRight: '0.75rem',
    },
  } as React.CSSProperties,
})
