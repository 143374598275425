import { noop } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

interface UseUserMediaOptions {
  video: boolean
  audio: boolean
}
export function useUserMedia(options: UseUserMediaOptions) {
  const { audio, video } = options
  const [stream, setStream] = useState<MediaStream>()
  const [audioDeviceAvailable, setAudioDeviceAvailable] = useState(false)
  const [videoDeviceAvailable, setVideoDeviceAvailable] = useState(false)

  //É necessário solicitar acesso aos dispositivos separadamente para verificar se estão disponíveis
  const checkDevicesStatusAndGetStreams = useCallback(
    ({ audio, video }: UseUserMediaOptions) =>
      Promise.all([
        navigator.mediaDevices
          ?.getUserMedia({ audio: true })
          .then((audioStream) => {
            setAudioDeviceAvailable(!!audioStream.getAudioTracks().length)

            if (audio) return audioStream.getAudioTracks()

            audioStream.getAudioTracks().forEach((track) => track.stop())
            return []
          })
          .catch(() => {
            setAudioDeviceAvailable(false)
            return []
          }),
        navigator.mediaDevices
          ?.getUserMedia({ video: true })
          .then((videoStream) => {
            setVideoDeviceAvailable(!!videoStream.getVideoTracks().length)

            if (video) return videoStream.getVideoTracks()

            videoStream.getVideoTracks().forEach((track) => track.stop())
            return []
          })
          .catch(() => {
            setVideoDeviceAvailable(false)
            return []
          }),
      ])
        .then(([audioTracks, videoTracks]) => {
          setStream(new MediaStream([...(audioTracks ?? []), ...(videoTracks ?? [])]))
        })
        .catch(noop), // Os erros serão lançados quando não houver acesso aos dispositivos, mas isso é esperado
    [setAudioDeviceAvailable, setVideoDeviceAvailable]
  )

  useEffect(() => {
    checkDevicesStatusAndGetStreams({ audio, video })
    const handleDeviceChange = () => checkDevicesStatusAndGetStreams({ audio, video })
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange)
  }, [checkDevicesStatusAndGetStreams, audio, video])

  //Para de usar os dispositivos de mídia ao destruir o componente
  useEffect(() => () => stream?.getTracks().forEach((track) => track.stop()), [stream])

  return { stream, audioDeviceAvailable, videoDeviceAvailable }
}
