import useSession from 'components/auth/useSession'
import { LotacaoOuEstagio, SessaoDataFragment } from 'graphql/types.generated'
import { Permission } from 'types/Permissions'

import { isLotacaoOrEstagio } from './useSessionUtils'

type Profissional = SessaoDataFragment['profissional']

interface AcessoLotacaoOrEstagioResult {
  acesso: LotacaoOuEstagio
  profissional: Profissional
  hasAuthorization: (permission: Permission) => boolean
}

export const useAcessoLotacaoOrEstagio = (): AcessoLotacaoOrEstagioResult => {
  const {
    data: { acesso, profissional },
    hasAuthorization,
  } = useSession()

  return isLotacaoOrEstagio(acesso) ? { acesso, profissional, hasAuthorization } : null
}
