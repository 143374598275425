/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, HFlow, useTheme, VFlow } from 'bold-ui'
import { orange } from 'bold-ui/lib/styles/colors'
import { composeHandlers } from 'bold-ui/lib/util/react'
import useFirebase from 'components/firebase/useFirebase'
import { CategoriaSubstanciaAlergiaSelectField, FormPrompt, SubmitButton, TextAreaField } from 'components/form'
import { RadioGroupField } from 'components/form/field/RadioGroupField'
import {
  AlergiaSelectField,
  AlergiaSelectFieldModel,
} from 'components/form/field/select/AlergiaSelectField/AlergiaSelectField'
import { ManifestacaoAlergiaSelectField } from 'components/form/field/select/ManifestacaoAlergiaSelectField'
import { StatusInformation } from 'components/StatusInformation'
import {
  CategoriaSubstanciaAlergiaLegado,
  CriticidadeAlergia,
  GrauCertezaAlergia,
  TipoReacaoAlergia,
} from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { isEqual } from 'lodash'
import { Fragment, useCallback, useMemo } from 'react'
import { emptyArray } from 'util/array'
import { toDate } from 'util/date/formatDate'

import {
  EditableListForm,
  EditableListFormProps,
  EditableListFormRenderProps,
} from '../../../../components/EditableListForm'
import { categoriaAlergiaRecord } from '../../../aside/types/AlergiaModel'
import { DataIdadeField } from '../../../components/DataIdadeField'
import { alergiasReacoesValidator } from '../problemas-condicoes/validator'
import { createAlergiaReacaoCalculator } from './calculator'
import { convertAlergiaReacao } from './converter'
import { AlergiaReacaoModel } from './model'
import { SubstanciaEspecificaField } from './SubstanciaEspecificaField'
import { alergiaIsEqual } from './util'

interface ButtonTexts {
  submit?: string
  cancel?: string
}

export interface AlergiasReacoesProps
  extends Pick<EditableListFormProps<AlergiaReacaoModel>, 'initialValues' | 'onSubmit' | 'initialValuesEqual'> {
  formValues?: AlergiaReacaoModel[]
  dataAtendimento?: Instant
  edicao?: boolean
  alergiasCidadao?: AlergiaSelectFieldModel[]
  isModal?: boolean
  buttonTexts?: ButtonTexts
  onCancel?(): void
}

export function AlergiasReacoesForm(props: AlergiasReacoesProps) {
  const {
    isModal,
    edicao,
    dataAtendimento,
    formValues,
    initialValues: externalInitialValues,
    alergiasCidadao = emptyArray,
    buttonTexts = edicao
      ? {
          cancel: 'Cancelar',
          submit: 'Salvar',
        }
      : { cancel: 'Cancelar', submit: 'Adicionar' },
    onCancel,
    ...rest
  } = props

  const {
    iniciadoEm,
    cidadao: { dataNascimento },
  } = useAtendimentoContext()

  const { analytics } = useFirebase()
  const theme = useTheme()

  const alergiaIsInForm = useCallback(
    (item: AlergiaSelectFieldModel) => formValues && !!formValues.find((o2) => alergiaIsEqual(item, o2)),
    [formValues]
  )

  const alergiasExistentes = alergiasCidadao.map((element) => convertAlergiaReacao(element, dataNascimento))
  const listaAlergiasBloqueadas = isModal
    ? formValues
      ? alergiasExistentes.concat(formValues)
      : alergiasExistentes
    : formValues

  const validators = useMemo(() => alergiasReacoesValidator(dataNascimento, dataAtendimento), [
    dataAtendimento,
    dataNascimento,
  ])

  const prefix = edicao ? externalInitialValues._id ?? 'edit' : 'new'
  const initialValues = useMemo(() => ({ isAvaliacao: !isModal ? true : false, ...externalInitialValues }), [
    externalInitialValues,
    isModal,
  ])
  const decorators = useMemo(() => [createAlergiaReacaoCalculator(dataNascimento, alergiasCidadao, prefix)], [
    alergiasCidadao,
    dataNascimento,
    prefix,
  ])

  const renderForm = ({
    handleSubmit,
    values,
    form: formApi,
    name,
  }: EditableListFormRenderProps<AlergiaReacaoModel>) => {
    const clearFields = () => {
      formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
      setTimeout(formApi.reset)
    }

    const showWarningLegado =
      values?.alergiaReacaoEvoluir && !values.alergiaReacaoEvoluir.ultimaEvolucao.possuiSubstanciaEspecificaCbara
    const showWarningLegadoCategoria =
      showWarningLegado && values?.categoriaSubstanciaEspecificaLegado !== CategoriaSubstanciaAlergiaLegado.ALIMENTO

    return (
      <VFlow>
        {isModal && <FormPrompt />}
        {!edicao && !isModal && (
          <div
            css={css`
              background-color: ${theme.pallete.gray.c90};
              padding: 1rem;
              border-bottom: 1px ${theme.pallete.divider} solid;
            `}
          >
            <Grid>
              <Cell size={6}>
                <AlergiaSelectField
                  label='Pesquisar por alergias e reações adversas do cidadão'
                  itemIsEqual={alergiaIsInForm}
                  name={name.alergiaReacaoEvoluir}
                  onClear={clearFields}
                  alergias={alergiasCidadao}
                  onChange={() => analytics.logEvent('select_alergia_pesquisa_AV')}
                />
              </Cell>
            </Grid>
          </div>
        )}

        <Grid
          style={css`
            padding: 0 ${!edicao ? '1rem' : 0};
          `}
        >
          <Cell size={6}>
            {edicao ? (
              <FormControl label='Categoria do agente causador'>
                {categoriaAlergiaRecord[values?.categoriaSubstancia]?.descricao}
              </FormControl>
            ) : (
              <Fragment>
                {showWarningLegadoCategoria && (
                  <StatusInformation
                    color={orange.c40}
                    icon='exclamationTriangleOutline'
                    text={`A categoria ${
                      categoriaAlergiaRecord[values.categoriaSubstanciaEspecificaLegado].descricao
                    } não é mais válida, selecione uma nova opção. `}
                  />
                )}
                <CategoriaSubstanciaAlergiaSelectField
                  label='Categoria do agente causador'
                  name={name.categoriaSubstancia}
                  disabled={!!values?.alergiaId && !showWarningLegadoCategoria}
                  required
                />
              </Fragment>
            )}
          </Cell>
          <Cell size={6}>
            {edicao ? (
              <FormControl label='Agente ou substância específica'>
                {values?.substanciaCbara?.nome ??
                  values?.substanciaImunobiologico?.nome ??
                  `${values.substanciaMedicamento?.medicamento?.principioAtivo} - ${values?.substanciaMedicamento?.medicamento?.concentracao}`}
              </FormControl>
            ) : (
              <Fragment>
                {showWarningLegado && (
                  <StatusInformation
                    color={orange.c40}
                    icon='exclamationTriangleOutline'
                    text={`O agente ${values.substanciaEspecificaLegado} não é mais válido, selecione uma nova opção.`}
                  />
                )}
                <SubstanciaEspecificaField
                  label='Agente ou substância específica'
                  meta={name}
                  values={values}
                  disabled={(!!values?.alergiaId && !showWarningLegado) || !values?.categoriaSubstancia}
                  allValues={listaAlergiasBloqueadas}
                  required
                />
              </Fragment>
            )}
          </Cell>
          <Cell sm={6}>
            <RadioGroupField
              name={name.tipoReacao}
              label='Tipo de reação'
              options={[
                { value: TipoReacaoAlergia.ALERGIA, label: 'Alergia' },
                { value: TipoReacaoAlergia.INTOLERANCIA, label: 'Intolerância' },
              ]}
              clearable
            />
          </Cell>
          <Cell sm={6}>
            <RadioGroupField
              name={name.criticidade}
              label='Criticidade'
              options={[
                { value: CriticidadeAlergia.ALTA, label: 'Alta' },
                { value: CriticidadeAlergia.BAIXA, label: 'Baixa' },
              ]}
              clearable
            />
          </Cell>
          <Cell sm={6}>
            <RadioGroupField
              name={name.grauCerteza}
              label='Grau de certeza'
              lines={2}
              options={[
                { value: GrauCertezaAlergia.CONFIRMADO, label: 'Confirmado' },
                { value: GrauCertezaAlergia.REFUTADO, label: 'Refutado' },
                { value: GrauCertezaAlergia.RESOLVIDO, label: 'Resolvido' },
                { value: GrauCertezaAlergia.SUSPEITO, label: 'Suspeito' },
              ]}
              clearable
            />
          </Cell>
          <Cell sm={6}>
            {showWarningLegado && values?.manifestacoesAlergiaLegado && (
              <StatusInformation
                color={orange.c40}
                icon='exclamationTriangleOutline'
                text={`A manifestação "${values.manifestacoesAlergiaLegado}" não é mais válida, selecione uma nova opção.`}
              />
            )}
            <ManifestacaoAlergiaSelectField
              label='Manifestações'
              name={name.manifestacoes}
              multiple
              itemIsEqual={isEqual}
            />
          </Cell>
          <Cell>
            <DataIdadeField
              name={name.dataInicio}
              label='Início'
              dataNascimentoCidadao={dataNascimento}
              minDate={toDate(dataNascimento)}
              maxDate={iniciadoEm}
            />
          </Cell>
          <Cell size={12}>
            <TextAreaField
              label='Observações'
              name={name.observacao}
              maxLength={400}
              placeholder='Insira impressões adicionais sobre a avaliação da alergia/reação adversa.'
              resize='vertical'
            />
          </Cell>
        </Grid>
        <HFlow justifyContent='flex-end' style={{ padding: '1rem' }}>
          <Button size='small' onClick={composeHandlers(clearFields, onCancel)}>
            {buttonTexts.cancel}
          </Button>
          <SubmitButton size='small' handleSubmit={handleSubmit}>
            {buttonTexts.submit}
          </SubmitButton>
        </HFlow>
      </VFlow>
    )
  }

  return (
    <EditableListForm<AlergiaReacaoModel>
      validate={validators}
      render={renderForm}
      prefix={prefix}
      initialValues={initialValues}
      decorators={decorators}
      {...rest}
    />
  )
}
