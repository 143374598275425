import ApolloClient from 'apollo-client'
import createDecorator, { Calculation } from 'final-form-calculate'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'
import { AtendimentoVacinacaoState, meta } from 'view/atendimentos/atendimento-vacinacao/model'

import { createAgendarConsultaCalculations } from '../soap/finalizacao/calculator/calculatorAgendarConsulta'
import { createNovoAtendimentoCalculations } from '../soap/finalizacao/calculator/calculatorNovoAtendimento'

const createCondicoesVacinacaoCalculator = (meta: MetaRoot<AtendimentoVacinacaoState>): Calculation[] => [
  {
    field: meta.condicoesVacinacao.gestante.absolutePath(),
    updates: {
      [meta.condicoesVacinacao.puerpera.absolutePath()]: (gestanteValue, formValues: AtendimentoVacinacaoState) => {
        return gestanteValue ? false : formValues.condicoesVacinacao.puerpera
      },
    },
  },

  {
    field: meta.condicoesVacinacao.puerpera.absolutePath(),
    updates: {
      [meta.condicoesVacinacao.gestante.absolutePath()]: (puerperaValue, formValues: AtendimentoVacinacaoState) => {
        return puerperaValue ? false : formValues.condicoesVacinacao.gestante
      },
    },
  },
]

export const createVacinacaoCalculator = (apollo: ApolloClient<object>, tipoEstabelecimento: TipoEstabelecimentoEnum) =>
  createDecorator(
    ...createCondicoesVacinacaoCalculator(meta),
    ...createAgendarConsultaCalculations(meta.finalizacaoVacinacao.agendamentoConsulta),
    ...createNovoAtendimentoCalculations(
      meta.finalizacaoVacinacao.desfechoAtendimento.atendimento,
      tipoEstabelecimento,
      apollo
    )
  )
