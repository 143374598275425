/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Icon, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import image from 'images/videochamada/videochamada-encerrada.png'
import { useHistory, useRouteMatch } from 'react-router'

import { VideochamadaUrlParams } from './VideochamadaRootView'

export function SairVideochamadaView() {
  const {
    params: { id: videochamadaId },
  } = useRouteMatch<VideochamadaUrlParams>()
  const history = useHistory()

  const handleVoltarClick = () => history.push(`/videochamada/${videochamadaId}`)

  return (
    <PageContent type='filled' style={styles.container}>
      <Grid alignItems='center' justifyContent='space-between'>
        <Cell size={5}>
          <VFlow>
            <Heading level={1}>Você saiu da chamada</Heading>
            <Button kind='primary' onClick={handleVoltarClick}>
              <Icon
                icon='arrowLeft'
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Voltar para sala
            </Button>
          </VFlow>
        </Cell>
        <Cell>
          <img
            width={528}
            height={390}
            src={image}
            alt='Duas pessoas com as mãos levantadas, representando que estão se despedindo em uma chamada de vídeo'
          />
        </Cell>
      </Grid>
    </PageContent>
  )
}

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 1024px;
  `,
}
