import { css } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import {
  CidadaoAtendimentoSelectField,
  EquipeSelectField,
  Form,
  FormRenderProps,
  SubmitButton,
  TextAreaField,
} from 'components/form'
import { FormApi } from 'final-form'
import { useAdicionarListaEsperaMutation, useCidadaoQuery } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { metaPath } from 'util/metaPath'
import { resolveEquipeTooltip } from 'view/atendimentos/tooltipResolver'

import { ProblemasCondicoesCheckField } from './components/ProblemasCondicoesCheckField'
import { TipoAtendimentoSelectField } from './components/TipoAtendimentoSelectField'
import { convertListaEsperaFormModelToInput } from './convert'
import { ListaEsperaFormModel } from './model'
import { listaEsperaValidator } from './validator'

const path = metaPath<ListaEsperaFormModel>()

interface ListaEsperaFormProps {
  cidadaoId?: string
}

export default function ListaEsperaForm(props: ListaEsperaFormProps) {
  const { cidadaoId } = props
  const alert = useAlert()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const tipoEstabelecimento = acesso?.unidadeSaude.tipoEstabelecimento
  const [salvar] = useAdicionarListaEsperaMutation()
  const errorHandler = useErrorHandler()

  const { data: cidadaoData } = useCidadaoQuery({
    fetchPolicy: 'cache-first',
    variables: { id: cidadaoId },
    skip: !cidadaoId,
  })

  const initialValues: ListaEsperaFormModel = useMemo(
    () => ({
      cidadao: cidadaoId && cidadaoData && { ...cidadaoData.cidadao, presenteListaAtendimento: false },
    }),
    [cidadaoData, cidadaoId]
  )

  const handleSubmit = (values: ListaEsperaFormModel, formApi: FormApi) => {
    salvar({ variables: { input: convertListaEsperaFormModelToInput(values) } })
      .then(() => {
        alert('success', `Cidadão foi adicionado com sucesso.`)
        formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
        setTimeout(formApi.reset)
      })
      .catch(errorHandler)
  }

  const renderForm = (formProps: FormRenderProps<ListaEsperaFormModel>) => {
    const { values } = formProps
    return (
      <Grid>
        <Cell size={5}>
          <VFlow>
            <CidadaoAtendimentoSelectField
              name={path.cidadao}
              label='Cidadão'
              ativo
              required
              obito={false}
              addCidadaoCallbackUrl='/garantia-acesso'
            />

            {tipoEstabelecimento !== TipoEstabelecimentoEnum.CEO && (
              <Tooltip text={resolveEquipeTooltip(values)}>
                <EquipeSelectField name={path.equipe} label='Equipe' unidadeSaudeId={acesso?.unidadeSaude.id} />
              </Tooltip>
            )}

            <TipoAtendimentoSelectField name={path.tipoAtendimento} label='Tipo de atendimento' required />
          </VFlow>
        </Cell>

        <Cell size={7}>
          <VFlow>
            <ProblemasCondicoesCheckField name={path.problemasCondicoes} />
            <TextAreaField
              name={path.motivo}
              label='Motivo da consulta'
              maxLength={200}
              required
              style={css`
                height: 5rem;
                resize: none;
              `}
            />
          </VFlow>
        </Cell>

        <Cell alignSelf='flex-end' size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='medium' onClick={formProps.form.reset}>
              Limpar campos
            </Button>
            <SubmitButton size='medium' handleSubmit={formProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }
  return (
    <Form<ListaEsperaFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={listaEsperaValidator}
    />
  )
}
