import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'

import { ProblemaCondicaoModel } from '../components/problemas-condicoes/model'

export const hasMudancaAvaliacoesAutomaticas = (
  listaAvaliacoesA: ProblemaCondicaoModel[],
  listaAvaliacoesB: ProblemaCondicaoModel[]
) =>
  getDiferencaEntreListasAvaliacoes(listaAvaliacoesA, listaAvaliacoesB)
    .concat(getDiferencaEntreListasAvaliacoes(listaAvaliacoesB, listaAvaliacoesA))
    .find((avaliacao) => avaliacao.isAutomatico)

const getDiferencaEntreListasAvaliacoes = (
  listaAvaliacaoA: ProblemaCondicaoModel[],
  listaAvaliacaoB: ProblemaCondicaoModel[]
) => listaAvaliacaoA.filter((itemA) => !listaAvaliacaoB.some((itemB) => saoAvaliacoesIguais(itemA, itemB)))

export const saoAvaliacoesIguais = (avaliacaoA: ProblemaCondicaoModel, avaliacaoB: ProblemaCondicaoModel) =>
  avaliacaoA.cid?.codigo === avaliacaoB.cid?.codigo &&
  avaliacaoA.ciap?.codigo === avaliacaoB.ciap?.codigo &&
  avaliacaoA.isAutomatico === avaliacaoB.isAutomatico

const isCiap = (ciapOuCid: CiapSelectFieldModel | CidSelectFieldModel): ciapOuCid is CiapSelectFieldModel =>
  ciapOuCid?.__typename === 'Ciap'
const isCid = (ciapOuCid: CiapSelectFieldModel | CidSelectFieldModel): ciapOuCid is CidSelectFieldModel =>
  ciapOuCid?.__typename === 'Cid10'

export function separateCiapCid(ciapOuCid: CiapSelectFieldModel | CidSelectFieldModel) {
  return {
    ciap: isCiap(ciapOuCid) ? ciapOuCid : null,
    cid: isCid(ciapOuCid) ? ciapOuCid : null,
  }
}
