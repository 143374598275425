import { CidadaoAtendimentoSelectFieldModel, EquipeSelectModel } from 'components/form'
import {
  ListaEsperaMotivoSaidaEnum,
  ListaEsperaProblemaCondicaoEnum,
  ListaEsperaTableQuery,
  ListaEsperaTipoAtendimentoEnum,
} from 'graphql/types.generated'

import { ProblemasCondicoesCheckFieldModel } from './components/ProblemasCondicoesCheckField'
import { TipoAtendimentoSelectModel } from './components/TipoAtendimentoSelectField'

export interface ListaEsperaModel {
  id: ID
  cidadao: CidadaoAtendimentoSelectFieldModel
  tipoAtendimento?: TipoAtendimentoSelectModel
  equipe?: EquipeSelectModel
  motivo?: string
  problemasCondicoes?: ProblemasCondicoesCheckFieldModel
  dataEntrada?: Instant
}

export interface ListaEsperaRemocaoModel {
  listaEsperaId: ID
  motivoSaida: ListaEsperaMotivoSaidaEnum
  qtdTentativasContato: number
  observacoes: string
}

export type ListaEsperaItem = ListaEsperaTableQuery['listaEsperaByUnidadeSaudeId']['content'][0]

export type CidadaoListaEspera = ListaEsperaTableQuery['listaEsperaByUnidadeSaudeId']['content'][0]['cidadao']

export interface ListaEsperaFormModel extends Omit<ListaEsperaModel, 'id' | 'dataEntrada'> {}

export const problemaCondicaoRecord: Record<ListaEsperaProblemaCondicaoEnum, string> = {
  GESTANTE: 'Gestante',
  PUERICULTURA: 'Puricultura',
  IDOSO: 'Idoso',
  SAUDE_MENTAL: 'Saúde mental',
  HIPERTENSAO_ARTERIAL: 'Hipertensão arterial',
  DIABETES: 'Diabetes',
  BOLSA_FAMILIA: 'Bolsa Família',
  OBESIDADE: 'Obesidade',
  OUTROS: 'Outros',
}

export const motivoSaidaRecord: Record<ListaEsperaMotivoSaidaEnum, string> = {
  ATENDIDO_OUTRO_ESTABELECIMENTO: 'Atendido em outro estabelecimento',
  ATENDIMENTO_AGENDADO: 'Atendimento agendado',
  MUDOU_TERRITORIO: 'Mudou-se de território',
  NAO_RESPONDEU_TENTATIVAS_CONTATO: 'Não respondeu tentativas de contato',
  OUTROS: 'Outros',
}

export const tipoAtendimentoRecord: Record<ListaEsperaTipoAtendimentoEnum, string> = {
  CONSULTA_MEDICA: 'Consulta médica',
  CONSULTA_ODONTOLOGICA: 'Consulta odontológica',
  CONSULTA_ENFERMAGEM: 'Consulta de enfermagem',
}
