import { EncaminhamentoEspecializadoByIdQuery } from 'graphql/types.generated'
import { isCboPodeRegistrarCid } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { classificacaoRiscoRecord, EncaminhamentoExternoEspecializadoModel } from '../../model'
import { EncaminhamentoExternoDetalhesPanelModel } from '../model'

export function convertEncaminhamentoEspecializadoRecente(
  encaminhamento: EncaminhamentoExternoEspecializadoModel,
  lotacao: LotacaoAtendimento
): EncaminhamentoExternoDetalhesPanelModel {
  if (!encaminhamento) return undefined
  const renderCiap = !isCboPodeRegistrarCid(lotacao.cbo.cbo2002)
  return {
    especialidade: encaminhamento.especialidadeSisreg.descricao,
    complemento: encaminhamento.complemento,
    renderCiap,
    hipoteseDiagnostica: renderCiap
      ? `${encaminhamento.ciapEncaminhamento.descricao} - ${encaminhamento.ciapEncaminhamento.codigo}`
      : `${encaminhamento.cidEncaminhamento.nome} - ${encaminhamento.cidEncaminhamento.codigo}`,
    classificacaoRisco: classificacaoRiscoRecord[encaminhamento.classificacaoRiscoEncaminhamento],
    motivo: encaminhamento.motivoEncaminhamento,
    observacoes: encaminhamento.observacoes,
    lotacao: {
      nome: lotacao.profissional.nome,
      cbo: lotacao.cbo.nome,
      cns: lotacao.profissional.cns,
      cpf: lotacao.profissional.cpf,
    },
  }
}

export function convertEncaminhamentoEspecializado(
  encaminhamento: EncaminhamentoEspecializadoByIdQuery['encaminhamentoEspecializado']
): EncaminhamentoExternoDetalhesPanelModel {
  if (!encaminhamento) return undefined
  const renderCiap = !isCboPodeRegistrarCid(encaminhamento.atendimentoProfissional.lotacao.cbo.cbo2002)
  return {
    especialidade: encaminhamento.especialidadeSisreg.descricao,
    complemento: encaminhamento.complemento,
    renderCiap,
    hipoteseDiagnostica: renderCiap
      ? `${encaminhamento.hipoteseDiagnosticoCiap.descricao} - ${encaminhamento.hipoteseDiagnosticoCiap.id}`
      : `${encaminhamento.hipoteseDiagnosticoCid10.nome} - ${encaminhamento.hipoteseDiagnosticoCid10.id}`,
    classificacaoRisco: classificacaoRiscoRecord[encaminhamento.classificacaoRiscoEncaminhamento],
    motivo: encaminhamento.motivoEncaminhamento,
    observacoes: encaminhamento.observacoes,
    lotacao: {
      nome: encaminhamento.atendimentoProfissional.lotacao.profissional.nome,
      cpf: encaminhamento.atendimentoProfissional.lotacao.profissional.cpf,
      cns: encaminhamento.atendimentoProfissional.lotacao.profissional.cns,
      cbo: encaminhamento.atendimentoProfissional.lotacao.cbo.nome,
    },
  }
}
