import { css } from '@emotion/core'
import { Button, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import theme from 'config/theme'
import React from 'react'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util'

import { MedicamentoFormModel } from '../model'

interface MedicamentoCardProps {
  medicamentoModel: MedicamentoFormModel
  editing: boolean
  replicating?: boolean
  onRemoveMedicamento(medicamento: MedicamentoFormModel): void
  onEditMedicamento(medicamento: MedicamentoFormModel): void
}

export default function MedicamentoCard(props: MedicamentoCardProps) {
  const { medicamentoModel, editing, onRemoveMedicamento, onEditMedicamento, replicating } = props
  const styles = createStyles(editing, replicating)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(medicamentoModel)

  const buildItemTitle = (): string => {
    return !!medicamentoModel.registroManual
      ? `${medicamentoModel.principioAtivoText} ${medicamentoModel.concentracaoText ?? ''}`
      : `${medicamentoModel.principioAtivoCombo.medicamento.principioAtivo} ${
          medicamentoModel.principioAtivoCombo.medicamento.concentracao ?? ''
        }`
  }

  const buildUnidadeFornecimento = (): string =>
    !!medicamentoModel.registroManual
      ? medicamentoModel.unidadeFornecimento
      : medicamentoModel.principioAtivoCombo.medicamento.unidadeFornecimento

  return (
    <>
      <HFlow justifyContent='space-between' style={styles.container}>
        <VFlow vSpacing={0} style={styles.textCard}>
          <Text fontWeight='bold'>{buildItemTitle()}</Text>
          <Text>{buildUnidadeFornecimento()}</Text>
        </VFlow>
        <HFlow hSpacing={0} alignItems='center'>
          <Tooltip text='Editar'>
            <Button
              size='small'
              skin='ghost'
              kind='normal'
              onClick={() => onEditMedicamento(medicamentoModel)}
              data-testid='EditarMedicamentoButton'
            >
              <Icon icon='penOutline' />
            </Button>
          </Tooltip>
          <Tooltip text={tooltipText}>
            <Button
              size='small'
              skin='ghost'
              onClick={() => onRemoveMedicamento(medicamentoModel)}
              disabled={isEditable}
              data-testid='ExcluirMedicamentoButton'
            >
              <Icon icon='trashOutline' />
            </Button>
          </Tooltip>
        </HFlow>
      </HFlow>
    </>
  )
}

const createStyles = (editing: boolean, replicating: boolean) => {
  return {
    container: css`
      width: 100%;
      max-width: 18rem;
      min-height: 4.5rem;
      border-radius: 2;
      box-sizing: 'border-box';
      padding-right: 0.25rem;
      transition: background-color 0.1s ease, border 0.1s linear;
      -webkit-transition: background-color 0.1s, border 0.1s linear;
      background-color: ${editing
        ? theme.pallete.status.info.background
        : replicating
        ? theme.pallete.status.alert.background
        : theme.pallete.surface.main};
      border: ${editing
        ? '2px solid' + theme.pallete.status.info.main
        : replicating
        ? '1px solid' + theme.pallete.status.alert.main
        : '1px solid' + theme.pallete.gray.c80};
    `,
    textCard: css`
      margin: 1rem 0 1rem 1rem;
      font-size: 13px;
      word-break: break-word;
    `,
  }
}
