import { FormState } from 'final-form'
import { FaixaEtariaConsumoAlimentar } from 'graphql/types.generated'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { AtendimentoObservacaoModel } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { MCADoisAnosOuMais } from './components/MCADoisAnosOuMais'
import MCAFormAccordion from './components/MCAFormAccordion'
import { MCAMenoresDeSeisMeses } from './components/MCAMenoresDeSeisMeses'
import { MCASeisAVinteETresMeses } from './components/MCASeisAVinteETresMeses'
import { CategoriaMarcadoresConsumoAlimentarRecord } from './model'
import { MarcadoresConsumoPanelModel } from './model'

export interface MarcadoresConsumoAlimentarPanelProps {
  name: MetaPath<MarcadoresConsumoPanelModel>
  faixaEtariaMCA: FaixaEtariaConsumoAlimentar
  atendimentoObservacao: AtendimentoObservacaoModel
}

export function MarcadoresConsumoAlimentarPanel(props: MarcadoresConsumoAlimentarPanelProps) {
  const { name, faixaEtariaMCA, atendimentoObservacao } = props
  const [alertMessage, setAlertMessage] = useState<string>(undefined)
  const { isAtendimentoObservacao, isObservacaoAndAuxiliar } = atendimentoObservacao
  const form = useForm<SoapState>()

  useEffect(() => {
    // TODO (Legacy): #11873
    const unsubscribe = form.subscribe(
      ({ submitFailed, hasValidationErrors, errors }: FormState<MarcadoresConsumoPanelModel>) => {
        if (submitFailed && hasValidationErrors && errors?.objetivo?.marcadoresConsumoAlimentar) {
          setAlertMessage('É necessário responder todas as perguntas do formulário para registrar o consumo alimentar.')
        } else {
          setAlertMessage(null)
        }
      },
      { submitFailed: true, hasValidationErrors: true, errors: true }
    )

    return () => unsubscribe()
  }, [form])

  const onClearFields = useCallback(() => setAlertMessage(undefined), [])

  const renderChildren = useMemo(() => {
    switch (faixaEtariaMCA) {
      case FaixaEtariaConsumoAlimentar.MENOR_SEIS_MESES:
        return <MCAMenoresDeSeisMeses name={name} />
      case FaixaEtariaConsumoAlimentar.MAIOR_SEIS_MESES_MENOR_DOIS_ANOS:
        return <MCASeisAVinteETresMeses name={name} />
      case FaixaEtariaConsumoAlimentar.MAIOR_DOIS_ANOS:
        return <MCADoisAnosOuMais name={name} />
    }
  }, [faixaEtariaMCA, name])

  return (
    !isObservacaoAndAuxiliar && (
      <MCAFormAccordion
        name={name}
        title='Marcadores de consumo alimentar'
        uuid='marcadoresConsumoAlimentar'
        heading={CategoriaMarcadoresConsumoAlimentarRecord[faixaEtariaMCA]}
        faixaEtariaMCA={faixaEtariaMCA}
        alertMessage={alertMessage}
        isAtendimentoObservacao={isAtendimentoObservacao}
        onClearFields={onClearFields}
      >
        {renderChildren}
      </MCAFormAccordion>
    )
  )
}
