import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { differenceInDays, differenceInYears, parseISO, toDate } from 'date-fns'
import {
  AtendimentoIndividualViewQuery,
  IdadeGestacionalAcompanhamentoPreNatal,
  IdentidadeGeneroEnum,
  MarcadorConsumoAlimentar,
  SexoEnum,
  StatusRevisaoAtendimento,
  TipoAtendimentoEnum,
  TipoAtendimentoProfissional,
  TipoEncaminhamentoExternoEnum,
} from 'graphql/types.generated'
import { CidadaoAtendimentoContextModel } from 'hooks/atendimento-context/model'
import { isUndefinedOrNull } from 'util/checks'
import { calculateAge } from 'util/date/calculateAge'
import { v4 as uuidv4 } from 'uuid'

import {
  convertDadosPreNatal,
  ConvertDadosPreNatalProps,
} from '../detail/soap/aside/pre-natal/util/AcompanhamentoPreNatalUtils'
import { EvolucaoProblema } from '../detail/soap/aside/types/ProblemaModel'
import { EvolucaoAvaliacaoQueryModel } from '../detail/soap/avaliacao/model'
import { FinalizacaoAtendimentoFormModel } from '../detail/soap/finalizacao'
import { ManterCidadaoLista } from '../detail/soap/finalizacao/components/DesfechoAtendimentoPanel'
import { ProcedimentoSigtapFieldModel } from '../detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { FinalizacaoAtendimentoQueryModel } from '../detail/soap/finalizacao/model'
import { ObjetivoFormModel } from '../detail/soap/objetivo'
import { EvolucaoObjetivoQueryModel } from '../detail/soap/objetivo/model'
import { ResultadoExameQueryModel, SolicitacaoExameQueryModel } from '../detail/soap/objetivo/resultados-exames/model'
import { AtestadoQueryModel } from '../detail/soap/plano/atestados/model'
import { EncaminhamentoExternoEspecializadoQueryModel } from '../detail/soap/plano/encaminhamento-externo/model'
import { EvolucaoPlanoQueryModel, OrientacaoQueryModel } from '../detail/soap/plano/model'
import { ReceitaMedicamentoQueryModel } from '../detail/soap/plano/prescricao-medicamento/model'
import { CIAP_W78_CODIGO, CID_Z34_CODIGO } from '../detail/soap/pre-natal/model'
import { EvolucaoSubjetivoQueryModel } from '../detail/soap/subjetivo/model'
import { AgendamentoDia } from '../types/AgendamentoDia'
import { AtendimentoProfissionalObservacaoInput, getAtendimentoObservacaoModel } from './atendimento-observacao/util'
import { CiapCidPreNatal, SoapEditableItem, SoapState, TipoServicoModel } from './model'

interface AtendimentoIndividualInitialValueProps {
  procedimentoAdministrativo: ProcedimentoSelectModel
  tipoAtendimento: TipoAtendimentoEnum
  agendamentosDia: AgendamentoDia[]
  tiposServicoFiltrado: TipoServicoModel[]
  canCreateNovoAtendimento: boolean
  isAtendimentoAgendado: boolean
  isAtendimentoProcedimentos: boolean
  isObservacaoAndResponsavel: boolean
  isObservacaoAndAuxiliar: boolean
  isRegistroTardio: boolean
  idadeGestacional?: IdadeGestacionalAcompanhamentoPreNatal
}

export interface SoapEditableModules {
  evolucaoSubjetivo: EvolucaoSubjetivoQueryModel
  evolucaoObjetivo: EvolucaoObjetivoQueryModel
  evolucaoAvaliacao: EvolucaoAvaliacaoQueryModel
  evolucaoPlano: EvolucaoPlanoQueryModel
  finalizacao: FinalizacaoAtendimentoQueryModel
  evolucoesProblema: EvolucaoProblema[]
  resultadosExames: ResultadoExameQueryModel[]
  solicitacoesExames: SolicitacaoExameQueryModel[]
  orientacoes: OrientacaoQueryModel[]
  receitasMedicamento: ReceitaMedicamentoQueryModel[]
  encaminhamentos: TipoEncaminhamentoExternoEnum[]
  encaminhamentosEspecializados: EncaminhamentoExternoEspecializadoQueryModel[]
  atestados: AtestadoQueryModel[]
  marcadorConsumoAlimentar: MarcadorConsumoAlimentar
}

const IDADE_MINIMA_EM_ANOS_DUM = 9

const shouldInformDum = (
  sexo: SexoEnum,
  identidadeGenero: IdentidadeGeneroEnum,
  dataNascimento: LocalDate,
  dataReferencia: Date
): boolean =>
  (sexo === SexoEnum.FEMININO || !isUndefinedOrNull(identidadeGenero)) &&
  differenceInYears(dataReferencia, parseISO(dataNascimento)) >= IDADE_MINIMA_EM_ANOS_DUM

const initialValueObjetivo = (isAtendimentoProcedimentos: boolean): Partial<ObjetivoFormModel> => ({
  medicoes: {
    openedAccordions: isAtendimentoProcedimentos ? ['medicoes'] : [],
  },
})

const initialValueFinalizacao = (
  tipoAtendimento: TipoAtendimentoEnum,
  procedimentoAdministrativo: ProcedimentoSelectModel,
  tiposServico: TipoServicoModel[],
  hasAgendamentosDia: boolean,
  canCreateNovoAtendimento: boolean,
  isObservacaoAndResponsavel: boolean,
  isObservacaoAndAuxiliar: boolean,
  isRegistroTardio: boolean
): Partial<FinalizacaoAtendimentoFormModel> => {
  const manterCidadaoLista =
    (((hasAgendamentosDia || tiposServico?.isNotEmpty()) && canCreateNovoAtendimento && !isObservacaoAndResponsavel) ||
      isObservacaoAndAuxiliar) &&
    !isRegistroTardio
      ? ManterCidadaoLista.SIM
      : ManterCidadaoLista.NAO

  const procedimentosAdministrativos: ProcedimentoSigtapFieldModel[] = procedimentoAdministrativo
    ? [{ _id: uuidv4(), procedimento: procedimentoAdministrativo, isAutomatico: true }]
    : []

  const tiposServicoIds = tiposServico?.map((tipoServico) => tipoServico.id)

  return {
    tipoAtendimento,
    procedimentosAdministrativos,
    desfechoAtendimento: {
      manterCidadaoLista,
      atendimento: {
        tiposServico: tiposServicoIds,
      },
    },
  }
}

export const initialValue = (props: AtendimentoIndividualInitialValueProps): Partial<SoapState> => {
  const {
    procedimentoAdministrativo,
    tipoAtendimento,
    agendamentosDia,
    tiposServicoFiltrado,
    canCreateNovoAtendimento,
    isAtendimentoAgendado,
    isAtendimentoProcedimentos,
    isObservacaoAndResponsavel,
    isObservacaoAndAuxiliar,
    isRegistroTardio,
    idadeGestacional,
  } = props

  const hasAgendamentosDia: boolean = isAtendimentoAgendado ? agendamentosDia?.length > 1 : agendamentosDia?.length > 0

  return {
    openedAccordions: ['S', 'O', 'A', 'P', 'D'],
    lastSaved: new Date(),
    objetivo: initialValueObjetivo(isAtendimentoProcedimentos),
    finalizacao: initialValueFinalizacao(
      tipoAtendimento,
      procedimentoAdministrativo,
      tiposServicoFiltrado,
      hasAgendamentosDia,
      canCreateNovoAtendimento,
      isObservacaoAndResponsavel,
      isObservacaoAndAuxiliar,
      isRegistroTardio
    ),
    avaliacao: {
      problemasECondicoes: [],
    },
    problemasECondicoes: [],
    medicoesAnteriores: [],
    lembretes: [],
    acompanhamentoPreNatal: {
      idadeGestacional,
    },
  }
}

export const getEditAtendProfId = (isAtendimentoObservacao: boolean, atendimentoProfissionalId: ID): ID => {
  return isAtendimentoObservacao ? atendimentoProfissionalId : null
}

export function isSoapEditableItem<T extends SoapEditableItem>(item: T): boolean {
  return !isUndefinedOrNull(item.editingId)
}

export const getTiposAtendimento = ({
  atendimentoProfissional,
  isAtendimentoAgendado,
  statusRevisaoAtendimento,
  isAcessoEstagio,
}: {
  atendimentoProfissional: AtendimentoProfissionalObservacaoInput
  isAtendimentoAgendado: boolean
  statusRevisaoAtendimento: StatusRevisaoAtendimento
  isAcessoEstagio: boolean
}) => {
  const {
    isAtendimentoObservacao,
    isObservacaoAndResponsavel,
    isObservacaoAndAuxiliar,
    responsavelObservacao,
    isObservacaoEmAndamento,
  } = getAtendimentoObservacaoModel(atendimentoProfissional, !!statusRevisaoAtendimento, isAcessoEstagio)

  const isAtendimentoOdonto = atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO

  const showAsAtendimentoProcedimentos =
    atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS || isObservacaoAndAuxiliar

  return {
    isAtendimentoOdonto,
    showAsAtendimentoProcedimentos,
    isAtendimentoAgendado,
    observacao: {
      isAtendimentoObservacao,
      isObservacaoAndResponsavel,
      isObservacaoAndAuxiliar,
      isObservacaoEmAndamento,
      responsavelObservacao,
    },
  }
}

export const getCidadaoAtendimento = (
  dataReferencia: Instant,
  cidadao: AtendimentoIndividualViewQuery['atendimento']['cidadao'],
  isGestante: boolean,
  dataPreNatalCard: ConvertDadosPreNatalProps['data']
): CidadaoAtendimentoContextModel => {
  const idadeEmAnos: number = cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, dataReferencia).years

  const informarDum: boolean =
    dataReferencia &&
    cidadao?.dataNascimento &&
    shouldInformDum(cidadao.sexo, cidadao.identidadeGeneroDbEnum, cidadao.dataNascimento, toDate(dataReferencia))

  const { dataInicioGestacao } = convertDadosPreNatal({ data: dataPreNatalCard })

  const idadeGestacional = differenceInDays(dataReferencia, dataInicioGestacao)

  return {
    ...cidadao,
    idadeEmAnos,
    informarDum,
    idadeGestacional,
    dataInicioGestacao,
    isGestante,
  }
}

export const getCiapCidPreNatal = (ciaps: CiapSelectFieldModel[], cids: CidSelectFieldModel[]): CiapCidPreNatal => {
  const cidZ34 = cids?.find((cid) => cid.codigo === CID_Z34_CODIGO)
  const ciapW78 = ciaps?.find((ciap) => ciap.codigo === CIAP_W78_CODIGO)

  return {
    ciap: ciapW78,
    cid: cidZ34,
  }
}
