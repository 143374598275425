import { HFlow, Icon, Text } from 'bold-ui'
import theme from 'config/theme'
import React from 'react'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { ProblemaCondicaoModel } from '../../../avaliacao/components/problemas-condicoes/model'
import { hasProblemaNaoEvolucaoEncerraGestacao } from '../../../avaliacao/components/problemas-condicoes/utils/verifications'

export function TagGestacaoEncerrada() {
  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(meta.avaliacao.problemasECondicoes.absolutePath(), {
    subscription: { value: true },
  })

  const isEncerramentoGestacao = hasProblemaNaoEvolucaoEncerraGestacao(problemasCondicoes)

  return (
    <Fragment>
      {isEncerramentoGestacao && (
        <HFlow alignItems='center' hSpacing={0.6}>
          <Icon icon='exclamationTriangleOutline' fill='alert' size={1} />
          <Text style={classes.textAlerta}>Gestação a ser encerrada</Text>
        </HFlow>
      )}
    </Fragment>
  )
}

const classes = {
  textAlerta: {
    color: theme.pallete.status.alert.main,
  },
}
