import { AcessoCbo } from 'graphql/types.generated'

import GruposCbo from './GruposCbo'

export const NIVEL_SUPERIOR: AcessoCbo[] = [
  AcessoCbo.MEDICO,
  AcessoCbo.CIRURGIAO_DENTISTA,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.ENFERMEIRO,
  AcessoCbo.PSICOLOGO,
  AcessoCbo.FARMACEUTICO,
]

export const NIVEL_TECNICO: AcessoCbo[] = [AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM, AcessoCbo.TSB]

const NIVEL_SUPERIOR_AS_STRING = NIVEL_SUPERIOR.map((item) => item.toString())
export const isCboNivelSuperior = (cbo: string) => {
  const grupoCbo = GruposCbo.find((item) => item.cbos.includes(cbo))
  return NIVEL_SUPERIOR_AS_STRING.includes(grupoCbo.grupo)
}

const GRUPO_CBO_DENTISTA = GruposCbo.find((item) => item.grupo === AcessoCbo.CIRURGIAO_DENTISTA)
export const isCboDentista = (cbo: string) => {
  return GRUPO_CBO_DENTISTA.cbos.includes(cbo)
}
