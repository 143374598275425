/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, HFlow, isEmpty, Theme, useTheme } from 'bold-ui'
import {
  CheckboxField,
  DateField,
  HorarioAgendaSelectField,
  HorarioAgendaSelectFieldModel,
  LocalAtendimentoSelectField,
  LocalAtendimentoSelectFieldModel,
  TextAreaField,
} from 'components/form'
import { addMonths, parseISO } from 'date-fns'
import { AcessoCbo } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { isDataAgendamentoInvalida } from 'util/atendimento/isDataAgendamentoInvalida'
import { MetaRoot } from 'util/metaPath'
import {
  LotacaoAgendaSelectField,
  LotacaoAgendaSelectFieldModel,
} from 'view/agenda/components/lotacao/LotacaoAgendaSelectField'

import { AgendarConsultaPanelProps } from './AgendarConsultaPanel'

export interface AgendarConsultaSectionModel {
  lotacao?: LotacaoAgendaSelectFieldModel
  data?: LocalDate
  horario?: HorarioAgendaSelectFieldModel
  isForaUbs?: boolean
  localAtendimento?: LocalAtendimentoSelectFieldModel
  observacoes?: string
}

interface AgendarConsultaSectionProps
  extends Omit<AgendarConsultaPanelProps, 'name' | 'isAtendimentoPreNatal' | 'prontuarioId'> {
  name: MetaRoot<AgendarConsultaSectionModel>
  isProximaConsulta?: boolean
  setIsFormAgendarProximaConsultaOpen?(isFormOpen: boolean): void
  onSubmitFormAgendarProximaConsulta?(): void
  cbosAcesso?: AcessoCbo[]
}

export function AgendarConsultaSection(props: AgendarConsultaSectionProps) {
  const {
    name,
    isProximaConsulta,
    registroTardio,
    hasPainelLateral = true,
    setIsFormAgendarProximaConsultaOpen,
    onSubmitFormAgendarProximaConsulta,
    cbosAcesso,
  } = props

  const theme = useTheme()
  const styles = createStyles(theme, isProximaConsulta)

  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const {
    input: { value: lotacao },
  } = useField(name.lotacao.absolutePath(), { subscription: { value: true } })

  const {
    input: { value: data },
  } = useField(name.data.absolutePath(), { subscription: { value: true } })

  const {
    input: { value: isForaUbs },
  } = useField(name.isForaUbs.absolutePath(), { subscription: { value: true } })

  const dataAgendamentoEhInvalida = isDataAgendamentoInvalida(data, serverTime)
  const lotacaoPreenchida = !isEmpty(lotacao)

  const handleClickCancelar = () => setIsFormAgendarProximaConsultaOpen(false)
  const handleClickSalvar = () => onSubmitFormAgendarProximaConsulta()

  return (
    <div css={styles.container}>
      <Grid>
        <Cell md={registroTardio ? 4 : 6} lg={registroTardio ? 4 : hasPainelLateral ? 6 : 4}>
          <LotacaoAgendaSelectField
            name={name.lotacao}
            label='Profissional'
            required={isProximaConsulta}
            isAgendaAd={false}
            loadItemsOnOpen={false}
            somenteCadastrarAgendamento
            cbosAcesso={cbosAcesso}
          />
        </Cell>
        <Cell md={3} lg={hasPainelLateral ? 3 : 2}>
          <DateField
            name={name.data}
            label='Data do agendamento'
            required={lotacaoPreenchida}
            disabled={!lotacaoPreenchida}
            minDate={serverTime}
            maxDate={addMonths(serverTime, 6)}
          />
        </Cell>
        <Cell md={3} lg={hasPainelLateral ? 3 : 2}>
          <HorarioAgendaSelectField
            name={name.horario}
            lotacaoId={lotacao?.id}
            disabled={dataAgendamentoEhInvalida}
            required={!dataAgendamentoEhInvalida}
            labels={{ inicial: 'Horário do agendamento' }}
            dia={data ? parseISO(data) : undefined}
          />
        </Cell>
        {registroTardio && (
          <Fragment>
            <Cell size={2} alignSelf='center'>
              <FormControl label={<span>&nbsp;</span>}>
                <CheckboxField label='Fora da UBS' name={name.isForaUbs} />
              </FormControl>
            </Cell>
            {isForaUbs && (
              <Cell size={4}>
                <LocalAtendimentoSelectField
                  name={name.localAtendimento}
                  required={isForaUbs}
                  label='Local de atendimento'
                  excludeUbs
                />
              </Cell>
            )}
          </Fragment>
        )}
        <Cell size={isProximaConsulta ? 9 : 12}>
          <TextAreaField
            label='Observações'
            name={name.observacoes}
            resize='vertical'
            maxLength={200}
            style={styles.observacoesField}
          />
        </Cell>
        {isProximaConsulta && (
          <Cell size={3} alignSelf='center'>
            <HFlow justifyContent='flex-end'>
              <Button size='small' kind='normal' onClick={handleClickCancelar}>
                Cancelar
              </Button>
              <Button size='small' kind='primary' onClick={handleClickSalvar}>
                Salvar
              </Button>
            </HFlow>
          </Cell>
        )}
      </Grid>
    </div>
  )
}

const createStyles = (theme: Theme, isProximaConsulta: Boolean) => ({
  container: css`
    padding: 1rem;
    ${isProximaConsulta ? `background-color: ${theme.pallete.primary.c90}` : ''};
  `,
  observacoesField: css`
    min-height: 4rem;
    width: 100%;
  `,
})
