import { Button, Cell, Grid, Heading, HFlow, Icon, Modal, ModalBody, Text, TextField, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import React from 'react'
import { useRouteMatch } from 'react-router'

interface VideochamadaUrlParams {
  id?: string
}

interface CompartilharChamadaModalProps {
  open: boolean
  onClose(): void
}

export function CompartilharChamadaModal({ onClose, open }: CompartilharChamadaModalProps) {
  const {
    params: { id: videochamadaId },
  } = useRouteMatch<VideochamadaUrlParams>()

  const alert = useAlert()

  const url = `${window.location.origin}/videochamada/${videochamadaId}`
  const handleCopiarLinkClick = () => {
    navigator.clipboard.writeText(url)
    alert('success', 'Link da chamada copiado com sucesso')
  }

  return (
    <Modal open={open} onClose={onClose} size='small'>
      <ModalBody>
        <VFlow vSpacing={1}>
          <Heading level={2}>
            <HFlow alignItems='center'>
              <Icon icon='infoCircleOutline' size={3} fill='primary' />
              Compartilhar chamada
            </HFlow>
          </Heading>
          <Text>
            Para compartilhar a chamada, é possível copiar o link e enviar para convidados de dentro ou de fora do e-SUS
            APS.
          </Text>
          <Grid alignItems='center' gap={0.5}>
            <Cell flexGrow={1}>
              <TextField disabled value={url} />
            </Cell>
            <Cell>
              <Button onClick={handleCopiarLinkClick} kind='primary' size='small'>
                Copiar
              </Button>
            </Cell>
          </Grid>
        </VFlow>
      </ModalBody>
    </Modal>
  )
}
