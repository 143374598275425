/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Icon, Tooltip, VFlow } from 'bold-ui'
import { ProcedimentoSelectField, ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { DEFAULT_SELECT_SIZE } from 'components/form/field/select/useAsyncQuerySelect'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { getFieldError } from 'components/form/final-form/util'
import { HLabel } from 'components/HLabel'
import { FormApi } from 'final-form'
import { GrupoProcedimentoEnum, TipoExameEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment, useState } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import metaPath, { MetaArray } from 'util/metaPath/metaPath'
import { createValidator, maxLength } from 'util/validation'
import { isEmpty } from 'util/validation/Util'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'
import { EditableList, EditableRow, useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'
import { ObservacaoFormGrid } from 'view/atendimentos/detail/components/ObservacaoFormGrid'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { showCodigoProcedimento } from '../../../objetivo/resultados-exames/util'
import { AppendItemExames } from './AppendItemExames'

export interface ExameListSelectFieldModel extends SoapEditableItem {
  _id: ID
  exame: ProcedimentoSelectModel
  observacao?: string
}

const path = metaPath<ExameListSelectFieldModel>()

export interface ExameListSelectFieldProps {
  name: MetaArray<ExameListSelectFieldModel>
  cidadao: CidadaoAtendimento
  tipoExame: TipoExameEnum
}

const validator = createValidator<ExameListSelectFieldModel>({
  observacao: [maxLength(150)],
})

export function ExameListSelectField(props: ExameListSelectFieldProps) {
  const { name, cidadao, tipoExame } = props

  const { atendimentoProfissional } = useAtendimentoContext()

  const {
    handleRowChanged,
    handleSubmit,
    input: { value },
    removeItem,
    meta,
  } = useEditableListField({ name })

  const procedimentoIsEqual = (exame: ProcedimentoSelectModel) => {
    return (value || []).find((exameValueItem) => exame.id === exameValueItem.exame.id)
  }

  const fieldError = getFieldError(meta)

  const renderForm = (formProps: FormRenderProps<ExameListSelectFieldModel>) => {
    const handleChange = (newValue: ProcedimentoSelectModel) => {
      newValue && !procedimentoIsEqual(newValue) && formProps.form.submit()
    }

    return (
      <ProcedimentoSelectField
        label='Exames'
        name={path.exame}
        filtroPadrao
        loadItemsOnOpen
        sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : undefined}
        dataNascimento={cidadao?.dataNascimento}
        dataAtendimento={dateAsYyyyMmDd(atendimentoProfissional.iniciadoEm)}
        gruposProcedimento={[GrupoProcedimentoEnum.FINALIDADE_DIAGNOSTICA]}
        onChange={handleChange}
        itemIsEqual={procedimentoIsEqual}
        tipoExame={tipoExame}
        error={fieldError}
        required
        components={{
          AppendItem: ({ items }) => AppendItemExames(items?.length, DEFAULT_SELECT_SIZE),
        }}
      />
    )
  }

  return (
    <VFlow vSpacing={0.5}>
      <Form<ExameListSelectFieldModel> render={renderForm} validate={validator} onSubmit={handleSubmit} />
      {!isEmpty(value) && (
        <EditableList>
          {value.map((item) => (
            <ProcedimentoRow key={item._id} model={item} onChange={handleRowChanged} onRemove={removeItem} />
          ))}
        </EditableList>
      )}
    </VFlow>
  )
}

interface ProcedimentoRowProps {
  model: ExameListSelectFieldModel
  onChange(values: ExameListSelectFieldModel): void
  onRemove(itemToRemove: ExameListSelectFieldModel): void
}

function ProcedimentoRow(props: ProcedimentoRowProps) {
  const { model, onChange, onRemove } = props
  const [editing, setEditing] = useState(false)

  const toggleEditing = () => setEditing(!editing)

  const handleRemove = () => onRemove(model)

  const handleSubmit = (value: ExameListSelectFieldModel, formApi: FormApi) => {
    onChange(value)
    setTimeout(formApi.reset)
    toggleEditing()
  }

  const textoExame = `${model.exame.descricao} - ${showCodigoProcedimento(model.exame.codigo)}`

  const renderForm = (formRenderProps: FormRenderProps<ExameListSelectFieldModel>) => (
    <VFlow
      vSpacing={0.5}
      style={css`
        padding: 1rem 0;
      `}
    >
      <Heading level={4}>Adicionar observação</Heading>
      <HLabel title='Exame:'>{textoExame}</HLabel>
      <ObservacaoFormGrid
        name={path.observacao}
        maxLength={150}
        onCancel={toggleEditing}
        onSubmit={formRenderProps.handleSubmit}
        style={css`
          resize: none;
        `}
      />
    </VFlow>
  )

  return (
    <EditableRow editing={editing}>
      {!editing && (
        <Fragment>
          <Grid
            style={css`
              margin: 0;
            `}
          >
            <Cell size={9} alignSelf='center'>
              {textoExame}
            </Cell>
            <Cell
              size={3}
              alignSelf='center'
              style={css`
                padding: 0.25rem 0;
                text-align: right;
              `}
            >
              <Tooltip text={model.observacao ? 'Editar observação' : 'Adicionar observação'}>
                <Button size='small' skin='ghost' onClick={toggleEditing}>
                  <Icon icon='chatOutline' />
                </Button>
              </Tooltip>
              <Tooltip text='Excluir'>
                <Button size='small' skin='ghost' onClick={handleRemove}>
                  <Icon icon='trashOutline' />
                </Button>
              </Tooltip>
            </Cell>
          </Grid>
          <Observacao observacao={model.observacao} />
        </Fragment>
      )}
      {editing && (
        <Form<ExameListSelectFieldModel>
          render={renderForm}
          initialValues={model}
          onSubmit={handleSubmit}
          validate={validator}
          initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
        />
      )}
    </EditableRow>
  )
}
