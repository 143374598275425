import { BuscaAtivaVacinacaoInput } from 'graphql/types.generated'

import { BuscaAtivaVacinacaoFormModel } from './model'

export default (model: BuscaAtivaVacinacaoFormModel): BuscaAtivaVacinacaoInput => ({
  grupoAlvo: model.grupoAlvo?.enum,
  status: model.status?.enum,
  equipeVinculadaIne: model.equipe?.ine,
  microareas: model.microareas,
  filtrosAvancados: {
    imunobiologicoIds: model.filtrosAvancados?.imunobiologicos?.map((imuno) => imuno.id),
    doseIds: model.filtrosAvancados?.doses?.map((dose) => dose.id),
  },
  tipoVisualizacao: model.tipoVisualizacao,
  pageParams: {
    sort: ['cidadao', 'idade'],
    ...model.pageParams,
  },
})
