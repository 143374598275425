/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HeadingSection, Theme, useStyles } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { FormSpy } from 'react-final-form'
import { isCboPodeRegistrarCid } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ChecklistEncaminhamento } from './ChecklistEncaminhameto'
import EncaminhamentoExternoEspecializadoForm from './EncaminhamentoExternoEspecializadoForm'
import EncaminhamentoExternoTable from './list/EncaminhamentoExternoTable'
import { EncaminhamentoExternoEspecializadoPlanoModel } from './model'

export interface EncaminhamentoExternoViewProps {
  name: MetaPath<EncaminhamentoExternoEspecializadoPlanoModel>
  prontuarioId: ID
  atendimentoId: ID
  cidadao: CidadaoAtendimento
  cboAtendProf: string
  atendimentoProfissional: AtendimentoProfissional
  isAtendimentoOdonto: boolean
}

export default function EncaminhamentoExternoView(props: EncaminhamentoExternoViewProps) {
  const {
    name,
    prontuarioId,
    atendimentoId,
    cidadao,
    cboAtendProf,
    atendimentoProfissional,
    isAtendimentoOdonto,
  } = props

  const {
    observacao: { isAtendimentoObservacao },
  } = useAtendimentoContext()

  const { handleSubmit, removeItem } = useEditableListField({
    name: name.encaminhamentosEspecializadosRecentes,
  })

  const { theme } = useStyles()
  const classes = createStyles(theme)

  const renderCiapForm = !isCboPodeRegistrarCid(cboAtendProf)

  return (
    <Fragment>
      <FormSpy<SoapState>>
        {({ values }) => (
          <Fragment>
            <HeadingSection level={4} title='Encaminhamentos' />
            <ChecklistEncaminhamento name={name.encaminhamentosRecentes} />

            <FormSectionAccordion
              name={name.openedAccordions}
              title='Serviço especializado'
              uuid='encaminhamentoServicoEspecializado'
              style={classes.accordionEspecializados}
              buttonStyle={classes.accordionEspecializadosButton}
            >
              <div css={classes.wrapper}>
                <EncaminhamentoExternoEspecializadoForm
                  prontuarioId={prontuarioId}
                  onSubmit={handleSubmit}
                  isAtendimentoOdonto={isAtendimentoOdonto}
                  cidadao={cidadao}
                  renderCiap={renderCiapForm}
                  encaminhamentosCache={values?.plano?.encaminhamentoExterno}
                />
              </div>
            </FormSectionAccordion>

            <EncaminhamentoExternoTable
              prontuarioId={prontuarioId}
              atendimentoId={atendimentoId}
              encaminhamentosCache={values?.plano?.encaminhamentoExterno}
              dataReferencia={atendimentoProfissional.iniciadoEm}
              lotacao={atendimentoProfissional.lotacao}
              isAtendimentoObservacao={isAtendimentoObservacao}
              atendimentoProfissionalId={atendimentoProfissional.id}
              removeItem={removeItem}
            />
          </Fragment>
        )}
      </FormSpy>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: css`
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  `,
  accordionEspecializados: css`
    margin-top: 0.5rem;
  `,
  accordionEspecializadosButton: css`
    padding: 0.5rem 1rem;
  `,
})
