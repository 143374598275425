import { DateRange } from 'bold-ui'
import { CboSelectModel } from 'components/form'
import { RadioOption, UnidadeSaudeSelectModel } from 'components/form/field'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/lotacaoAndEstagioField/LotacaoAndEstagioSelectField'
import { PerfilSelectModel } from 'components/form/field/select/PerfilSelectField/PerfilSelectField'
import { metaPath } from 'util/metaPath'

export enum NivelEstagioEnum {
  TECNICO = 'TECNICO',
  SUPERIOR = 'SUPERIOR',
}

export const nivelEstagioOptions: RadioOption<NivelEstagioEnum>[] = [
  { value: NivelEstagioEnum.SUPERIOR, label: 'Superior' },
  { value: NivelEstagioEnum.TECNICO, label: 'Técnico' },
]

export const path = metaPath<EstagioFormModel>()

export interface EstagioFormModel {
  profissionalId: ID
  unidadeSaude: UnidadeSaudeSelectModel
  lotacaoSupervisora: LotacaoAndEstagioSelectFieldModel
  cboFormacao: CboSelectModel
  periodoEstagio: DateRange
  perfis: PerfilSelectModel[]
  instituicaoEnsino: string
  nivelEstagio: NivelEstagioEnum
}
