/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, Tooltip } from 'bold-ui'
import { RadioGroupField } from 'components/form'
import { TipoAtendimentoEnum } from 'graphql/types.generated'
import { memo } from 'react'
import { Meta } from 'util/metaPath'

export interface TipoAtendimentoPanelProps {
  name: Meta<TipoAtendimentoEnum>
  isAtendimentoAgendado: boolean
  isAtendimentoOdonto: boolean
  tooltipText?: string
  disabled?: boolean
}

export const TipoAtendimentoPanel = memo(
  ({
    name,
    isAtendimentoAgendado,
    isAtendimentoOdonto,
    tooltipText = '',
    disabled = false,
  }: TipoAtendimentoPanelProps) => (
    <FormControl label='Tipo de atendimento' style={styles.formControl} required>
      <Tooltip text={tooltipText}>
        <div css={styles.tooltip}>
          <RadioGroupField
            name={name}
            lines={isAtendimentoAgendado && !isAtendimentoOdonto ? 2 : 1}
            options={getTiposAtendimentoOptions(isAtendimentoAgendado, isAtendimentoOdonto)}
            disabled={disabled}
          />
        </div>
      </Tooltip>
    </FormControl>
  )
)

function getTiposAtendimentoOptions(isAtendimentoAgendado: boolean, isAtendimentoOdonto: boolean) {
  const options = []

  if (isAtendimentoAgendado) {
    options.push({ label: 'Consulta agendada', value: TipoAtendimentoEnum.CONSULTA })
    if (!isAtendimentoOdonto)
      options.push({
        label: 'Consulta agendada programada / Cuidado continuado',
        value: TipoAtendimentoEnum.CONSULTA_AGENDADA_CUIDADO_CONTINUADO,
      })
  }

  options.push(
    { label: 'Consulta no dia', value: TipoAtendimentoEnum.CONSULTA_NO_DIA },
    { label: 'Urgência', value: TipoAtendimentoEnum.URGENCIA }
  )

  return options
}

const styles = {
  formControl: css`
    display: flex;
    flex-direction: column;
  `,
  tooltip: css`
    margin-right: auto;
  `,
}
