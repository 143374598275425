/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  Tag,
  Text,
  VFlow,
} from 'bold-ui'
import { InfoIcon } from 'components/InfoIcon'
import { Cep, Telefone } from 'components/label'
import { useServerTime } from 'hooks/useServerTime'
import { useHistory, useRouteMatch } from 'react-router'
import {
  domicilioTipoImovelRecord,
  IMOVEIS_COM_RESPONSAVEL_TECNICO,
  ImovelModel,
  TipoImovelEnum,
  tipoImovelRecord,
} from 'view/visualizacaoTerritorial/model'
import {
  getTextoDiasDesdeUltimaVisita,
  redirectToAtualizarImovelCds,
} from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { ResponsavelTecnicoImovelList } from './ResponsavelTecnicoImovelList'

interface ModalImovelProps extends ModalProps {
  imovel: ImovelModel
  lotacaoId: ID
  profissionalId: ID
}

export function ModalOutrosImoveis(props: ModalImovelProps) {
  const {
    imovel: {
      uuidOrigemImovel,
      cep,
      complemento,
      numero,
      diasDesdeUltimaVisita,
      tipoCdsImovelId,
      tipoDomicilioId,
      logradouro,
      numeroMoradores,
      responsavelTecnico,
      bairro,
      telefoneResidencial,
      telefoneContato,
    },
    lotacaoId,
    profissionalId,
    onClose,
    ...rest
  } = props

  const { IconImovel, tooltip, fill } =
    tipoCdsImovelId !== TipoImovelEnum.DOMICILIO
      ? tipoImovelRecord[tipoCdsImovelId]
      : domicilioTipoImovelRecord[tipoDomicilioId]

  const { getServerTimeNow } = useServerTime()

  const styles = createStyles(fill)

  const history = useHistory()
  const match = useRouteMatch()

  return (
    <Modal size='small' closeOnBackdropClick onClose={onClose} {...rest}>
      <ModalBody>
        <HFlow alignItems='center' hSpacing={0.5}>
          <IconImovel css={styles.icon} />
          <VFlow vSpacing={0}>
            <Heading level={5}>{tooltip}</Heading>
            <Heading level={1}>
              {`${logradouro}`.titleCase()} nº {numero ?? 'S/N'}
              {complemento ? ` | ${complemento.titleCase()}` : ''}
            </Heading>
          </VFlow>
        </HFlow>
        <HFlow css={styles.hflowItens}>
          {IMOVEIS_COM_RESPONSAVEL_TECNICO.includes(+tipoCdsImovelId) && (
            <Tag>Nº de moradores: {numeroMoradores ?? '0'}</Tag>
          )}
        </HFlow>
        <VFlow style={styles.vflowItens}>
          <Grid>
            <Cell size={3}>
              <InfoLabel title='CEP'>
                <Cep value={cep} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Bairro'>{bairro?.titleCase()}</InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Telefone'>
                <Telefone value={telefoneContato ?? telefoneResidencial} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <HFlow hSpacing={0}>
                <InfoLabel title='Última visita'>
                  {diasDesdeUltimaVisita ? (
                    <Text>{getTextoDiasDesdeUltimaVisita(getServerTimeNow(), diasDesdeUltimaVisita)}</Text>
                  ) : (
                    'desconhecida'
                  )}
                </InfoLabel>
                <InfoIcon
                  icon='infoCircleFilled'
                  text='Data da visita mais recente realizada para o imóvel em si, a família ou qualquer um dos moradores.'
                />
              </HFlow>
            </Cell>

            {IMOVEIS_COM_RESPONSAVEL_TECNICO.includes(+tipoCdsImovelId) && responsavelTecnico && (
              <Cell size={12}>
                <ResponsavelTecnicoImovelList responsavelTecnico={responsavelTecnico} />
              </Cell>
            )}
          </Grid>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button
            title='Atualizar imóvel'
            kind='normal'
            onClick={() => redirectToAtualizarImovelCds(history, match, uuidOrigemImovel, profissionalId, lotacaoId)}
          >
            Atualizar imóvel
          </Button>
          <Button title='Fechar' kind='primary' onClick={onClose}>
            Fechar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const createStyles = (iconFill: string) => ({
  tag: css`
    margin-right: 0.5rem;
  `,
  icon: css`
    fill: ${iconFill};
    width: 2.5rem;
    height: 2.5rem;
  `,
  vflowItens: css`
    margin-top: 1rem;
  `,
  hflowItens: css`
    margin-top: 1rem;
  `,
})
