import { FORM_ERROR } from 'final-form'
import { CategoriaSubstanciaAlergia, SituacaoProblema } from 'graphql/types.generated'
import moment from 'moment'
import { isUndefinedOrNull } from 'util/checks'
import { createValidator, ErrorObject, range, required } from 'util/validation'
import { isEmpty, isValid } from 'util/validation/Util'

import { Problema } from '../../../aside/types/ProblemaModel'
import { DataIdadeFieldModel } from '../../../components/DataIdadeField'
import { TipoPreNatal } from '../../../pre-natal/model'
import { isCidFromFamiliaZ34 } from '../../../pre-natal/util'
import { validateProblemaCondicaoPreNatal } from '../../../pre-natal/validator'
import { AlergiaReacaoModel } from '../alergias-reacoes/model'
import { ProblemaCondicaoModel } from './model'
import {
  hasProblemaComCiapW78Ativo,
  hasProblemaCondicaoComCiapW78Ativo,
  isProblemaCondicaoComCiapW78,
  isProblemaCondicaoComCiapW78Ativo,
} from './utils/verifications'

const MESSAGE_RELACAO_DUPLICADA_A_EVOLUIR =
  'Este problema/condição já foi adicionado e deve ser evoluído na Avaliação do SOAP.'
const MESSAGE_RELACAO_DUPLICADA = 'Este relacionamento de CIAP2 e CID10 já está adicionado.'
const MESSAGE_RELACAO_DUPLICADA_COM_ATIVO_OU_LATENTE =
  'Não é possivel adicionar problemas e/ou condições ativas ou latentes repetidas para um mesmo par de CID10 e CIAP.'
const MESSAGE_CONDICAO_ATIVA_GRAVIDEZ_DUPLICADA = 'Já existe uma condição de gravidez ativa.'

export const alergiasReacoesValidator = (dataNascimentoCidadao: LocalDate, dataInicioAtendimento: Instant) =>
  createValidator<AlergiaReacaoModel>(
    {
      dataInicio: dataProblemaValidator(dataNascimentoCidadao, dataInicioAtendimento),
      categoriaSubstancia: [required],
    },
    (formValues, errors) => {
      if (formValues?.categoriaSubstancia && formValues.categoriaSubstancia === CategoriaSubstanciaAlergia.BIOLOGICO) {
        errors.substanciaImunobiologico = required(formValues?.substanciaImunobiologico)
      } else if (
        formValues?.categoriaSubstancia &&
        formValues.categoriaSubstancia === CategoriaSubstanciaAlergia.MEDICAMENTO
      ) {
        errors.substanciaMedicamento = required(formValues?.substanciaMedicamento)
      } else {
        errors.substanciaCbara = required(formValues?.substanciaCbara)
      }

      return errors
    }
  )

export const problemasCondicoesValidator = (
  somenteCiap: boolean,
  values: ProblemaCondicaoModel[],
  isEdit: boolean,
  dataInicioAtendimento: Instant,
  dataNascimentoCidadao: LocalDate,
  problemasAtivosLatentes: Problema[],
  isGestante: boolean,
  initialValues: ProblemaCondicaoModel,
  tipoPreNatal?: TipoPreNatal,
  isLPC?: boolean
) =>
  createValidator<ProblemaCondicaoModel>(
    {
      dataInicio: dataProblemaValidator(dataNascimentoCidadao, dataInicioAtendimento),
      dataFim: dataProblemaValidator(dataNascimentoCidadao, dataInicioAtendimento),
    },
    (formValues, errors) => {
      const emptyCiapCid = isEmpty(formValues?.ciap) && isEmpty(formValues?.cid)

      if (somenteCiap) {
        errors.ciap = required(formValues?.ciap)
      } else {
        errors[FORM_ERROR] = emptyCiapCid ? 'Pelo menos um dos campos é de preenchimento obrigatório.' : null
        validateRelacaoCiapCidPreNatal(formValues, errors)
      }

      if (!emptyCiapCid && isValid(errors)) {
        validateRelacaoDuplicada(values, formValues, isEdit, errors, isLPC, problemasAtivosLatentes, somenteCiap)
      }

      errors = validateProblemaCondicaoPreNatal(
        formValues,
        errors,
        isEdit,
        isGestante,
        initialValues,
        isLPC,
        tipoPreNatal
      )

      if (formValues?.incluirNaListaProblemas || isLPC) {
        if (isUndefinedOrNull(formValues?.situacaoProblema))
          errors.situacaoProblema = required(formValues?.situacaoProblema)
        if (
          formValues?.dataFim?.data &&
          formValues?.dataInicio?.data &&
          moment(formValues.dataFim.data).isBefore(moment(formValues.dataInicio.data))
        ) {
          errors.dataFim = { data: 'Deve ser posterior ou igual ao Início do problema.' }
        }
      }

      return errors
    }
  )

const dataProblemaValidator = (dataMinima: LocalDate, dataMaxima: number) =>
  createValidator<DataIdadeFieldModel>({}, (formValues, errors) => {
    if (formValues) {
      errors.idade = range(0, 11)(formValues?.idade?.meses) && 'Meses deve ter valor entre 0 e 11.'

      if (!errors.idade) errors.idade = range(0, 999)(formValues?.idade?.anos) && 'Anos deve ter valor entre 0 e 999.'

      if (moment(formValues.data).isBefore(moment(dataMinima))) {
        errors.data = 'Deve ser posterior ou igual à Data de nascimento do cidadão.'
      }

      if (moment(formValues.data).startOf('day').isAfter(moment(dataMaxima))) {
        errors.data = 'Deve ser anterior ou igual à Data do atendimento.'
      }
    }

    return errors
  })

function validateRelacaoDuplicada(
  values: ProblemaCondicaoModel[],
  formValues: ProblemaCondicaoModel,
  isEdit: boolean,
  errors: ErrorObject<ProblemaCondicaoModel>,
  isLPC: boolean,
  problemasAtivosLatentes: Problema[],
  somenteCiap: boolean
) {
  isLPC
    ? validateRelacaoDuplicadaLPC(formValues, values, isEdit, somenteCiap, problemasAtivosLatentes, errors)
    : validateRelacaoDuplicadaAvaliacao(formValues, values, isEdit, somenteCiap, problemasAtivosLatentes, errors)
}

function validateRelacaoDuplicadaLPC(
  formValues: ProblemaCondicaoModel,
  values: ProblemaCondicaoModel[],
  isEdit: boolean,
  somenteCiap: boolean,
  problemasAtivosLatentes: Problema[],
  errors: ErrorObject<ProblemaCondicaoModel>
) {
  const hasItemDuplicado = findCiapOuCidDuplicadoLPC(formValues, values, isEdit, somenteCiap)
  const hasItemDuplicadoAtivoOuLatente = findCiapOuCidDuplicadoEmAtivosLatentes(
    formValues,
    problemasAtivosLatentes,
    true
  )
  const hasCondicaoAtivaGravidezDuplicada = findCondicaoAtivaGravidezDuplicada(
    isEdit,
    formValues,
    values,
    problemasAtivosLatentes
  )

  fillErrorsWithRelacaoDuplicadaMessage(
    hasCondicaoAtivaGravidezDuplicada,
    errors,
    hasItemDuplicado,
    hasItemDuplicadoAtivoOuLatente,
    MESSAGE_RELACAO_DUPLICADA_A_EVOLUIR
  )
}

function validateRelacaoDuplicadaAvaliacao(
  formValues: ProblemaCondicaoModel,
  values: ProblemaCondicaoModel[],
  isEdit: boolean,
  somenteCiap: boolean,
  problemasAtivosLatentes: Problema[],
  errors: ErrorObject<ProblemaCondicaoModel>
) {
  const problemaAEvouirId = formValues.problemaId
  const problemasAtivosLatentesSemPossivelEvolucao = problemasAtivosLatentes.filter(
    (item) => item.id !== problemaAEvouirId
  )

  const hasItemDuplicado = findCiapOuCidDuplicado(formValues, values, isEdit, somenteCiap)
  const hasItemDuplicadoAtivoOuLatente =
    formValues.incluirNaListaProblemas &&
    findCiapOuCidDuplicadoEmAtivosLatentes(formValues, problemasAtivosLatentesSemPossivelEvolucao, false)
  const hasCondicaoAtivaGravidezDuplicada = findCondicaoAtivaGravidezDuplicada(
    isEdit,
    formValues,
    values,
    problemasAtivosLatentesSemPossivelEvolucao
  )

  fillErrorsWithRelacaoDuplicadaMessage(
    hasCondicaoAtivaGravidezDuplicada,
    errors,
    hasItemDuplicado,
    hasItemDuplicadoAtivoOuLatente,
    MESSAGE_RELACAO_DUPLICADA_COM_ATIVO_OU_LATENTE
  )
}

function fillErrorsWithRelacaoDuplicadaMessage(
  hasCondicaoAtivaGravidezDuplicada: boolean,
  errors: ErrorObject<ProblemaCondicaoModel>,
  hasItemDuplicado: boolean,
  hasItemDuplicadoAtivoOuLatente: boolean,
  messageRelacaoDuplicadaAtivoOuLatente: string
) {
  if (hasCondicaoAtivaGravidezDuplicada) {
    errors[FORM_ERROR] = MESSAGE_CONDICAO_ATIVA_GRAVIDEZ_DUPLICADA
  } else {
    errors[FORM_ERROR] = hasItemDuplicado
      ? MESSAGE_RELACAO_DUPLICADA
      : hasItemDuplicadoAtivoOuLatente && messageRelacaoDuplicadaAtivoOuLatente
  }
}

function findCondicaoAtivaGravidezDuplicada(
  isEdit: boolean,
  inputForm: ProblemaCondicaoModel,
  currentValues: ProblemaCondicaoModel[],
  problemasAtivosLatentesCidadao: Problema[]
) {
  const currentValuesWithoutInput = isEdit
    ? currentValues.filter((value) => value._id !== inputForm._id)
    : currentValues
  return (
    isProblemaCondicaoComCiapW78Ativo(inputForm) &&
    (hasProblemaCondicaoComCiapW78Ativo(currentValuesWithoutInput) ||
      hasProblemaComCiapW78Ativo(problemasAtivosLatentesCidadao))
  )
}

function findCiapOuCidDuplicado(
  form: ProblemaCondicaoModel,
  values: ProblemaCondicaoModel[],
  isEdit: boolean,
  somenteCiap: boolean
) {
  return (
    (values ?? []).find(
      (problema) =>
        form?.ciap?.id === problema.ciap?.id &&
        (somenteCiap ? true : form?.cid?.id === problema.cid?.id) &&
        (isEdit ? form._id !== problema._id : true) &&
        !(problema.isRegistradoAgora && problema.situacaoProblema === SituacaoProblema.RESOLVIDO)
    ) !== undefined
  )
}

function findCiapOuCidDuplicadoLPC(
  form: ProblemaCondicaoModel,
  values: ProblemaCondicaoModel[],
  isEdit: boolean,
  somenteCiap: boolean
) {
  return (
    (values ?? []).find(
      (problema) =>
        form?.ciap?.id === problema.ciap?.id &&
        (somenteCiap ? true : form?.cid?.id === problema.cid?.id) &&
        (isEdit ? form._id !== problema._id : true) &&
        problema.situacaoProblema !== SituacaoProblema.RESOLVIDO &&
        form.situacaoProblema !== SituacaoProblema.RESOLVIDO
    ) !== undefined
  )
}

function findCiapOuCidDuplicadoEmAtivosLatentes(
  form: ProblemaCondicaoModel,
  problemasAtivosLatentes: Problema[],
  checkIfParAtualIsResolvido: boolean
) {
  return (
    (problemasAtivosLatentes ?? []).find(
      (problema) =>
        problema.ciap?.id === form.ciap?.id &&
        form.cid?.id === problema.cid10?.id &&
        problema.situacao !== SituacaoProblema.RESOLVIDO &&
        (checkIfParAtualIsResolvido ? form.situacaoProblema !== SituacaoProblema.RESOLVIDO : true)
    ) !== undefined
  )
}

function validateRelacaoCiapCidPreNatal(formValues: ProblemaCondicaoModel, errors: ErrorObject<ProblemaCondicaoModel>) {
  const isCiapW78 = isProblemaCondicaoComCiapW78(formValues)
  const isCidFamiliaZ34 = isCidFromFamiliaZ34(formValues?.cid?.codigo)
  if ((isCiapW78 && !isCidFamiliaZ34) || (!isCiapW78 && isCidFamiliaZ34)) {
    errors[FORM_ERROR] = 'Pré-natal precisa de CIAP2 e CID10 relacionados.'
  }
}
