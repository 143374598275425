import { compact } from 'lodash'

import { showDescricaoCodigoProcedimento } from '../util'
import { HistoricoTecidosMolesQueryModel, TecidosMolesTableItemModel } from './model'
import { TecidosMolesFieldModel } from './TecidosMolesField'

export const joinTecidosMoles = (
  valoresHistorico: HistoricoTecidosMolesQueryModel[],
  valoresAtuais: ReadonlyArray<TecidosMolesFieldModel>,
  lotacao: TecidosMolesTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): TecidosMolesTableItemModel[] => {
  return [
    ...(valoresHistorico ?? []).map(convertHistoricoToJoined),
    ...(valoresAtuais || []).map((item) => convertValorAtualToJoined(item, lotacao, atendIniciadoEm)),
  ].sort((a, b) => b.lastUpdate - a.lastUpdate)
}

const convertValorAtualToJoined = (
  item: TecidosMolesFieldModel,
  lotacao: TecidosMolesTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): TecidosMolesTableItemModel => {
  const { _id, lastUpdate, local, caracteristicasLesao, observacao, problema: cid10, procedimentos } = item

  return {
    cacheId: _id,
    lotacao,
    lastUpdate: lastUpdate ?? atendIniciadoEm,
    parteBucal: local.label,
    cid10,
    procedimentos,
    descricaoSintomatologia: caracteristicasLesao?.sintomatologia?.descricaoOutro,
    observacaoProcedimentos: observacao,
    searchProcedimentos: procedimentos?.map(showDescricaoCodigoProcedimento).join(' '),
    isRegistradoAgora: true,
    caracteristicasLesao: caracteristicasLesao
      ? compact([
          caracteristicasLesao.base,
          caracteristicasLesao.consistencia,
          caracteristicasLesao.cor,
          caracteristicasLesao.lesaoFundamental?.lesaoEnvolvida,
          caracteristicasLesao.quantidade,
          caracteristicasLesao.sintomatologia?.value !== 'outros' && caracteristicasLesao.sintomatologia?.value,
          caracteristicasLesao.superficie,
          caracteristicasLesao.tamanho,
        ])
      : [],
  }
}

const convertHistoricoToJoined = (item: HistoricoTecidosMolesQueryModel): TecidosMolesTableItemModel => {
  const {
    parteBucal: { descricao: parteBucal },
    caracteristicasLesao,
    cid10,
    descricaoSintomatologia,
    evolucaoProcedimentoOdonto,
    atendimentoProfissionalOdonto: {
      atendimentoProfissional: { iniciadoEm, lotacao },
    },
  } = item

  const procedimentos = evolucaoProcedimentoOdonto?.procedimentos

  return {
    lotacao,
    parteBucal,
    cid10,
    descricaoSintomatologia,
    procedimentos,
    caracteristicasLesao: caracteristicasLesao?.flatMap(({ caracteristicaLesao }) => caracteristicaLesao) ?? [],
    observacaoProcedimentos: evolucaoProcedimentoOdonto?.observacao,
    searchProcedimentos: procedimentos?.map(showDescricaoCodigoProcedimento).join(' '),
    isRegistradoAgora: false,
    lastUpdate: iniciadoEm,
  }
}
