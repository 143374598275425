import 'react-big-calendar/lib/css/react-big-calendar.css'

import { ExternalStyles } from 'bold-ui'
import { getDay, max, min } from 'date-fns'
import { subSeconds } from 'date-fns/esm'
import React, { useMemo } from 'react'
import { View } from 'react-big-calendar'
import { emptyArray } from 'util/array'

import { TimeInterval } from '.'
import { AgendaDayPeriod } from './AgendaDayPeriod'
import { AgendaEventProps } from './AgendaEventWrapper'
import { defaultMessages } from './agendaLocalizer'
import { AgendaTimeSlotProps } from './AgendaTimeSlotWrapper'
import { AgendaEventModel, AgendaMessages, WeekTimeIntervals } from './model'
import { computeAvailableSlots, computeTimeUntilNextSlot, timeIntervalInDay } from './utils'

export interface AgendaComponents<TEvent extends AgendaEventModel> {
  Event?: React.ComponentType<AgendaEventProps<TEvent>>
  TimeSlot?: React.ComponentType<AgendaTimeSlotProps>
  AvailableSlotHover?: React.ComponentType<AgendaTimeSlotProps>
}

export interface AgendaProps<TEvent extends AgendaEventModel> {
  date?: number
  getNow?(): Date
  events?: TEvent[]
  view?: View
  availableTimes?: WeekTimeIntervals
  stepInMinutes?: number
  disablePastDates?: boolean
  disablePartialSlots?: boolean
  showUnavailableLimits?: boolean
  showToolbar?: boolean

  style?: ExternalStyles

  messages?: AgendaMessages
  components?: AgendaComponents<TEvent>

  onSelectSlot?(start: Date, end: Date): void

  onNavigate?(newStart: Date, newEnd: Date): void
}

export function Agenda<TEvent extends AgendaEventModel = AgendaEventModel>(props: AgendaProps<TEvent>) {
  const {
    date,
    getNow = () => new Date(),
    events = emptyArray,
    stepInMinutes = 30,
    disablePastDates = true,
    disablePartialSlots = true,
    showUnavailableLimits = false,
    components,
    messages = defaultMessages,
    availableTimes = {},
    showToolbar = false,
    view,
    style: externalStyles,
    onSelectSlot,
    onNavigate,
  } = props

  const currentDate = new Date(date)
  const { isAvailable, minTime, maxTime } = useMemo(() => computeAvailableSlots(availableTimes), [availableTimes])

  const [eventsMinStart, eventsMaxEnd] = useMemo(
    () => (events.length ? [min(events.map((e) => e.start)), max(events.map((e) => e.end))] : [undefined, undefined]),
    [events]
  )

  const minStart = eventsMinStart ? min([eventsMinStart, minTime(currentDate)]) : minTime(currentDate)
  const maxTimeOfDay = eventsMaxEnd ? max([eventsMaxEnd, maxTime(currentDate)]) : maxTime(currentDate)
  const maxEnd = computeTimeUntilNextSlot(maxTimeOfDay, stepInMinutes)

  const handleNavigate =
    onNavigate &&
    ((newDate: Date) => {
      const maxTimeOfNewDay = computeTimeUntilNextSlot(maxTime(newDate), stepInMinutes)
      return onNavigate(minTime(newDate), maxTimeOfNewDay)
    })

  const periods: TimeInterval[] = availableTimes[getDay(date)]

  return (
    <>
      {(periods?.length ? periods : [undefined]).map((interval, idx, arr) => {
        const timeInterval = interval ? timeIntervalInDay(currentDate, interval) : undefined
        return (
          <AgendaDayPeriod<TEvent>
            key={`${timeInterval?.start}|${timeInterval?.end}`}
            start={timeInterval?.start}
            end={subSeconds(timeIntervalInDay(currentDate, arr[idx + 1])?.start, 1)}
            isFirst={idx === 0}
            isLast={idx === arr.length - 1}
            date={currentDate}
            events={events}
            getNow={getNow}
            showToolbar={showToolbar}
            showUnavailableLimits={showUnavailableLimits}
            stepInMinutes={stepInMinutes}
            style={externalStyles}
            view={view}
            disablePastDates={disablePastDates}
            disablePartialSlots={disablePartialSlots}
            messages={messages}
            availableTimes={availableTimes}
            components={components}
            onSelectSlot={onSelectSlot}
            onNavigate={handleNavigate}
            isAvailable={isAvailable}
            minStart={minStart}
            maxEnd={maxEnd}
          />
        )
      })}
    </>
  )
}
