import { Alert, Cell, Grid, Heading, HFlow, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import { Form, SwitchField } from 'components/form'
import { useFlags } from 'config/useFlagsContext'
import { useAlternarConexaoInternetMutation } from 'graphql/hooks.generated'
import { AlternarConexaoInternetMutationVariables, ConexaoQuery } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'

import { ConfiguracaoAgendaOnlineBox } from './agenda-online/ConfiguracaoAgendaOnlineBox'
import { ConfiguracoesCadSusBox } from './cadsus/ConfiguracoesCadSusBox'
import { ConfiguracaoInternetTesteConexao } from './ConfiguracaoInternetTesteConexao'
import { ConfiguracoesHorusBox } from './horus/ConfiguracoesHorusBox'
import { ConfiguracoesVideochamadaBox } from './videochamada/ConfiguracoesVideochamadaBox'

export interface ConfiguracoesInternetBoxProps {
  internet: ConexaoQuery['conexao']['internet']
  cadsus: ConexaoQuery['conexao']['cadsus']
  horus: ConexaoQuery['conexao']['horus']
  videochamada: ConexaoQuery['conexao']['videochamadas']
  agendaOnline: ConexaoQuery['conexao']['agendaOnline']
  refetch(): void
}

const meta = metaPath<AlternarConexaoInternetMutationVariables>()

export function ConfiguracoesInternetBox(props: ConfiguracoesInternetBoxProps) {
  const alert = useAlert()
  const flags = useFlags()

  const [alternarConexaoMutation] = useAlternarConexaoInternetMutation()

  const renderForm = (formProps: FormRenderProps<AlternarConexaoInternetMutationVariables>) => {
    const label: string = props.internet.habilitado ? 'Habilitado' : 'Desabilitado'
    return (
      <VFlow>
        <HFlow justifyContent='space-between'>
          <Heading style={{ display: 'inline' }} level={3}>
            Internet
          </Heading>
          <SwitchField label={label} name={meta.habilitado} onChange={handleChange(formProps)} />
        </HFlow>
        <Alert type='info' inline>
          Ao desabilitar a conexão com a internet, o Envio Online e a conexão com CADSUS, Hórus, Agenda Online, RNDS, a
          Unificação de Base e Videochamadas serão desabilitados.
        </Alert>
        {formProps.values.habilitado && (
          <ConfiguracaoInternetTesteConexao refetch={props.refetch} internet={props.internet} />
        )}
      </VFlow>
    )
  }

  const handleChange = (formProps: FormRenderProps) => (e) => {
    formProps.handleSubmit()
  }

  const handleSubmit = (values: AlternarConexaoInternetMutationVariables) => {
    return alternarConexaoMutation({ variables: values }).then(() => {
      const state: string = values.habilitado ? 'habilitada' : 'desabilitada'
      alert('success', 'Conexão com internet ' + state + ' com sucesso.')
      props.refetch()
    })
  }

  return (
    <Box>
      {props.internet && (
        <VFlow vSpacing={0.5}>
          <Form<AlternarConexaoInternetMutationVariables>
            onSubmit={handleSubmit}
            initialValues={{ habilitado: props.internet.habilitado }}
            render={renderForm}
          />
          <Grid gap={2}>
            <Cell size={6}>
              <ConfiguracoesCadSusBox
                internetHabilitada={props.internet.habilitado}
                cadsus={props.cadsus}
                load={props.refetch}
              />
            </Cell>
            <Cell size={6}>
              <ConfiguracoesHorusBox
                internetHabilitada={props.internet.habilitado}
                horus={props.horus}
                load={props.refetch}
              />
            </Cell>
            {flags.VIDEOCHAMADA_ENABLED && (
              <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarVideochamadas}>
                <Cell size={6}>
                  <ConfiguracoesVideochamadaBox
                    internetHabilitada={props.internet.habilitado}
                    videochamada={props.videochamada}
                    load={props.refetch}
                  />
                </Cell>
                <Cell size={6} />
              </CheckPermission>
            )}
            <Cell size={12}>
              <ConfiguracaoAgendaOnlineBox
                agendaOnline={props.agendaOnline}
                internetHabilitada={props.internet.habilitado}
                refetch={props.refetch}
              />
            </Cell>
          </Grid>
        </VFlow>
      )}
    </Box>
  )
}
