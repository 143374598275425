import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, maxLength, required } from 'util/validation'

import { ListaEsperaRemocaoModel } from '../model'

export const removerCidadaoListaEsperaValidator = () =>
  createValidator<ListaEsperaRemocaoModel>(
    {
      motivoSaida: [required],
      observacoes: [maxLength(200)],
    },
    (values: ListaEsperaRemocaoModel, errors: ErrorObject<ListaEsperaRemocaoModel>) => {
      if (values.motivoSaida === ListaEsperaMotivoSaidaEnum.OUTROS) {
        errors.observacoes = required(values.observacoes)
      } else if (values.motivoSaida === ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO) {
        errors.qtdTentativasContato = required(values.qtdTentativasContato)
      }
      return errors
    }
  )
