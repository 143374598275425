import useSession from 'components/auth/useSession'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { LoadingIndicator } from 'components/loading'
import { useVideochamadaViewQuery } from 'graphql/hooks.generated'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { capitalize } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { useEntrarEmVideochamada } from './hooks/useEntrarEmVideochamada'
import { LocalVideocallParticipant } from './model'
import { SalaEsperaVideochamadaUsuariosExternosView } from './SalaEsperaVideochamadaUsuariosExternosView'
import { VideochamadaEncerradaView } from './VideochamadaEncerradaView'
import { VideochamadaView } from './VideochamadaView'

export interface VideochamadaUrlParams {
  id: string
}

export interface VideochamadaQueryParams {
  audio?: string
  video?: string
}

export function VideochamadaRootView() {
  const session = useSession()

  const queryParams = useUrlQueryParams<VideochamadaQueryParams>()

  const {
    params: { id: videochamadaId },
  } = useRouteMatch<VideochamadaUrlParams>()

  const {
    loading,
    data: { videochamada },
  } = useVideochamadaViewQuery({ variables: { id: videochamadaId } })

  const isOwner = !isUndefinedOrNull(session?.data?.acesso?.id) && session.data.acesso.id === videochamada?.owner.id
  const ownerName = videochamada?.owner.profissional.nome
  const cboProfissional = videochamada
    ? isLotacaoOrEstagio(videochamada.owner)
      ? capitalize(videochamada.owner.cbo.nome)
      : null
    : null

  const [selfData, setSelfData] = useState<LocalVideocallParticipant>()
  const [audioEnabled, setAudioEnabled] = useState(queryParams.audio !== 'false')
  const [videoEnabled, setVideoEnabled] = useState(queryParams.video !== 'false')

  //Se é o owner, entra sem passar pela sala de espera
  const entrarEmVideochamada = useEntrarEmVideochamada({ videochamadaId, onEntrar: setSelfData })
  useEffect(() => {
    if (isOwner && !selfData) entrarEmVideochamada(ownerName)
  }, [entrarEmVideochamada, isOwner, ownerName, selfData])

  return loading || (isOwner && !selfData) ? (
    <LoadingIndicator />
  ) : !videochamada ? (
    <VideochamadaEncerradaView />
  ) : selfData ? (
    <VideochamadaView
      selfData={selfData}
      isOwner={isOwner}
      videochamadaId={videochamadaId}
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      setAudioEnabled={setAudioEnabled}
      setVideoEnabled={setVideoEnabled}
    />
  ) : (
    <SalaEsperaVideochamadaUsuariosExternosView
      videochamadaId={videochamadaId}
      nomeProfissional={ownerName}
      cboProfissional={cboProfissional}
      onEntrar={setSelfData}
      audioEnabled={audioEnabled}
      videoEnabled={videoEnabled}
      setAudioEnabled={setAudioEnabled}
      setVideoEnabled={setVideoEnabled}
    />
  )
}
