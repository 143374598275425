/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { CheckboxField, Form, FormRenderProps, NumberField, SubmitButton } from 'components/form'
import { resolveValue } from 'components/form/final-form/useField'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { metaPath } from 'util/metaPath'

import AtestadoModel, { CidadaoAtestado } from '../components/AtestadoModel'
import LicencaMaternidadeTextBox, { getLicencaMaternidadeText } from './LicencaMaternidadeTextBox'
import { licencaMaternidadeFormValidator } from './validator'

export interface LicencaMaternidadeFormProps {
  cidadao: CidadaoAtestado
  dataAtendimento: Instant
  onSubmit(values: AtestadoModel): void
}

export const name = metaPath<AtestadoModel>()

export const cpfCnsDecorator = createDecorator({
  field: name.naoPossui.absolutePath(),
  updates: {
    [name.cpfCns.absolutePath()]: (value: boolean, allValues: AtestadoModel) => {
      if (value) return (allValues.cpfCns = '')
      else return allValues.cpfCns
    },
  },
})

export default function LicencaMaternidadeForm(props: LicencaMaternidadeFormProps) {
  const { cidadao, dataAtendimento, onSubmit } = props
  const possuiCpfCns = cidadao.cpf || cidadao.cns

  const alert = useAlert()

  const handleSubmit = (values: AtestadoModel, formApi: FormApi) => {
    onSubmit({ ...values, tipo: TipoAtestadoEnum.LICENCA_MATERNIDADE })
    setTimeout(formApi.reset)
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    alert('success', 'Licença maternidade salva com sucesso.')
  }

  const handleCancel = (formProps: FormRenderProps<AtestadoModel>) => {
    if (formProps.dirty) {
      confirm({
        title: 'Deseja cancelar a inclusão?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar inclusão',
        confirmLabel: 'Sim, cancelar inclusão',
        onConfirm: () => {
          alert('success', 'Cadastro de licença maternidade cancelado com sucesso.')
          formProps.form.reset()
        },
      })()
    } else {
      alert('success', 'Cadastro de licença maternidade cancelado com sucesso.')
    }
    formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
  }

  const renderForm = (formProps: FormRenderProps<AtestadoModel>) => {
    const values = resolveValue(formProps.values, name) || ({} as AtestadoModel)
    values.descricao = getLicencaMaternidadeText(cidadao, values.cpfCns, values.naoPossui, dataAtendimento)
    values.data = dataAtendimento

    return (
      <Fragment>
        {!possuiCpfCns && (
          <Cell size={8}>
            <Grid gap={1}>
              <Cell size={6}>
                <NumberField
                  name={name.cpfCns}
                  label='CPF/CNS'
                  disabled={values.naoPossui}
                  required={!values.naoPossui}
                  maxLength={15}
                />
              </Cell>
              <Cell>
                <CheckboxField
                  name={name.naoPossui}
                  label='Não possui'
                  style={css`
                    margin-top: 1.75rem;
                    font-style: h5;
                  `}
                />
              </Cell>
            </Grid>
          </Cell>
        )}
        <Cell size={12}>
          <VFlow>
            <LicencaMaternidadeTextBox naoPossui={values.naoPossui} cpfCns={values.cpfCns} {...props} />
            <HFlow justifyContent='flex-end'>
              {!possuiCpfCns && (
                <Button size='small' onClick={() => handleCancel(formProps)}>
                  Cancelar
                </Button>
              )}
              <SubmitButton size='small' kind='primary' handleSubmit={formProps.handleSubmit}>
                Salvar atestado
              </SubmitButton>
            </HFlow>
          </VFlow>
        </Cell>
      </Fragment>
    )
  }

  return (
    <Form<AtestadoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      validate={!possuiCpfCns && licencaMaternidadeFormValidator}
      decorators={[cpfCnsDecorator]}
    />
  )
}
