import { cns, cpf, createValidator, required } from 'util/validation'

import AtestadoModel from '../components/AtestadoModel'

export const licencaMaternidadeFormValidator = createValidator<AtestadoModel>({}, (values: AtestadoModel, errors) => {
  if (!errors.cpfCns && !values.naoPossui) {
    errors.cpfCns = required(values.cpfCns)

    const [lengthCpf, cpfRule] = cpf
    const [lengthCns, cnsRule] = cns

    if (values?.cpfCns) {
      if (lengthCpf && cpfRule(values.cpfCns) && lengthCns && cnsRule(values.cpfCns)) {
        return { cpfCns: 'CPF/CNS inválido.' }
      }
    }
  }

  return errors
})
