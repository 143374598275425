/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, colors, Link, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { LoadingIndicator } from 'components/loading'
import theme from 'config/theme'
import { useHistoricoPlanosDeCuidadoQuery } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { ResponsavelObservacaoModel } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model'

import { HistoricoPlanoCuidado } from './components/HistoricoPlanoCuidado'
import { PlanoCuidadoModel } from './model'
import { PlanoCuidadoRootView, urlPlanosDeCuidado } from './PlanoCuidadoRootView'

interface HistoricoPlanoCuidadoPanelProps {
  atendProfId: ID
  responsavelPelaObservacao: ResponsavelObservacaoModel
}

export const HistoricoPlanoCuidadoPanel = (props: HistoricoPlanoCuidadoPanelProps) => {
  const { atendProfId, responsavelPelaObservacao } = props

  const match = useRouteMatch()
  const history = useHistory()

  const {
    loading,
    data: { planosDeCuidado },
  } = useHistoricoPlanosDeCuidadoQuery({ variables: { atendProfId } })

  const planoCuidadoMaisRecente: PlanoCuidadoModel = planosDeCuidado?.[0]
  const atendProfIniciador = planoCuidadoMaisRecente?.atendimentoProfissionalObservacao.atendimentoObservacao.iniciador

  return (
    <Fragment>
      <VFlow vSpacing={0.5}>
        <Alert type='info' inline>
          Os registros feitos neste atendimento estarão vinculados ao plano de cuidado da observação, sendo que o
          profissional responsável é {responsavelPelaObservacao?.profissional.nome}.
        </Alert>
        <Box css={styles.box}>
          <VFlow vSpacing={0.5}>
            <Text fontWeight='bold'>Plano de cuidado mais recente da observação</Text>
            {!loading ? (
              planosDeCuidado?.length > 0 ? (
                <VFlow vSpacing={0.5}>
                  <HistoricoPlanoCuidado planoDeCuidado={planoCuidadoMaisRecente} />
                  <Link
                    tabIndex={1}
                    onClick={() => history.push(`${match.url}${urlPlanosDeCuidado}`)}
                    style={{ fontSize: 'inherit' }}
                  >
                    Ver todos os planos de cuidado
                  </Link>
                </VFlow>
              ) : (
                <Text fontStyle='italic'>Nenhum registro encontrado</Text>
              )
            ) : (
              <LoadingIndicator />
            )}
          </VFlow>
        </Box>
      </VFlow>
      <PlanoCuidadoRootView planosDeCuidado={planosDeCuidado} iniciador={atendProfIniciador} loading={loading} />
    </Fragment>
  )
}

const styles = {
  box: css`
    padding: 1rem;
    background: ${colors.gray.c90};
    border: 1px solid ${colors.gray.c80};
    border-radius: ${theme.radius.input}px;
  `,
}
