/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DataTable, Heading, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import { orange } from 'bold-ui/lib/styles/colors'
import { usePecField } from 'components/form/final-form/useField'
import { getFieldError } from 'components/form/final-form/util'
import { PopperButton, PopperControls } from 'components/popper'
import { TableBox } from 'components/table'
import { isAfter } from 'date-fns'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { useCallback, useState } from 'react'
import { formatDate } from 'util/date/formatDate'
import { toDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { MetaPath, metaPath } from 'util/metaPath'

import { useEditableListField } from '../../components/EditableList'
import { ObservacaoLabel } from './componentes/ObservacaoLabel'
import { HospitalarAntecedentesPopper } from './HospitalarAntecedentesPopper'
import { HospitalarAntecedentesTableHeader } from './HospitalarAntecedentesTableHeader'
import { CirurgiaInternacaoItem, CirurgiaInternacaoModel, HospitalarAntecedentesFilterModel } from './model'

export interface HospitalarAntecedentesTableProps {
  name: MetaPath<CirurgiaInternacaoModel>
  dataNascimentoCidadao: LocalDate
}

export const HospitalarAntecedentesTable = (props: HospitalarAntecedentesTableProps) => {
  const { name, dataNascimentoCidadao } = props
  const [filter, setFilter] = useState<HospitalarAntecedentesFilterModel>()
  const classes = createStyles()
  const { meta } = usePecField({ name: name })
  const hasError = getFieldError(meta)

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField({
    name: name,
  })

  const convertCirurgiaInternacaoToItem = useCallback(
    (cirurgiasInternacoes: CirurgiaInternacaoModel[]): CirurgiaInternacaoItem[] => {
      const dataNascimentoFormatted = toDate(dataNascimentoCidadao)

      return cirurgiasInternacoes
        ? cirurgiasInternacoes.map((item) => ({
            ...item,
            hasError: isAfter(dataNascimentoFormatted, toDate(item.dataRealizada?.data)),
          }))
        : []
    },
    [dataNascimentoCidadao]
  )

  const filteredItems = useFilter<CirurgiaInternacaoItem, HospitalarAntecedentesFilterModel>({
    items: convertCirurgiaInternacaoToItem(value),
    filter,
    filtersType: [filterTextTypeConfig],
  })

  const handleExcluir = (item: CirurgiaInternacaoItem) => () => {
    removeItem(item)
  }

  const renderCirurgiaInternacao = (item: CirurgiaInternacaoItem) => {
    return (
      <div css={item.observacao && classes.padding}>
        <span style={{ wordBreak: 'break-all' }}>{item.descricao}</span>
        <ObservacaoLabel observacao={item.observacao} />
      </div>
    )
  }

  const renderData = (item: CirurgiaInternacaoItem) => {
    const hasErrorAgora = item.hasError && !item.idCirurgiaInternacao

    return item.dataRealizada?.data ? (
      <HFlow hSpacing={0.5} style={hasErrorAgora && classes.errorData}>
        {hasErrorAgora && (
          <Tooltip text='Data deve ser posterior ou igual à data de nascimento'>
            <Icon icon='exclamationTriangleOutline' size={1} />
          </Tooltip>
        )}
        <span>
          <Text color={hasErrorAgora ? 'alert' : 'normal'}>{formatDate(item.dataRealizada?.data)}</Text>
          {!item.hasError && <Text> | {humanizeAge(dataNascimentoCidadao, item.dataRealizada?.data)}</Text>}
        </span>
      </HFlow>
    ) : (
      '-'
    )
  }

  const renderButtons = (item: CirurgiaInternacaoItem) => {
    return (
      !item.idCirurgiaInternacao && (
        <HFlow justifyContent='flex-end' hSpacing={0}>
          <AntecedentesPopperButton
            item={item}
            onChange={handleRowChanged}
            dataNascimentoCidadao={dataNascimentoCidadao}
          />
          <Tooltip text='Excluir'>
            <Button size='small' skin='ghost' onClick={handleExcluir(item)}>
              <Icon icon='trashOutline' />
            </Button>
          </Tooltip>
        </HFlow>
      )
    )
  }

  return (
    <VFlow vSpacing={0.4}>
      <Heading level={3}>Antecedentes hospitalares</Heading>
      {hasError && <Text color='danger'>Preenchimento incorreto.</Text>}
      <TableBox
        header={
          <HospitalarAntecedentesTableHeader
            dataNascimentoCidadao={dataNascimentoCidadao}
            onSubmit={handleSubmit}
            onChangeFilter={setFilter}
          />
        }
      >
        <DataTable<CirurgiaInternacaoItem>
          rows={filteredItems}
          columns={[
            {
              name: 'cirurgiaInternacao',
              header: 'Cirurgia e/ou internação',
              render: renderCirurgiaInternacao,
              style: classes.columnWidth,
            },
            {
              name: 'dataRealizada',
              header: 'Data | Idade',
              render: renderData,
            },
            {
              name: 'buttons',
              header: '',
              render: renderButtons,
            },
          ]}
        />
      </TableBox>
    </VFlow>
  )
}

interface AntecedentesPopperButtonProps {
  item: CirurgiaInternacaoItem
  onChange(value: CirurgiaInternacaoItem): void
  dataNascimentoCidadao: LocalDate
}

const AntecedentesPopperButton = (props: AntecedentesPopperButtonProps) => {
  const { item, onChange, dataNascimentoCidadao } = props

  const renderPopper = ({ close }: PopperControls) => (
    <HospitalarAntecedentesPopper
      close={close}
      onChange={onChange}
      editingItem={item}
      dataNascimentoCidadao={dataNascimentoCidadao}
    />
  )

  return (
    <Tooltip text='Editar'>
      <PopperButton size='small' skin='ghost' renderPopper={renderPopper}>
        <Icon icon='penOutline' />
      </PopperButton>
    </Tooltip>
  )
}

const createStyles = () => ({
  padding: css`
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
  `,
  columnWidth: css`
    width: 50%;
  `,
  errorData: css`
    color: ${orange.c40};
    align-items: center;
  `,
})

const metaRow = metaPath<CirurgiaInternacaoItem>()
const metaFilter = metaPath<HospitalarAntecedentesFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.texto,
  searchFields: [metaRow.descricao],
  removeTagsOnFilter: true,
}
