import { Cell } from 'bold-ui'
import { TextField } from 'components/form'
import { FormaFarmaceuticaSelectField } from 'components/form/field/select/FormaFarmaceuticaSelectField'
import React from 'react'
import { MetaRoot } from 'util/metaPath'

import { MedicamentoFormModel } from '../model'

export interface MedicamentoNaoCadastradoSectionProps {
  path: MetaRoot<MedicamentoFormModel>
}

export default function MedicamentoNaoCadastradoSection({ path }: MedicamentoNaoCadastradoSectionProps) {
  return (
    <>
      <Cell size={6} data-testid='PrincipioAtivo'>
        <TextField
          name={path.principioAtivoText}
          label='Princípio ativo'
          maxLength={200}
          required
          data-testid='PrincipioAtivoTextField'
        />
      </Cell>
      <Cell size={6}>
        <TextField
          name={path.concentracaoText}
          label='Concentração'
          maxLength={100}
          data-testid='ConcentracaoTextField'
        />
      </Cell>
      <Cell size={6} data-testid='FormaFarmaceutica'>
        <FormaFarmaceuticaSelectField
          name={path.formaFarmaceutica}
          label='Forma farmacêutica'
          required
          data-testid='FormaFarmaceuticaCombobox'
        />
      </Cell>
      <Cell size={6} />
    </>
  )
}
