/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useFirebase from 'components/firebase/useFirebase'
import { ErrorField } from 'components/form'
import { usePecField } from 'components/form/final-form/useField'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useEffect, useMemo, useState } from 'react'
import { MetaArray, metaPath } from 'util/metaPath'
import { CiapCidPreNatal, meta as metaSoapState } from 'view/atendimentos/atendimento-individual/model'
import { EditableList, useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { Problema } from '../../../aside/types/ProblemaModel'
import { MetasPreNatal, modalMessageDiscardPreNatalData, TipoPreNatal } from '../../../pre-natal/model'
import { hasMudancaAvaliacoesAutomaticas } from '../../utils/avaliacaoUtils'
import { CiapCidRow } from './components/CiapCidRow'
import { ProblemaCondicaoModel, TodosProblemasCondicoesModel } from './model'
import { ProblemasCondicoesForm } from './ProblemasCondicoesForm'
import { cidCiapString } from './utils/messages'
import {
  hasOnlyOneProblemaCondicaoDePreNatal,
  hasOnlyOneProblemaNaoEvolucaoEncerraGestacao,
  isProblemaCondicaoDePreNatal,
  isProblemaNaoEvolucaoEncerraGestacao,
} from './utils/verifications'

const meta = metaPath<ProblemaCondicaoModel>()

export interface ProblemasCondicoesFieldProps {
  name: MetaArray<ProblemaCondicaoModel>
  prontuarioId: ID
  dataAtendimento: Instant
  ciapCidPreNatal: CiapCidPreNatal
  metasPreNatal: MetasPreNatal
  tipoPreNatal?: TipoPreNatal
  somenteCiap?: boolean
  problemasAtivosLatentes: Problema[]
  onAutomaticChange?(): void
}

export function ProblemasCondicoesField(props: ProblemasCondicoesFieldProps) {
  const {
    name,
    somenteCiap,
    prontuarioId,
    dataAtendimento,
    onAutomaticChange,
    ciapCidPreNatal,
    tipoPreNatal,
    problemasAtivosLatentes,
  } = props

  const { analytics } = useFirebase()

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value: problemasCondicoesAvaliados },
  } = useEditableListField({
    name,
  })

  const {
    input: { value: problemasCondicoesAnteriores },
  } = usePecField({ name: metaSoapState.problemasECondicoes.absolutePath(), subscription: { value: true } })

  const {
    tools: { resetToUndefined: resetPreNatal },
  } = usePecField({ name: metaSoapState.preNatal.absolutePath() })

  const {
    tools: { resetToUndefined: resetDum },
  } = usePecField({ name: metaSoapState.objetivo.dum.absolutePath() })

  const {
    tools: { resetToUndefined: resetDesfechoGravidez },
  } = usePecField({ name: metaSoapState.avaliacao.encerrarGestacao.absolutePath() })

  const alert = useAlert()

  const [listaAvaliacoesAnterior, setListaAvaliacoesAnterior] = useState(problemasCondicoesAvaliados || [])

  const hasMudancaAvalAutomaticas = useMemo(
    () => hasMudancaAvaliacoesAutomaticas(problemasCondicoesAvaliados || [], listaAvaliacoesAnterior),
    [listaAvaliacoesAnterior, problemasCondicoesAvaliados]
  )

  useEffect(() => {
    if (hasMudancaAvalAutomaticas) {
      onAutomaticChange()
      setListaAvaliacoesAnterior(problemasCondicoesAvaliados)
    }
  }, [hasMudancaAvalAutomaticas, onAutomaticChange, problemasCondicoesAvaliados])

  const {
    cidadao: { isGestante },
    permissoes: { hasPermissionPreNatal },
  } = useAtendimentoContext()

  const handleAddItem = (values: ProblemaCondicaoModel, api: FormApi<ProblemaCondicaoModel>) => {
    handleSubmit({ ...values, isAvaliadoAgora: true }, api)
    values?.incluirNaListaProblemas && alert('success', alertMessageAddProblema(values))

    analytics.logEvent('adiciona_problema_condicao_via_AV', {
      'Adicionado LP': values?.incluirNaListaProblemas ?? false,
    })
  }

  const handleEditItem = (values: ProblemaCondicaoModel) => {
    handleRowChanged({ ...values, isAvaliadoAgora: true })
    values?.incluirNaListaProblemas && alert('success', alertMessageEditProblema(values))
  }

  const defaultRemoveItem = (itemToRemove: ProblemaCondicaoModel) => {
    removeItem(itemToRemove)
    itemToRemove?.incluirNaListaProblemas && alert('success', alertMessageRemoveProblema(itemToRemove))
  }

  const handleProblemaGestacaoRemove = (itemToRemove: ProblemaCondicaoModel) =>
    confirm({
      ...modalMessageDiscardPreNatalData(itemToRemove),
      onConfirm: () => {
        defaultRemoveItem(itemToRemove)
        resetPreNatal()
        if (tipoPreNatal !== TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL) {
          resetDum()
        }
      },
    })()

  const handleProblemaEncerraGestacaoRemove = (itemToRemove: ProblemaCondicaoModel) =>
    confirm({
      title: 'Deseja confirmar a exclusão da condição?',
      body: `A exclusão da ${cidCiapString(itemToRemove)} irá desabilitar os campos de Desfecho da gravidez.`,
      onConfirm: () => {
        defaultRemoveItem(itemToRemove)
        resetDesfechoGravidez()
      },
    })()

  const handleRemoveItem = (itemToRemove: ProblemaCondicaoModel) => {
    let doDefaultRemove = true

    if (hasPermissionPreNatal) {
      if (
        isProblemaCondicaoDePreNatal(itemToRemove, isGestante) &&
        hasOnlyOneProblemaCondicaoDePreNatal(problemasCondicoesAvaliados, isGestante)
      ) {
        doDefaultRemove = false
        handleProblemaGestacaoRemove(itemToRemove)
      }

      if (
        isProblemaNaoEvolucaoEncerraGestacao(itemToRemove) &&
        hasOnlyOneProblemaNaoEvolucaoEncerraGestacao(problemasCondicoesAvaliados)
      ) {
        doDefaultRemove = false
        handleProblemaEncerraGestacaoRemove(itemToRemove)
      }
    }

    if (doDefaultRemove) defaultRemoveItem(itemToRemove)
  }

  const todosProblemas: TodosProblemasCondicoesModel = {
    problemasCondicoesAvaliados: problemasCondicoesAvaliados || [],
    problemasCondicoesAnteriores: problemasCondicoesAnteriores || [],
  }
  const hasProblemasCondicoes = problemasCondicoesAvaliados?.length > 0

  return (
    <VFlow vSpacing={0.5}>
      <ErrorField name={name} />
      <EditableList>
        <ProblemasCondicoesForm
          onSubmit={handleAddItem}
          prontuarioId={prontuarioId}
          dataAtendimento={dataAtendimento}
          problemasAtivosLatentes={problemasAtivosLatentes}
          ciapCidPreNatal={ciapCidPreNatal}
          tipoPreNatal={tipoPreNatal}
          {...todosProblemas}
        />

        {hasProblemasCondicoes && (
          <div>
            <Grid
              style={css`
                margin: 0;
                padding: 0 1rem;
              `}
            >
              <Cell size={somenteCiap ? 6 : 4}>
                <Text fontWeight='bold'>CIAP 2</Text>
              </Cell>
              {!somenteCiap && (
                <Cell size={3}>
                  <Text fontWeight='bold'>CID 10</Text>
                </Cell>
              )}
              <Cell size={5}>
                <Text fontWeight='bold'>Lista de problemas/condições</Text>
              </Cell>
            </Grid>
          </div>
        )}
        {hasProblemasCondicoes &&
          problemasCondicoesAvaliados.map((item) => (
            <CiapCidRow
              key={item._id}
              name={meta}
              model={item}
              onChange={handleEditItem}
              onRemove={handleRemoveItem}
              somenteCiap={somenteCiap}
              dataAtendimento={props.dataAtendimento}
              prontuarioId={props.prontuarioId}
              tipoPreNatal={tipoPreNatal}
              problemasAtivosLatentes={problemasAtivosLatentes}
              {...todosProblemas}
            />
          ))}
      </EditableList>
    </VFlow>
  )
}

const alertMessageAddProblema = (model: ProblemaCondicaoModel) =>
  model.problemaCondicaoEvoluir
    ? `A avaliação do problema/condição${cidCiapString(model)}foi incluída na Lista de problemas/condições.`
    : `O Problema/condição${cidCiapString(model)}foi incluído na Lista de problemas/condições.`

const alertMessageEditProblema = (model: ProblemaCondicaoModel) =>
  `A avaliação do problema/condição${cidCiapString(model)}foi alterada na Lista de problemas/condições.`

const alertMessageRemoveProblema = (model: ProblemaCondicaoModel) =>
  model.problemaCondicaoEvoluir
    ? `A avaliação do problema/condição${cidCiapString(model)}foi removida da Lista de problemas/condições.`
    : `O problema/condição${cidCiapString(model)}foi removido da Lista de problemas/condições.`
