import { PageContent } from 'components/layout/PageContent'
import React, { Dispatch } from 'react'
import { AgendamentosCidadaoView } from 'view/agenda/cidadao/AgendamentosCidadaoView'
import { TipoAtendimentoFooter } from 'view/atendimentos/model'

import { AtendimentoFooter } from '../AtendimentoFooter'

export interface AgendamentosCidadaoAtendimentoView {
  atendimentoId: ID
  registroTardio: boolean
  isAtendimentoObservacao: boolean
  isObservacaoAndResponsavel: boolean
  clearCache: Dispatch<void>
  tipoAtendimentoProfissional: TipoAtendimentoFooter
}

export function AgendamentosCidadaoAtendimentoView(props: AgendamentosCidadaoAtendimentoView) {
  const {
    atendimentoId,
    clearCache,
    registroTardio,
    tipoAtendimentoProfissional,
    isAtendimentoObservacao = false,
    isObservacaoAndResponsavel = false,
  } = props

  return (
    <>
      <PageContent fluid type='filled'>
        <AgendamentosCidadaoView />
      </PageContent>
      <AtendimentoFooter
        atendimentoId={atendimentoId}
        tipoAtendimentoProfissional={tipoAtendimentoProfissional}
        clearCache={clearCache}
        registroTardio={registroTardio}
        isAtendimentoObservacao={isAtendimentoObservacao}
        isObservacaoAndResponsavel={isObservacaoAndResponsavel}
      />
    </>
  )
}
