import useLocalStorage from '@rehooks/local-storage'
import { Button, Heading, Icon, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { useFlags } from 'config/useFlagsContext'
import { differenceInCalendarDays, sub } from 'date-fns'
import {
  useListaAtendimentoPollingEnabledQuery,
  useListaAtendimentoPollingQuery,
  useListaAtendimentoQuery,
  usePossuiAtendimentosNaoFinalizadosQuery,
  useTipoServicoCheckQuery,
} from 'graphql/hooks.generated'
import { PageParams } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import useLocalStorageState from 'hooks/useLocalStorageState'
import { isEmpty } from 'lodash'
import qs from 'qs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import Permissions from 'types/Permissions'
import { safeDateAsYyyyMmDd } from 'util/date/formatDate'

import AtendimentoListing from '../components/AtendimentoListing'
import { ExibindoSomenteNaoFinalizadosAlert } from './alerts/ExibindoSomenteNaoFinalizadosAlert'
import { ExistemAtendimentosNaoFinalizadosAlert } from './alerts/ExistemAtendimentosNaoFinalizadosAlert'
import { ListaAtendimentoFilter, ListaAtendimentoFilterModel } from './filter/ListaAtendimentoFilter'
import { ListaAtendimentoFilterPopperModel } from './filter/ListaAtendimentoFilterPopperForm'
import { useListaAtendimentoFilterDefault } from './filter/useListaAtendimentoFilterDefault'
import { ListaAtendimentoFooter } from './ListaAtendimentoFooter'
import { ListaAtendimentoForm } from './ListaAtendimentoForm'
import { ListaAtendimentoItem } from './ListaAtendimentoItem'
import { AtendimentosFilterModel, AtendimentosNaoFinalizadosPeriodo, DIFF_DAYS_SOMENTE_NAO_FINALIZADOS } from './model'
import { convertListaAtendimentoFilterToInput, partitionAtendimentos } from './util'

type AtendimentoTopicModel = { idUbs: number }

interface UrlParams {
  cidadaoId: string
}

export function ListaAtendimentosView() {
  const handleRejection = useErrorHandler()
  const location = useLocation()
  const { OBSERVACAO_ENABLED } = useFlags()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const acessoId = acesso?.id

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 5,
  })

  const filterDefault = useListaAtendimentoFilterDefault()

  const { startDate: currentServerTime } = filterDefault.filter.periodo

  const lastUpdateRef = useRef<Date>()

  const [state, setState] = useLocalStorageState<AtendimentosFilterModel>(
    `${acessoId}/lista-atendimento-state`,
    filterDefault
  )

  const [backupFilterStorage, setBackupFilterStorage, deleteBackupFilterStorage] = useLocalStorage<
    ListaAtendimentoFilterPopperModel
  >(`${acessoId}/lista-atendimento-filter-backup`)

  const [alreadySeenAlertDate, setAlreadySeenAlertDate] = useLocalStorage<Date>(
    `${acessoId}/lista-atendimento-already-seen-alert`
  )

  const [adicionarMode, setAdicionarMode] = useState(!!urlParams.cidadaoId)
  const [naoFinalizadosPeriodo, setNaoFinalizadosPeriodo] = useState<AtendimentosNaoFinalizadosPeriodo>(
    mountNaoFinalizadosPeriodo(state.lastUpdate)
  )
  const [viuHaMaisDias, setViuHaMaisDias] = useState(false)

  const { data: pollingEnabled } = useListaAtendimentoPollingEnabledQuery()

  const { refetch: refetchPolling } = useListaAtendimentoPollingQuery({
    variables: {
      time: new Date(state.lastUpdate).valueOf(),
    },
    skip: !pollingEnabled?.atendimentoPollingEnabled,
  })

  const { data: possuiAtendimentosNaoFinalizados } = usePossuiAtendimentosNaoFinalizadosQuery({
    variables: {
      input: {
        inicio: safeDateAsYyyyMmDd(naoFinalizadosPeriodo.inicio),
        fim: safeDateAsYyyyMmDd(naoFinalizadosPeriodo.fim),
      },
    },
    skip: !!alreadySeenAlertDate && !viuHaMaisDias,
  })

  const { data, refetch } = useListaAtendimentoQuery({
    variables: {
      input: convertListaAtendimentoFilterToInput(state, naoFinalizadosPeriodo),
    },
  })

  const { atendimentosEmObservacao, restAtendimentos } = partitionAtendimentos(data?.atendimentos?.content)

  const refetchLista = useCallback(() => {
    refetch()
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          lastUpdate: currentServerTime,
        }))
      })
      .catch(handleRejection)
  }, [currentServerTime, handleRejection, refetch, setState])

  useAtmosphere<AtendimentoTopicModel>({
    topic: `atendimento/${acesso?.unidadeSaude.id}`,
    onMessage: (data) => data?.idUbs && refetchLista(),
  })

  // utilizado para cache dos dados exibidos em TipoServicoCheckField.tsx
  useTipoServicoCheckQuery({
    fetchPolicy: 'cache-and-network',
    variables: { input: { pageParams: { sort: ['nome'], size: 100 }, unidadeSaudeSessao: true } },
  })

  const handleAdicionarCidadaoClick = () => {
    setAdicionarMode(!adicionarMode)
    analytics.logEvent('adicionar_cidadao_LA')
  }

  const onChangeFilter = useCallback(
    (filter: ListaAtendimentoFilterModel) => {
      setState((prevState) => ({
        ...prevState,
        filter,
        pageParams: {
          ...prevState.pageParams,
          page: 0,
        },
        lastUpdate: currentServerTime,
      }))
    },
    [currentServerTime, setState]
  )

  const onChangeFooter = useCallback(
    (pageParams: PageParams) => {
      setState((prevState) => ({ ...prevState, pageParams }))
    },
    [setState]
  )

  const handleClear = useCallback(() => {
    deleteBackupFilterStorage()
    onChangeFilter(filterDefault.filter)
  }, [deleteBackupFilterStorage, filterDefault.filter, onChangeFilter])

  const onClickSomenteAtendimentosNaoFinalizados = () => {
    onChangeFilter({
      ...filterDefault.filter,
      periodo: { startDate: undefined, endDate: undefined },
      somenteNaoFinalizados: true,
    })

    setAlreadySeenAlertDate(currentServerTime)
  }

  const onClickManterFiltroAtual = () => {
    setAlreadySeenAlertDate(currentServerTime)
  }

  const updateBackupFilterStorage = useCallback(
    (values: ListaAtendimentoFilterPopperModel) => setBackupFilterStorage(values),
    [setBackupFilterStorage]
  )

  const resetFilterToInitialState = useCallback(() => {
    // reseta alguns dados do filtro atual
    setState((prevState) => {
      return {
        ...prevState,
        filter: {
          ...prevState?.filter,
          query: undefined,
        },
      } as AtendimentosFilterModel
    })
  }, [setState])

  useEffect(() => {
    // roda quando a tela carrega a primeira vez
    deleteBackupFilterStorage()
    resetFilterToInitialState()
  }, [resetFilterToInitialState, deleteBackupFilterStorage])

  useEffect(() => {
    lastUpdateRef.current = state.lastUpdate
  }, [state.lastUpdate])

  useEffect(() => {
    if (pollingEnabled && pollingEnabled.atendimentoPollingEnabled) {
      let shouldFetch = true

      const timeout = setInterval(() => {
        if (shouldFetch) {
          shouldFetch = false
          refetchPolling({ time: new Date(lastUpdateRef.current).valueOf() })
            .then(({ data }) => data.atendimentoPolling && refetchLista())
            .finally(() => (shouldFetch = true))
            .catch(handleRejection)
        }
      }, 2 * 1000)

      return () => {
        clearInterval(timeout)
      }
    }
  }, [handleRejection, pollingEnabled, refetchLista, refetchPolling])

  useEffect(() => {
    // atualiza o lastDailyUpdate e reseta alguns filtros a cada 24h

    if (!state.lastDailyUpdate || differenceInCalendarDays(currentServerTime, new Date(state.lastDailyUpdate)) > 0) {
      if (!isEmpty(backupFilterStorage)) {
        setBackupFilterStorage({ ...backupFilterStorage, periodo: filterDefault.filter.periodo })
      }

      setState((prevValue) => ({
        ...prevValue,
        filter: {
          ...prevValue.filter,
          periodo: filterDefault.filter.periodo,
        },
        lastDailyUpdate: currentServerTime,
      }))
    }
  }, [backupFilterStorage, filterDefault.filter.periodo, currentServerTime, setBackupFilterStorage, setState, state])

  useEffect(() => {
    // atualiza se ja marcou o alert ha mais de 7 dias baseado no lastDailyUpdate
    setViuHaMaisDias(
      alreadySeenAlertDate &&
        differenceInCalendarDays(new Date(state.lastDailyUpdate), new Date(alreadySeenAlertDate)) >
          DIFF_DAYS_SOMENTE_NAO_FINALIZADOS
    )
  }, [alreadySeenAlertDate, state.lastDailyUpdate])

  useEffect(() => {
    // recalula o periodo de atendimentos nao finalizados baseado no lastDailyUpdate
    setNaoFinalizadosPeriodo(mountNaoFinalizadosPeriodo(state.lastDailyUpdate))
  }, [state.lastDailyUpdate])

  const hasAtendimentos = data?.atendimentos?.content?.length > 0

  const renderHeading = (
    <VFlow>
      {!!state?.filter?.somenteNaoFinalizados && <ExibindoSomenteNaoFinalizadosAlert onClear={handleClear} />}

      {possuiAtendimentosNaoFinalizados?.possuiAtendimentosNaoFinalizados &&
        !state?.filter?.somenteNaoFinalizados &&
        (!alreadySeenAlertDate || viuHaMaisDias) && (
          <ExistemAtendimentosNaoFinalizadosAlert
            diffDays={DIFF_DAYS_SOMENTE_NAO_FINALIZADOS}
            onClickExibirSomenteNaoFinalizados={onClickSomenteAtendimentosNaoFinalizados}
            onClickManterFiltroAtual={onClickManterFiltroAtual}
          />
        )}

      <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
        <VFlow>
          <Button kind='primary' size='medium' onClick={handleAdicionarCidadaoClick}>
            <Icon icon={adicionarMode ? 'angleUp' : 'angleDown'} style={{ marginRight: '0.5rem' }} />
            {adicionarMode ? 'Cancelar adição' : 'Adicionar cidadão'}
          </Button>
          {adicionarMode && <ListaAtendimentoForm serverTime={currentServerTime} cidadaoId={urlParams.cidadaoId} />}
        </VFlow>
      </CheckPermission>
    </VFlow>
  )

  const renderFilter = (
    <ListaAtendimentoFilter
      filter={state.filter}
      filterBackup={backupFilterStorage}
      filterDefault={filterDefault.filter}
      onChangeFilter={onChangeFilter}
      updateFilterBackup={updateBackupFilterStorage}
      deleteFilterBackup={() => deleteBackupFilterStorage()}
      onClear={handleClear}
    />
  )

  return (
    <AtendimentoListing
      title='Lista de atendimentos'
      heading={renderHeading}
      filter={renderFilter}
      footer={
        <ListaAtendimentoFooter
          pageInfo={data.atendimentos?.pageInfo}
          pageParams={state.pageParams}
          onChange={onChangeFooter}
        />
      }
    >
      {hasAtendimentos ? (
        <VFlow>
          {OBSERVACAO_ENABLED && atendimentosEmObservacao?.length > 0 && (
            <VFlow vSpacing={0.5}>
              <Heading level={5}>Cidadãos em observação</Heading>

              {atendimentosEmObservacao.map((atendimento) => (
                <ListaAtendimentoItem key={atendimento.id} atend={atendimento} />
              ))}
            </VFlow>
          )}

          {restAtendimentos?.length > 0 && (
            <VFlow vSpacing={0.5}>
              <Heading level={5}>Lista de atendimentos</Heading>

              {restAtendimentos.map((atendimento) => (
                <ListaAtendimentoItem key={atendimento.id} atend={atendimento} />
              ))}
            </VFlow>
          )}
        </VFlow>
      ) : (
        <div style={{ marginTop: '0.5rem' }}> Nenhum resultado encontrado.</div>
      )}
    </AtendimentoListing>
  )
}

const mountNaoFinalizadosPeriodo = (lastUpdate: Date): AtendimentosNaoFinalizadosPeriodo => {
  const lastUpdateDate = new Date(lastUpdate)
  const inicio = sub(lastUpdateDate, { days: DIFF_DAYS_SOMENTE_NAO_FINALIZADOS })
  const fim = sub(lastUpdateDate, { days: 1 })

  return { inicio, fim }
}
