import { SelectField, SelectFieldProps } from 'components/form'
import { ListaEsperaTipoAtendimentoEnum } from 'graphql/types.generated'
import React from 'react'

import { tipoAtendimentoRecord } from '../model'

export interface TipoAtendimentoSelectModel {
  value: ListaEsperaTipoAtendimentoEnum
  nome: string
}

export interface TipoAtendimentoSelectFieldProps
  extends Omit<SelectFieldProps<TipoAtendimentoSelectModel>, 'items' | 'itemToString'> {}

export function TipoAtendimentoSelectField(props: TipoAtendimentoSelectFieldProps) {
  const itemToString = (item: TipoAtendimentoSelectModel) => item && item.nome

  return (
    <SelectField<TipoAtendimentoSelectModel>
      items={items.sort((a, b) => (a.nome > b.nome ? 1 : -1))}
      itemToString={itemToString}
      {...props}
    />
  )
}

const items: TipoAtendimentoSelectModel[] = [
  {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_MEDICA,
    nome: tipoAtendimentoRecord[ListaEsperaTipoAtendimentoEnum.CONSULTA_MEDICA],
  },
  {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_ODONTOLOGICA,
    nome: tipoAtendimentoRecord[ListaEsperaTipoAtendimentoEnum.CONSULTA_ODONTOLOGICA],
  },
  {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_ENFERMAGEM,
    nome: tipoAtendimentoRecord[ListaEsperaTipoAtendimentoEnum.CONSULTA_ENFERMAGEM],
  },
]
