import ApolloClient from 'apollo-client'
import { resolveValue } from 'components/form/final-form/useField'
import createDecorator, { Calculation } from 'final-form-calculate'
import { Procedimento, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { ProcedimentosAutomaticosEscutaInicial } from 'types/enums'
import { MetaArray, MetaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'

import { createMedicoesEscutaCalculations } from '../components/MedicoesPanel/calculator'
import { MedicoesPanelModel } from '../components/MedicoesPanel/MedicoesPanel'
import { createAgendarConsultaCalculations } from '../detail/soap/finalizacao/calculator/calculatorAgendarConsulta'
import { createNovoAtendimentoCalculations } from '../detail/soap/finalizacao/calculator/calculatorNovoAtendimento'
import { ProcedimentoSigtapFieldModel } from '../detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { EscutaInicialState, name } from './model'

export const createEscutaInicialCalculator = (
  procedimentosAutomaticos: Array<Procedimento>,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  apollo: ApolloClient<object>
) =>
  createDecorator(
    ...createMedicoesEscutaCalculations(name.medicoes),
    ...createProcedimentosAutomaticosCalculations(name.medicoes, procedimentosAutomaticos),
    ...createNovoAtendimentoCalculations(name.desfecho.atendimento, tipoEstabelecimento, apollo),
    ...createAgendarConsultaCalculations(name.agendamentoConsulta)
  )

const createProcedimentosAutomaticosCalculations = (
  medicoes: MetaPath<MedicoesPanelModel>,
  procedimentosAutomaticos: Array<Procedimento>
): Calculation[] => [
  {
    field: [medicoes.peso.absolutePath(), medicoes.altura.absolutePath()],
    updates: {
      [name.procedimentos.absolutePath()]: (
        values: MetaArray<ProcedimentoSigtapFieldModel>,
        allValues: EscutaInicialState
      ) => {
        const peso = resolveValue(allValues, medicoes.peso)
        const altura = resolveValue(allValues, medicoes.altura)
        let listaProcedimentos: ProcedimentoSigtapFieldModel[]
        const procedToAdd =
          peso && altura
            ? ProcedimentosAutomaticosEscutaInicial.AVALIACAO_ANTROPOMETRICA
            : peso
            ? ProcedimentosAutomaticosEscutaInicial.MEDICAO_DE_PESO
            : altura
            ? ProcedimentosAutomaticosEscutaInicial.MEDICAO_DE_ALTURA
            : null

        listaProcedimentos = addProcedimentoAutomatico(
          allValues,
          name.procedimentos,
          procedimentosAutomaticos,
          procedToAdd
        )

        const procedsToRemove = [
          ProcedimentosAutomaticosEscutaInicial.MEDICAO_DE_PESO,
          ProcedimentosAutomaticosEscutaInicial.MEDICAO_DE_ALTURA,
          ProcedimentosAutomaticosEscutaInicial.AVALIACAO_ANTROPOMETRICA,
        ].filter((proced) => proced !== procedToAdd)

        procedsToRemove.forEach((procedimento: ProcedimentosAutomaticosEscutaInicial) => {
          listaProcedimentos = removeProcedimentoAutomatico(listaProcedimentos, procedimento)
        })

        return listaProcedimentos
      },
    },
  },
  {
    field: [medicoes.pressaoArterialSistolica.absolutePath(), medicoes.pressaoArterialDiastolica.absolutePath()],
    updates: {
      [name.procedimentos.absolutePath()]: (
        values: MetaArray<ProcedimentoSigtapFieldModel>,
        allValues: EscutaInicialState
      ) => {
        const pressaoArterialHasValue =
          resolveValue(allValues, medicoes.pressaoArterialSistolica) &&
          resolveValue(allValues, medicoes.pressaoArterialDiastolica)

        const listaProcedimentos = addProcedimentoAutomatico(
          allValues,
          name.procedimentos,
          procedimentosAutomaticos,
          pressaoArterialHasValue && ProcedimentosAutomaticosEscutaInicial.AFERICAO_PRESSAO_ARTERIAL
        )
        return pressaoArterialHasValue
          ? listaProcedimentos
          : removeProcedimentoAutomatico(
              listaProcedimentos,
              ProcedimentosAutomaticosEscutaInicial.AFERICAO_PRESSAO_ARTERIAL
            )
      },
    },
  },
  {
    field: medicoes.glicemia.absolutePath(),
    updates: {
      [name.procedimentos.absolutePath()]: (
        values: MetaArray<ProcedimentoSigtapFieldModel>,
        allValues: EscutaInicialState
      ) => {
        const glicemiaHasValue = resolveValue(allValues, medicoes.glicemia)
        const listaProcedimentos = addProcedimentoAutomatico(
          allValues,
          name.procedimentos,
          procedimentosAutomaticos,
          glicemiaHasValue && ProcedimentosAutomaticosEscutaInicial.GLICEMIA_CAPILAR
        )
        return glicemiaHasValue
          ? listaProcedimentos
          : removeProcedimentoAutomatico(listaProcedimentos, ProcedimentosAutomaticosEscutaInicial.GLICEMIA_CAPILAR)
      },
    },
  },
  {
    field: medicoes.temperatura.absolutePath(),
    updates: {
      [name.procedimentos.absolutePath()]: (
        values: MetaArray<ProcedimentoSigtapFieldModel>,
        allValues: EscutaInicialState
      ) => {
        const temperaturaHasValue = resolveValue(allValues, medicoes.temperatura)
        const listaProcedimentos = addProcedimentoAutomatico(
          allValues,
          name.procedimentos,
          procedimentosAutomaticos,
          temperaturaHasValue && ProcedimentosAutomaticosEscutaInicial.AFERICAO_DE_TEMPERATURA
        )
        return temperaturaHasValue
          ? listaProcedimentos
          : removeProcedimentoAutomatico(
              listaProcedimentos,
              ProcedimentosAutomaticosEscutaInicial.AFERICAO_DE_TEMPERATURA
            )
      },
    },
  },
]

const addProcedimentoAutomatico = (
  allValues: EscutaInicialState,
  existedProcedimentos: MetaArray<ProcedimentoSigtapFieldModel>,
  procedimentosAutomaticos: Array<Procedimento>,
  procedimentoAutomaticoEnum: ProcedimentosAutomaticosEscutaInicial
) => {
  const procedimentoToAdd: ProcedimentoSigtapFieldModel = {
    _id: uuidv4(),
    procedimento: procedimentosAutomaticos.find(({ codigo }) => codigo === procedimentoAutomaticoEnum),
    isAutomatico: true,
  }

  const procedimentosExistentes = resolveValue<ProcedimentoSigtapFieldModel[]>(allValues, existedProcedimentos) || []
  if (
    procedimentoToAdd.procedimento &&
    !procedimentosExistentes.find(({ procedimento }) => procedimento.id === procedimentoToAdd.procedimento.id)
  ) {
    procedimentosExistentes.push(procedimentoToAdd)
  }

  return procedimentosExistentes
}

const removeProcedimentoAutomatico = (
  listaProcedimentos: ProcedimentoSigtapFieldModel[],
  procedimentoAutomaticoEnum: ProcedimentosAutomaticosEscutaInicial
) => {
  return listaProcedimentos.filter((proced) =>
    proced.isAutomatico ? proced.procedimento.codigo !== procedimentoAutomaticoEnum : proced
  )
}
