import { saveAs } from 'file-saver'

export const downloadFile = (blob: Blob, fileName: string) => {
  saveAs(blob, fileName)
}

export const defaultHandleError = (error: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', (e) => {
      reject((e.srcElement as any).result)
    })
    reader.readAsText(error.response.data)
  })
}
